import { createSlice } from "@reduxjs/toolkit";

const setPasswordSlice = createSlice({
	name: "setPassword",
	initialState: { user: null, token: null },
	reducers: {
		setPasswordAction: (state, action) => {
			console.log("9 set password action:", action.payload);
			const { access, refresh } = action.payload;
			state.refresh = refresh;
			state.token = access;
		},
	},
});

export const { setPasswordAction } = setPasswordSlice.actions;

export default setPasswordSlice.reducer;

export const selectCurrentToken = (state) => state;
