import React from "react";
import { Typography } from "@mui/material";

import styles from "./index.module.scss";

const Heading3 = ({ text, extraClass, error, fontType, handleClick }) => {
	const customClass = `${styles.heading3} ${extraClass} ${
		error ? styles.error : ""
	} ${fontType === "semibold" && styles.semibold} `;

	return (
		<Typography component="p" className={customClass} onClick={handleClick}>
			{text}
		</Typography>
	);
};

export default Heading3;
