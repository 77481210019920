import { apiSlice } from "../../app/api/apiSlice";

export const getUserStateLicenseSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUserStateLicense: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/tpo/state_license/${credentials.brokerId}/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetUserStateLicenseMutation } = getUserStateLicenseSlice;
