import { apiSlice } from "../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateLoan: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/update/${credentials?.loanGuid}/`,
				method: "POST",
				body: credentials?.loanData,
			}),
		}),
	}),
});

export const { useUpdateLoanMutation } = applicationApiSlice;
