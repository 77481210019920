import { apiSlice } from "../../../app/api/apiSlice";

export const removeUserApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		removeUser: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/admin/aeam_users/${credentials?.email}/`,
				method: "DELETE",
			}),
		}),
	}),
});

export const { useRemoveUserMutation } = removeUserApiSlice;
