import React, { useState } from "react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// mui components
import { InputLabel, Stack } from "@mui/material";

// api slice
import { useCreateUserMutation } from "../../../../features/admin/create-user/createUserApiSlice";

// custom components
import OutlinedTextInput from "../../../custom-input/outlined-text-input";
import SecondarySelect from "../../../custom-select/secondary-select";
import PrimaryButton from "../../../buttons/primary-button";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Heading6 from "../../../typography/heading-06";

// custom styles
import styles from "./index.module.scss";

const AddUserDrawer = ({
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	setProcessingText,
	handleOpenSuccessModal,
	setSuccessText,
	handleOpenErrorModal,
	setErrorText,
	// custom prop
	setRedirect,
}) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [userRole, setUserRole] = useState("");
	const [email, setEmail] = useState("");

	const userRoleList = [
		{ label: "Account Executive", value: "AE" },
		{ label: "Account Manager", value: "AM" },
	];

	const [createUser] = useCreateUserMutation();

	const handleCancel = () => {
		console.log("50 cancel clicked.");
		setFirstName("");
		setLastName("");
		setUserRole("");
		setEmail("");
	};

	const handleAddUser = async () => {
		console.log("315 running ");

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Creating new user");

		// dispatch API and open modal if successful
		try {
			var formData = new FormData();
			formData.append("email", email);
			formData.append("first_name", firstName);
			formData.append("last_name", lastName);
			formData.append("role", userRole);

			const userData = await createUser({
				formData,
			}).unwrap();

			console.log("310 file upload res:", userData);

			handleCloseProcessingModal();

			setSuccessText(`${email} have been added as ${userRole}`);
			setRedirect("admin");
			handleOpenSuccessModal();

			console.log("70 all files attached");
		} catch (err) {
			console.log("95 err:", err);

			handleCloseProcessingModal();
			setErrorText(err?.data?.data || "User already exists.");
			handleOpenErrorModal();
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Add new AE/AM"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.drawerBodyContainer}>
				{/* first name */}
				<Stack direction="row" className={styles.nameInputContainer}>
					<Stack direction="column" className={styles.inputContainer}>
						<Stack direction="row" className={styles.folderContainer}>
							<InputLabel htmlFor="input-first-name">
								<Heading6
									text="First Name"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>
						</Stack>

						<OutlinedTextInput
							id="input-first-name"
							type="text"
							placeholder="First Name"
							value={firstName}
							handleChange={(event) => setFirstName(event.target.value)}
						/>
					</Stack>
					{/* last name */}
					<Stack direction="column" className={styles.inputContainer}>
						<Stack direction="row" className={styles.folderContainer}>
							<InputLabel htmlFor="input-last-name">
								<Heading6
									text="Last Name"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>
						</Stack>

						<OutlinedTextInput
							id="input-last-name"
							type="text"
							placeholder="Last Name"
							value={lastName}
							handleChange={(event) => setLastName(event.target.value)}
						/>
					</Stack>
				</Stack>

				{/* user role */}
				<Stack direction="column" className={styles.inputContainer}>
					<Stack direction="row" className={styles.folderContainer}>
						<InputLabel htmlFor="input-user-type">
							<Heading6
								text="User Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>
					</Stack>

					<SecondarySelect
						displayEmpty={true}
						value={userRole || ""}
						handleChange={(event) => setUserRole(event.target.value)}
						options={userRoleList}
					/>
				</Stack>

				{/* email */}
				<Stack direction="column" className={styles.inputContainer}>
					<Stack direction="row" className={styles.folderContainer}>
						<InputLabel htmlFor="input-email">
							<Heading6
								text="Email"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>
					</Stack>

					<OutlinedTextInput
						id="input-email"
						type="text"
						placeholder="Email"
						value={email}
						handleChange={(event) => setEmail(event.target.value)}
					/>
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.drawerFooterContainer}
				>
					<SecondaryButton
						text="Reset"
						extraClass={styles.cancelBtn}
						extraTextClass={styles.cancelText}
						onClick={handleCancel}
						disabled={
							firstName === "" &&
							lastName === "" &&
							userRole === "" &&
							email === ""
						}
					/>
					<PrimaryButton
						text="Add AE/AM"
						onClick={handleAddUser}
						disabled={
							firstName === "" ||
							lastName === "" ||
							userRole === "" ||
							email === ""
						}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default AddUserDrawer;
