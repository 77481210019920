import { apiSlice } from "../../app/api/apiSlice";

export const deleteRealEstateApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		deleteRealEstate: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/delete_reo_property/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useDeleteRealEstateMutation } = deleteRealEstateApiSlice;
