import React, { useEffect, useState } from "react";

// mui components
import { Stack, Tab, Tabs } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import TabPanel from "../../../tab-panel";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Heading4 from "../../../typography/heading-04";
import Heading5 from "../../../typography/heading-05";
import Heading6 from "../../../typography/heading-06";

// helper utils
import { formatCurrency } from "../../../../utils/format-currency";

// custom styles
import styles from "./index.module.scss";

const ViewLinkedLiabilities = ({
	handleCloseDrawer,
	// custom prop
	selectedTab,
	liabilitiesData,
	selectedRealEstate,
	selectedRealEstateData,
}) => {
	const [linkedLiabilities, setlinkedLiabilities] = useState([]);
	const [tabValue, setTabValue] = useState(0);

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		console.log("40 liabilities data:", liabilitiesData[selectedTab]);

		console.log("40 selected real estate:", selectedRealEstate);
		console.log("40 selected real estate data:", selectedRealEstateData);

		if (
			liabilitiesData[selectedTab] !== undefined &&
			liabilitiesData[selectedTab]?.length > 0
		) {
			let filteredLiabilities = liabilitiesData[selectedTab]?.filter(
				(row) => row?.reoId === selectedRealEstate
			);

			console.log("45 filtered:", filteredLiabilities);
			setlinkedLiabilities(filteredLiabilities);
		}
	}, [liabilitiesData]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="View Linked Liabilities"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.drawerBodyContainer}>
				{linkedLiabilities?.length > 0 ? (
					<Stack direction="column" className={styles.tabContainer}>
						<Tabs
							value={tabValue}
							onChange={handleChangeTab}
							aria-label="view linked liabilities tabs"
							variant="scrollable"
							scrollButtons="auto"
							TabIndicatorProps={{
								style: {
									backgroundColor: "#76D99E",
								},
							}}
						>
							{linkedLiabilities?.map((item, index) => (
								<Tab
									key={index}
									className={styles.tabBtnContainer}
									label={
										<Stack direction="row" className={styles.tabLabelContainer}>
											<Heading6
												text={`Liability ${index + 1}`}
												fontType="semibold"
												extraClass={
													tabValue === index
														? styles.activeTabText
														: styles.tabText
												}
											/>
										</Stack>
									}
									{...a11yProps(index)}
								/>
							))}
						</Tabs>

						{linkedLiabilities?.map((item, index) => (
							<TabPanel
								value={tabValue}
								key={index}
								index={index}
								className={tabValue === index ? styles.tabPanelContainer : ""}
							>
								<Stack direction="column" className={styles.tabPanelBody}>
									{/* lien amount */}
									<Stack direction="row" className={styles.tabPanelRow}>
										<Heading5
											text="Lien Amount"
											fontType="semibold"
											extraClass={styles.tabPanelText}
										/>

										<Heading5
											text={formatCurrency.format(
												Number(item?.unpaidBalanceAmount || 0)
											)}
											fontType="semibold"
											extraClass={styles.tabPanelValue}
										/>
									</Stack>

									{/* lien holder */}
									<Stack direction="row" className={styles.tabPanelRow}>
										<Heading5
											text="Lien Holder"
											fontType="semibold"
											extraClass={styles.tabPanelText}
										/>

										<Heading5
											text={item?.holderName}
											fontType="semibold"
											extraClass={styles.tabPanelValue}
										/>
									</Stack>

									{/* monthly payment */}
									<Stack direction="row" className={styles.tabPanelRow}>
										<Heading5
											text="Monthly Payment"
											fontType="semibold"
											extraClass={styles.tabPanelText}
										/>

										<Heading5
											text={formatCurrency.format(
												Number(item?.monthlyPaymentAmount || 0)
											)}
											fontType="semibold"
											extraClass={styles.tabPanelValue}
										/>
									</Stack>

									{/* monthly taxes */}
									<Stack direction="row" className={styles.tabPanelRow}>
										<Heading5
											text="Monthly Insurance, Taxes and HOA"
											fontType="semibold"
											extraClass={styles.tabPanelText}
										/>

										<Heading5
											text={formatCurrency.format(
												Number(
													selectedRealEstateData?.maintenanceExpenseAmount || 0
												)
											)}
											fontType="semibold"
											extraClass={styles.tabPanelValue}
										/>
									</Stack>

									{/* monthly rent */}
									<Stack direction="row" className={styles.tabPanelRow}>
										<Heading5
											text="Current or Estimated Monthly Rent"
											fontType="semibold"
											extraClass={styles.tabPanelText}
										/>

										<Heading5
											text={formatCurrency.format(
												Number(
													selectedRealEstateData?.rentalIncomeGrossAmount || 0
												)
											)}
											fontType="semibold"
											extraClass={styles.tabPanelValue}
										/>
									</Stack>
								</Stack>
							</TabPanel>
						))}
					</Stack>
				) : (
					<Stack>
						<Heading4 text="No liabilities linked to this real estate property." />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default ViewLinkedLiabilities;
