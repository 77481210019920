import { isEmptyOrUndefined } from "../filter-value";

export const validateDemographicInformation = (
	borrowerPairData,
	borrowerDemographicInfo
) => {
	let error = false;

	const demographicInfoNeedValidation = (index) => {
		return (
			index % 2 === 0 || !isEmptyOrUndefined(borrowerPairData[index]?.firstName)
		);
	};

	for (let i = 0; i < borrowerDemographicInfo.length; i++) {
		const currentInfo = borrowerDemographicInfo[i];
		if (demographicInfoNeedValidation(i)) {
			// ethnicity validation
			if (
				currentInfo["hmdaEthnicityHispanicLatinoIndicator"] !== true &&
				currentInfo["hmdaEthnicityNotHispanicLatinoIndicator"] !== true &&
				currentInfo["hmdaEthnicityDoNotWishIndicator"] !== true
			) {
				console.log("Error: no ethnicity selected for i:: ", i);
				return true;
			}

			// race validation
			if (
				currentInfo["hmdaAmericanIndianIndicator"] !== true &&
				currentInfo["hmdaAfricanAmericanIndicator"] !== true &&
				currentInfo["hmdaAsianIndicator"] !== true &&
				currentInfo["hmdaPacificIslanderIndicator"] !== true &&
				currentInfo["hmdaWhiteIndicator"] !== true &&
				currentInfo["hmdaRaceDoNotWishProvideIndicator"] !== true
			) {
				console.log("Error: no race selected for i:: ", i);
				return true;
			}

			// sex validation
			if (
				currentInfo["hmdaGendertypeFemaleIndicator"] !== true &&
				currentInfo["hmdaGendertypeMaleIndicator"] !== true &&
				currentInfo["hmdaGendertypeDoNotWishIndicator"] !== true
			) {
				console.log("Error: no sex selected for i:: ", i);
				return true;
			}
		}
	}

	return error;
};
