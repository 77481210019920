import { apiSlice } from "../../../app/api/apiSlice";

export const documentRepositoryApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		documentRepository: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/admin/upload_resource_document/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useDocumentRepositoryMutation } = documentRepositoryApiSlice;
