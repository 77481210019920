export const filterValue = (fieldData, fieldId) => {
	if (Array.isArray(fieldData)) {
		var result = fieldData?.find((obj) => obj?.fieldId === fieldId);
		return result?.value || "";
	} else {
		return "";
	}
};

// check if a string is empty or undefined
export const isEmptyOrUndefined = (string) => {
	string =
		string || string === 0 || string === false ? string.toString() : string;
	return !string || string.trim().length === 0;
};

export const isNotNullAndUndefinedAndEmpty = (value) => {
	return value !== null && value !== undefined && value !== "";
};

export const isNullOrUndefinedOrEmpty = (value) => {
	return value === null || value === undefined || value === "";
};

export const findFieldValue = (fieldValues, fieldId) => {
	return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
};
