import { apiSlice } from "../../app/api/apiSlice";

export const createCalyxLoanApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createCalyxLoan: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/create_calyx_loan/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useCreateCalyxLoanMutation } = createCalyxLoanApiSlice;
