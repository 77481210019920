import { isEmptyOrUndefined } from "../filter-value";

// This functions checks if a given address needs validation as if its a coBorrower
// address does it have a corresponding coBorrower first name?
// And we can add more conditions in future as requirements changes.
const doesAddressNeedsValidation = (address, borrowerPairData, i) => {
	const coBorrowerPairIndex = Math.floor(i / 4) * 2 + 1;

	if (
		address["applicantType"] === "Borrower" ||
		(address["applicantType"] === "CoBorrower" &&
			!isEmptyOrUndefined(borrowerPairData[coBorrowerPairIndex]?.firstName))
	) {
		return true;
	}
	return false;
};

export const validateContactInformation = (
	borrowerPairData,
	borrowerResidences
) => {
	let error = false;

	// These are the mandatory fields which are not contingent or must be present if address needs validation.
	const mandatoryAddressFields = [
		"uRLA2020StreetAddress",
		"addressCity",
		"addressPostalCode",
		"addressState",
	];

	for (let i = 0; i < borrowerResidences.length; i++) {
		const address = borrowerResidences[i];

		console.log("35 address:", address);

		// check only if its borrower address or a co-borrower name exists
		if (doesAddressNeedsValidation(address, borrowerPairData, i)) {
			// loop over all mandatory fields one by one for each address which needs validation to check if its present or not.

			if (address?.residencyType !== "Prior") {
				// run if address is Current or Mailing
				for (let mandatoryField of mandatoryAddressFields) {
					error ||= isEmptyOrUndefined(address[mandatoryField]);
					if (error) {
						console.log("Error: ", mandatoryField, address);
						return true;
					}
				}

				if (address["foreignAddressIndicator"]) {
					error ||=
						isEmptyOrUndefined(address["country"]) ||
						address["country"] === "US";
					if (error) {
						console.log("Error: ", "country", address);
						return true;
					}
				}

				if (address["residencyType"] === "Current") {
					if (
						isEmptyOrUndefined(address["residencyBasisType"]) ||
						isEmptyOrUndefined(address["durationTermYears"]) ||
						(address["durationTermYears"] === 0 &&
							(isEmptyOrUndefined(address["durationTermMonths"]) ||
								address["durationTermMonths"] === 0))
					) {
						return true;
					} else if (
						address["residencyBasisType"] === "Rent" &&
						isEmptyOrUndefined(address["rent"])
					) {
						return true;
					}
				}
			} else if (
				address?.residencyType === "Prior" &&
				borrowerResidences[i - 1]?.durationTermYears < 2
			) {
				// run if address is Prior but the Current address "Living here for:" is less than 2 years
				for (let mandatoryField of mandatoryAddressFields) {
					error ||= isEmptyOrUndefined(address[mandatoryField]);
					if (error) {
						console.log("Error: ", mandatoryField, address);
						return true;
					}
				}

				if (address["foreignAddressIndicator"]) {
					error ||=
						isEmptyOrUndefined(address["country"]) ||
						address["country"] === "US";
					if (error) {
						console.log("Error: ", "country", address);
						return true;
					}
				}

				if (address["residencyType"] === "Current") {
					if (
						isEmptyOrUndefined(address["residencyBasisType"]) ||
						isEmptyOrUndefined(address["durationTermYears"]) ||
						(address["durationTermYears"] === 0 &&
							(isEmptyOrUndefined(address["durationTermMonths"]) ||
								address["durationTermMonths"] === 0))
					) {
						return true;
					} else if (
						address["residencyBasisType"] === "Rent" &&
						isEmptyOrUndefined(address["rent"])
					) {
						return true;
					}
				}
			}
		}
	}
	return error;
};
