import { apiSlice } from "../../app/api/apiSlice";

export const getBorrowerPairSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBorrowerPair: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/loan/borrower_pair/${credentials.loanGuid}/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetBorrowerPairMutation } = getBorrowerPairSlice;
