import { apiSlice } from "../../app/api/apiSlice";

export const fieldApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		field: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/field_values/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useFieldMutation } = fieldApiSlice;
