import { apiSlice } from "../../../app/api/apiSlice";

export const resetOrganizationUsersPasswordApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		resetOrganizationUsersPassword: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/tpo/org_users_reset_password/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useResetOrganizationUsersPasswordMutation } =
	resetOrganizationUsersPasswordApiSlice;
