import { createSlice } from "@reduxjs/toolkit";

const sameAsCurrentAddressSlice = createSlice({
	name: "sameAsCurrentAddress",
	initialState: { sameAsCurrentAddress: null },
	reducers: {
		setSameAsCurrentAddressReducer: (state, action) => {
			const data = action.payload;
			console.log("9 action data:", data);
			state.sameAsCurrentAddress = data;
		},
	},
});

export const { setSameAsCurrentAddressReducer } =
	sameAsCurrentAddressSlice.actions;

export default sameAsCurrentAddressSlice.reducer;

export const selectSameAsCurrentAddress = (state) =>
	state.sameAsCurrentAddress.sameAsCurrentAddress;
