import { apiSlice } from "../../app/api/apiSlice";

export const uploadAttachmentDocumentApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		uploadAttachmentDocument: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/upload_attachment_document/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useUploadAttachmentDocumentMutation } =
	uploadAttachmentDocumentApiSlice;
