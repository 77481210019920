import React, { useEffect, useState } from "react";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import MaskedInput from "../../custom-input/masked-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomCheckbox from "../../custom-checkbox";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";
import Body2 from "../../typography/body-02";

// helper utils
import {
	booleanOptions,
	propertyJointOptions,
	propertyTypeOptions,
} from "../../../utils/select-options";
import {
	isEmptyOrUndefined,
	isNullOrUndefinedOrEmpty,
} from "../../../utils/filter-value";

// custom styles
import styles from "./index.module.scss";

const DeclarationInfo = ({
	declarationIndex,
	data,
	setData,
	coBorrower,
	disabled,
	error,
	coBorrowerName,
}) => {
	const [borrowerAmount, setBorrowerAmount] = useState("");
	const [coBorrowerAmount, setCoBorrowerAmount] = useState("");

	useEffect(() => {
		// set use state amounts from data
		if (coBorrower) {
			console.log("28 dec bo index:", declarationIndex - 1);
			setBorrowerAmount(
				data[declarationIndex - 1]?.undisclosedBorrowedFundsAmount || ""
			);

			console.log("28 dec co index:", declarationIndex);
			setCoBorrowerAmount(
				data[declarationIndex]?.undisclosedBorrowedFundsAmount || ""
			);
		} else {
			console.log("28 dec bo index:", declarationIndex);
			setBorrowerAmount(
				data[declarationIndex - 1]?.undisclosedBorrowedFundsAmount || ""
			);
		}
	}, [data]);

	const handleBorrowerAmount = (value) => {
		setBorrowerAmount(value);

		setData(
			data.map((data, index) => {
				if (declarationIndex - 1 === index) {
					return { ...data, undisclosedBorrowedFundsAmount: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleCoBorrowerAmount = (value) => {
		setCoBorrowerAmount(value);

		setData(
			data.map((data, index) => {
				if (declarationIndex === index) {
					return { ...data, undisclosedBorrowedFundsAmount: value };
				} else {
					return data;
				}
			})
		);
	};

	const handleDeclarationData = (borrowerType, key, value) => {
		console.log("30 borrower type:", borrowerType);
		console.log("30 borrower key:", key);
		console.log("30 borrower value:", value);

		setData(
			data.map((data, index) => {
				console.log("70 dec index:", declarationIndex);
				console.log("70 index:", index);

				let rowIndex;

				if (borrowerType === "borrower") {
					rowIndex = declarationIndex - 1;
				} else {
					rowIndex = declarationIndex;
				}

				if (rowIndex === index) {
					return {
						...data,
						[key]: value === true ? true : value === false ? false : value,
					};
				} else {
					return data;
				}
			})
		);
	};

	const noneBIChaptersSelected = (index) => {
		if (
			(isEmptyOrUndefined(data[index]["bankruptcyIndicatorChapterSeven"]) ||
				data[index]["bankruptcyIndicatorChapterSeven"] == false) &&
			(isEmptyOrUndefined(data[index]["bankruptcyIndicatorChapterEleven"]) ||
				data[index]["bankruptcyIndicatorChapterEleven"] == false) &&
			(isEmptyOrUndefined(data[index]["bankruptcyIndicatorChapterTwelve"]) ||
				data[index]["bankruptcyIndicatorChapterTwelve"] == false) &&
			(isEmptyOrUndefined(data[index]["bankruptcyIndicatorChapterThirteen"]) ||
				data[index]["bankruptcyIndicatorChapterThirteen"] == false)
		) {
			return true;
		}
		return false;
	};

	return (
		<Stack direction="column" className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Declaration Information" fontType="semibold" />

				<Body3 text="Provide details about the borrower and co-borrower" />
			</Stack>

			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}></Stack>
					<Stack direction="row" className={styles.optionsContainer}>
						<Heading5 text="Borrower" extraClass={styles.declarationInput} />
						<Heading5 text="Co Borrower" extraClass={styles.declarationInput} />
					</Stack>
				</Stack>
			</Stack>

			{/* question A */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="A." />
						<Body2 text="Will you occupy the property as your primary residence?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-occupy-intent"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={data[declarationIndex - 1]?.intentToOccupyIndicator}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"intentToOccupyIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.intentToOccupyIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-occupy-intent"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.intentToOccupyIndicator}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"intentToOccupyIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]?.intentToOccupyIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.rowContainer}>
					<Stack
						direction="row"
						className={styles.questionContainer + " " + styles.subQuestion}
					>
						<Body2 text="If YES, have you had an ownership interest in another property in the last three years?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-homeowner-three-years"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]?.homeownerPastThreeYearsIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"homeownerPastThreeYearsIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								data[declarationIndex - 1]?.intentToOccupyIndicator &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.homeownerPastThreeYearsIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-homeowner-three-years"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.homeownerPastThreeYearsIndicator}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"homeownerPastThreeYearsIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									data[declarationIndex]?.intentToOccupyIndicator &&
									isEmptyOrUndefined(
										data[declarationIndex]?.homeownerPastThreeYearsIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.rowContainer}>
					<Stack
						direction="row"
						className={styles.questionContainer + " " + styles.subQuestion}
					>
						<Body2 text="If YES, complete (1) and (2) below:" />
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.rowContainer}>
					<Stack
						direction="row"
						className={styles.questionContainer + " " + styles.subQuestion}
					>
						<Body2 text="(1)  What type of property did you own: Primary Residence (PR), Second Home (SH), or Investment Property (IP)?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-property-usage"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={data[declarationIndex - 1]?.priorPropertyUsageType}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"priorPropertyUsageType",
									event.target.value
								)
							}
							options={propertyTypeOptions}
							disabled={disabled}
							error={
								error &&
								data[declarationIndex - 1]?.homeownerPastThreeYearsIndicator &&
								isNullOrUndefinedOrEmpty(
									data[declarationIndex - 1]?.priorPropertyUsageType
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-property-usage"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.priorPropertyUsageType}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"priorPropertyUsageType",
										event.target.value
									)
								}
								options={propertyTypeOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									data[declarationIndex]?.homeownerPastThreeYearsIndicator &&
									isNullOrUndefinedOrEmpty(
										data[declarationIndex]?.priorPropertyUsageType
									)
								}
							/>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.rowContainer}>
					<Stack
						direction="row"
						className={styles.questionContainer + " " + styles.subQuestion}
					>
						<Body2 text="(2)  How did you hold title to the property: by yourself (S), jointly with your spouse (SP), or jointly with another person (O)?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-prior-property"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={data[declarationIndex - 1]?.priorPropertyTitleType}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"priorPropertyTitleType",
									event.target.value
								)
							}
							options={propertyJointOptions}
							disabled={disabled}
							error={
								error &&
								data[declarationIndex - 1]?.homeownerPastThreeYearsIndicator &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.priorPropertyTitleType
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-prior-property"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.priorPropertyTitleType}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"priorPropertyTitleType",
										event.target.value
									)
								}
								options={propertyJointOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									data[declarationIndex]?.homeownerPastThreeYearsIndicator &&
									isEmptyOrUndefined(
										data[declarationIndex]?.priorPropertyTitleType
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question B */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="B." />
						<Body2 text="If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-seller-special-relationship"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]
									?.specialBorrowerSellerRelationshipIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"specialBorrowerSellerRelationshipIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]
										?.specialBorrowerSellerRelationshipIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-seller-special-relationship"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]
										?.specialBorrowerSellerRelationshipIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"specialBorrowerSellerRelationshipIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]
											?.specialBorrowerSellerRelationshipIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question C */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="C." />
						<Body2 text="Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor that you have not disclosed on this loan application?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-undisclosed-funds"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]?.undisclosedBorrowedFundsIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"undisclosedBorrowedFundsIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.undisclosedBorrowedFundsIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-undisclosed-funds"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]?.undisclosedBorrowedFundsIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"undisclosedBorrowedFundsIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]?.undisclosedBorrowedFundsIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.rowContainer}>
					<Stack
						direction="row"
						className={styles.questionContainer + " " + styles.subQuestion}
					>
						<Body2 text="If YES, what is the amount of this money?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower amount */}
						<MaskedInput
							id="borrower-undisclosed-funds"
							extraClass={styles.declarationInput}
							placeholder="$"
							type="currency"
							value={borrowerAmount}
							onInputChange={handleBorrowerAmount}
							disabled={disabled}
							error={
								error &&
								data[declarationIndex - 1]?.undisclosedBorrowedFundsIndicator ==
									true &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.undisclosedBorrowedFundsAmount
								)
							}
						/>
						{/* co-borrower amount */}
						{coBorrower && (
							<MaskedInput
								id="coborrower-undisclosed-funds"
								extraClass={styles.declarationInput}
								placeholder="$"
								type="currency"
								value={coBorrowerAmount}
								onInputChange={handleCoBorrowerAmount}
								disabled={disabled}
								error={
									error &&
									coBorrowerName &&
									data[declarationIndex]?.undisclosedBorrowedFundsIndicator ==
										true &&
									isEmptyOrUndefined(
										data[declarationIndex]?.undisclosedBorrowedFundsAmount
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question D */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="D." />
						<Body2 text="Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-undisclosed-mortgage"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]
									?.undisclosedMortgageApplicationIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"undisclosedMortgageApplicationIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]
										?.undisclosedMortgageApplicationIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-undisclosed-mortgage"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]
										?.undisclosedMortgageApplicationIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"undisclosedMortgageApplicationIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]
											?.undisclosedMortgageApplicationIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.rowContainer}>
					<Stack
						direction="row"
						className={styles.questionContainer + " " + styles.subQuestion}
					>
						<Body2 text="Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-undisclosed-credit"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]
									?.undisclosedCreditApplicationIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"undisclosedCreditApplicationIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]
										?.undisclosedCreditApplicationIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-undisclosed-credit"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]?.undisclosedCreditApplicationIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"undisclosedCreditApplicationIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]
											?.undisclosedCreditApplicationIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question E */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="E." />
						<Body2 text="Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-property-clean-energy"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]
									?.propertyProposedCleanEnergyLienIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"propertyProposedCleanEnergyLienIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]
										?.propertyProposedCleanEnergyLienIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-property-clean-energy"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]
										?.propertyProposedCleanEnergyLienIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"propertyProposedCleanEnergyLienIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]
											?.propertyProposedCleanEnergyLienIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question F */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="F." />
						<Body2 text="Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-undisclosed-signer"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]?.undisclosedComakerOfNoteIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"undisclosedComakerOfNoteIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.undisclosedComakerOfNoteIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-undisclosed-signer"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]?.undisclosedComakerOfNoteIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"undisclosedComakerOfNoteIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]?.undisclosedComakerOfNoteIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question G */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="G." />
						<Body2 text="Are there any outstanding judgements against you?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-outstanding-judgements"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={data[declarationIndex - 1]?.outstandingJudgementsIndicator}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"outstandingJudgementsIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.outstandingJudgementsIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-outstanding-judgements"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.outstandingJudgementsIndicator}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"outstandingJudgementsIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]?.outstandingJudgementsIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question H */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="H." />
						<Body2 text="Are you currently delinquent or in default on a federal debt?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-delinquent-indicator"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]?.presentlyDelinquentIndicatorURLA
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"presentlyDelinquentIndicatorURLA",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.presentlyDelinquentIndicatorURLA
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-delinquent-indicator"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.presentlyDelinquentIndicatorURLA}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"presentlyDelinquentIndicatorURLA",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]?.presentlyDelinquentIndicatorURLA
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question I */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="I." />
						<Body2 text="Are you a party to a lawsuit in which you potentially have any personal financial liability?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-party-lawsuit"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={data[declarationIndex - 1]?.partyToLawsuitIndicatorURLA}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"partyToLawsuitIndicatorURLA",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.partyToLawsuitIndicatorURLA
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-party-lawsuit"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.partyToLawsuitIndicatorURLA}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"partyToLawsuitIndicatorURLA",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]?.partyToLawsuitIndicatorURLA
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question J */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="J." />
						<Body2 text="Have you conveyed title to any property in lieu of foreclosure in the past 7 years?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-property-deed"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]
									?.priorPropertyDeedInLieuConveyedIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"priorPropertyDeedInLieuConveyedIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]
										?.priorPropertyDeedInLieuConveyedIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-property-deed"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]
										?.priorPropertyDeedInLieuConveyedIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"priorPropertyDeedInLieuConveyedIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]
											?.priorPropertyDeedInLieuConveyedIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question K */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="K." />
						<Body2 text="Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-property-short-sale"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]
									?.priorPropertyShortSaleCompletedIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"priorPropertyShortSaleCompletedIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]
										?.priorPropertyShortSaleCompletedIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-property-short-sale"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]
										?.priorPropertyShortSaleCompletedIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"priorPropertyShortSaleCompletedIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]
											?.priorPropertyShortSaleCompletedIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question L */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="L." />
						<Body2 text="Have you had property foreclosed upon in the last 7 years?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-property-foreclosure"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={
								data[declarationIndex - 1]
									?.priorPropertyForeclosureCompletedIndicator
							}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"priorPropertyForeclosureCompletedIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]
										?.priorPropertyForeclosureCompletedIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-property-foreclosure"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={
									data[declarationIndex]
										?.priorPropertyForeclosureCompletedIndicator
								}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"priorPropertyForeclosureCompletedIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]
											?.priorPropertyForeclosureCompletedIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>
			</Stack>

			{/* question M */}
			<Stack direction="column" className={styles.sectionContainer}>
				<Stack direction="row" className={styles.rowContainer}>
					<Stack direction="row" className={styles.questionContainer}>
						<Body2 text="M." />
						<Body2 text="Have you declared bankruptcy within the past 7 years?" />
					</Stack>

					<Stack direction="row" className={styles.optionsContainer}>
						{/* borrower select */}
						<SecondarySelect
							id="borrower-bankruptcy"
							extraClass={styles.declarationInput}
							displayEmpty={true}
							value={data[declarationIndex - 1]?.bankruptcyIndicator}
							handleChange={(event) =>
								handleDeclarationData(
									"borrower",
									"bankruptcyIndicator",
									event.target.value
								)
							}
							options={booleanOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									data[declarationIndex - 1]?.bankruptcyIndicator
								)
							}
						/>
						{/* co-borrower select */}
						{coBorrower && (
							<SecondarySelect
								id="coborrower-bankruptcy"
								extraClass={styles.declarationInput}
								displayEmpty={true}
								value={data[declarationIndex]?.bankruptcyIndicator}
								handleChange={(event) =>
									handleDeclarationData(
										"coBorrower",
										"bankruptcyIndicator",
										event.target.value
									)
								}
								options={booleanOptions}
								disabled={disabled || !coBorrower}
								error={
									error &&
									coBorrowerName &&
									isEmptyOrUndefined(
										data[declarationIndex]?.bankruptcyIndicator
									)
								}
							/>
						)}
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.rowContainer}>
					<Stack
						direction="row"
						className={styles.questionContainer + " " + styles.subQuestion}
					>
						<Body2 text="If YES, identify the type(s) of bankruptcy:" />
					</Stack>

					<Stack direction="column">
						<Stack direction="row" className={styles.optionsContainer}>
							{/* borrower chapter 7 */}
							<Box className={styles.declarationInput}>
								<CustomCheckbox
									id="borrower-chapter-seven-checkbox"
									checked={
										data[declarationIndex - 1]
											?.bankruptcyIndicatorChapterSeven || false
									}
									handleChange={() =>
										handleDeclarationData(
											"borrower",
											"bankruptcyIndicatorChapterSeven",
											!data[declarationIndex - 1]
												?.bankruptcyIndicatorChapterSeven
										)
									}
									label="Chapter 7"
									disabled={
										disabled ||
										(data[declarationIndex - 1]?.bankruptcyIndicator !==
											"true" &&
											data[declarationIndex - 1]?.bankruptcyIndicator !== true)
									}
									error={
										error &&
										data[declarationIndex - 1]?.bankruptcyIndicator == true &&
										noneBIChaptersSelected(declarationIndex - 1)
									}
								/>
							</Box>

							{/* co-borrower chapter 7 */}
							{coBorrower && (
								<Box className={styles.declarationInput}>
									<CustomCheckbox
										id="coborrower-chapter-seven-checkbox"
										checked={
											data[declarationIndex]?.bankruptcyIndicatorChapterSeven ||
											false
										}
										handleChange={() =>
											handleDeclarationData(
												"coBorrower",
												"bankruptcyIndicatorChapterSeven",
												!data[declarationIndex]?.bankruptcyIndicatorChapterSeven
											)
										}
										label="Chapter 7"
										disabled={
											disabled ||
											(data[declarationIndex]?.bankruptcyIndicator !== "true" &&
												data[declarationIndex]?.bankruptcyIndicator !== true)
										}
										error={
											error &&
											coBorrowerName &&
											data[declarationIndex]?.bankruptcyIndicator == true &&
											noneBIChaptersSelected(declarationIndex)
										}
									/>
								</Box>
							)}
						</Stack>

						<Stack direction="row" className={styles.optionsContainer}>
							{/* borrower chapter 11 */}
							<Box className={styles.declarationInput}>
								<CustomCheckbox
									id="borrower-chapter-eleven-checkbox"
									checked={
										data[declarationIndex - 1]
											?.bankruptcyIndicatorChapterEleven || false
									}
									handleChange={() =>
										handleDeclarationData(
											"borrower",
											"bankruptcyIndicatorChapterEleven",
											!data[declarationIndex - 1]
												?.bankruptcyIndicatorChapterEleven
										)
									}
									label="Chapter 11"
									disabled={
										disabled ||
										(data[declarationIndex - 1]?.bankruptcyIndicator !==
											"true" &&
											data[declarationIndex - 1]?.bankruptcyIndicator !== true)
									}
									error={
										error &&
										data[declarationIndex - 1]?.bankruptcyIndicator == true &&
										noneBIChaptersSelected(declarationIndex - 1)
									}
								/>
							</Box>

							{/* co-borrower chapter 11 */}
							{coBorrower && (
								<Box className={styles.declarationInput}>
									<CustomCheckbox
										id="coborrower-chapter-eleven-checkbox"
										checked={
											data[declarationIndex]
												?.bankruptcyIndicatorChapterEleven || false
										}
										handleChange={() =>
											handleDeclarationData(
												"coBorrower",
												"bankruptcyIndicatorChapterEleven",
												!data[declarationIndex]
													?.bankruptcyIndicatorChapterEleven
											)
										}
										label="Chapter 11"
										disabled={
											disabled ||
											(data[declarationIndex]?.bankruptcyIndicator !== "true" &&
												data[declarationIndex]?.bankruptcyIndicator !== true)
										}
										error={
											error &&
											coBorrowerName &&
											data[declarationIndex]?.bankruptcyIndicator == true &&
											noneBIChaptersSelected(declarationIndex)
										}
									/>
								</Box>
							)}
						</Stack>

						<Stack direction="row" className={styles.optionsContainer}>
							{/* borrower chapter 12 */}
							<Box className={styles.declarationInput}>
								<CustomCheckbox
									id="borrower-chapter-twelve-checkbox"
									checked={
										data[declarationIndex - 1]
											?.bankruptcyIndicatorChapterTwelve || false
									}
									handleChange={() =>
										handleDeclarationData(
											"borrower",
											"bankruptcyIndicatorChapterTwelve",
											!data[declarationIndex - 1]
												?.bankruptcyIndicatorChapterTwelve
										)
									}
									label="Chapter 12"
									disabled={
										disabled ||
										(data[declarationIndex - 1]?.bankruptcyIndicator !==
											"true" &&
											data[declarationIndex - 1]?.bankruptcyIndicator !== true)
									}
									error={
										error &&
										data[declarationIndex - 1]?.bankruptcyIndicator == true &&
										noneBIChaptersSelected(declarationIndex - 1)
									}
								/>
							</Box>

							{/* co-borrower chapter 12 */}
							{coBorrower && (
								<Box className={styles.declarationInput}>
									<CustomCheckbox
										id="coborrower-chapter-twelve-checkbox"
										checked={
											data[declarationIndex]
												?.bankruptcyIndicatorChapterTwelve || false
										}
										handleChange={() =>
											handleDeclarationData(
												"coBorrower",
												"bankruptcyIndicatorChapterTwelve",
												!data[declarationIndex]
													?.bankruptcyIndicatorChapterTwelve
											)
										}
										label="Chapter 12"
										disabled={
											disabled ||
											(data[declarationIndex]?.bankruptcyIndicator !== "true" &&
												data[declarationIndex]?.bankruptcyIndicator !== true)
										}
										error={
											error &&
											coBorrowerName &&
											data[declarationIndex]?.bankruptcyIndicator == true &&
											noneBIChaptersSelected(declarationIndex)
										}
									/>
								</Box>
							)}
						</Stack>

						<Stack direction="row" className={styles.optionsContainer}>
							{/* borrower chapter 13 */}
							<Box className={styles.declarationInput}>
								<CustomCheckbox
									id="borrower-chapter-thirteen-checkbox"
									checked={
										data[declarationIndex - 1]
											?.bankruptcyIndicatorChapterThirteen || false
									}
									handleChange={() =>
										handleDeclarationData(
											"borrower",
											"bankruptcyIndicatorChapterThirteen",
											!data[declarationIndex - 1]
												?.bankruptcyIndicatorChapterThirteen
										)
									}
									label="Chapter 13"
									disabled={
										disabled ||
										(data[declarationIndex - 1]?.bankruptcyIndicator !==
											"true" &&
											data[declarationIndex - 1]?.bankruptcyIndicator !== true)
									}
									error={
										error &&
										data[declarationIndex - 1]?.bankruptcyIndicator == true &&
										noneBIChaptersSelected(declarationIndex - 1)
									}
								/>
							</Box>

							{/* co-borrower chapter 13 */}
							{coBorrower && (
								<Box className={styles.declarationInput}>
									<CustomCheckbox
										id="coborrower-chapter-thirteen-checkbox"
										checked={
											data[declarationIndex]
												?.bankruptcyIndicatorChapterThirteen || false
										}
										handleChange={() =>
											handleDeclarationData(
												"coBorrower",
												"bankruptcyIndicatorChapterThirteen",
												!data[declarationIndex]
													?.bankruptcyIndicatorChapterThirteen
											)
										}
										label="Chapter 13"
										disabled={
											disabled ||
											(data[declarationIndex]?.bankruptcyIndicator !== "true" &&
												data[declarationIndex]?.bankruptcyIndicator !== true)
										}
										error={
											error &&
											coBorrowerName &&
											data[declarationIndex]?.bankruptcyIndicator == true &&
											noneBIChaptersSelected(declarationIndex)
										}
									/>
								</Box>
							)}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<CustomDivider />
		</Stack>
	);
};

export default DeclarationInfo;
