import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui components
import { Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

// api slice
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// reducer selector
import { selectFieldValues } from "../../../features/field-values/fieldValueSlice";
import { getFieldValues } from "../../../app/services/field-values";

// custom components
import Heading3 from "../../typography/heading-03";
import PrimaryButton from "../../buttons/primary-button";
import TertiaryButton from "../../buttons/tertiary-button";
import CustomTable from "../../custom-table";

// custom styles
import styles from "./index.module.scss";

const ChangeRequestCard = ({
	toggleDrawer,
	handleTabChange,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	handleOpenErrorModal,
	setErrorModalText,
}) => {
	const params = useParams();

	const [field] = useFieldMutation();
	const [documentsList, setDocumentsList] = useState([]);

	const fieldValues = useSelector(selectFieldValues);

	const changeRequestColumns = [
		{ id: "request-date", label: "Request Date", minWidth: 320 },
		{ id: "comments", label: "Comments", minWidth: 320 },
		{ id: "status", label: "Status", minWidth: 165 },
		{ id: "requestor", label: "Requestor", minWidth: 165 },
	];

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error requesting change");
	};

	useEffect(() => {
		handleOpenLoadingDataModal();

		getFieldValues({
			loanGuid: params?.id,
			fieldList:
				"CX.BSM.CIC.REQUEST.01.DT,CX.BSM.CIC.REQUEST.02.DT,CX.BSM.CIC.REQUEST.03.DT,CX.BSM.CIC.REQUEST.04.DT,CX.BSM.CIC.REQUEST.05.DT,CX.BSM.CIC.REQUEST.06.DT,CX.BSM.CIC.REQUEST.07.DT,CX.BSM.CIC.REQUEST.08.DT,CX.BSM.CIC.REQUEST.09.DT,CX.BSM.CIC.REQUEST.10.DT,CX.BSM.CIC.REQUEST.01.STATUS,CX.BSM.CIC.REQUEST.02.STATUS,CX.BSM.CIC.REQUEST.03.STATUS,CX.BSM.CIC.REQUEST.04.STATUS,CX.BSM.CIC.REQUEST.05.STATUS,CX.BSM.CIC.REQUEST.06.STATUS,CX.BSM.CIC.REQUEST.07.STATUS,CX.BSM.CIC.REQUEST.08.STATUS,CX.BSM.CIC.REQUEST.09.STATUS,CX.BSM.CIC.REQUEST.10.STATUS,CX.BSM.COC.REQUESTOR.01,CX.BSM.COC.REQUESTOR.02,CX.BSM.COC.REQUESTOR.03,CX.BSM.COC.REQUESTOR.04,CX.BSM.COC.REQUESTOR.05,CX.BSM.COC.REQUESTOR.06,CX.BSM.COC.REQUESTOR.07,CX.BSM.COC.REQUESTOR.08,CX.BSM.COC.REQUESTOR.09,CX.BSM.COC.REQUESTOR.10,CX.BSM.COC.REQ.FRM.TPO.01,CX.BSM.COC.REQ.FRM.TPO.02,CX.BSM.COC.REQ.FRM.TPO.03,CX.BSM.COC.REQ.FRM.TPO.04,CX.BSM.COC.REQ.FRM.TPO.05,CX.BSM.COC.REQ.FRM.TPO.06,CX.BSM.COC.REQ.FRM.TPO.07,CX.BSM.COC.REQ.FRM.TPO.08,CX.BSM.COC.REQ.FRM.TPO.09,CX.BSM.COC.REQ.FRM.TPO.10",
			getFieldValueApi: field,
			handleErrorModal: handleErrorModal,
		});
	}, []);

	const convertFieldArr = (data) => {
		let result = [];
		let objMap = {};

		for (let item of data) {
			let key = item.fieldId;
			let value = item.value;

			let match = key.match(/\.(\d+)(?:\.|$)/);

			if (match) {
				let number = parseInt(match[1]);

				if (!objMap[number]) {
					objMap[number] = {};
				}

				if (key.toLowerCase().includes(".dt")) {
					objMap[number].date = value;
				} else if (key.toLowerCase().includes(".status")) {
					objMap[number].status = value;
				} else if (key.toLowerCase().includes(".req.frm.")) {
					objMap[number].comment = value;
				} else if (key.toLowerCase().includes(".requestor.")) {
					objMap[number].requestor = value;
				}
			}
		}

		for (let number in objMap) {
			let obj = objMap[number];
			if (
				obj.date !== "" ||
				obj.status !== "" ||
				obj.comment !== "" ||
				obj.requestor !== ""
			) {
				result.push({
					date: obj.date || "",
					status: obj.status || "",
					comment: obj.comment || "",
					requestor: obj.requestor || "",
				});
			}
		}

		console.log("120 result:", result);
		setDocumentsList(result);
	};

	useEffect(() => {
		if (fieldValues !== undefined && fieldValues?.length > 0) {
			console.log("40 change request values:", fieldValues);
			convertFieldArr(fieldValues);

			handleCloseLoadingDataModal();
		}
	}, [fieldValues]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Change Requests"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
					<PrimaryButton
						text="Add Change Request"
						startIcon={<AddOutlinedIcon className={styles.primaryBtnIcon} />}
						onClick={(e) => toggleDrawer(e, "change-request")}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				{/* change request table  */}
				<CustomTable
					enableSort={true}
					columnData={changeRequestColumns}
					rowData={documentsList}
					tableType="change-request"
					setSortedData={setDocumentsList}
				/>
			</Stack>
		</Stack>
	);
};

export default ChangeRequestCard;
