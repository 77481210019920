import { apiSlice } from "../../app/api/apiSlice";

export const createOrderServiceApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createOrderService: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/credit_order_service/${credentials.providerId}/`,
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useCreateOrderServiceMutation } =
	createOrderServiceApiSlice;
