import { createSlice } from "@reduxjs/toolkit";

const fieldValues = createSlice({
	name: "fieldValues",
	initialState: {
		data: [],
	},
	reducers: {
		setFieldValues: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setFieldValues } = fieldValues.actions;
export default fieldValues.reducer;

export const selectFieldValues = (state) => state.fieldValues.data;
