import { apiSlice } from "../../app/api/apiSlice";

export const downloadAttachmentApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		downloadAttachment: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/download_attachments/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useDownloadAttachmentMutation } = downloadAttachmentApiSlice;
