import React, { useRef } from "react";
import { IMaskInput } from "react-imask";

import SSNInput from "./components";

import { maskMap } from "./constants";

import styles from "./index.module.scss";

const MaskedInput = ({
	type,
	extraClass,
	fullWidth,
	placeholder,
	onInputChange,
	value,
	disabled,
	error,
	...props
}) => {
	const ref = useRef(null);
	const inputRef = useRef(null);

	const customClass = `${
		disabled
			? styles.maskedInput + " " + styles.disabledMaskedInput
			: styles.maskedInput
	} ${extraClass ? extraClass : ""} ${fullWidth ? styles.fullWidth : ""} ${
		error ? styles.error : ""
	}`;

	if (maskMap[type] && maskMap[type].mask) {
		if (type === "ssn") {
			return (
				<SSNInput
					className={customClass}
					placeholder={placeholder}
					onChange={onInputChange}
					value={value}
					disabled={disabled}
					{...props}
				/>
			);
		}

		return (
			<IMaskInput
				className={customClass}
				mask={maskMap[type].mask}
				value={value ? String(value) : value}
				unmask={true}
				ref={ref}
				inputRef={inputRef}
				onAccept={(_, mask) => onInputChange(mask.unmaskedValue)}
				placeholder={placeholder}
				disabled={disabled}
				{...props}
			/>
		);
	}
	return null;
};

export default MaskedInput;
