import React from "react";

import { Box, CircularProgress } from "@mui/material";

const CustomLoader = (props) => {
	return (
		<Box
			sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
		>
			<CircularProgress {...props} />
		</Box>
	);
};

export default CustomLoader;
