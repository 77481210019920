import { apiSlice } from "../../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createAdminLoan: builder.mutation({
			query: (credentials) => ({
				url: "api/v1/admin/create_impersonated_loan/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useCreateAdminLoanMutation } = applicationApiSlice;
