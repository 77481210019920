import React from "react";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";
import { ErrorOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const AdditionalLoanContact = ({
	additionalContact,
	setAdditionalContact,
	disabled,
	error,
}) => {
	const handlePhone = (value) => {
		setAdditionalContact((prevState) => ({
			...prevState,
			phone: value,
		}));
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text="Additional Loan Contact Information"
					fontType="semibold"
				/>
				<Body3 text="Provide details about the additional loan contact" />
			</Stack>

			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* first name */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="additional-contact-name">
							<Heading6
								text="Name"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id="additional-contact-name"
							placeholder="Name"
							fullWidth={true}
							value={additionalContact?.name}
							handleChange={(event) =>
								setAdditionalContact((prevState) => ({
									...prevState,
									name: event.target.value,
								}))
							}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				{/* contact information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* phone */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="additional-contact-phone">
							<Heading6
								text="Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="additional-contact-phone"
							placeholder="[XXX] XXX-XXXX"
							type="phone"
							fullWidth={true}
							value={additionalContact?.phone}
							onInputChange={handlePhone}
							disabled={disabled}
							error={
								error &&
								additionalContact?.name !== "" &&
								(additionalContact?.phone === undefined ||
									additionalContact?.phone === "")
							}
						/>
					</Stack>

					{/* email */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="additional-contact-email">
							<Heading6
								text="Email"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id="additional-contact-email"
							placeholder="Email"
							fullWidth={true}
							value={additionalContact?.email}
							handleChange={(event) =>
								setAdditionalContact((prevState) => ({
									...prevState,
									email: event.target.value,
								}))
							}
							disabled={disabled}
							error={
								error &&
								additionalContact?.name !== "" &&
								(additionalContact?.email === undefined ||
									additionalContact?.email === "")
							}
							showEndIcon={
								error &&
								additionalContact?.name !== "" &&
								(additionalContact?.email === undefined ||
									additionalContact?.email === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
};

export default AdditionalLoanContact;
