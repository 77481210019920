import { apiSlice } from "../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getOrganizationDetails: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/organization/${credentials?.organizationName}/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetOrganizationDetailsMutation } = applicationApiSlice;
