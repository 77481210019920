import { isEmptyOrUndefined } from "../filter-value";

export const validateMilitaryInformation = (
	borrowerPairData,
	borrowerMilitary
) => {
	let error = false;
	const militaryInfoNeedValidation = (index) => {
		return (
			index % 2 === 0 || !isEmptyOrUndefined(borrowerPairData[index]?.firstName)
		);
	};
	for (let i = 0; i < borrowerMilitary.length; i++) {
		const currentMillitaryInfo = borrowerMilitary[i];
		if (militaryInfoNeedValidation(i)) {
			if (
				isEmptyOrUndefined(
					currentMillitaryInfo["selfDeclaredMilitaryServiceIndicator"]
				)
			) {
				console.log(
					"Military info validation Error found: selfDeclaredMilitaryServiceIndicator undefined ",
					i
				);
				return true;
			}
			if (
				currentMillitaryInfo[
					"selfDeclaredMilitaryServiceIndicator"
				].toString() === true.toString()
			) {
				if (
					(isEmptyOrUndefined(currentMillitaryInfo["activeDuty"]) ||
						currentMillitaryInfo["activeDuty"].toString() ===
							false.toString()) &&
					(isEmptyOrUndefined(
						currentMillitaryInfo["spousalVABenefitsEligibilityIndicator"]
					) ||
						currentMillitaryInfo[
							"spousalVABenefitsEligibilityIndicator"
						].toString() === false.toString()) &&
					(isEmptyOrUndefined(currentMillitaryInfo["veteran"]) ||
						currentMillitaryInfo["veteran"].toString() === false.toString()) &&
					(isEmptyOrUndefined(
						currentMillitaryInfo["reserveNationalGuardReserveActivated"]
					) ||
						currentMillitaryInfo[
							"reserveNationalGuardReserveActivated"
						].toString() === false.toString())
				) {
					console.log(
						"Military info validation Error found: selfDeclaredMilitaryServiceIndicator true, other options none selected ",
						i
					);
					return true;
				}
			}
		}
	}
	return error;
};
