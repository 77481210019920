import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import LoanProgress from "../../components/loan-progress";
import ViewLoanApplication from "./components/borrower-info-step";
import LoanAddressApplication from "../../components/loan-form/loan-address-application";
import CreditReissueApplication from "../../components/loan-form/credit-reissue-application";
import LoanEmploymentApplication from "../../components/loan-form/loan-employment-application";
import LoanAssetsApplication from "../../components/loan-form/loan-assets-application";
import LoanDemographicApplication from "../../components/loan-form/loan-demographic-application";
import ViewLoanTerms from "./components/product-terms";
import UnderwritingSummary from "../../components/loan-form/underwriting-summary";
import LoanPricingApplication from "../../components/loan-form/loan-pricing-application";
import ViewLoanFee from "./components/fee";
import Heading5 from "../../components/typography/heading-05";

// api slices
import { useFieldMutation } from "../../features/field-values/fieldValueApiSlice";

//reducer slices
import { selectUserDetails } from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// mui icons
import { CloseOutlined } from "@mui/icons-material";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";
import NavigationModal from "../../components/modal/navigation-modal";

//helper utils
import { LOAN_CHANNEL_CONSTANTS } from "../../utils/common-constants";
import { selectedUserWithLoanChannel } from "../../utils/common-helpers";

// custom styles
import styles from "./index.module.scss";

const ViewLoan = () => {
	const params = useParams();
	const navigate = useNavigate();

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);
	const [field, { isLoading: fetchingValues }] = useFieldMutation();

	const loanSteps = [
		"Borrower Information",
		"Address",
		"Credit Reissue",
		"Employment and Income",
		"Assets and Liabilities",
		"Declarations and Others",
		"Loan Product and Terms",
		"Underwriting Summary",
		"Loan Pricing",
		`${
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS
				? "Closing Information"
				: "Fees"
		}`,
	];

	const [loaderModal, setLoaderModal] = useState(false);

	const handleOpenLoaderModal = () => {
		setLoaderModal(true);
	};

	const handleCloseLoaderModal = () => {
		setLoaderModal(false);
	};

	const [exitModal, setExitModal] = useState(false);

	const handleOpenExitModal = () => {
		setExitModal(true);
	};

	const handleCloseExitModal = () => {
		setExitModal(false);
	};

	const handleConfirmExit = () => {
		navigate(`/loan-summary/${params?.id}`);
	};

	const [navigationModalText, setNavigationModalText] = useState("");
	const [navigationModalVisible, setNavigationModalVisible] = useState(false);
	const [orgLoanChannel, setOrgLoanChannel] = useState("");

	const [hideLoan, setHideLoan] = useState(false);

	const handleOpenNavigationModal = () => {
		setNavigationModalVisible(true);
	};

	const handleCloseNavigationModal = () => {
		setNavigationModalVisible(false);
	};

	const handleNavigatePipeline = () => {
		handleCloseNavigationModal();
		navigate("/pipeline");
	};

	useEffect(() => {
		// check if user is from the same organization and loan channel
		console.log("145 user channel:", userDetails?.organization_name);
		console.log("145 org loan channel:", orgLoanChannel);

		if (
			userDetails?.organization_name !== undefined &&
			userDetails?.organization_name !== "" &&
			orgLoanChannel !== undefined &&
			orgLoanChannel !== ""
		) {
			if (userDetails?.organization_name !== orgLoanChannel) {
				console.log("145 organization does not match");
				setNavigationModalText("You do not have access to this loan.");
				handleOpenNavigationModal();
				setHideLoan(true);
			}
		}
	}, [userDetails, orgLoanChannel]);

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("160 field values:", fieldValues);

		setOrgLoanChannel(findFieldValue(fieldValues, "TPO.X14"));
	};

	const getFieldValues = async (loanId) => {
		console.log("135 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "TPO.X14",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("180 error:", err);
		}
	};

	const [loanGuid, setLoanGuid] = useState("");

	const [loanApplicationStage, setLoanApplicationStage] = useState(0);

	useEffect(() => {
		console.log("52 params id:", params?.id);

		if (params?.id !== undefined && params?.id !== "") {
			setLoanGuid(params?.id);
		}
	}, [params]);

	useEffect(() => {
		if (loanGuid !== undefined && loanGuid !== "") {
			console.log("170 loan Guid:", loanGuid);
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loans",
			path: "/pipeline",
		},
		{
			label: params?.loanId,
			path: "",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack direction="row" className={styles.stackContainer}>
					<Stack direction="row" className={styles.bodyContainer}>
						<Stack direction="column" className={styles.loanContainer}>
							{/* page title and close btn container */}
							<Box direction="row" className={styles.headerContainer}>
								<Stack className={styles.headerTextContainer}>
									<Heading5
										text={`Step ${loanApplicationStage + 1}: ${
											loanSteps[loanApplicationStage]
										}`}
										fontType="semibold"
										className={styles.headerText}
									/>
								</Stack>

								<Stack
									className={styles.closeIconContainer}
									onClick={handleOpenExitModal}
								>
									<CloseOutlined className={styles.closeIcon} />
								</Stack>
							</Box>

							{/* loan form */}
							{!hideLoan &&
								(loanApplicationStage === 0 ? (
									<ViewLoanApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid || params?.id}
										handleOpenLoaderModal={handleOpenLoaderModal}
										handleCloseLoaderModal={handleCloseLoaderModal}
										currentStage={0}
									/>
								) : loanApplicationStage === 1 ? (
									<LoanAddressApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={1}
										viewLoan={true}
									/>
								) : loanApplicationStage === 2 ? (
									<CreditReissueApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={2}
										viewLoan={true}
									/>
								) : loanApplicationStage === 3 ? (
									<LoanEmploymentApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={3}
										viewLoan={true}
									/>
								) : loanApplicationStage === 4 ? (
									<LoanAssetsApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={4}
										viewLoan={true}
									/>
								) : loanApplicationStage === 5 ? (
									<LoanDemographicApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={5}
										viewLoan={true}
									/>
								) : loanApplicationStage === 6 ? (
									<ViewLoanTerms
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										handleOpenLoaderModal={handleOpenLoaderModal}
										handleCloseLoaderModal={handleCloseLoaderModal}
										currentStage={6}
										disabled={true}
									/>
								) : loanApplicationStage === 7 ? (
									<UnderwritingSummary
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={7}
										viewLoan={true}
									/>
								) : loanApplicationStage === 8 ? (
									<LoanPricingApplication
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={8}
										viewLoan={true}
									/>
								) : (
									loanApplicationStage === 9 && (
										<ViewLoanFee
											setLoanApplicationStage={setLoanApplicationStage}
											loanGuid={loanGuid}
											handleOpenLoaderModal={handleOpenLoaderModal}
											handleCloseLoaderModal={handleCloseLoaderModal}
											currentStage={9}
											disabled={true}
										/>
									)
								))}
						</Stack>

						{/* application progress */}
						<LoanProgress
							loanSteps={loanSteps}
							currentStep={loanApplicationStage}
							clickNavigation={true}
							setLoanApplicationStage={setLoanApplicationStage}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modals */}
			<LoaderModal open={loaderModal} />
			<ConfirmationModal
				open={exitModal}
				text="Are you sure you want to exit?"
				confirmText="Exit Application"
				handleConfirm={handleConfirmExit}
				closeText="Return to Application"
				handleClose={handleCloseExitModal}
			/>
			<NavigationModal
				open={navigationModalVisible}
				text={navigationModalText}
				confirmText="Okay"
				handleConfirm={handleNavigatePipeline}
			/>
		</Box>
	);
};

export default ViewLoan;
