import React from "react";

// mui components
import { Modal, Stack, Box } from "@mui/material";

// custom components
import Heading2 from "../../typography/heading-02";
import Body1 from "../../typography/body-01";
import PrimaryButton from "../../buttons/primary-button";

// custom animations
import Lottie from "lottie-react";
import errorAnimation from "../../../assets/animations/error.json";

// custom styles
import styles from "./index.module.scss";

export default function ErrorModal({ open, handleClose, text }) {
	return (
		<Modal
			open={open}
			aria-labelledby="error modal"
			aria-describedby="error modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Box className={styles.animationContainer}>
					<Lottie animationData={errorAnimation} loop={true} />
				</Box>

				<Stack
					direction="column"
					alignItems="center"
					className={styles.textContainer}
				>
					<Heading2
						text="Oops..."
						extraClass={styles.errorText}
						fontType="semibold"
					/>

					<Body1 text={text} extraClass={styles.errorSubText} />
				</Stack>

				<PrimaryButton
					text="OK"
					extraClass={styles.btnContainer}
					onClick={handleClose}
					fullWidth={true}
				/>
			</Stack>
		</Modal>
	);
}
