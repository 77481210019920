import { apiSlice } from "../../../app/api/apiSlice";

export const createUserApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createUser: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/admin/create_aeam_users/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useCreateUserMutation } = createUserApiSlice;
