import React, { useEffect, useState } from "react";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// custom components
import TabPanel from "../../tab-panel";
import RequiredError from "../../required-error";
import BorrowerEmploymentInfo from "../../loan-form-card/borrower-employment-information";
import AdditionalIncome from "../../loan-form-card/additional-income";
import ViewLoanInfoCard from "../../../pages/view-loan/components/info-card";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading6 from "../../typography/heading-06";

// api slice
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// custom modal
import LoaderModal from "../../modal/loader-modal";

// custom styles
import styles from "./index.module.scss";
import { CREDIT_REISSUE_CONSTANTS } from "../../../utils/credit-reissue-constants";

export const LoanEmploymentApplication = ({
	setLoanApplicationStage,
	loanGuid,
	currentStage,
	viewLoan,
}) => {
	console.log("45 loan id:", loanGuid);

	const [field, { isLoading: loadingValues }] = useFieldMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [borrowerResponseData, setBorrowerResponseData] = useState([]);
	const [borrowerPairData, setBorrowerPairData] = useState([]);
	const [creditReissueData, setCreditReissueData] = useState("");

	const [borrowerFinances, setBorrowerFinances] = useState([]);

	const [borrowerAdditionalIncome, setBorrowerAdditionalIncome] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const addMissingId = (array) => {
		return array.map((obj, index) => {
			if (!obj.id) {
				// If id is missing, create a new id based on the index
				obj.id = `Employment/${index}`;
			}
			return obj;
		});
	};

	const reorderFinanceArray = (arr) => {
		console.log("235 employment arr:", arr);
		// Separate the objects into two groups: borrowers and co-borrowers
		const borrowers = arr.filter((obj) => obj.owner === "Borrower");

		const coBorrowers = arr.filter((obj) => obj.owner === "CoBorrower");

		console.log("125 borrowers:", borrowers);
		console.log("125 Co Borrowers:", coBorrowers);

		if (borrowers?.length === 0) {
			let borrowerItem = {
				owner: "Borrower",
				currentEmploymentIndicator: true,
			};

			borrowers.push(borrowerItem);
		}

		if (coBorrowers?.length === 0) {
			let coborrowerItem = {
				owner: "CoBorrower",
				currentEmploymentIndicator: true,
			};

			coBorrowers.push(coborrowerItem);
		}

		console.log("333 borrower employment arr:", borrowers);
		console.log("333 co-borrower employment arr:", coBorrowers);

		const mergeArrays = (a, b) =>
			(a.length > b.length ? a : b).reduce(
				(acc, cur, i) => (a[i] && b[i] ? [...acc, a[i], b[i]] : [...acc, cur]),
				[]
			);

		// Combine the sorted residency and mailing objects into a new array
		const reorderedArray = mergeArrays(borrowers, coBorrowers);

		console.log("250 reorder finance:", reorderedArray);

		// Assign the correct id value to each object based on its index in the new array
		let finalArray;

		if (reorderedArray?.length < 2) {
			finalArray = reorderedArray.map((obj, index) => {
				const id = `Employment/${index}`;
				return { ...obj, id };
			});
		} else {
			finalArray = reorderedArray;
		}

		console.log("70 final employment array:", finalArray);

		const missingId = finalArray.filter((obj) => {
			return obj.id === null || obj.id === undefined || obj.id === "";
		});

		console.log("80 missing id array:", missingId);

		if (missingId.length > 0) {
			addMissingId(finalArray);
		}

		console.log("375 added missing id:", finalArray);

		const sortedEmploymentArr = finalArray.sort((a, b) => {
			const aIndex = parseInt(a.id.split("/")[1]);
			const bIndex = parseInt(b.id.split("/")[1]);
			return aIndex - bIndex;
		});

		// console.log("70 sorted employment:", sortedEmploymentArr);

		console.log("200 sorted employment arr:", sortedEmploymentArr);
		return sortedEmploymentArr;
	};

	const getAllBorrowerPair = async () => {
		const borrowerPairData = await getBorrowerPair({
			loanGuid,
		}).unwrap();

		console.log("90 borrower data:", borrowerPairData?.response);

		let financeArr = [];
		let additionalIncomeArr = [];
		let previousIncomeArr = [];

		for (let index = 0; index < borrowerPairData?.response?.length; index++) {
			console.log("100 res data:", borrowerPairData?.response[index]);

			if (
				borrowerPairData?.response[index]?.employment !== undefined &&
				borrowerPairData?.response[index]?.employment?.length > 0
			) {
				console.log(
					"450 borrower pair employment:",
					borrowerPairData?.response[index]?.employment
				);

				financeArr.push(
					reorderFinanceArray(borrowerPairData?.response[index]?.employment)
				);
			} else {
				financeArr.push([
					{
						id: "Employment/0",
						owner: "Borrower",
						currentEmploymentIndicator: true,
					},
					{
						id: "Employment/1",
						owner: "CoBorrower",
						currentEmploymentIndicator: true,
					},
				]);
			}

			if (borrowerPairData?.response[index]?.otherIncomeSources?.length > 0) {
				additionalIncomeArr.push(
					borrowerPairData?.response[index]?.otherIncomeSources
				);
			} else {
				additionalIncomeArr.push([]);
			}

			if (
				Object.keys(borrowerPairData?.response[index]?.borrower).length > 0 &&
				Object.keys(borrowerPairData?.response[index]?.coborrower).length > 0
			) {
				// both borrower and co-borrower
				previousIncomeArr.push([
					{
						previousGrossMonthlyIncome:
							borrowerPairData?.response[index]?.borrower
								?.previousGrossMonthlyIncome,
					},
					{
						previousGrossMonthlyIncome:
							borrowerPairData?.response[index]?.coborrower
								?.previousGrossMonthlyIncome,
					},
				]);
			} else if (
				Object.keys(borrowerPairData?.response[index]?.coborrower).length > 0
			) {
				// only borrower, no co-borrower
				previousIncomeArr.push([
					{
						previousGrossMonthlyIncome:
							borrowerPairData?.response[index]?.borrower
								?.previousGrossMonthlyIncome,
					},
					{
						previousGrossMonthlyIncome: "",
					},
				]);
			} else if (
				Object.keys(borrowerPairData?.response[index]?.borrower).length > 0
			) {
				// no borrower, only co-borrower
				previousIncomeArr.push([
					{
						previousGrossMonthlyIncome: "",
					},
					{
						previousGrossMonthlyIncome:
							borrowerPairData?.response[index]?.coborrower
								?.previousGrossMonthlyIncome,
					},
				]);
			} else {
				// no borrower, only co-borrower
				previousIncomeArr.push([
					{
						previousGrossMonthlyIncome: "",
					},
					{
						previousGrossMonthlyIncome: "",
					},
				]);
			}
		}

		setBorrowerFinances(financeArr);
		setBorrowerAdditionalIncome(additionalIncomeArr);

		setBorrowerResponseData(borrowerPairData?.response);

		let pairArr = [];

		borrowerPairData?.response?.map((item) =>
			pairArr.push(item.borrower, item.coborrower)
		);

		console.log("135 pair arr:", pairArr);

		setBorrowerPairData(pairArr);
	};

	const getFieldValues = async () => {
		try {
			const getValues = await field({
				loan_guid: loanGuid,
				fields: "CX.LC.Credit.Provided",
			}).unwrap();

			console.log("175 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setCreditReissueData(getValues?.response[0]?.value || "");
			}
		} catch (err) {
			console.log("270 error:", err);
		}
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
			getFieldValues();
		}
	}, [loanGuid]);

	console.log("200 employment arr:", borrowerFinances);
	console.log("200 additional income sources:", borrowerAdditionalIncome);

	const [error, setError] = useState("");

	const updateBorrowerPairFunc = async (index) => {
		console.log("345 index:", index);
		console.log("345 res data:", borrowerResponseData[index]);

		console.log("1700 borrower finances:", borrowerFinances);
		console.log("1700 borrower additional income:", borrowerAdditionalIncome);

		let applications = [
			{
				borrower: {
					...borrowerResponseData[index]?.borrower,
				},
				coborrower: {
					...borrowerResponseData[index]?.coborrower,
				},
				otherIncomeSources:
					borrowerAdditionalIncome[index]?.length > 0
						? borrowerAdditionalIncome[index]
						: [],
				employment: borrowerFinances[index],
			},
		];

		console.log("1700 update borrower pair data:", {
			id: borrowerResponseData[index].borrowerPairId,
			...applications[0],
		});

		const updateLoanBroker = await updateBorrowerPair({
			loanGuid,
			loanData: {
				id: borrowerResponseData[index].borrowerPairId,
				applicationId: borrowerResponseData[index].borrowerPairId,
				applicationIndex: index,
				...applications[0],
			},
			borrowerPairId: borrowerResponseData[index].borrowerPairId,
		}).unwrap();

		console.log("90 update broker loan:", updateLoanBroker);

		return updateLoanBroker;
	};

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		if (creditReissueData === CREDIT_REISSUE_CONSTANTS.REISSUE) {
			setLoanApplicationStage(currentStage - 1);
		} else {
			setLoanApplicationStage(currentStage - 2);
		}
	};

	const handleNext = async () => {
		console.log("450 pairData:", borrowerPairData);
		console.log("450 financesData:", borrowerFinances);
		console.log("450 additional income:", borrowerAdditionalIncome);

		try {
			console.log("700 loan id:", loanGuid);
			console.log("700 borrowerResponseData:", borrowerResponseData);
			console.log("700 borrowerPairData:", borrowerPairData);

			for (let index = 0; index < borrowerResponseData.length; index++) {
				const element = borrowerResponseData[index];
				console.log("390 el:", element);

				let updateResponse = await updateBorrowerPairFunc(index);

				console.log("393 updateResponse:", updateResponse);

				if (
					index === borrowerResponseData.length - 1 &&
					updateResponse.message.includes("success")
				) {
					console.log("395 update borrower pair");
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setLoanApplicationStage(currentStage + 1);
				}
			}
		} catch (err) {
			console.log("95 err:", err);
			setError(err?.data?.details);
		}
	};

	const handleNextView = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	console.log("525 borrower pair:", borrowerPairData);

	const renderTabs = () => {
		let arr = [];
		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			arr.push(
				<Tab
					className={styles.tabBtnContainer}
					label={
						<Stack direction="row" className={styles.tabLabelContainer}>
							<Heading6
								text={`Borrower Pair ${i}`}
								fontType="semibold"
								extraClass={
									tabValue === i - 1 ? styles.activeTabText : styles.tabText
								}
							/>
						</Stack>
					}
					{...a11yProps(i - 1)}
				/>
			);
		}
		return arr;
	};

	const informationViewElements = (assetLiabilityREIndex, i) => {
		return (
			<Stack>
				{viewLoan && (
					<ViewLoanInfoCard
						borrower={borrowerPairData[0]}
						coBorrower={borrowerPairData[1]}
					/>
				)}

				{/* employment information */}
				<BorrowerEmploymentInfo
					data={borrowerFinances[i - 1]}
					setData={setBorrowerFinances}
					borrowerPairIndex={i - 1}
					disabled={viewLoan}
				/>

				{/* additional income */}
				{borrowerAdditionalIncome
					?.slice(assetLiabilityREIndex, assetLiabilityREIndex + 1)
					?.map((additionalIncome, index) => (
						<AdditionalIncome
							key={index}
							additionalIncomeIndex={index + assetLiabilityREIndex}
							additionalIncomeData={additionalIncome}
							data={borrowerAdditionalIncome}
							setData={setBorrowerAdditionalIncome}
							disabled={viewLoan}
						/>
					))}
			</Stack>
		);
	};

	const renderInformation = () => {
		let arr = [];
		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			// i is initialized to 1 instead of 0 so that we can it helps in thinking about borrower pair number.
			let assetLiabilityREIndex = i - 1;

			if (borrowerPairData.length <= 2) {
				arr.push(
					<Box className={styles.tabPanelContainer}>
						{informationViewElements(assetLiabilityREIndex, i)}
					</Box>
				);
			} else {
				arr.push(
					<TabPanel
						value={tabValue}
						index={i - 1}
						className={tabValue === i - 1 ? styles.tabPanelContainer : ""}
					>
						{informationViewElements(assetLiabilityREIndex, i)}
					</TabPanel>
				);
			}
		}
		return arr;
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				{borrowerPairData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="basic tabs example"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						{renderTabs()}
					</Tabs>
				)}

				{renderInformation()}
			</Stack>

			{error !== "" && (
				<RequiredError
					headerText={error.includes("Loan is locked") ? "" : "Missing fields"}
					text={
						error.includes("Loan is locked")
							? "This loan is currently being edited by Logan Finance. Once Logan Finance exits the loan, you will be able to navigate the loan in the system and the data displayed will be updated as needed."
							: error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value") &&
							  "Please provide a value for all fields highlighted above."
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text={viewLoan ? "Continue" : "Save and Continue"}
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() => (viewLoan ? handleNextView() : handleNext())}
				/>
			</Stack>

			{/* liability modal */}
			<LoaderModal
				open={
					loadingBorrowerPairData ||
					loadingValues ||
					updatingBorrowerPair ||
					isUpdatingFields
				}
			/>
		</Stack>
	);
};

export default LoanEmploymentApplication;
