import { apiSlice } from "../../app/api/apiSlice";

export const underwritingConditionApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		underwritingCondition: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/get_conditions/${credentials?.loanGuid}/underwriting`,
				method: "GET",
			}),
		}),
	}),
});

export const { useUnderwritingConditionMutation } =
	underwritingConditionApiSlice;
