import React, { useEffect } from "react";
import { useIMask } from "react-imask";
import { maskMap } from "./constants";

const SSNInput = ({ onChange, ...props }) => {
	const { ref, unmaskedValue } = useIMask(maskMap.ssn);

	useEffect(() => {
		onChange(unmaskedValue);
	}, [unmaskedValue]);

	return <input ref={ref} disabled={props.disabled} {...props} />;
};

export default SSNInput;
