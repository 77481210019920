import React from "react";
import { Typography } from "@mui/material";

import styles from "./index.module.scss";

const Heading2 = ({ text, extraClass, error, fontType }) => {
	const customClass = `${styles.heading2} ${extraClass} ${
		error ? styles.error : ""
	} ${fontType === "semibold" && styles.semibold} `;

	return (
		<Typography component="p" className={customClass}>
			{text}
		</Typography>
	);
};

export default Heading2;
