import { isNullOrUndefinedOrEmpty } from "../filter-value";
import { CLIENT_ID_ENABLED_PARTNERS } from "../credit-reissue-constants";

export const validateCreditReissueInformation = (creditReissueData) => {
	if (isNullOrUndefinedOrEmpty(creditReissueData)) {
		console.log("Error: ", "creditReissueData", creditReissueData);
		return true;
	}
};

export const isValidatedCreditReissueForm = ({
	creditCredentialsData,
	creditVendorsData,
	borrowerPairCount,
	borrowerIndex,
}) => {
	const creditReissueForm = creditCredentialsData[borrowerIndex];
	const selectedVendor = creditVendorsData.find(
		(item) => item.partner_name === creditReissueForm?.creditVendor
	);

	// Always required fields
	if (
		isNullOrUndefinedOrEmpty(creditReissueForm.creditVendor) ||
		isNullOrUndefinedOrEmpty(creditReissueForm.creditReportIdentifier) ||
		isNullOrUndefinedOrEmpty(creditReissueForm.userName) ||
		isNullOrUndefinedOrEmpty(creditReissueForm.password) ||
		isNullOrUndefinedOrEmpty(creditReissueForm.providerId) ||
		isNullOrUndefinedOrEmpty(creditReissueForm.reportOn)
	) {
		console.log(
			"35 required fields missing error at index:",
			"creditCredentialsData",
			borrowerIndex,
			creditCredentialsData
		);
		return false;
	}

	if (
		CLIENT_ID_ENABLED_PARTNERS.includes(selectedVendor.partner_name) &&
		isNullOrUndefinedOrEmpty(creditReissueForm.clientID)
	) {
		console.log(
			"isValidatedCreditReissueForm: 48 required fields missing error at index:",
			"creditCredentialsData: clientID",
			borrowerIndex,
			creditCredentialsData
		);
		return false;
	}

	if (
		selectedVendor?.required_fields?.includes("subscriberId") &&
		isNullOrUndefinedOrEmpty(creditReissueForm.subscriberId)
	) {
		console.log(
			"isValidatedCreditReissueForm: 61 required fields missing error at index:",
			"creditCredentialsData: subscriberId",
			borrowerIndex,
			creditCredentialsData
		);
		return false;
	}

	if (
		selectedVendor?.required_fields?.includes("productCode") &&
		isNullOrUndefinedOrEmpty(creditReissueForm.productCode)
	) {
		console.log(
			"isValidatedCreditReissueForm: 74 required fields missing error at index:",
			"creditCredentialsData: productCode",
			borrowerIndex,
			creditCredentialsData
		);
		return false;
	}

	return true;
};
