import React from "react";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import { ErrorOutline } from "@mui/icons-material";

// custom components
import Heading5 from "../typography/heading-05";
import Body3 from "../typography/body-03";

// custom styles
import styles from "./index.module.scss";

const RequiredError = ({ headerText, text, extraClass }) => {
	const customClass = styles.requiredErrorContainer + " " + extraClass;

	return (
		<Stack direction="row" className={customClass}>
			<Box className={styles.errorIconContainer}>
				<ErrorOutline className={styles.errorIcon} />
			</Box>
			<Stack direction="column" className={styles.textContainer}>
				<Heading5 text={headerText} extraClass={styles.headerText} />
				<Body3 text={text} extraClass={styles.bodyText} />
			</Stack>
		</Stack>
	);
};

export default RequiredError;
