import { store } from "../store";
import { setFieldValues } from "../../features/field-values/fieldValueSlice";

export const getFieldValues = async ({
	loanGuid,
	fieldList,
	getFieldValueApi,
	handleErrorModal,
}) => {
	const formData = {
		loan_guid: loanGuid,
		fields: fieldList,
	};

	try {
		const fieldValue = await getFieldValueApi(formData).unwrap();

		// save response in redux store
		store.dispatch(setFieldValues(fieldValue?.response));
	} catch (err) {
		console.log("260 error:", err);
		handleErrorModal(err);
	}
};
