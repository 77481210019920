import React, { useEffect, useState } from "react";

// mui components
import { Stack, Tab, Tabs } from "@mui/material";

// custom components
import { TabPanel } from "../../tab-panel";
import RequiredError from "../../required-error";
import CreditBorrowerInformation from "../../loan-form-card/credit-borrower-information";
import PrimaryButton from "../../buttons/primary-button";
import Heading6 from "../../typography/heading-06";
import CreditCredentialsInformation from "../../loan-form-card/credit-credentials-information";
import SecondaryButton from "../../buttons/secondary-button";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// api slice
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useGetLoanMutation } from "../../../features/get-loan-data/getLoanApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";
import { useCreditVendorsMutation } from "../../../features/credit-reissue/creditVendorsApiSlice";
import { useCreateOrderServiceMutation } from "../../../features/credit-reissue/createOrderServiceApiSlice";
import { useCheckOrderStatusMutation } from "../../../features/credit-reissue/checkOrderStatusApiSlice";

// custom modal
import LoaderModal from "../../modal/loader-modal";
import SuccessModal from "../../modal/success-modal";
import ErrorModal from "../../modal/error-modal";
import ConfirmationModal from "../../modal/confirmation-modal";

// helper utils
import { requiredFieldsForCreditReissue } from "../../../utils/requiredFields";
import { validateContactInformation } from "../../../utils/required-field-helper/contact-info-required-helper";
import { isValidatedCreditReissueForm } from "../../../utils/required-field-helper/credit-reissue-validation-helper";
import { DEFAULT_CREDIT_CREDENTIALS_FORM } from "../../../utils/credit-reissue-constants";

// custom styles
import styles from "./index.module.scss";

export const CreditReissueApplication = ({
	setLoanApplicationStage,
	applicationData,
	setApplicationData,
	loanGuid,
	currentStage,
	viewLoan,
}) => {
	console.log("24 application data edit:", applicationData);
	console.log("45 loan id:", loanGuid);

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [field, { isLoading: isFetchingFields }] = useFieldMutation();

	const [creditVendors, { isLoading: isFetchingCreditVendors }] =
		useCreditVendorsMutation();

	const [createOrderService, { isLoading: isCreatingOrderService }] =
		useCreateOrderServiceMutation();

	const [checkOrderStatus, { isLoading: isCheckingOrderStatus }] =
		useCheckOrderStatusMutation();

	const [getLoan, { isLoading: loadingloanData }] = useGetLoanMutation();

	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [loanApplicationData, setLoanApplicationData] = useState([{}, {}]);
	const [borrowerResponseData, setBorrowerResponseData] = useState([]);
	const [creditCredentialsData, setCreditCredentialsData] = useState([]);

	const [creditVendorsData, setCreditVendorsData] = useState([]);
	const [creditReissuedVendors, setCreditReissuedVendors] = useState([]);
	const [tabValue, setTabValue] = useState(0);

	const [borrowerResidences, setBorrowerResidences] = useState([]);

	const [errorModalVisible, setErrorModalVisible] = useState(false);
	const [errorModalText, setErrorModalText] = useState("");

	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const [successModalText, setSuccessModalText] = useState("");

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const [isCheckingCreditReissueStatus, setIsCheckingCreditReissueStatus] =
		useState(false);

	const [error, setError] = useState("");
	let checkReissueStatusInterval = null;

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setError("");
		setSuccessModalVisible(false);
	};

	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
		handleNext();
	};

	const handleSubmitConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const modelArr = [
		{
			id: "Residence/0",
			applicantType: "Borrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/1",
			applicantType: "Borrower",
			mailingAddressIndicator: false,
			residencyType: "Prior",
		},
		{
			id: "Residence/2",
			applicantType: "Borrower",
			mailingAddressIndicator: true,
		},
		{
			id: "Residence/3",
			applicantType: "CoBorrower",
			mailingAddressIndicator: false,
			residencyType: "Current",
		},
		{
			id: "Residence/4",
			applicantType: "CoBorrower",
			mailingAddressIndicator: false,
			residencyType: "Prior",
		},
		{
			id: "Residence/5",
			applicantType: "CoBorrower",
			mailingAddressIndicator: true,
		},
	];

	const findMissingObjects = (data) => {
		const missingObjects = [];

		console.log("100 model arr:", modelArr);
		console.log("100 data arr:", data);

		for (const modelObj of modelArr) {
			const found = data.some(
				(dataObj) =>
					dataObj.applicantType === modelObj.applicantType &&
					(dataObj.residencyType || undefined) === modelObj.residencyType &&
					(dataObj.mailingAddressIndicator || false) ===
						modelObj.mailingAddressIndicator
			);

			if (!found) {
				missingObjects.push(modelObj);
			}
		}

		return missingObjects;
	};

	const reorderResidencesArray = (
		arr,
		borrowerMailingAddress,
		coborrowerMailingAddress,
		borrowerPairIndex
	) => {
		console.log("100 arr:", arr);

		const missingObjectsArr = findMissingObjects(arr);
		console.log("100 missing object arr:", missingObjectsArr);

		const mergedArray = [...arr, ...missingObjectsArr];

		console.log("130 merged array:", mergedArray);

		// Separate the objects into two groups: borrowers and co-borrowers
		const borrowers = mergedArray
			.filter((obj) => obj.applicantType === "Borrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		const coBorrowers = mergedArray
			.filter((obj) => obj.applicantType === "CoBorrower")
			.sort((a, b) => {
				const numA = parseInt(a.id.split("Residence/")[1]);
				const numB = parseInt(b.id.split("Residence/")[1]);
				return numA - numB;
			});

		console.log("125 borrowers:", borrowers);
		console.log("125 Co Borrowers:", coBorrowers);

		console.log("150 borrower mailing address:", borrowerMailingAddress);
		console.log("150 coborrower mailing address:", coborrowerMailingAddress);

		const reorderedArray = [...borrowers, ...coBorrowers];

		console.log("60 reorder:", reorderedArray);

		// Assign the correct id value to each object based on its index in the new array
		let finalArray;

		// if array length is less than 6
		if (reorderedArray?.length <= 6) {
			finalArray = reorderedArray.map((obj, index) => {
				const id = `Residence/${
					obj.applicantType === "Borrower" && obj.residencyType === "Current"
						? 0
						: obj.applicantType === "Borrower" && obj.residencyType === "Prior"
						? 1
						: obj.applicantType === "Borrower" && obj.mailingAddressIndicator
						? 2
						: obj.applicantType === "CoBorrower" &&
						  obj.residencyType === "Current"
						? 3
						: obj.applicantType === "CoBorrower" &&
						  obj.residencyType === "Prior"
						? 4
						: obj.applicantType === "CoBorrower" && obj.mailingAddressIndicator
						? 5
						: index
				}`;
				return { ...obj, id };
			});
		} else {
			finalArray = reorderedArray;
		}

		console.log("70 final residence array:", finalArray);

		const sortedArr = finalArray.sort((a, b) => {
			const aIndex = parseInt(a.id.split("/")[1]);
			const bIndex = parseInt(b.id.split("/")[1]);
			return aIndex - bIndex;
		});

		console.log("70 sorted:", sortedArr);
		console.log("200 sorted arr:", sortedArr);
		return sortedArr;
	};

	const checkReissueForDisclaimer = () => {
		/**
		 * @description; Check and Show disclaimer for Credit Reissue
		 */

		if (creditReissuedVendors.includes(false)) {
			setConfirmModalText(
				"Before you continue, make sure that you've reissued credit for all borrower pairs"
			);
			handleOpenConfirmModal();
		} else {
			handleNext();
		}
	};

	const checkReissueStatus = async (transactionId, borrowerIndex) => {
		/**
		 * @description; Check transaction status for one borrower-pair at a time
		 */
		try {
			setIsCheckingCreditReissueStatus(true);
			const response = await checkOrderStatus({
				...creditCredentialsData[borrowerIndex],
				transactionId,
			}).unwrap();
			console.log(
				"CreditReissueApplication:checkReissueStatus:response",
				response
			);
			clearTimeout(checkReissueStatusInterval);
			checkReissueStatusInterval = null;
			let tempCreditReissuedVendors = [...creditReissuedVendors];

			if (response?.status?.toLowerCase() === "success") {
				setIsCheckingCreditReissueStatus(false);
				tempCreditReissuedVendors[borrowerIndex] = true;
				setCreditReissuedVendors([...tempCreditReissuedVendors]);
				setSuccessModalText(
					`The credit report from ${creditCredentialsData[borrowerIndex]?.creditVendor} Vendor has been Successfully Reissued.`
				);

				// Update fields in Encompass
				let valuesArr = [];
				valuesArr.push({
					id: `300#${borrowerIndex + 1}`,
					value: creditCredentialsData[borrowerIndex].creditReportIdentifier, // TODO prashant; change creditReportIdentifier to clientId if required in future based on Group 2 and 3
				});
				valuesArr.push({
					id: `CX.LC.CreditVendor.Pair${borrowerIndex + 1}`,
					value: creditCredentialsData[borrowerIndex].creditVendor,
				});

				const updateFieldValues = await updateFields({
					loan_guid: loanGuid,
					field_values: valuesArr,
				}).unwrap();
				console.log(
					"CreditReissueApplication:checkReissueStatus:updateFields",
					updateFieldValues
				);

				handleOpenSuccessModal();
			} else if (
				response?.errors?.length > 0 ||
				response?.status?.toLowerCase() === "error"
			) {
				setIsCheckingCreditReissueStatus(false);
				tempCreditReissuedVendors[borrowerIndex] = true;
				setCreditReissuedVendors([...tempCreditReissuedVendors]);
				setErrorModalText(
					`The following error was returned by the Credit Provider: ${response?.details}`
				);
				handleOpenErrorModal();
			} else if (response?.status?.toLowerCase() === "pending") {
				checkReissueStatusInterval = setTimeout(
					() => checkReissueStatus(transactionId, borrowerIndex),
					2000
				);
			}
		} catch (err) {
			setIsCheckingCreditReissueStatus(false);
			console.log("CreditReissueApplication:checkReissueStatus:error", err);
			clearTimeout(checkReissueStatusInterval);
			checkReissueStatusInterval = null;

			let tempCreditReissuedVendors = [...creditReissuedVendors];
			tempCreditReissuedVendors[borrowerIndex] = true;
			setCreditReissuedVendors([...tempCreditReissuedVendors]);

			setErrorModalText(
				`The following error was returned by the Credit Provider: ${
					err?.data?.details ||
					err?.details ||
					err?.message ||
					err?.summary ||
					""
				}`
			);
			handleOpenErrorModal();
		}
	};

	const reissueCredit = async (borrowerIndex) => {
		/**
		 * @description; Reissue credit for one borrower-pair at a time
		 */

		// Validate credit credentials of one borrower-pair
		if (
			!isValidatedCreditReissueForm({
				creditCredentialsData,
				creditVendorsData,
				borrowerPairCount: loanApplicationData.length / 2,
				borrowerIndex,
			})
		) {
			setError(
				`Please provide a value for credit-${borrowerIndex} all fields highlighted above.`
			);
			return;
		}

		/**
		 * @description: Create order for credit reissue
		 */
		try {
			const response = await createOrderService({
				...creditCredentialsData[borrowerIndex],
				entityId: `${loanGuid}#${borrowerResponseData[borrowerIndex]?.borrowerPairId}`,
				entityType: "urn:elli:encompass:loan:borrower",
			}).unwrap();
			console.log(
				"CreditReissueApplication:reissueCredit:createOrderService:res",
				response
			);

			if (response?.transaction_id) {
				checkReissueStatusInterval = setTimeout(
					() => checkReissueStatus(response.transaction_id, borrowerIndex),
					0
				);
			}
		} catch (err) {
			console.log(
				"CreditReissueApplication:reissueCredit:createOrderService:error",
				err
			);
			setErrorModalText(
				`The vendor platform returned some error. Please retry!`
			);
			handleOpenErrorModal();
		}
	};

	useEffect(() => {
		const getAllBorrowerPair = async () => {
			const borrowerPairData = await getBorrowerPair({
				loanGuid,
			}).unwrap();

			let residenceArr = [];
			let creditCredentialFormsArr = [];
			let creditReissuedVendorsArr = [];
			// let totalHousingArr = [];

			for (let index = 0; index < borrowerPairData?.response?.length; index++) {
				console.log("100 res data:", borrowerPairData?.response[index]);

				residenceArr.push(
					...reorderResidencesArray(
						borrowerPairData?.response[index]?.residences,
						borrowerPairData?.response[index]?.borrower
							?.mailingAddressSameAsPresentIndicator,
						borrowerPairData?.response[index]?.coborrower
							?.mailingAddressSameAsPresentIndicator,
						index
					)
				);

				creditCredentialFormsArr.push({
					...DEFAULT_CREDIT_CREDENTIALS_FORM,
				});
				creditReissuedVendorsArr.push(false);
			}

			setBorrowerResidences(residenceArr);
			// setTotalHousingPayment(totalHousingArr);

			/**
			 *  @description; set default credit-credentials form data based on number of borrower-pairs
			 */
			setCreditCredentialsData(creditCredentialFormsArr);
			setCreditReissuedVendors(creditReissuedVendorsArr);

			setBorrowerResponseData(borrowerPairData?.response);
		};

		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	useEffect(() => {
		const getLoanDetails = async () => {
			const loanData = await getLoan({
				loanid: loanGuid,
			}).unwrap();

			console.log("230 loan data:", loanData);

			let borrowerPairs = loanData?.response?.applications
				?.map((application) => [application.borrower, application.coborrower])
				.flat();

			try {
				const getValues = await field({
					loan_guid: loanGuid,
					fields:
						"CX.FOREIGNNATLBORR1,CX.FOREIGNNATLBORR2,CX.FOREIGNNATLBORR3,CX.FOREIGNNATLBORR4,CX.FOREIGNNATLBORR5,CX.FOREIGNNATLBORR6",
				}).unwrap();

				console.log("175 all fields:", getValues?.response);
				console.log("240 borrowerPairs:", borrowerPairs);

				let updatedBorrowerPairs = [];

				if (getValues?.response?.length > 0) {
					for (let index = 0; index < borrowerPairs.length; index++) {
						const item = borrowerPairs[index];
						const updatedItem = { ...item };

						if (getValues?.response[index]?.value === "Yes") {
							updatedItem.urla2020CitizenshipResidencyType = "Foreign National";
						}

						updatedBorrowerPairs.push(updatedItem);
					}

					console.log("250 updated borrower pair:", updatedBorrowerPairs);
					setLoanApplicationData(updatedBorrowerPairs);
				}
			} catch (err) {
				console.log("270 error:", err);
			}
		};

		console.log("1130 loan guid:", loanGuid);
		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getLoanDetails();
		}
	}, [loanGuid]);

	useEffect(() => {
		const getCreditVendors = async () => {
			try {
				const response = await creditVendors().unwrap();
				console.log(
					"CreditReissueApplication:useEffect:credit vendors:",
					response
				);
				let sortedVendorList = [...response];

				// Filter credit partners/vendors for group 1 and 4 only
				sortedVendorList = sortedVendorList.filter(
					(vendor) => vendor.group === "Group 1" || vendor.group === "Group 4"
				);

				// Sort partners/vendors name in ASC order
				sortedVendorList.sort((a, b) => {
					const partnerNameA = a.partner_name.toLowerCase();
					const partnerNameB = b.partner_name.toLowerCase();
					if (partnerNameA < partnerNameB) return -1;
					if (partnerNameA > partnerNameB) return 1;
					return 0;
				});
				setCreditVendorsData(sortedVendorList);

				const tempCreditVendorDropdownOptions = [];
				response.forEach((item) =>
					tempCreditVendorDropdownOptions.push({
						label: item.partner_name,
						value: item.partner_name,
					})
				);
			} catch (err) {
				console.log("CreditReissueApplication:useEffect:error:", err);
			}
		};

		getCreditVendors();
	}, []);

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const handleNext = async () => {
		setError("");

		console.log("340 application data:", applicationData);
		console.log("340 loanApplication data:", loanApplicationData);
		console.log("340 creditCredentialsData:", creditCredentialsData);

		if (!requiredFieldsForCreditReissue(loanApplicationData, setError)) {
			return;
		}

		let borrowerPairData = [];

		console.log("445 loan applicationData:", loanApplicationData);

		let applications = [];

		for (let index = 0; index < loanApplicationData.length; index += 2) {
			if (
				loanApplicationData[index]?.urla2020CitizenshipResidencyType ===
				"Foreign National"
			) {
				loanApplicationData[index].urla2020CitizenshipResidencyType =
					"NonPermanentResidentAlien";
			}

			if (
				loanApplicationData[index + 1]?.urla2020CitizenshipResidencyType ===
				"Foreign National"
			) {
				loanApplicationData[index + 1].urla2020CitizenshipResidencyType =
					"NonPermanentResidentAlien";
			}

			const pairIndex = index === 0 ? 0 : index === 2 ? 1 : 2;
			const residences = [
				borrowerResidences[6 * pairIndex + 0],
				borrowerResidences[6 * pairIndex + 1],
				borrowerResidences[6 * pairIndex + 2],
				borrowerResidences[6 * pairIndex + 3],
				borrowerResidences[6 * pairIndex + 4],
				borrowerResidences[6 * pairIndex + 5],
			];

			applications.push({
				borrower: loanApplicationData[index],
				coBorrower: loanApplicationData[index + 1],
				residences,
			});

			borrowerPairData.push([
				{
					borrower: loanApplicationData[index],
					coborrower: loanApplicationData[index + 1],
					residences,
				},
			]);
		}

		console.log("415 applicationData:", applicationData);
		console.log("415 borrower pair data;", borrowerPairData);

		if (validateContactInformation(borrowerPairData, borrowerResidences)) {
			setError("Please provide a value for all fields highlighted above.");
			return;
		}

		setApplicationData({
			...applicationData,
			applications,
		});

		console.log("305 loan guid:", loanGuid);

		let apiObj = {
			...applicationData,
			applications: applications[0],
		};

		// update loan api
		console.log("510 loan id:", loanGuid);
		console.log("510 loan data:", apiObj);

		console.log("430 application data:", applicationData);
		console.log("430 applications:", applications);

		console.log("475 api obj:", {
			...applicationData,
			applications,
		});

		// if (loanApplicationData?.length >= 3) {
		console.log("440 borrower pair data:", borrowerPairData);
		console.log("440 borrower response data:", borrowerResponseData);

		let borrowerPair;

		for (let index = 0; index < borrowerPairData.length; index++) {
			console.log("445 index:", index);
			console.log(
				"450 borrower pair id:",
				borrowerResponseData[index]?.borrowerPairId
			);

			// if borrower pair id exists then update the pair
			try {
				borrowerPair = await updateBorrowerPair({
					loanGuid: loanGuid,
					borrowerPairId: borrowerResponseData[index].borrowerPairId,
					loanData: { ...borrowerPairData[index][0] },
				}).unwrap();
			} catch (err) {
				console.log("550 update error borrower pair data:", err);
				setError(err?.data?.details || "Failed to update borrower pair");
			}
		}

		console.log("550 update borrower pair data:", borrowerPair);

		// continue to next screen
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage + 1);
	};

	const handleNextView = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	// loader - loadingBorrowerPairData
	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				<Tabs
					value={tabValue}
					onChange={handleChangeTab}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						style: {
							backgroundColor: "#76D99E",
						},
					}}
				>
					<Tab
						className={styles.tabBtnContainer}
						label={
							<Stack direction="row" className={styles.tabLabelContainer}>
								<Heading6
									text="Borrower Pair 1"
									fontType="semibold"
									extraClass={
										tabValue === 0 ? styles.activeTabText : styles.tabText
									}
								/>
							</Stack>
						}
						{...a11yProps(0)}
					/>
					{loanApplicationData?.length > 2 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(1)}
						/>
					)}
					{loanApplicationData?.length > 4 && (
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 3"
										fontType="semibold"
										extraClass={
											tabValue === 2 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(2)}
						/>
					)}
				</Tabs>

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 0 && (
					<TabPanel
						value={tabValue}
						index={0}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<CreditBorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								residenceIndex={index % 2 !== 0 ? 3 : 0}
								residenceData={borrowerResidences[index % 2 !== 0 ? 3 : 0]}
								data={loanApplicationData}
								setLoanApplicationData={setLoanApplicationData}
								borrowerResidences={borrowerResidences}
								setBorrowerResidences={setBorrowerResidences}
								error={error.includes("provide a value")}
								disabled={viewLoan}
							/>
						))}

						{/* Credit credential information */}
						<CreditCredentialsInformation
							key={0}
							creditCredentialsDataIndex={0}
							creditCredentialsData={creditCredentialsData}
							setCreditCredentialsData={setCreditCredentialsData}
							error={error.includes(`provide a value for credit-${0}`)}
							creditVendorsData={creditVendorsData}
							loanApplicationData={loanApplicationData}
							disabled={viewLoan}
						/>
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						value={tabValue}
						index={1}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<CreditBorrowerInformation
								key={index + 2}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								residenceIndex={index % 2 !== 0 ? 9 : 6}
								residenceData={borrowerResidences[index % 2 !== 0 ? 9 : 6]}
								data={loanApplicationData}
								setLoanApplicationData={setLoanApplicationData}
								borrowerResidences={borrowerResidences}
								setBorrowerResidences={setBorrowerResidences}
								error={error.includes("provide a value")}
								disabled={viewLoan}
							/>
						))}

						{/* Credit credential information */}
						<CreditCredentialsInformation
							key={1}
							creditCredentialsDataIndex={1}
							creditCredentialsData={creditCredentialsData}
							setCreditCredentialsData={setCreditCredentialsData}
							error={error.includes(`provide a value for credit-${1}`)}
							creditVendorsData={creditVendorsData}
							loanApplicationData={loanApplicationData}
							disabled={viewLoan}
						/>
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 4 && (
					<TabPanel
						value={tabValue}
						index={2}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<CreditBorrowerInformation
								key={index + 4}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								residenceIndex={index % 2 !== 0 ? 15 : 12}
								residenceData={borrowerResidences[index % 2 !== 0 ? 15 : 12]}
								data={loanApplicationData}
								setLoanApplicationData={setLoanApplicationData}
								borrowerResidences={borrowerResidences}
								setBorrowerResidences={setBorrowerResidences}
								error={error.includes("provide a value")}
								disabled={viewLoan}
							/>
						))}

						{/* Credit credential information */}
						<CreditCredentialsInformation
							key={2}
							creditCredentialsDataIndex={2}
							creditCredentialsData={creditCredentialsData}
							setCreditCredentialsData={setCreditCredentialsData}
							error={error.includes(`provide a value for credit-${2}`)}
							creditVendorsData={creditVendorsData}
							loanApplicationData={loanApplicationData}
							disabled={viewLoan}
						/>
					</TabPanel>
				)}
			</Stack>

			{error !== "" && (
				<RequiredError
					headerText={error.includes("Loan is locked") ? "" : "Missing fields"}
					text={
						error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted above."
							: error.includes("Loan is locked")
							? "This loan is currently being edited by Logan Finance. Once Logan Finance exits the loan, you will be able to navigate the loan in the system and the data displayed will be updated as needed."
							: error.includes("borrower.dependentCount") &&
							  "Please check your dependent count."
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>

				<Stack direction="row">
					<PrimaryButton
						text="Reissue Credit to Borrower"
						onClick={() => reissueCredit(tabValue)}
						disabled={viewLoan}
					/>
					<PrimaryButton
						text={viewLoan ? "Continue" : "Save and Continue"}
						endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
						onClick={() =>
							viewLoan ? handleNextView() : checkReissueForDisclaimer()
						}
						extraClass={styles.continueBtn}
					/>
				</Stack>
			</Stack>

			{/* modals */}
			<LoaderModal
				open={
					loadingBorrowerPairData ||
					loadingloanData ||
					isFetchingFields ||
					isUpdatingFields ||
					updatingBorrowerPair ||
					isFetchingCreditVendors ||
					isCreatingOrderService ||
					isCheckingCreditReissueStatus
				}
			/>
			<ErrorModal
				open={errorModalVisible}
				text={errorModalText}
				handleClose={handleCloseErrorModal}
			/>
			<SuccessModal
				open={successModalVisible}
				text={successModalText}
				handleClose={handleCloseSuccessModal}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText="Reissue credit to all borrowers"
				handleConfirm={handleSubmitConfirmModal}
				closeText="Continue"
				handleClose={handleCloseConfirmModal}
			/>
		</Stack>
	);
};

export default CreditReissueApplication;
