import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import PrimaryButton from "../../buttons/primary-button";
import TertiaryButton from "../../buttons/tertiary-button";
import CustomTable from "../../custom-table";
import CustomDivider from "../../custom-divider";
import Heading3 from "../../typography/heading-03";
import Heading4 from "../../typography/heading-04";
import Body1 from "../../typography/body-01";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useGetAttachmentsMutation } from "../../../features/get-attachments/getAttachmentsApiSlice";
import { useDownloadAttachmentMutation } from "../../../features/download-attachment/downloadAttachmentApiSlice";

// helper functions
import { filterValue } from "../../../utils/filter-value";
import { formatCurrency } from "../../../utils/format-currency";
import {
	appraisal1Fields,
	appraisal2Fields,
	appraisal3Fields,
	appraisalFieldTitle,
} from "../../../utils/appraisalsConstants";

// custom styles
import styles from "./index.module.scss";

const AppraisalsCard = ({
	handleTabChange,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
	handleOpenErrorModal,
	setErrorModalText,
}) => {
	const params = useParams();

	const [field] = useFieldMutation();
	const [getAttachments] = useGetAttachmentsMutation();
	const [downloadAttachment] = useDownloadAttachmentMutation();

	const [fieldData, setFieldData] = useState([]);
	const [docsList, setDocsList] = useState([]);

	const documentColumns = [
		{ id: "date", label: "Date", minWidth: 165 },
		{ id: "name", label: "Document Name", minWidth: 310 },
		{ id: "download", label: "", minWidth: 165 },
	];

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error getting Appraisals date");
	};

	const handleDownloadClick = async (documentId) => {
		var formData = new FormData();
		formData.append("loan_guid", params?.id);
		formData.append("attachment_ids", documentId);

		try {
			const downloadAttachmentData = await downloadAttachment({
				formData,
			}).unwrap();

			let attachmentArr = downloadAttachmentData?.response?.attachments;

			for (let index = 0; index < attachmentArr.length; index++) {
				window.open(attachmentArr[index]?.originalUrls[0], "_blank").focus();
			}
		} catch (err) {
			console.log("95 err:", err);
		}
	};

	const getFieldValues = async () => {
		console.log("75 loan id:", params?.id);
		handleOpenLoadingDataModal();

		try {
			const getValues = await field({
				loan_guid: params?.id,
				fields:
					"CX.APPR1.TYPE,CX.APPR1.STATUS,CX.APPR1.STATUSDT,CX.APPR1.FOLLOWUPDT,CX.APPR1.ORDERDATE,CX.APPR1.INSPECTDATE,CX.APPR1.DUEDATE,CX.APPR1.RECDATE,CX.APPR1.PRICE,CX.APPR1.PDAMT,CX.APPR2.TYPE,CX.APPR2.STATUS,CX.APPR2.STATUSDT,CX.APPR2.FOLLOWUPDT,CX.APPR2.ORDERDATE,CX.APPR2.INSPECTDATE,CX.APPR2.DUEDATE,CX.APPR2.RECDATE,CX.APPR2.PRICE,CX.APPR2.PDAMT,CX.APPR3.TYPE,CX.APPR3.STATUS,CX.APPR3.STATUS.DT,CX.APPR3.FOLLOWDT,CX.APPR3.ORDERDATE,CX.APPR3.INSPECTDATE,CX.APPR3.DUEDATE,CX.APPR3.RECDATE,CX.APPR3.PRICE,CX.APPR3.PDAMT",
			}).unwrap();

			console.log("75 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setFieldData(getValues?.response);
				handleCloseLoadingDataModal();
				setLoadingDataText("Setting up the lock info");
			}
		} catch (err) {
			console.log("270 error:", err);
			handleErrorModal(err);
		}
	};

	const getDocumentsList = async () => {
		const formData = new FormData();
		formData.append("loan_guid", params?.id);

		try {
			const uploadedDocsList = await getAttachments({ formData }).unwrap();

			console.log("75 all fields:", uploadedDocsList?.response);

			if (uploadedDocsList?.response?.length > 0) {
				setDocsList(
					uploadedDocsList?.response?.filter(
						(item) => item?.assignedTo?.entityName === "Property - Appraisal"
					)
				);
			}
		} catch (err) {
			console.log("260 error:", err);
			handleErrorModal(err);
		}
	};

	useEffect(() => {
		getFieldValues();
		getDocumentsList();
	}, []);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Appraisals"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
					<PrimaryButton
						text="Order Appraisal & Check Status"
						onClick={(e) =>
							window.open("https://lender.reggora.com/auth/login", "_blank")
						}
					/>
				</Stack>
			</Stack>

			<Stack direction="column" className={styles.bottomContainer}>
				<Stack direction="column" className={styles.infoContainer}>
					<Heading4
						text="Appraisal #1"
						fontType="semibold"
						extraClass={styles.containerHeader}
					/>

					<Stack direction="row" columnGap={8} flexWrap="wrap">
						<Stack direction="column">
							{/* Type of Appraisal */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.typeOfAppraisal}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(
												fieldData,
												appraisal1Fields.typeOfAppraisal
											) || ""
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							{/* Order Status */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.orderStatus}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(fieldData, appraisal1Fields.orderStatus) || ""
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							{/* Status Date*/}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.statusDate}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(fieldData, appraisal1Fields.statusDate)
												? DateTime.fromJSDate(
														new Date(
															filterValue(
																fieldData,
																appraisal1Fields.statusDate
															)
														)
												  )?.toFormat("L/d/yyyy")
												: "//"
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							{/* Follow-Up Date */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.followUpDate}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(fieldData, appraisal1Fields.followUpDate)
												? DateTime.fromJSDate(
														new Date(
															filterValue(
																fieldData,
																appraisal1Fields.followUpDate
															)
														)
												  )?.toFormat("L/d/yyyy")
												: "//"
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							{/* Order Date */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.orderDate}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(fieldData, appraisal1Fields.orderDate)
												? DateTime.fromJSDate(
														new Date(
															filterValue(fieldData, appraisal1Fields.orderDate)
														)
												  )?.toFormat("L/d/yyyy")
												: "//"
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							{/* Inspection Date */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.inspectionDate}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(fieldData, appraisal1Fields.inspectionDate)
												? DateTime.fromJSDate(
														new Date(
															filterValue(
																fieldData,
																appraisal1Fields.inspectionDate
															)
														)
												  )?.toFormat("L/d/yyyy")
												: "//"
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>
						</Stack>

						<Stack direction="column">
							{/* Due Date */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.dueDate}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(fieldData, appraisal1Fields.dueDate)
												? DateTime.fromJSDate(
														new Date(
															filterValue(fieldData, appraisal1Fields.dueDate)
														)
												  )?.toFormat("L/d/yyyy")
												: "//"
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>
							{/* Received Date */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.receivedDate}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={
											filterValue(fieldData, appraisal1Fields.receivedDate)
												? DateTime.fromJSDate(
														new Date(
															filterValue(
																fieldData,
																appraisal1Fields.receivedDate
															)
														)
												  )?.toFormat("L/d/yyyy")
												: "//"
										}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>
							{/* Cost */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.cost}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={formatCurrency.format(
											Number(filterValue(fieldData, appraisal1Fields.cost) || 0)
										)}
									/>
								</Box>
							</Stack>
							{/* Amount Paid */}
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text={appraisalFieldTitle.amountPaid}
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={formatCurrency.format(
											Number(
												filterValue(fieldData, appraisal1Fields.amountPaid) || 0
											)
										)}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				{filterValue(fieldData, appraisal2Fields.typeOfAppraisal) && (
					<Stack direction="column" className={styles.infoContainer}>
						<CustomDivider extraClass={styles.divider} />

						<Heading4
							text="Appraisal #2"
							fontType="semibold"
							extraClass={styles.containerHeader}
						/>

						<Stack direction="row" columnGap={8} flexWrap="wrap">
							<Stack direction="column">
								{/* Type of Appraisal */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.typeOfAppraisal}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(
													fieldData,
													appraisal2Fields.typeOfAppraisal
												) || ""
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Order Status */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.orderStatus}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal2Fields.orderStatus) ||
												""
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Status Date*/}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.statusDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal2Fields.statusDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal2Fields.statusDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Follow-Up Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.followUpDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal2Fields.followUpDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal2Fields.followUpDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Order Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.orderDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal2Fields.orderDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal2Fields.orderDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Inspection Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.inspectionDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal2Fields.inspectionDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal2Fields.inspectionDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>
							</Stack>
							<Stack direction="column">
								{/* Due Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.dueDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal2Fields.dueDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(fieldData, appraisal2Fields.dueDate)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Received Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.receivedDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal2Fields.receivedDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal2Fields.receivedDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Cost */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.cost}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={formatCurrency.format(
												Number(
													filterValue(fieldData, appraisal2Fields.cost) || 0
												)
											)}
										/>
									</Box>
								</Stack>

								{/* Amount Paid */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.amountPaid}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={formatCurrency.format(
												Number(
													filterValue(fieldData, appraisal2Fields.amountPaid) ||
														0
												)
											)}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				)}

				{filterValue(fieldData, appraisal3Fields.typeOfAppraisal) && (
					<Stack direction="column" className={styles.infoContainer}>
						<CustomDivider extraClass={styles.divider} />

						<Heading4
							text="Appraisal #3"
							fontType="semibold"
							extraClass={styles.containerHeader}
						/>

						<Stack direction="row" columnGap={8} flexWrap="wrap">
							<Stack direction="column">
								{/* Type of Appraisal */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.typeOfAppraisal}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(
													fieldData,
													appraisal3Fields.typeOfAppraisal
												) || ""
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Order Status */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.orderStatus}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal3Fields.orderStatus) ||
												""
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Status Date*/}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.statusDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal3Fields.statusDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal3Fields.statusDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Follow-Up Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.followUpDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal3Fields.followUpDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal3Fields.followUpDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Order Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.orderDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal3Fields.orderDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal3Fields.orderDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Inspection Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.inspectionDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal3Fields.inspectionDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal3Fields.inspectionDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText2}
										/>
									</Box>
								</Stack>
							</Stack>
							<Stack direction="column">
								{/* Due Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.dueDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal3Fields.dueDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(fieldData, appraisal3Fields.dueDate)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Received Date */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.receivedDate}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={
												filterValue(fieldData, appraisal3Fields.receivedDate)
													? DateTime.fromJSDate(
															new Date(
																filterValue(
																	fieldData,
																	appraisal3Fields.receivedDate
																)
															)
													  )?.toFormat("L/d/yyyy")
													: "//"
											}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>

								{/* Cost */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.cost}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={formatCurrency.format(
												Number(
													filterValue(fieldData, appraisal3Fields.cost) || 0
												)
											)}
										/>
									</Box>
								</Stack>

								{/* Amount Paid */}
								<Stack
									direction="row"
									alignItems="center"
									className={styles.infoDetailContainer}
								>
									<Box className={styles.infoDetailTitle}>
										<Body1
											text={appraisalFieldTitle.amountPaid}
											extraClass={styles.labelText}
										/>
									</Box>
									<Box className={styles.infoDetailValue}>
										<Body1
											text={formatCurrency.format(
												Number(
													filterValue(fieldData, appraisal3Fields.amountPaid) ||
														0
												)
											)}
											extraClass={styles.valueText}
										/>
									</Box>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				)}

				<CustomDivider extraClass={styles.divider} />
				<Stack direction="column" className={styles.infoContainer}>
					<Heading4
						text="Documents"
						fontType="semibold"
						extraClass={styles.containerHeader}
					/>

					<CustomTable
						columnData={documentColumns}
						rowData={docsList}
						tableType="appraisal-documents"
						handleDownload={handleDownloadClick}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default AppraisalsCard;
