import { apiSlice } from "../../app/api/apiSlice";

export const getAttachmentsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAttachments: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/get_attachments/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useGetAttachmentsMutation } = getAttachmentsApiSlice;
