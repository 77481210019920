import React from "react";

// mui components
import { Modal, Stack } from "@mui/material";
import { HelpOutlineOutlined } from "@mui/icons-material";

// custom components
import PrimaryButton from "../../buttons/primary-button";
import Heading4 from "../../typography/heading-04";
import Body2 from "../../typography/body-02";

// custom styles
import styles from "./index.module.scss";

export default function HelpTextModal({
	open,
	modalText,
	extraHelpText,
	handleClose,
}) {
	return (
		<Modal
			open={open}
			aria-labelledby="loading modal"
			aria-describedby="loading modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					className={styles.modalHeadingContainer}
				>
					<HelpOutlineOutlined className={styles.modalHeadingIcon} />
					<Heading4
						text="Help Text"
						fontType="semibold"
						extraClass={styles.modalHeadingText}
					/>
				</Stack>
				<Stack
					direction="column"
					alignItems="center"
					className={styles.modalText}
				>
					<Body2 extraClass={styles.helpCentreText} text={modalText} />
					<br />
					{extraHelpText && (
						<Body2 extraClass={styles.helpCentreText} text={extraHelpText} />
					)}
				</Stack>
				<PrimaryButton
					text="Return to portal"
					extraClass={styles.backButton}
					onClick={handleClose}
				/>
			</Stack>
		</Modal>
	);
}
