import { apiSlice } from "../../app/api/apiSlice";

export const checkOrderStatusApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		checkOrderStatus: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/credit_order_status/partner/${credentials.providerId}/transaction/${credentials.transactionId}/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useCheckOrderStatusMutation } =
	checkOrderStatusApiSlice;
