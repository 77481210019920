import { createSlice } from "@reduxjs/toolkit";

const categoryCondition = createSlice({
	name: "categoryCondition",
	initialState: {
		data: "",
	},
	reducers: {
		setCategoryCondition: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setCategoryCondition } = categoryCondition.actions;
export default categoryCondition.reducer;

export const selectCategoryCondition = (state) => state.categoryCondition?.data;
