import { apiSlice } from "../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBrokerList: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/admin/broker_search?organization=${credentials?.companyName}&email=${credentials?.email}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetBrokerListMutation } = applicationApiSlice;
