import { apiSlice } from "../../../app/api/apiSlice";

export const renameRepositoryFileApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		renameRepositoryFile: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/admin/update_resource_document/${credentials?.itemId}/`,
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useRenameRepositoryFileMutation } = renameRepositoryFileApiSlice;
