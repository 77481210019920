import { apiSlice } from "../../app/api/apiSlice";

export const populateFieldsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		populateFields: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/populate_custom_fields/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { usePopulateFieldsMutation } = populateFieldsApiSlice;
