import React from "react";

// mui components
import { Stack } from "@mui/material";

// custom components
import Heading4 from "../typography/heading-04";
import Heading6 from "../typography/heading-06";

// custom styles
import styles from "./index.module.scss";

const EmptyTextContainer = ({ headerText, bodyText }) => {
	return (
		<Stack className={styles.emptyContainer}>
			<Heading4
				text={headerText}
				fontType="semibold"
				extraClass={styles.emptyHeaderText}
			/>
			<Heading6
				text={bodyText}
				fontType="semibold"
				extraClass={styles.emptyBodyText}
			/>
		</Stack>
	);
};

export default EmptyTextContainer;
