import { createSlice } from "@reduxjs/toolkit";

const loanDocsSlice = createSlice({
	name: "uploadDocs",
	initialState: { files: null },
	reducers: {
		setLoanDocsReducer: (state, action) => {
			console.log("17 action:", action);
			const data = action.payload;
			console.log("10 data:", data);
			state.files = data;
		},
	},
});

export const { setLoanDocsReducer } = loanDocsSlice.actions;

export default loanDocsSlice.reducer;

export const selectLoanDocs = (state) => state.loanDocs.files;
