import React, { useState } from "react";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";

// helper utils
import { creditReportOnOptions } from "../../../utils/select-options";
import { isEmptyOrUndefined } from "../../../utils/filter-value";
import {
	CLIENT_ID_ENABLED_PARTNERS,
	CREDIT_REISSUE_REPORT_ON_CONSTANTS,
	DEFAULT_CREDIT_CREDENTIALS_FORM,
} from "../../../utils/credit-reissue-constants";

// custom styles
import styles from "./index.module.scss";

const CreditCredentialsInformation = ({
	creditCredentialsDataIndex,
	creditCredentialsData,
	setCreditCredentialsData,
	creditVendorsData,
	loanApplicationData,
	disabled,
	error,
}) => {
	const [selectedVendor, setSelectedVendor] = useState({});

	const setCreditVendor = (vendorName) => {
		const vendor = creditVendorsData.find(
			(item) => item.partner_name === vendorName
		);
		setSelectedVendor(vendor);
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="row" className={styles.cardHeaderContainer}>
				<Heading5 text={"Credit Credentials"} fontType="semibold" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* Credit Credentials Information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* Credit Report Identifier */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-credit-report-id`}>
							<Heading6
								text="Credit Report Identifier"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`outlined-input-credit-report-id`}
							placeholder="Credit Report Identifier"
							value={
								creditCredentialsData[creditCredentialsDataIndex]
									?.creditReportIdentifier
							}
							handleChange={(event) =>
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...data,
												creditReportIdentifier: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]
										?.creditReportIdentifier
								)
							}
						/>
					</Stack>

					{/* Credit Vendor */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-credit-vendor`}>
							<Heading6
								text="Credit Vendor"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id={`outlined-input-credit-vendor`}
							placeholder="Credit Vendor"
							displayEmpty={true}
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.creditVendor
							}
							handleChange={(event) => {
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...DEFAULT_CREDIT_CREDENTIALS_FORM,
												creditVendor: event.target.value,
												providerId:
													creditVendorsData.find(
														(item) => item.partner_name === event.target.value
													).id || "",
												productName:
													creditVendorsData.find(
														(item) => item.partner_name === event.target.value
													)?.product_name || "",
												reportOn: loanApplicationData[
													creditCredentialsDataIndex * 2 + 1
												]?.firstName
													? CREDIT_REISSUE_REPORT_ON_CONSTANTS.JOINT
													: CREDIT_REISSUE_REPORT_ON_CONSTANTS.INDIVIDUAL,
											};
										} else {
											return data;
										}
									})
								);
								setCreditVendor(event.target.value);
							}}
							options={creditVendorsData.reduce((acc, item) => {
								return [
									...acc,
									{
										label: item.partner_name,
										value: item.partner_name,
									},
								];
							}, [])}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]
										?.creditVendor
								)
							}
						/>
					</Stack>

					{/* Client Id */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-client-id`}>
							<Heading6
								text="Client ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`outlined-input-client-id`}
							placeholder="Client ID"
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.clientID
							}
							handleChange={(event) =>
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...data,
												clientID: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={
								disabled ||
								!CLIENT_ID_ENABLED_PARTNERS.includes(
									selectedVendor.partner_name
								)
							}
							error={
								CLIENT_ID_ENABLED_PARTNERS.includes(
									selectedVendor.partner_name
								) &&
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]?.clientID
								)
							}
						/>
					</Stack>

					{/* Subscriber Id */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-subscriber-id`}>
							<Heading6
								text="Subscriber ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`outlined-input-subscriber-id`}
							placeholder="Subscriber ID"
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.subscriberId
							}
							handleChange={(event) =>
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...data,
												subscriberId: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={
								disabled ||
								!selectedVendor?.required_fields?.includes("subscriberId")
							}
							error={
								selectedVendor?.required_fields?.includes("subscriberId") &&
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]
										?.subscriberId
								)
							}
						/>
					</Stack>

					{/* Login Id */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-login-id`}>
							<Heading6
								text="Login ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`outlined-input-login-id`}
							placeholder="Login ID"
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.userName
							}
							handleChange={(event) =>
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...data,
												userName: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]?.userName
								)
							}
						/>
					</Stack>

					{/* Password */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-password`}>
							<Heading6
								text="Password"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`outlined-input-password`}
							placeholder="Password"
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.password
							}
							handleChange={(event) =>
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...data,
												password: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]?.password
								)
							}
						/>
					</Stack>

					{/* Report On */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-report-on`}>
							<Heading6
								text="Report On"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id={`outlined-input-report-on`}
							placeholder="Report On"
							displayEmpty={true}
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.reportOn ||
								""
							}
							handleChange={(event) =>
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...data,
												reportOn: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							options={creditReportOnOptions}
							disabled={disabled}
							error={
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]?.reportOn
								)
							}
						/>
					</Stack>

					{/* Product Code */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-product-code`}>
							<Heading6
								text="Product Code"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`outlined-input-product-code`}
							placeholder="Product Code"
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.productCode
							}
							handleChange={(event) =>
								setCreditCredentialsData(
									creditCredentialsData.map((data, index) => {
										if (creditCredentialsDataIndex === index) {
											return {
												...data,
												productCode: event.target.value,
											};
										} else {
											return data;
										}
									})
								)
							}
							disabled={
								disabled ||
								!selectedVendor?.required_fields?.includes("productCode")
							}
							error={
								selectedVendor?.required_fields?.includes("productCode") &&
								error &&
								isEmptyOrUndefined(
									creditCredentialsData[creditCredentialsDataIndex]?.productCode
								)
							}
						/>
					</Stack>

					{/* Provider Id */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor={`outlined-input-provider-id`}>
							<Heading6
								text="Provider ID"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							type="text"
							id={`outlined-input-provider-id`}
							placeholder="Provider ID"
							value={
								creditCredentialsData[creditCredentialsDataIndex]?.providerId
							}
							disabled={true}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
};

export default CreditCredentialsInformation;
