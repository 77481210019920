import React from "react";

// mui components
import { Checkbox, TableCell, TableRow } from "@mui/material";

// custom components
import SecondarySelect from "../../custom-select/secondary-select";
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import CustomCheckbox from "../../custom-checkbox";

// helper utils
import {
	liabilityTypeOptions,
	lienPositionOptions,
	ownerOptions,
} from "../../../utils/select-options";

// custom styles
import styles from "../index.module.scss";

const LinkLiabilityRow = ({
	row,
	index,
	selectedRealEstate,
	handleSelectLiability,
}) => {
	const labelId = `enhanced-table-checkbox-${row?.id || "0"}`;

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={() => handleSelectLiability(row)}
			role="checkbox"
			selected={row?.reoId === selectedRealEstate}
			className={row?.reoId === selectedRealEstate ? styles.highlightRow : ""}
		>
			{/* checkbox */}
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={row?.reoId === selectedRealEstate}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>

			{/* account owner */}
			<TableCell>
				<SecondarySelect
					value={row?.owner}
					handleChange={() => console.log("50 liability owner")}
					options={ownerOptions}
					displayEmpty={true}
					disabled={true}
				/>
			</TableCell>

			{/* account type */}
			<TableCell>
				<SecondarySelect
					value={row?.liabilityType}
					handleChange={() => console.log("50 liability type")}
					options={liabilityTypeOptions}
					displayEmpty={true}
					disabled={true}
				/>
			</TableCell>

			{/* company name */}
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.holderName}
					handleChange={() => console.log("50 liability holder")}
					disabled={true}
				/>
			</TableCell>

			{/* account number */}
			<TableCell>
				<OutlinedTextInput
					type="text"
					value={row?.accountIdentifier}
					handleChange={() => console.log("50 liability account number")}
					disabled={true}
				/>
			</TableCell>

			{/* unpaid balance */}
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.unpaidBalanceAmount}
					onInputChange={() => console.log("50 liability account number")}
					disabled={true}
				/>
			</TableCell>

			{/* paid off checkbox */}
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.payoffStatusIndicator || false}
					handleChange={() => console.log("50 liability account number")}
					disabled={true}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>

			{/* monthly amount */}
			<TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.monthlyPaymentAmount?.toString()}
					onInputChange={() => console.log("50 liability account number")}
					disabled={true}
				/>
			</TableCell>

			{/* included in RE owned */}
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.reoId || false}
					handleChange={() => console.log("120 reo id:", row?.reoId)}
					disabled={true}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>

			{/* exclusion DTI */}
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.exclusionIndicator || false}
					handleChange={() => console.log(!row?.exclusionIndicator)}
					extraClass={styles.checkboxInput}
					disabled={true}
				/>
			</TableCell>

			{/* resubordination checkbox */}
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.subjectLoanResubordinationIndicator}
					handleChange={() =>
						console.log(!row?.subjectLoanResubordinationIndicator)
					}
					extraClass={styles.checkboxInput}
					disabled={true}
				/>
			</TableCell>

			{/* Bank Liability Considered */}
			<TableCell align="center">
				<CustomCheckbox
					checked={row?.bankLiabilityConsideredIndicator || false}
					handleChange={() =>
						console.log(!row?.bankLiabilityConsideredIndicator)
					}
					extraClass={styles.checkboxInput}
					disabled={true}
				/>
			</TableCell>

			{/* current lien position */}
			<TableCell align="center">
				<SecondarySelect
					value={row?.currentLienPosition || ""}
					handleChange={() => console.log(row?.currentLienPosition)}
					options={lienPositionOptions}
					displayEmpty={true}
					disabled={true}
				/>
			</TableCell>

			{/* proposed lien position */}
			<TableCell align="center">
				<SecondarySelect
					value={row?.proposedLienPosition || ""}
					handleChange={() => console.log(row?.currentLienPosition)}
					options={lienPositionOptions}
					displayEmpty={true}
					disabled={true}
				/>
			</TableCell>
		</TableRow>
	);
};

export default LinkLiabilityRow;
