import React from "react";

// mui components
import { Modal, Stack, Box } from "@mui/material";

// custom components
import Heading2 from "../../typography/heading-02";
import Body1 from "../../typography/body-01";
import PrimaryButton from "../../buttons/primary-button";

// custom animations
import Lottie from "lottie-react";
import successAnimation from "../../../assets/animations/success.json";

// custom styles
import styles from "./index.module.scss";

export default function SuccessModal({ open, handleClose, text, confirmText }) {
	return (
		<Modal
			open={open}
			aria-labelledby="success modal"
			aria-describedby="success modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Box className={styles.animationContainer}>
					<Lottie animationData={successAnimation} loop={true} />
				</Box>

				<Stack
					direction="column"
					alignItems="center"
					className={styles.textContainer}
				>
					<Heading2
						text="Success!"
						extraClass={styles.successText}
						fontType="semibold"
					/>

					<Body1 text={text} extraClass={styles.successSubText} />
				</Stack>

				<PrimaryButton
					text={confirmText ? confirmText : "OK"}
					onClick={handleClose}
					fullWidth={true}
				/>
			</Stack>
		</Modal>
	);
}
