import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// mui components
import { Box, Stack } from "@mui/material";

// api slice
import { useUpdateFieldsMutation } from "../../../../features/update-fields/updateFieldsApiSlice";
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";

// reducer slice
import { getFieldValues } from "../../../../app/services/field-values";

// custom components
import OutlinedTextarea from "../../../custom-input/outlined-textarea";
import SecondaryButton from "../../../buttons/secondary-button";
import PrimaryButton from "../../../buttons/primary-button";
import Heading3 from "../../../typography/heading-03";

// helper utils
import { filterValue } from "../../../../utils/filter-value";

// custom styles
import styles from "./index.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";

const RequestClosingDateDrawer = ({
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	handleOpenSuccessModal,
	setProcessingText,
	setSuccessText,
	// error
	handleOpenErrorModal,
	setErrorText,
}) => {
	const params = useParams();

	const [closingDate, setClosingDate] = useState("");
	const [comment, setComment] = useState("");

	const [updateFields] = useUpdateFieldsMutation();
	const [field] = useFieldMutation();

	const handleCancel = () => {
		console.log("50 cancel clicked.");

		setClosingDate("");
		setComment("");
	};

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseProcessingModal();
		handleOpenErrorModal();
		setErrorText("There was an error getting change requests");
	};

	const getClosingDateDrawerValues = async () => {
		console.log("75 loan id:", params?.id);

		try {
			const getValues = await field({
				loan_guid: params?.id,
				fields: "CX.REQUESTED.CLOSING.DATE,CX.REQ.CLOSING.DATE.COMMENTS",
			}).unwrap();

			console.log("75 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setClosingDate(
					filterValue(getValues?.response, "CX.REQUESTED.CLOSING.DATE")
				);
				setComment(
					filterValue(getValues?.response, "CX.REQ.CLOSING.DATE.COMMENTS")
				);
				handleCloseProcessingModal();
			}
		} catch (err) {
			console.log("270 error:", err);
			handleErrorModal(err);
		}
	};

	useEffect(() => {
		handleOpenProcessingModal();
		getClosingDateDrawerValues();
	}, []);

	// upload documents
	const handleSubmit = async () => {
		if (comment?.length === 0) return;

		console.log("315 running:", closingDate, comment);

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Your request is being processed");

		let valuesArr = [
			{
				id: "CX.REQUESTED.CLOSING.DATE",
				value: DateTime.fromJSDate(new Date(closingDate)).toFormat(
					"yyyy-MM-dd"
				),
			},
			{ id: "CX.REQ.CLOSING.DATE.COMMENTS", value: comment },
		];

		// dispatch API and open modal if successful
		try {
			// uploaded successfully
			const updateFieldValues = await updateFields({
				loan_guid: params?.id,
				field_values: valuesArr,
			}).unwrap();

			console.log("90 updated values:", updateFieldValues);

			if (updateFieldValues?.response?.includes("Something went wrong")) {
				handleCloseProcessingModal();

				// Find the index of the first '{' character
				const startIndex = updateFieldValues?.response.indexOf("{");
				// Extract the JSON substring
				const jsonString = updateFieldValues?.response.slice(startIndex);
				// Parse the JSON substring
				const jsonObject = JSON.parse(jsonString);

				console.log("125 json:", jsonObject);

				setErrorText(jsonObject?.details || "Loan is locked on Encompass.");
				handleOpenErrorModal();
				return;
			}

			setClosingDate("");
			setComment("");

			getFieldValues({
				loanGuid: params?.id,
				fieldList:
					"763,CX.REQUESTED.CLOSING.DATE,CX.REQ.CLOSING.DATE.COMMENTS,CX.SCHEDULED.CLOSING.DATE,CX.RESCHEDULED.CLOSING.DATE,CX.Closing.Docs.Sent.Date,CX.WIRE.REQUEST.DATE,748,411,416,88,417,412,413,1174,414,Log.MS.CurrentMilestone",
				getFieldValueApi: field,
				handleErrorModal: handleErrorModal,
			});

			handleCloseProcessingModal();
			handleOpenSuccessModal();
			setSuccessText(
				"Your request has been received. You will be notified once closing has been confirmed and scheduled."
			);
		} catch (err) {
			console.log("95 err:", err);
		}
	};

	// Get the current date and time in EST timezone
	const currentDate = new Date().toLocaleString("en-US", {
		timeZone: "America/New_York",
	});

	// Extract the current hour from the current date
	const currentHour = new Date(currentDate).getHours();

	// Get tomorrow's date
	const tomorrowDate = new Date();
	tomorrowDate.setDate(tomorrowDate.getDate() + 1);

	// Disable tomorrow's date if the current time is past 2 PM EST
	const isPast2PM = currentHour >= 14; // 14 is 2 PM in 24-hour format

	console.log("180 is past 2pm:", isPast2PM);

	console.log("185 new Date:", new Date());
	console.log("185 tomorrow:", tomorrowDate);

	const disabledDates = isPast2PM ? [new Date(), tomorrowDate] : [new Date()];

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Request Closing Date"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>

			<Stack direction="column" className={styles.drawerBodyContainer}>
				<Box className={styles.headerContainer}>
					<Heading3
						text="Requested closing date:"
						extraClass={styles.labelText}
						fontType="semibold"
					/>
				</Box>

				{/* closing date */}
				<Box className={styles.datepickerContainer}>
					<DatePicker
						selected={
							closingDate !== null &&
							closingDate !== undefined &&
							closingDate !== ""
								? new Date(closingDate)
								: ""
						}
						dateFormat="L/d/yyyy"
						onChange={(date) => setClosingDate(date || "")}
						className={styles.datepicker}
						excludeDates={disabledDates}
						disabledKeyboardNavigation={true}
						showMonthDropdown={true}
						showYearDropdown={true}
						dropdownMode="select"
						highlightDates={[closingDate]}
						placeholderText="Closing Date"
					/>
				</Box>

				<Box className={styles.headerContainer}>
					<Heading3
						text="Closing date comments:"
						extraClass={styles.labelText}
						fontType="semibold"
					/>
				</Box>

				{/* closing comments */}
				<OutlinedTextarea
					extraClass={styles.changeCommentInput}
					variant="outlined"
					placeholder="Please tell us anything we need to know about this closing, or provide updated contact information for Closing Agent"
					multiline={true}
					rows={5}
					value={comment}
					inputProps={{ maxLength: 250 }}
					handleChange={(e) => setComment(e.target.value)}
				/>
			</Stack>

			{/* footer container */}
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				className={styles.drawerFooterContainer}
			>
				<SecondaryButton
					text="Reset"
					extraClass={styles.cancelBtn}
					extraTextClass={styles.cancelText}
					onClick={handleCancel}
					disabled={!(closingDate !== "" || comment !== "")}
				/>
				<PrimaryButton
					text="Submit"
					onClick={handleSubmit}
					disabled={closingDate === "" || comment === ""}
				/>
			</Stack>
		</Stack>
	);
};

export default RequestClosingDateDrawer;
