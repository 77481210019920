import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// redux slice
import { logOut, selectUserDetails } from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// local storage utils
import { storageDelete } from "../../utils/localStorage";

// mui components
import {
	Box,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
} from "@mui/material";

// custom components
import Heading5 from "../typography/heading-05";

// custom modal
import HelpCenterModal from "../modal/help-center-modal";

// mui icons
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { HelpOutlineOutlined } from "@mui/icons-material";

// custom icons
import LoganLogo from "../../assets/icons/logo";

// drawer data
import { drawerTopData, drawerBottomData } from "./drawer-data";

// helper utils
import { selectedUserWithLoanChannel } from "../../utils/common-helpers";
import { LOAN_CHANNEL_CONSTANTS } from "../../utils/common-constants";

// custom styles
import styles from "./index.module.scss";

const CustomDrawer = ({
	drawerWidth,
	handleDrawerItemClick,
	mobileOpen,
	handleDrawerToggle,
}) => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const [currentPath, setCurrentPath] = useState("");
	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);
	const [helpCenterOpened, setHelpCenterOpened] = useState(false);

	const [drawerTopItems, setDrawerTopItems] = useState(drawerTopData);

	useEffect(() => {
		if (
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
		) {
			let filteredData = drawerTopData?.filter(
				(item) => item?.title !== "Order Appraisal"
			);
			setDrawerTopItems(filteredData);
		} else {
			setDrawerTopItems(drawerTopData);
		}
	}, [userDetails, impersonatedUser]);

	useEffect(() => {
		let location = pathname.split("/");
		console.log("92 pathname", location[1]);
		setCurrentPath(location[1]);
	}, [pathname]);

	const isCurrentPath = (loanUrl) => {
		if (typeof loanUrl === "string") {
			return currentPath === loanUrl?.toLowerCase();
		} else {
			return loanUrl.some((url) => pathname.includes(url));
		}
	};

	const handleLogout = () => {
		dispatch(logOut());
		storageDelete("access-token");
		storageDelete("refresh-token");
	};

	const shouldRenderItem = (item) => {
		const isExecutiveOrManager =
			userDetails?.user_roles?.includes("executive") ||
			userDetails?.user_roles?.includes("manager") ||
			userDetails?.user_roles?.includes("AE") ||
			userDetails?.user_roles?.includes("AM");

		if (item?.url === "new-loan") {
			// does not render for executive or manager
			return !isExecutiveOrManager;
		}

		if (item?.title === "Order Appraisal") {
			// does not render for executive or manager
			return !isExecutiveOrManager;
		}

		if (item?.title === "Schedule Training") {
			// does not render for executive or manager
			return !isExecutiveOrManager;
		}

		if (item?.title === "Price my Loan") {
			// does not render for either NDC or CORR users
			return !(
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
			);
		}

		return true;
	};

	const drawer = (
		<Box className={styles.drawerDiv}>
			<Box className={styles.logoContainer}>
				<LoganLogo extraStyles={{ width: "90%" }} />
			</Box>

			<List className={styles.drawerList}>
				{drawerTopItems?.map((item, index) => {
					if (shouldRenderItem(item)) {
						return (
							<ListItem
								key={index}
								disablePadding
								className={
									isCurrentPath(item.url)
										? styles.drawerSelectedItem
										: styles.drawerItem
								}
							>
								<ListItemButton
									onClick={() => handleDrawerItemClick(item.title, item.path)}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<Heading5
										text={item.title}
										fontType="semibold"
										extraClass={
											isCurrentPath(item.url)
												? styles.drawerSelectedText
												: styles.drawerText
										}
									/>
								</ListItemButton>
							</ListItem>
						);
					}
					return null;
				})}
			</List>
			<Divider className={styles.dividerLine} />
			<List className={styles.drawerList}>
				<ListItem disablePadding className={styles.drawerItem}>
					<ListItemButton onClick={handleLogout}>
						<ListItemIcon>
							<LogoutOutlinedIcon sx={{ fontSize: "32px" }} />
						</ListItemIcon>
						<Heading5
							text="Log Out"
							fontType="semibold"
							extraClass={styles.drawerText}
						/>
					</ListItemButton>
				</ListItem>
				{userDetails?.user_roles?.includes("admin") &&
					drawerBottomData?.map((item, index) => (
						<ListItem
							key={index}
							disablePadding
							className={
								isCurrentPath(item.url)
									? styles.drawerSelectedItem
									: styles.drawerItem
							}
						>
							<ListItemButton
								onClick={() => handleDrawerItemClick(item.title, item.path)}
							>
								<ListItemIcon>{item.icon}</ListItemIcon>
								<Heading5
									text={item.title}
									fontType="semibold"
									extraClass={
										isCurrentPath(item.url)
											? styles.drawerSelectedText
											: styles.drawerText
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
			</List>
			<List className={`${styles.helpContainer} ${styles.drawerList}`}>
				<List disablePadding className={styles.drawerItem}>
					<ListItemButton onClick={() => setHelpCenterOpened(true)}>
						<ListItemIcon>
							<HelpOutlineOutlined />
						</ListItemIcon>
						<Heading5
							text="Help Center"
							fontType="semibold"
							extraClass={styles.drawerText}
						/>
					</ListItemButton>
				</List>
			</List>
		</Box>
	);

	return (
		<Box
			component="nav"
			sx={{
				width: { sm: drawerWidth },
				flexShrink: { sm: 0 },
			}}
			aria-label="mailbox folders"
		>
			<Drawer
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: "block", sm: "none" },
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
					},
				}}
				className={styles.drawerMobileContainer}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: "none", sm: "block" },
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
					},
				}}
				open
				className={styles.drawerContainer}
			>
				{drawer}
			</Drawer>
			<HelpCenterModal
				open={helpCenterOpened}
				showHelpCentre={setHelpCenterOpened}
			/>
		</Box>
	);
};

export default CustomDrawer;
