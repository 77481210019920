import { apiSlice } from "../../../app/api/apiSlice";

export const resetPasswordApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		resetPassword: builder.mutation({
			query: (credentials) => ({
				url: "api/v1/admin/reset_password/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useResetPasswordMutation } = resetPasswordApiSlice;
