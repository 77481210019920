import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import TertiaryButton from "../../buttons/tertiary-button";
import CustomDivider from "../../custom-divider";
import Heading3 from "../../typography/heading-03";
import Heading4 from "../../typography/heading-04";
import Body1 from "../../typography/body-01";

// helper functions
import { filterValue } from "../../../utils/filter-value";

// reducer slice
import { selectLockRequestLog } from "../../../features/lock-request-log/lockRequestLogSlice";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// custom styles
import styles from "./index.module.scss";

const LoanContactCard = ({
	handleTabChange,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
	handleOpenErrorModal,
	setErrorModalText,
}) => {
	const params = useParams();

	const [field] = useFieldMutation();

	const lockRequestLogData = useSelector(selectLockRequestLog);
	console.log("50 lockRequestLogData:", lockRequestLogData);

	const [fieldData, setFieldData] = useState([]);

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error getting important dates");
	};

	const getFieldValues = async () => {
		console.log("75 loan id:", params?.id);
		handleOpenLoadingDataModal();

		try {
			const getValues = await field({
				loan_guid: params?.id,
				fields:
					"LoanTeamMember.Email.TPO Account Exec,LoanTeamMember.Name.TPO Account Exec,LoanTeamMember.Email.TPO Account Manager,LoanTeamMember.Name.TPO Account Manager,LoanTeamMember.Email.Underwriter,LoanTeamMember.Name.Underwriter,LoanTeamMember.Email.Closer,LoanTeamMember.Name.Closer,TPO.X61,TPO.X63,TPO.X74,TPO.X76",
			}).unwrap();

			console.log("75 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setFieldData(getValues?.response);
				handleCloseLoadingDataModal();
				setLoadingDataText("Setting up the lock info");
			}
		} catch (err) {
			console.log("270 error:", err);
			handleErrorModal(err);
		}
	};

	useEffect(() => {
		getFieldValues();
	}, []);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Loan Contacts"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
				</Stack>
			</Stack>

			<Stack direction="column" className={styles.bottomContainer}>
				<Stack direction="column" className={styles.infoContainer}>
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="Logan Loan Contacts" fontType="semibold" />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="Email Address" fontType="semibold" />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="Name" fontType="semibold" />
						</Box>
					</Stack>

					{/* Account executive */}
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Account Executive" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href={`mailto:${
											filterValue(
												fieldData,
												"LoanTeamMember.Email.TPO Account Exec"
											) || ""
										}`}
										rel="noopener noreferrer"
										target="_top"
									>
										{filterValue(
											fieldData,
											"LoanTeamMember.Email.TPO Account Exec"
										) || ""}
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Name.TPO Account Exec"
									) || ""
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* Account manager */}
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Account Manager" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href={`mailto:${
											filterValue(
												fieldData,
												"LoanTeamMember.Email.TPO Account Manager"
											) || ""
										}`}
										rel="noopener noreferrer"
										target="_top"
									>
										{filterValue(
											fieldData,
											"LoanTeamMember.Email.TPO Account Manager"
										) || ""}
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									filterValue(
										fieldData,
										"LoanTeamMember.Name.TPO Account Manager"
									) || ""
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* Loan underwriter */}
					<Stack direction="row" className={styles.infoDetailRow}>
						<Stack className={styles.infoDetailCol}>
							<Body1 text="Loan Underwriter" extraClass={styles.labelText} />
						</Stack>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href={`mailto:${
											filterValue(
												fieldData,
												"LoanTeamMember.Email.Underwriter"
											) || ""
										}`}
										rel="noopener noreferrer"
										target="_top"
									>
										{filterValue(
											fieldData,
											"LoanTeamMember.Email.Underwriter"
										) || ""}
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Name.Underwriter") ||
									""
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* Loan closer */}
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Loan Closer" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href={`mailto:${
											filterValue(fieldData, "LoanTeamMember.Email.Closer") ||
											""
										}`}
										rel="noopener noreferrer"
										target="_top"
									>
										{filterValue(fieldData, "LoanTeamMember.Email.Closer") ||
											""}
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									filterValue(fieldData, "LoanTeamMember.Name.Closer") || ""
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>
				</Stack>

				<CustomDivider extraClass={styles.sectionHeaderLine} />

				<Stack direction="column" className={styles.infoContainer}>
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="Logan Departments" fontType="semibold" />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="Email Address" fontType="semibold" />
						</Box>
					</Stack>

					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="System Help Desk" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href="mailto:TPOPortalSupport@loganfinance.com"
										rel="noopener noreferrer"
										target="_top"
									>
										tpoportalsupport@loganfinance.com
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Pricing Desk" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href="mailto:LockDesk@loganfinance.com"
										rel="noopener noreferrer"
										target="_top"
									>
										lockdesk@loganfinance.com
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Closing Desk" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href="mailto:ClosingRequest@loganfinance.com"
										rel="noopener noreferrer"
										target="_top"
									>
										closingrequest@loganfinance.com
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Scenario Desk" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href="mailto:scenarios@loganfinance.com"
										rel="noopener noreferrer"
										target="_top"
									>
										scenarios@loganfinance.com
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1
								text="Project (Condo) Desk"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href="mailto:CondoReview@loganfinance.com"
										rel="noopener noreferrer"
										target="_top"
									>
										condoreview@loganfinance.com
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1
								text="Appraisal Support Desk"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={
									<a
										href="mailto:AppraisalSupport@loganfinance.com"
										rel="noopener noreferrer"
										target="_top"
									>
										appraisalsupport@loganfinance.com
									</a>
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>
				</Stack>

				<CustomDivider extraClass={styles.sectionHeaderLine} />

				<Stack direction="column" className={styles.infoContainer}>
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="TPO Contacts" fontType="semibold" />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="Email Address" fontType="semibold" />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Heading4 text="Name" fontType="semibold" />
						</Box>
					</Stack>

					{/* Loan Officer */}
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Loan Officer" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={filterValue(fieldData, "TPO.X63") || ""}
								extraClass={styles.valueText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={filterValue(fieldData, "TPO.X61") || ""}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{/* Processor */}
					<Stack direction="row" className={styles.infoDetailRow}>
						<Box className={styles.infoDetailCol}>
							<Body1 text="Processor" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={filterValue(fieldData, "TPO.X76") || ""}
								extraClass={styles.valueText}
							/>
						</Box>
						<Box className={styles.infoDetailCol}>
							<Body1
								text={filterValue(fieldData, "TPO.X74") || ""}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default LoanContactCard;
