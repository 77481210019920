import React from "react";

const LoginSkeleton = ({ extraStyles }) => {
	return (
		<svg
			width="385"
			height="385"
			viewBox="0 0 385 385"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={extraStyles}
		>
			<g filter="url(#filter0_d_0_1)">
				<path
					d="M21 25.465C21 17.4762 27.4762 11 35.465 11H376.297V366.297H35.465C27.4762 366.297 21 359.821 21 351.832V25.465Z"
					fill="#F7F7F7"
				/>
			</g>
			<rect
				x="65.2998"
				y="50.7793"
				width="158.211"
				height="36.1625"
				rx="7.23251"
				fill="#D9D9D9"
			/>
			<path
				d="M245.208 58.0118C245.208 54.0174 248.446 50.7793 252.441 50.7793H376.297V86.9418H252.441C248.446 86.9418 245.208 83.7037 245.208 79.7093V58.0118Z"
				fill="#D9D9D9"
			/>
			<path
				d="M376.298 133.049H79.7648C71.776 133.049 65.2998 139.525 65.2998 147.514V366.297"
				stroke="#D9D9D9"
				strokeWidth="1.80813"
			/>
			<defs>
				<filter
					id="filter0_d_0_1"
					x="0.206546"
					y="0.151241"
					width="384.227"
					height="384.227"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="-6.32844" dy="3.61625" />
					<feGaussianBlur stdDeviation="7.23251" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_0_1"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_0_1"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export default LoginSkeleton;
