import { createSlice } from "@reduxjs/toolkit";

const brokerListSlice = createSlice({
	name: "uploadDocs",
	initialState: { files: null },
	reducers: {
		setBrokerListReducer: (state, action) => {
			console.log("17 action:", action);
			const data = action.payload;
			console.log("10 data:", data);
			state.files = data;
		},
	},
});

export const { setBrokerListReducer } = brokerListSlice.actions;

export default brokerListSlice.reducer;

export const selectBrokerList = (state) => state.brokerList.files;
