import { apiSlice } from "../../app/api/apiSlice";

export const updateBorrowerPairSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateBorrowerPair: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/loan/update_borrower_pair/${credentials.loanGuid}/application/${credentials.borrowerPairId}/`,
				method: "PATCH",
				body: credentials.loanData,
			}),
		}),
	}),
});

export const { useUpdateBorrowerPairMutation } = updateBorrowerPairSlice;
