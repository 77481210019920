import React from "react";

// mui components
import { InputLabel, Modal, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading3 from "../../typography/heading-03";

// custom styles
import styles from "./index.module.scss";

export default function RenameModal({
	open,
	text,
	renameText,
	setRenameText,
	handleSubmit,
	handleClose,
}) {
	return (
		<Modal
			open={open}
			aria-labelledby="rename modal"
			aria-describedby="rename modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Stack direction="column" className={styles.inputContainer}>
					<InputLabel htmlFor="input-file-rename">
						<Heading3
							text={`Rename file "${text}" to:`}
							extraClass={styles.renameText}
							fontType="semibold"
						/>
					</InputLabel>

					<OutlinedTextInput
						id="input-file-rename"
						placeholder="Enter File Name"
						type="text"
						fullWidth={true}
						value={renameText?.title}
						handleChange={(e) =>
							setRenameText({ ...renameText, title: e.target.value })
						}
					/>
				</Stack>

				<PrimaryButton text="Rename" onClick={handleSubmit} fullWidth={true} />

				<SecondaryButton
					text="Cancel"
					onClick={handleClose}
					fullWidth={true}
					extraClass={styles.closeBtn}
					extraTextClass={styles.closeBtnText}
				/>
			</Stack>
		</Modal>
	);
}
