import React from "react";

// mui components
import { TextField } from "@mui/material";

const OutlinedTextarea = ({
	variant,
	label,
	name,
	placeholder,
	inputProps,
	multiline,
	rows,
	value,
	extraClass,
	handleChange,
}) => {
	return (
		<TextField
			variant={variant}
			id={`${variant}-basic`}
			label={label}
			name={name}
			placeholder={placeholder}
			inputProps={inputProps}
			multiline={multiline}
			rows={rows}
			value={value}
			className={extraClass}
			onChange={handleChange}
		/>
	);
};

export default OutlinedTextarea;
