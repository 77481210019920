export const handleSetPropertyDetails = (
	applicationData,
	setHomeownersInsurance,
	setFloodInsurance,
	setPropertyTaxes,
	setRealEstateTaxes,
	setHoaDues
) => {
	console.log("10 fees:", applicationData?.fees);

	// Find the fee with the specified feeType
	const taxAmount = applicationData?.fees?.find(
		(fee) => fee.feeType === "PropertyTaxes"
	);

	const floodAmount = applicationData?.fees?.find(
		(fee) => fee.feeType === "FloodInsurance"
	);

	setHomeownersInsurance(applicationData?.proposedHazardInsuranceAmount);
	setFloodInsurance(floodAmount ? floodAmount.monthlyPayment : 0);
	setPropertyTaxes(taxAmount ? taxAmount.monthlyPayment : 0);
	setRealEstateTaxes(applicationData?.proposedRealEstateTaxesAmount);
	setHoaDues(applicationData?.proposedDuesAmount);

	return;
};
