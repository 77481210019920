import { apiSlice } from "../../app/api/apiSlice";

export const viewRepositoryApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		viewRepository: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/resource_documents/${credentials?.folderName}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useViewRepositoryMutation } = viewRepositoryApiSlice;
