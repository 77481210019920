import { apiSlice } from "../../app/api/apiSlice";

export const countyListApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		countyList: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/user/get_us_zipcode_view/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useCountyListMutation } = countyListApiSlice;
