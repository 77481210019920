import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import SecondarySelect from "../../custom-select/secondary-select";
import MaskedInput from "../../custom-input/masked-input";

// helper utils
import {
	ownerOptions,
	otherAssetsTypeOptions,
	otherAssetsDescriptionOptions,
} from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const OtherAssetsInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<IconButton
					id={`${index}-other-asset-delete-btn`}
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.vodIndex)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>

			{/* asset owner */}
			<TableCell>
				<SecondarySelect
					id={`${index}-other-asset-owner`}
					value={row?.borrowerType || ""}
					handleChange={(event) =>
						handleUpdate(index, "borrowerType", event.target.value)
					}
					options={ownerOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* type */}
			<TableCell>
				<SecondarySelect
					id={`${index}-other-asset-type`}
					value={row?.assetType || ""}
					handleChange={(event) =>
						handleUpdate(index, "assetType", event.target.value)
					}
					options={otherAssetsTypeOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* description */}
			<TableCell>
				<SecondarySelect
					id={`${index}-other-asset-description`}
					value={row?.otherDescription || ""}
					handleChange={(event) =>
						handleUpdate(index, "otherDescription", event.target.value)
					}
					options={otherAssetsDescriptionOptions}
					displayEmpty={true}
					disabled={disableRow || row?.assetType !== "Other"}
				/>
			</TableCell>

			{/* Cash or Market value */}
			<TableCell>
				<MaskedInput
					id={`${index}-other-asset-market-value`}
					placeholder="$"
					type="currency"
					value={row?.cashOrMarketValue || ""}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "cashOrMarketValue", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
		</TableRow>
	);
};

export default OtherAssetsInformationRow;
