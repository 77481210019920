import React, { useState } from "react";
import { Stack } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import styles from "./index.module.scss";
import Body1 from "../typography/body-01";

const SupportingDocs = ({
	fileCount,
	fileTypes,
	text,
	handleFileAttach,
	multiple,
}) => {
	const [error, setError] = useState("");

	return (
		<FileUploader
			key={Math.floor(Math.random() * 100)}
			handleChange={(file) => {
				setError("");
				console.log("18 file:", file);
				handleFileAttach(file);
			}}
			name="file"
			types={fileTypes}
			multiple={multiple}
			onTypeError={(err) => setError(err)}
		>
			{error && <Body1 text={error} extraClass={styles.errorText} />}

			<form method="post" className={styles.formContainer}>
				<FileUploadOutlinedIcon className={styles.uploadIcon} />

				<Stack flexDirection="row" className={styles.uploadTextContainer}>
					<Body1 text="Click Here" extraClass={styles.clickText} />
					<Body1 text={text} extraClass={styles.uploadText} />
				</Stack>

				{fileCount !== undefined && fileCount > 0 && (
					<Body1
						text={`${fileCount} ${fileCount === 1 ? "file" : "files"} selected`}
						extraClass={styles.filesText}
					/>
				)}
			</form>
		</FileUploader>
	);
};

export default SupportingDocs;
