import { apiSlice } from "../../../app/api/apiSlice";

export const deleteDocumentRepositorySlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		deleteDocumentRepository: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/admin/delete_resource_document/${credentials?.itemId}/`,
				method: "DELETE",
			}),
		}),
	}),
});

export const { useDeleteDocumentRepositoryMutation } =
	deleteDocumentRepositorySlice;
