import React from "react";
import { DateTime } from "luxon";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// mui icons
import { DownloadOutlined } from "@mui/icons-material";
// custom components
import Body2 from "../../typography/body-02";

// custom styles
import styles from "../index.module.scss";

const AppraisalDocumentsRow = ({ row, handleDownload }) => {
	return (
		<TableRow hover tabIndex={-1} key={row.id}>
			<TableCell>
				<Body2 text={DateTime.fromISO(row?.createdDate).toFormat("M/d/yyyy")} />
			</TableCell>
			<TableCell>
				<Body2 text={row?.title} />
			</TableCell>
			<TableCell>
				<IconButton
					className={styles.iconBtn}
					onClick={() => handleDownload(row?.id)}
				>
					<DownloadOutlined className={styles.downloadIcon} />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default AppraisalDocumentsRow;
