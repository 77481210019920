import { apiSlice } from "../../../app/api/apiSlice";

export const amMilestoneCountSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAmMilestoneCount: builder.mutation({
			query: () => ({
				url: "/api/v1/admin/aeam_filtered_milestone/AM/",
				method: "GET",
			}),
		}),
	}),
});

export const { useGetAmMilestoneCountMutation } = amMilestoneCountSlice;
