import React, { useEffect, useState } from "react";

// mui components
import { Modal, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading4 from "../../typography/heading-04";

// custom styles
import styles from "./index.module.scss";

export default function LiabilityLinkModal({
	open,
	handleClose,
	selectedRealEstate,
	liabilitiesData,
	setLiabilitiesData,
	selectedTab,
	confirmText,
	closeText,
}) {
	const [liabilitiesInfo, setLiabilitiesInfo] = useState([]);

	const linkLiabilityColumns = [
		{ id: "index", label: "", minWidth: 48 },
		{ id: "account-owner", label: "Account Owner", minWidth: 165 },
		{ id: "account-type", label: "Account Type", minWidth: 165 },
		{
			id: "company-name",
			label: "Company Name",
			minWidth: 310,
		},
		{ id: "account-number", label: "Account Number", minWidth: 310 },
		{ id: "unpaid-balance", label: "Unpaid Balance", minWidth: 120 },
		{ id: "paid-off", label: "Paid Off", minWidth: 120, align: "center" },
		{ id: "monthly-amount", label: "Monthly Amount", minWidth: 120 },
		{
			id: "re-owned",
			label: "Included in RE owned",
			minWidth: 210,
			align: "center",
		},
		{ id: "exclude-dti", label: "Exclude DTI", minWidth: 120 },
		{ id: "resubordination", label: "Resubordinate Indicator", minWidth: 210 },
		{
			id: "bank-liability-considered",
			label: "Bank Liabilities Considered in Exposure",
			minWidth: 310,
		},
		{
			id: "current-lien-position",
			label: "Current Lien Position",
			minWidth: 210,
		},
		{
			id: "proposed-lien-position",
			label: "Proposed Lien Position",
			minWidth: 210,
		},
	];

	useEffect(() => {
		if (liabilitiesData !== undefined && liabilitiesData?.length > 0) {
			setLiabilitiesInfo(liabilitiesData[selectedTab]);
		}
	}, [liabilitiesData]);

	const handleLink = () => {
		for (let index = 0; index < liabilitiesData[selectedTab].length; index++) {
			setLiabilitiesData((prevArr) =>
				prevArr?.map((subArray, index) => {
					if (index === selectedTab) {
						return liabilitiesInfo;
					}

					return subArray;
				})
			);

			if (index === liabilitiesInfo?.length - 1) {
				handleClose();
			}
		}
	};

	const handleChangeCheckbox = (item) => {
		setLiabilitiesInfo((prevState) => {
			return prevState.map((el) =>
				el.volIndex === item?.volIndex
					? {
							...el,
							reoId:
								item?.reoId === selectedRealEstate ? "" : selectedRealEstate,
					  }
					: el
			);
		});
	};

	return (
		<Modal
			open={open}
			aria-labelledby="link liability modal"
			aria-describedby="link liability modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack direction="column" className={styles.modalBodyContainer}>
				<Heading4 text="Link Liabilities" fontType="semibold" />

				<CustomTable
					tableType="link-liability"
					columnData={linkLiabilityColumns}
					rowData={
						liabilitiesInfo !== undefined
							? liabilitiesInfo?.filter(
									(item) =>
										item?.reoId === undefined ||
										item?.reoId === "" ||
										item?.reoId === selectedRealEstate
							  )
							: []
					}
					selectedRealEstate={selectedRealEstate}
					handleSelectLiability={handleChangeCheckbox}
				/>

				<Stack direction="row" className={styles.footerContainer}>
					<PrimaryButton
						text={confirmText}
						onClick={handleLink}
						fullWidth={true}
					/>

					<SecondaryButton
						text={closeText}
						onClick={() => {
							handleClose();
							setLiabilitiesInfo(liabilitiesData[selectedTab]);
						}}
						fullWidth={true}
						extraClass={styles.closeBtn}
						extraTextClass={styles.closeBtnText}
					/>
				</Stack>
			</Stack>
		</Modal>
	);
}
