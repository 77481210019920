import { store } from "../store";
import { setUnderwritingConditions } from "../../features/underwriting-conditions/underwritingSlice";

export const getUnderwritingConditions = async ({
	loanGuid,
	apiRequest,
	handleErrorModal,
}) => {
	try {
		const underwritingValue = await apiRequest({ loanGuid }).unwrap();

		// save response in redux store
		store.dispatch(setUnderwritingConditions(underwritingValue?.data));
	} catch (err) {
		console.log("260 error:", err);
		handleErrorModal(err);
	}
};
