import React from "react";

export default function OrderAppraisalIcon({ extraStyles, extraPathStyles }) {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={extraStyles}
		>
			<g clipPath="url(#clip0_926_8499)">
				<path
					d="M9.11625 1.87502C9.35066 1.64068 9.66855 1.50903 10 1.50903C10.3315 1.50903 10.6493 1.64068 10.8838 1.87502L13.75 4.74127V3.12502C13.75 2.95926 13.8158 2.80028 13.9331 2.68307C14.0503 2.56586 14.2092 2.50002 14.375 2.50002H15.625C15.7908 2.50002 15.9497 2.56586 16.0669 2.68307C16.1842 2.80028 16.25 2.95926 16.25 3.12502V7.24127L19.1925 10.1825C19.2506 10.2406 19.2967 10.3096 19.3282 10.3855C19.3596 10.4615 19.3758 10.5428 19.3758 10.625C19.3758 10.7072 19.3596 10.7886 19.3282 10.8645C19.2967 10.9404 19.2506 11.0094 19.1925 11.0675C19.1344 11.1256 19.0654 11.1717 18.9895 11.2032C18.9136 11.2346 18.8322 11.2508 18.75 11.2508C18.6678 11.2508 18.5864 11.2346 18.5105 11.2032C18.4346 11.1717 18.3656 11.1256 18.3075 11.0675L10 2.75877L3.75 9.00877V16.875C3.75 17.0408 3.81585 17.1997 3.93306 17.317C4.05027 17.4342 4.20924 17.5 4.375 17.5H9.375C9.54076 17.5 9.69973 17.5659 9.81694 17.6831C9.93415 17.8003 10 17.9593 10 18.125C10 18.2908 9.93415 18.4497 9.81694 18.567C9.69973 18.6842 9.54076 18.75 9.375 18.75H4.375C3.87772 18.75 3.40081 18.5525 3.04917 18.2008C2.69754 17.8492 2.5 17.3723 2.5 16.875V10.2588L1.6925 11.0675C1.63439 11.1256 1.5654 11.1717 1.48948 11.2032C1.41355 11.2346 1.33218 11.2508 1.25 11.2508C1.16782 11.2508 1.08644 11.2346 1.01052 11.2032C0.934596 11.1717 0.86561 11.1256 0.8075 11.0675C0.74939 11.0094 0.703295 10.9404 0.671846 10.8645C0.640397 10.7886 0.62421 10.7072 0.62421 10.625C0.62421 10.5428 0.640397 10.4615 0.671846 10.3855C0.703295 10.3096 0.74939 10.2406 0.8075 10.1825L9.11625 1.87502Z"
					fill="#5F5F5F"
				/>
				<path
					d="M15.625 20C16.7853 20 17.8981 19.5391 18.7186 18.7186C19.5391 17.8981 20 16.7853 20 15.625C20 14.4647 19.5391 13.3519 18.7186 12.5314C17.8981 11.7109 16.7853 11.25 15.625 11.25C14.4647 11.25 13.3519 11.7109 12.5314 12.5314C11.7109 13.3519 11.25 14.4647 11.25 15.625C11.25 16.7853 11.7109 17.8981 12.5314 18.7186C13.3519 19.5391 14.4647 20 15.625 20ZM16.0675 12.6825L17.9425 14.5575C18.0599 14.6749 18.1258 14.834 18.1258 15C18.1258 15.166 18.0599 15.3251 17.9425 15.4425C17.8251 15.5599 17.666 15.6258 17.5 15.6258C17.334 15.6258 17.1749 15.5599 17.0575 15.4425L16.25 14.6337V18.125C16.25 18.2908 16.1842 18.4497 16.0669 18.5669C15.9497 18.6842 15.7908 18.75 15.625 18.75C15.4592 18.75 15.3003 18.6842 15.1831 18.5669C15.0658 18.4497 15 18.2908 15 18.125V14.6337L14.1925 15.4425C14.0753 15.5599 13.9163 15.6259 13.7504 15.626C13.5846 15.6261 13.4255 15.5603 13.3081 15.4431C13.1908 15.3259 13.1248 15.1669 13.1247 15.0011C13.1245 14.8352 13.1903 14.6761 13.3075 14.5588L15.1825 12.6838C15.2406 12.6255 15.3095 12.5794 15.3855 12.5479C15.4614 12.5164 15.5428 12.5001 15.625 12.5001C15.7072 12.5001 15.7886 12.5164 15.8645 12.5479C15.9405 12.5794 16.0094 12.6255 16.0675 12.6838V12.6825Z"
					fill="#5F5F5F"
				/>
			</g>
			<defs>
				<clipPath id="clip0_926_8499">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
