import { createSlice } from "@reduxjs/toolkit";

const postClosingConditions = createSlice({
	name: "postClosingConditions",
	initialState: {
		data: [],
	},
	reducers: {
		setPostClosingConditions: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setPostClosingConditions } = postClosingConditions.actions;
export default postClosingConditions.reducer;

export const selectPostClosingConditions = (state) =>
	state.postClosingConditions?.data;
