import { apiSlice } from "../../app/api/apiSlice";

export const passwordApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		setPasswordAction: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/tpo/set_password/",
				method: "POST",
				body: { ...credentials },
			}),
		}),
	}),
});

export const { useSetPasswordActionMutation } = passwordApiSlice;
