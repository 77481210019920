import { store } from "../store";
import { setConditionStatus } from "../../features/condition-status/conditionStatusSlice";

export const getConditionStatus = async ({
	loanGuid,
	apiRequest,
	handleErrorModal,
	handleCloseModal,
}) => {
	try {
		const conditionStatusValue = await apiRequest({ loanGuid }).unwrap();

		// save response in redux store
		store.dispatch(setConditionStatus(conditionStatusValue));
		handleCloseModal();
	} catch (err) {
		console.log("260 error:", err);
		handleErrorModal(err);
	}
};
