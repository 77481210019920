import { createSlice } from "@reduxjs/toolkit";

const preliminaryConditions = createSlice({
	name: "preliminaryConditions",
	initialState: {
		data: [],
	},
	reducers: {
		setPreliminaryConditions: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setPreliminaryConditions } = preliminaryConditions.actions;
export default preliminaryConditions.reducer;

export const selectPreliminaryConditions = (state) =>
	state.preliminaryConditions?.data;
