import { apiSlice } from "../../../app/api/apiSlice";

export const deleteDocumentFolderSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		deleteDocumentFolder: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/admin/delete_res_doc_folder/${credentials?.folderName}/`,
				method: "DELETE",
			}),
		}),
	}),
});

export const { useDeleteDocumentFolderMutation } = deleteDocumentFolderSlice;
