import { TableCell, TableRow } from "@mui/material";
import React from "react";
import Body2 from "../../typography/body-02";
import { useNavigate } from "react-router-dom";

const LoanStatusRow = ({ row }) => {
	const navigate = useNavigate();

	return (
		<TableRow hover tabIndex={-1} key={row.id}>
			<TableCell
				onClick={() => navigate(`/pipeline?status=${row?.label || ""}`)}
			>
				<Body2 text={row.label} isLink={true} />
			</TableCell>
			<TableCell>
				<Body2 text={row.count} />
			</TableCell>
		</TableRow>
	);
};

export default LoanStatusRow;
