import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import MaskedInput from "../../custom-input/masked-input";
import CustomCheckbox from "../../custom-checkbox";

// helper utils
import {
	ownerOptions,
	liabilityTypeOptions,
	lienPositionOptions,
} from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const LiabilityInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
	isSubmitted,
}) => {
	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.label}
			className={
				isSubmitted &&
				(row?.subjectLoanResubordinationIndicator ||
					row?.bankLiabilityConsideredIndicator) &&
				(!row?.currentLienPosition || !row?.proposedLienPosition)
					? styles.errorRow
					: ""
			}
		>
			{/* delete icon */}
			<TableCell>
				<IconButton
					id={`${index}-liability-delete-btn`}
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.vodIndex)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>

			{/* account owner */}
			<TableCell>
				<SecondarySelect
					id={`${index}-liability-owner`}
					value={row?.owner || ""}
					handleChange={(event) =>
						handleUpdate(index, "owner", event.target.value)
					}
					options={ownerOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* account type */}
			<TableCell>
				<SecondarySelect
					id={`${index}-liability-type`}
					value={row?.liabilityType || ""}
					handleChange={(event) =>
						handleUpdate(index, "liabilityType", event.target.value)
					}
					options={liabilityTypeOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* company name */}
			<TableCell>
				<OutlinedTextInput
					id={`${index}-liability-holder-name`}
					type="text"
					value={row?.holderName || ""}
					handleChange={(event) =>
						handleUpdate(index, "holderName", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>

			{/* account number */}
			<TableCell>
				<OutlinedTextInput
					id={`${index}-liability-account-number`}
					type="text"
					value={row?.accountIdentifier || ""}
					handleChange={(event) =>
						handleUpdate(index, "accountIdentifier", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>

			{/* unpaid balance */}
			<TableCell>
				<MaskedInput
					id={`${index}-liability-unpaid-balance`}
					placeholder="$"
					type="currency"
					value={row?.unpaidBalanceAmount || ""}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "unpaidBalanceAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>

			{/* paid off checkbox */}
			<TableCell align="center">
				<CustomCheckbox
					id={`${index}-liability-pay-off-indicator`}
					checked={row?.payoffStatusIndicator || false}
					handleChange={() =>
						handleUpdate(
							index,
							"payoffStatusIndicator",
							!row?.payoffStatusIndicator
						)
					}
					disabled={disableRow}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>

			{/* monthly amount */}
			<TableCell>
				<MaskedInput
					id={`${index}-liability-monthly-payment`}
					placeholder="$"
					type="currency"
					value={row?.monthlyPaymentAmount?.toString() || ""}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "monthlyPaymentAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>

			{/* included in RE owned */}
			<TableCell align="center">
				<CustomCheckbox
					id={`${index}-liability-inluded-re`}
					checked={row?.reoId || false}
					handleChange={() => console.log("120 reo id:", row?.reoId)}
					disabled={true}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>

			{/* exclusion DTI */}
			<TableCell align="center">
				<CustomCheckbox
					id={`${index}-liability-exclusion-dti`}
					checked={row?.exclusionIndicator || false}
					handleChange={() =>
						handleUpdate(index, "exclusionIndicator", !row?.exclusionIndicator)
					}
					extraClass={styles.checkboxInput}
					disabled={disableRow}
				/>
			</TableCell>

			{/* resubordination checkbox */}
			<TableCell align="center">
				<CustomCheckbox
					id={`${index}-liability-resubordinate`}
					checked={row?.subjectLoanResubordinationIndicator}
					handleChange={() => {
						handleUpdate(
							index,
							"subjectLoanResubordinationIndicator",
							!row?.subjectLoanResubordinationIndicator
						);
					}}
					extraClass={styles.checkboxInput}
					disabled={disableRow}
				/>
			</TableCell>

			{/* Bank Liability Considered */}
			<TableCell align="center">
				<CustomCheckbox
					id={`${index}-liability-bank-considered`}
					checked={row?.bankLiabilityConsideredIndicator || false}
					handleChange={() =>
						handleUpdate(
							index,
							"bankLiabilityConsideredIndicator",
							!row?.bankLiabilityConsideredIndicator
						)
					}
					extraClass={styles.checkboxInput}
					disabled={disableRow}
				/>
			</TableCell>

			{/* current lien position */}
			<TableCell align="center">
				<SecondarySelect
					id={`${index}-liability-current-lien`}
					extraClass={
						isSubmitted &&
						(row?.subjectLoanResubordinationIndicator ||
							row?.bankLiabilityConsideredIndicator) &&
						!row?.currentLienPosition &&
						styles.errorContainer
					}
					value={row?.currentLienPosition || ""}
					handleChange={(event) => {
						handleUpdate(index, "currentLienPosition", event.target.value);
					}}
					options={lienPositionOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* proposed lien position */}
			<TableCell align="center">
				<SecondarySelect
					id={`${index}-liability-proposed-lien`}
					extraClass={
						isSubmitted &&
						(row?.subjectLoanResubordinationIndicator ||
							row?.bankLiabilityConsideredIndicator) &&
						!row?.proposedLienPosition &&
						styles.errorContainer
					}
					value={row?.proposedLienPosition || ""}
					handleChange={(event) => {
						handleUpdate(index, "proposedLienPosition", event.target.value);
					}}
					options={lienPositionOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>
		</TableRow>
	);
};

export default LiabilityInformationRow;
