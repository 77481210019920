import { apiSlice } from "../../app/api/apiSlice";

export const attachDocumentConditionApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		attachDocumentCondition: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/attach_document_condition/${credentials?.loanGuid}/`,
				method: "POST",
				body: credentials?.data,
			}),
		}),
	}),
});

export const { useAttachDocumentConditionMutation } =
	attachDocumentConditionApiSlice;
