import { apiSlice } from "../../app/api/apiSlice";

export const uploadV3XmlApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		uploadV3Xml: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/user/v3/upload_xml/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useUploadV3XmlMutation } = uploadV3XmlApiSlice;
