import React, { useState } from "react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import SupportingDocs from "../../../supporting-docs";
import OutlinedTextInput from "../../../custom-input/outlined-text-input";
import PrimaryButton from "../../../buttons/primary-button";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Body2 from "../../../typography/body-02";
import Body3 from "../../../typography/body-03";

// helper utils
import { formatBytes } from "../../../../utils/format-bytes";

// mui icons
import UploadFileIcon from "@mui/icons-material/UploadFile";

// api slice
import { useExtendLockMutation } from "../../../../features/extend-lock/extendLockApiSlice";

// custom styles
import styles from "./index.module.scss";

const ExtendLockDrawer = ({
	loanData,
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	handleOpenSuccessModal,
	setProcessingText,
	setSuccessText,
}) => {
	const [extendLock] = useExtendLockMutation();

	const fileTypes = ["PDF", "PNG", "JPEG", "JPG", "HTML", "TXT"];

	const [extendDays, setExtendDays] = useState(0);

	const [extendRequestFile, setExtendRequestFile] = useState(null);

	const handleFileAttach = async (file) => {
		setExtendRequestFile(file);
	};

	const deleteFile = () => {
		setExtendRequestFile(null);
	};

	const handleCancel = () => {
		console.log("40 cancel clicked.");
		setExtendRequestFile(null);
		setExtendDays(0);
	};

	const handleSubmitExtendLock = async () => {
		if (
			extendRequestFile === null ||
			extendRequestFile === undefined ||
			extendRequestFile?.length === 0 ||
			extendDays === 0
		)
			return;

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Your Request is being processed");

		var formData = new FormData();
		formData.append("loan_number", loanData?.loanNumber);
		formData.append(
			"borrower_name",
			loanData?.applications[0]?.borrower?.fullName
		);
		formData.append("attachment", extendRequestFile);
		formData.append("days", extendDays);

		// dispatch API and open modal if successful
		try {
			const extendLockData = await extendLock({ formData }).unwrap();
			console.log("310 file upload res:", extendLockData);
			if (extendLockData?.message === "Email sent successfully.") {
				handleCloseProcessingModal();
				handleOpenSuccessModal();
				setSuccessText("Your Extend Lock Request has been sent");
				setExtendDays(0);
			}
		} catch (err) {
			console.log("95 err:", err);
			handleCloseProcessingModal();
			// TODO aqib open error modal
		}
	};

	const handleChangeExtendDays = (event) => {
		console.log("90 check:", isNaN(event.target.value));

		if (isNaN(event.target.value)) {
			return;
		}

		const min = 0;
		const max = 999;

		const value = Math.max(min, Math.min(max, Number(event.target.value)));

		console.log("830 value:", value);

		setExtendDays(parseInt(value).toFixed(0));
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Extend Lock"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.drawerBodyContainer}>
				<Stack direction="column" className={styles.formContainer}>
					<Heading3
						text="Upload Supporting Documentation:"
						extraClass={styles.labelText}
						fontType="semibold"
					/>

					<SupportingDocs
						fileCount={
							extendRequestFile !== null && extendRequestFile !== undefined
								? 1
								: 0
						}
						fileTypes={fileTypes}
						text="or drag to upload supporting files"
						handleFileAttach={handleFileAttach}
						multiple={false}
					/>
				</Stack>

				{extendRequestFile !== undefined && extendRequestFile !== null && (
					<Stack direction="column" className={styles.uploadedFileContainer}>
						<Stack direction="row" className={styles.fileListContainer}>
							<Box className={styles.fileIconContainer}>
								<UploadFileIcon className={styles.fileIcon} />
							</Box>
							<Stack direction="column" className={styles.fileNameContainer}>
								<Body2 text={extendRequestFile?.name} />
								<Body3 text={formatBytes(extendRequestFile?.size)} />
							</Stack>

							<Box
								sx={{ cursor: "pointer" }}
								onClick={(event) => deleteFile(event)}
								className={styles.deleteIconContainer}
							>
								<CloseIcon className={styles.deleteIcon} />
							</Box>
						</Stack>
					</Stack>
				)}

				<Stack
					direction="row"
					alignItems="center"
					className={styles.formContainer}
				>
					<Heading3
						text="Lock Extend Date:"
						extraClass={styles.labelText}
						fontType="semibold"
					/>

					<OutlinedTextInput
						variant="outlined"
						placeholder="Extend days"
						inputProps={{
							inputMode: "numeric",
							pattern: "[0-9]*",
							min: 0,
							max: 999,
							maxLength: 3,
						}}
						value={extendDays}
						handleChange={handleChangeExtendDays}
					/>
				</Stack>
			</Stack>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				className={styles.drawerFooterContainer}
			>
				{/* TODO create a function to clear files */}
				<SecondaryButton
					text="Reset"
					extraClass={styles.cancelBtn}
					extraTextClass={styles.cancelText}
					onClick={handleCancel}
					disabled={Number(extendDays) === 0 || extendRequestFile === null}
				/>
				<PrimaryButton
					text="Request Lock"
					onClick={handleSubmitExtendLock}
					disabled={Number(extendDays) === 0 || extendRequestFile === null}
				/>
			</Stack>
		</Stack>
	);
};

export default ExtendLockDrawer;
