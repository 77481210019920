import { apiSlice } from "../../app/api/apiSlice";

export const getLoanDetailSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getLoanDetail: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/pipeline/v3/${credentials.loanGuid}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetLoanDetailMutation } = getLoanDetailSlice;
