import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";

import { Checkbox, TableCell, TableRow } from "@mui/material";

import Body1 from "../../typography/body-01";

import styles from "../index.module.scss";

const BrokerDocumentRow = ({ row, handleRowClick, selectedRow }) => {
	const [rowHighlighted, setRowHighlighted] = useState(false);

	useEffect(() => {
		var d1 = DateTime.now().minus({ minutes: 2 });
		var d2 = DateTime.fromISO(row?.createdDate);

		let timer;

		if (d2 > d1) {
			setRowHighlighted(true);
			timer = setTimeout(() => {
				setRowHighlighted(false);
			}, 5000);
		}

		return () => clearTimeout(timer);
	}, [row]);

	const isSelected = (name) => selectedRow.indexOf(name) !== -1;

	const isItemSelected = isSelected(row.id);
	const labelId = `enhanced-table-checkbox-${row.id}`;

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={(event) => handleRowClick(event, row.id)}
			role="checkbox"
			aria-checked={isItemSelected}
			selected={isItemSelected}
			className={rowHighlighted ? styles.highlightRow : ""}
		>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={isItemSelected}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>
			<TableCell>
				<Body1 text={row.title} />
			</TableCell>
			<TableCell>
				<Body1
					text={DateTime.fromISO(row?.createdDate, {
						zone: "America/New_York",
					}).toFormat("MM/dd/yyyy HH:mm a")}
				/>
			</TableCell>
		</TableRow>
	);
};

export default BrokerDocumentRow;
