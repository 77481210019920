import { createSlice } from "@reduxjs/toolkit";

const conditionStatus = createSlice({
	name: "conditionStatus",
	initialState: {
		data: {},
	},
	reducers: {
		setConditionStatus: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setConditionStatus } = conditionStatus.actions;
export default conditionStatus.reducer;

export const selectConditionStatus = (state) => state.conditionStatus?.data;
