import React from "react";
import { visuallyHidden } from "@mui/utils";

// mui components
import {
	Box,
	Stack,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";

// custom components
import Heading5 from "../../typography/heading-05";

// helper functions
import { sortAmount, sortDate } from "../../../utils/sort-methods";

// custom styles
import styles from "./index.module.scss";

const TableHeadConditionTracking = (props) => {
	const {
		data,
		// sort
		order,
		orderBy,
		onRequestSort,
		filteredLoans,
		setFilteredLoans,
	} = props;

	const createSortHandler = (property) => (event) => {
		let fieldId;

		if (property === "upload-date") {
			fieldId = "createdDate";
		} else if (property === "name") {
			fieldId = "title";
		} else if (property === "description") {
			fieldId = "description";
		} else if (property === "category") {
			fieldId = "category";
		} else if (property === "status") {
			fieldId = "status";
		} else if (property === "sub-status") {
			fieldId = "sub_status";
		}

		let sortedData;

		if (property.includes("date")) {
			sortedData = sortDate(filteredLoans, order, fieldId);
		} else {
			sortedData = sortAmount(filteredLoans, order, fieldId);
		}

		setFilteredLoans(sortedData);
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{data.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{
							width: headCell.minWidth,
							verticalAlign: headCell.label === "Remedy" && "text-top",
						}}
					>
						<Stack direction="column" className={styles.headerStack}>
							{headCell.label === "Remedy" ? (
								<Heading5
									text={headCell.label}
									extraClass={styles.thText}
									fontType="semibold"
								/>
							) : (
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : "asc"}
									onClick={createSortHandler(headCell.id)}
									className={styles.tableLabelLeft}
								>
									<Heading5
										text={headCell.label}
										extraClass={styles.thText}
										fontType="semibold"
									/>
									{orderBy === headCell.id ? (
										<Box component="span" sx={visuallyHidden}>
											{order === "desc"
												? "sorted descending"
												: "sorted ascending"}
										</Box>
									) : null}
								</TableSortLabel>
							)}
						</Stack>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadConditionTracking;
