import { apiSlice } from "../../app/api/apiSlice";

export const conditionStatusApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		conditionStatus: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/get_conditions_count/${credentials?.loanGuid}/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useConditionStatusMutation } = conditionStatusApiSlice;
