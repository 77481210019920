import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";

// mui components
import { Box, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomRadio from "../../custom-radio";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// helper utils
import {
	countryOptions,
	ownershipShareOptions,
	selfEmployedOptions,
	stateOptions,
} from "../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";
import CustomCheckbox from "../../custom-checkbox";

const EmploymentInfo = ({
	coBorrower,
	borrowerPairIndex,
	financeIndex,
	financeData,
	primary,
	additional,
	previous,
	data,
	setData,
	disabled,
}) => {
	const [employmentStartDate, setEmploymentStartDate] = useState("");
	const [employmentEndDate, setEmploymentEndDate] = useState("");

	useEffect(() => {
		// start date
		if (
			financeData?.employmentStartDate !== null &&
			financeData?.employmentStartDate !== undefined &&
			financeData?.employmentStartDate !== ""
		) {
			let year = financeData?.employmentStartDate?.slice(-4);
			let month = financeData?.employmentStartDate?.slice(0, 2);
			let date = financeData?.employmentStartDate?.slice(3, 5);

			let formattedJSDate = new Date(year, month - 1, date);
			console.log("27 formatted JS date:", formattedJSDate);
			const isValidDate = (dateObject) =>
				new Date(dateObject).toString() !== "Invalid Date";

			if (isValidDate(formattedJSDate)) {
				setEmploymentStartDate(formattedJSDate);
			} else {
				let newYear = financeData?.employmentStartDate?.slice(0, 4);
				let newMonth = financeData?.employmentStartDate?.slice(5, 7);
				let newDate = financeData?.employmentStartDate?.slice(8, 10);

				console.log("44 newYear:", newYear);
				console.log("44 newMonth:", newMonth);
				console.log("44 newDate:", newDate);

				let formattedDate = new Date(newYear, newMonth - 1, newDate);
				console.log("27 view loan formatted JS date:", formattedDate);

				if (isValidDate(formattedDate)) {
					setEmploymentStartDate(formattedDate);
				} else {
					setEmploymentStartDate(null);
				}
			}
		} else {
			setEmploymentStartDate(null);
		}

		// end date
		if (
			financeData?.endDate !== null &&
			financeData?.endDate !== undefined &&
			financeData?.endDate !== ""
		) {
			let year = financeData?.endDate?.slice(-4);
			let month = financeData?.endDate?.slice(0, 2);
			let date = financeData?.endDate?.slice(3, 5);

			let formattedJSDate = new Date(year, month - 1, date);
			console.log("27 formatted JS date:", formattedJSDate);
			const isValidDate = (dateObject) =>
				new Date(dateObject).toString() !== "Invalid Date";

			if (isValidDate(formattedJSDate)) {
				setEmploymentEndDate(formattedJSDate);
			} else {
				let newYear = financeData?.endDate?.slice(0, 4);
				let newMonth = financeData?.endDate?.slice(5, 7);
				let newDate = financeData?.endDate?.slice(8, 10);

				console.log("44 newYear:", newYear);
				console.log("44 newMonth:", newMonth);
				console.log("44 newDate:", newDate);

				let formattedDate = new Date(newYear, newMonth - 1, newDate);
				console.log("27 view loan formatted JS date:", formattedDate);

				if (isValidDate(formattedDate)) {
					setEmploymentEndDate(formattedDate);
				} else {
					setEmploymentEndDate(null);
				}
			}
		} else {
			setEmploymentEndDate(null);
		}
	}, []);

	const [phoneNumber, setPhoneNumber] = useState("");
	const [baseIncome, setBaseIncome] = useState("");
	const [overtimeIncome, setOvertimeIncome] = useState("");
	const [bonusIncome, setBonusIncome] = useState("");
	const [commissionIncome, setCommissionIncome] = useState("");
	const [otherIncome, setOtherIncome] = useState("");
	const [totalIncome, setTotalIncome] = useState("");

	useEffect(() => {
		console.log("130 finance data:", financeData?.monthlyIncomeAmount);

		setPhoneNumber(financeData?.phoneNumber);
		setBaseIncome(financeData?.basePayAmount);
		setOvertimeIncome(financeData?.overtimeAmount);
		setBonusIncome(financeData?.bonusAmount);
		setCommissionIncome(financeData?.commissionsAmount);
		setOtherIncome(financeData?.otherAmount);

		if (totalIncome === undefined || totalIncome === "") {
			// we want to update this based on the field values only on the initial render, not the calculation renders
			setTotalIncome(financeData?.monthlyIncomeAmount);
		}
	}, [financeData]);

	const handleUpdateValue = (field, newValue) => {
		console.log("440 object data:", financeData);
		console.log("440 newValue:", newValue);

		let employmentIndex = data?.findIndex(
			(item) =>
				item?.id === financeData?.id && item?.owner === financeData?.owner
		);

		console.log("445 object data:", data[employmentIndex]);

		setData((prevData) => {
			const newData = [...prevData]; // Create a shallow copy of the parent array
			const updatedRow = [...newData[borrowerPairIndex]]; // Create a shallow copy of the sub array

			if (
				field === "selfEmployedIndicator" &&
				(newValue === "false" || newValue === false)
			) {
				updatedRow[employmentIndex] = {
					...updatedRow[employmentIndex],
					ownershipInterestType: "",
				};
			}

			// Update the object at the specified index with the new value
			updatedRow[employmentIndex] = {
				...updatedRow[employmentIndex],
				[field]: newValue,
			};

			// Update the parent array with the updated sub array
			newData[borrowerPairIndex] = updatedRow;

			return newData;
		});
	};

	const handlePhoneNumber = (value) => {
		setPhoneNumber(value);

		handleUpdateValue("phoneNumber", value);
	};

	const handleBaseIncome = (value) => {
		setBaseIncome(value);

		setTotalIncome((prevTotalIncome) => {
			return (
				Number(value) +
				Number(overtimeIncome) +
				Number(bonusIncome) +
				Number(commissionIncome) +
				Number(otherIncome)
			);
		});

		handleUpdateValue("basePayAmount", value);
	};

	const handleOvertimeIncome = (value) => {
		setOvertimeIncome(value);

		setTotalIncome((prevTotalIncome) => {
			return (
				Number(baseIncome) +
				Number(value) +
				Number(bonusIncome) +
				Number(commissionIncome) +
				Number(otherIncome)
			);
		});

		handleUpdateValue("overtimeAmount", value);
	};

	const handleBonusIncome = (value) => {
		setBonusIncome(value);

		setTotalIncome((prevTotalIncome) => {
			return (
				Number(baseIncome) +
				Number(overtimeIncome) +
				Number(value) +
				Number(commissionIncome) +
				Number(otherIncome)
			);
		});

		handleUpdateValue("bonusAmount", value);
	};

	const handleCommissionIncome = (value) => {
		setCommissionIncome(value);

		setTotalIncome((prevTotalIncome) => {
			return (
				Number(baseIncome) +
				Number(overtimeIncome) +
				Number(bonusIncome) +
				Number(value) +
				Number(otherIncome)
			);
		});

		handleUpdateValue("commissionsAmount", value);
	};

	const handleOtherIncome = (value) => {
		setOtherIncome(value);

		setTotalIncome((prevTotalIncome) => {
			return (
				Number(baseIncome) +
				Number(overtimeIncome) +
				Number(bonusIncome) +
				Number(commissionIncome) +
				Number(value)
			);
		});

		handleUpdateValue("otherAmount", value);
	};

	const handleTotalIncome = (value) => {
		setTotalIncome(value);
	};

	useEffect(() => {
		console.log("99 employment start date:", employmentStartDate);

		if (
			employmentStartDate !== null &&
			employmentStartDate !== undefined &&
			employmentStartDate !== ""
		) {
			let formattedDate =
				DateTime.fromJSDate(employmentStartDate).toFormat("MM-dd-yyyy");

			console.log("105 formatted start date:", formattedDate);

			handleUpdateValue("employmentStartDate", formattedDate);
		}
	}, [employmentStartDate]);

	useEffect(() => {
		console.log("99 employment end date:", employmentEndDate);

		if (
			employmentEndDate !== null &&
			employmentEndDate !== undefined &&
			employmentEndDate !== ""
		) {
			let formattedDate =
				DateTime.fromJSDate(employmentEndDate).toFormat("MM-dd-yyyy");

			console.log("105 formatted end date:", formattedDate);

			handleUpdateValue("endDate", formattedDate);
		}
	}, [employmentEndDate]);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text={`${
						additional ? "Additional/Self " : previous ? "Previous" : ""
					} Employment and Income - ${
						coBorrower ? "Co Borrower " : "Borrower "
					}`}
					fontType="semibold"
				/>

				<Body3 text="Not applicable for DSCR" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}

				<Stack direction="column" className={styles.inputSectionContainer}>
					<Box
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<CustomCheckbox
							checked={financeData?.foreignAddressIndicator || false}
							handleChange={() =>
								handleUpdateValue(
									"foreignAddressIndicator",
									!financeData?.foreignAddressIndicator
								)
							}
							label="Foreign Address"
							disabled={disabled}
						/>
					</Box>

					{/* employer relationship */}
					<Stack direction="column" className={styles.inputContainer}>
						<CustomCheckbox
							checked={
								financeData?.specialEmployerRelationshipIndicator || false
							}
							handleChange={() =>
								handleUpdateValue(
									"specialEmployerRelationshipIndicator",
									!financeData?.specialEmployerRelationshipIndicator
								)
							}
							label="I am employed by a family member, property seller, real estate agent or other party to the transaction."
							disabled={disabled}
						/>
					</Stack>

					{/* employment status */}
					<Stack direction="column" className={styles.inputContainer}>
						<CustomRadio
							id="employment-status"
							value={financeData?.selfEmployedIndicator}
							handleChange={(event) =>
								handleUpdateValue("selfEmployedIndicator", event.target.value)
							}
							label="Employment:"
							disabled={disabled}
							data={selfEmployedOptions}
						/>
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* employer name */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-name`}
						>
							<Heading6
								text={
									financeData?.selfEmployedIndicator === true ||
									financeData?.selfEmployedIndicator === "true"
										? "Business Name"
										: "Employer Name"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-name`}
							placeholder="Name"
							type="text"
							fullWidth={true}
							value={financeData?.employerName || ""}
							handleChange={(event) =>
								handleUpdateValue("employerName", event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* ownership share */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						{(financeData?.selfEmployedIndicator === true ||
							financeData?.selfEmployedIndicator === "true") && (
							<CustomRadio
								id="ownership-share"
								value={financeData?.ownershipInterestType}
								handleChange={(event) =>
									handleUpdateValue("ownershipInterestType", event.target.value)
								}
								label="Ownership Share:"
								disabled={disabled}
								data={ownershipShareOptions}
							/>
						)}
					</Stack>

					{/* employer address line */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-address`}
						>
							<Heading6
								text={
									financeData?.selfEmployedIndicator === true ||
									financeData?.selfEmployedIndicator === "true"
										? "Address Line 1"
										: "Employment Address Line 1"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-address`}
							placeholder="Address Line 1"
							type="text"
							fullWidth={true}
							value={financeData?.uRLA2020StreetAddress || ""}
							handleChange={(event) => {
								handleUpdateValue("addressStreetLine1", event.target.value);
								handleUpdateValue("uRLA2020StreetAddress", event.target.value);
							}}
							disabled={disabled}
						/>
					</Stack>

					{/* employer unit number */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-unit`}
						>
							<Heading6
								text={
									financeData?.selfEmployedIndicator === true ||
									financeData?.selfEmployedIndicator === "true"
										? "Address Line 2 / Unit Number"
										: "Employment Address Line 2 / Unit Number"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-unit`}
							placeholder="Unit Number"
							type="text"
							fullWidth={true}
							value={financeData?.unitNumber || ""}
							handleChange={(event) =>
								handleUpdateValue("unitNumber", event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* employer city */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-city`}
						>
							<Heading6
								text="City"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-city`}
							placeholder="City"
							type="text"
							fullWidth={true}
							value={financeData?.addressCity || ""}
							handleChange={(event) =>
								handleUpdateValue("addressCity", event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* employer state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}select-employer-state`}
						>
							<Heading6
								text={
									financeData?.foreignAddressIndicator ? "Province" : "State"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						{financeData?.foreignAddressIndicator ? (
							<OutlinedTextInput
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-employer-state`}
								type="text"
								placeholder="Province"
								value={financeData?.addressState || ""}
								handleChange={(event) =>
									handleUpdateValue("addressState", event.target.value)
								}
								disabled={disabled}
							/>
						) : (
							<SecondarySelect
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-employer-state`}
								displayEmpty={true}
								value={financeData?.addressState || ""}
								handleChange={(event) =>
									handleUpdateValue("addressState", event.target.value)
								}
								options={stateOptions}
								disabled={disabled}
							/>
						)}
					</Stack>

					{/* employer postal code */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-zip`}
						>
							<Heading6
								text={
									financeData?.foreignAddressIndicator
										? "Postal Code"
										: "Zip Code"
								}
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-zip`}
							placeholder={
								financeData?.foreignAddressIndicator
									? "Postal Code"
									: "Zip Code"
							}
							type="text"
							fullWidth={true}
							value={financeData?.addressPostalCode || ""}
							handleChange={(event) =>
								handleUpdateValue("addressPostalCode", event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>

					{financeData?.foreignAddressIndicator && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel
								htmlFor={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-citizenship-country`}
							>
								<Heading6
									text="Country"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}select-citizenship-country`}
								displayEmpty={true}
								value={financeData?.country || ""}
								handleChange={(event) =>
									handleUpdateValue("country", event.target.value)
								}
								options={countryOptions}
								disabled={disabled}
							/>
						</Stack>
					)}

					{/* employer phone */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-phone`}
						>
							<Heading6
								text="Phone"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-employer-phone`}
							placeholder="[XXX] XXX-XXXX"
							type="phone"
							value={phoneNumber?.toString() || ""}
							onInputChange={handlePhoneNumber}
							disabled={disabled}
						/>
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* job title */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-title`}
						>
							<Heading6
								text="Title or Position"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}outlined-input-title`}
							placeholder="Title"
							type="text"
							value={financeData?.positionDescription || ""}
							handleChange={(event) =>
								handleUpdateValue("positionDescription", event.target.value)
							}
							disabled={disabled}
						/>
					</Stack>

					{/* monthly income */}
					{previous && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel
								htmlFor={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}masked-income`}
							>
								<Heading6
									text="Previous Gross Monthly Income"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id={`${coBorrower ? "co-borrower-" : "borrower-"}masked-income`}
								placeholder="$"
								type="currency"
								value={financeData?.basePayAmount?.toString() || ""}
								onInputChange={(value) =>
									handleUpdateValue("basePayAmount", value)
								}
								disabled={disabled}
							/>
						</Stack>
					)}

					{/* start date */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel
							htmlFor={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}employment-start-date-picker`}
						>
							<Heading6
								text="Start Date"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<DatePicker
							id={`${
								coBorrower ? "co-borrower-" : "borrower-"
							}employment-start-date-picker`}
							className={
								disabled
									? styles.datepicker + " " + styles.disabledDatepicker
									: styles.datepicker
							}
							format="MM-dd-yyyy"
							value={employmentStartDate}
							onChange={setEmploymentStartDate}
							disabled={disabled}
							maxDate={new Date()}
						/>
					</Stack>

					{/* end date */}
					{previous && (
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel
								htmlFor={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}employment-end-date-picker`}
							>
								<Heading6
									text="End Date"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<DatePicker
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}employment-end-date-picker`}
								className={
									disabled
										? styles.datepicker + " " + styles.disabledDatepicker
										: styles.datepicker
								}
								format="MM-dd-yyyy"
								value={employmentEndDate}
								onChange={setEmploymentEndDate}
								disabled={disabled}
								maxDate={new Date()}
							/>
						</Stack>
					)}
				</Stack>

				{!previous &&
					(financeData?.selfEmployedIndicator === true ||
					financeData?.selfEmployedIndicator === "true" ? (
						<Stack
							direction="row"
							className={
								styles.inputContainer + " " + styles.incomeInputContainer
							}
						>
							<InputLabel
								htmlFor={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}monthly-income`}
								className={styles.centeredLabel}
							>
								<Heading6
									text="Monthly Income (or Loss)"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id={`${
									coBorrower ? "co-borrower-" : "borrower-"
								}monthly-income`}
								placeholder="$"
								type="currency"
								value={
									financeData?.employmentMonthlyIncomeAmount?.toString() || ""
								}
								onInputChange={(value) =>
									handleUpdateValue("employmentMonthlyIncomeAmount", value)
								}
								disabled={disabled}
							/>
						</Stack>
					) : (
						<Stack direction="column" className={styles.inputSectionContainer}>
							<Heading5 text="Gross Monthly Income" fontType="semibold" />

							{/* base income */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}base-income`}
									className={styles.centeredLabel}
								>
									<Heading6
										text="Base"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id={`${coBorrower ? "co-borrower-" : "borrower-"}base-income`}
									placeholder="$"
									type="currency"
									value={baseIncome?.toString() || ""}
									onInputChange={handleBaseIncome}
									disabled={disabled}
								/>
							</Stack>

							{/* overtime income */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}overtime-income`}
									className={styles.centeredLabel}
								>
									<Heading6
										text="Overtime"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}overtime-income`}
									placeholder="$"
									type="currency"
									value={overtimeIncome?.toString() || ""}
									onInputChange={handleOvertimeIncome}
									disabled={disabled}
								/>
							</Stack>

							{/* bonus income */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}bonus-income`}
									className={styles.centeredLabel}
								>
									<Heading6
										text="Bonus"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}bonus-income`}
									placeholder="$"
									type="currency"
									value={bonusIncome?.toString() || ""}
									onInputChange={handleBonusIncome}
									disabled={disabled}
								/>
							</Stack>

							{/* commission income */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}commission-income`}
									className={styles.centeredLabel}
								>
									<Heading6
										text="Commission"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}commission-income`}
									placeholder="$"
									type="currency"
									value={commissionIncome?.toString() || ""}
									onInputChange={handleCommissionIncome}
									disabled={disabled}
								/>
							</Stack>

							{/* other income */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}other-income`}
									className={styles.centeredLabel}
								>
									<Heading6
										text="Other"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}other-income`}
									placeholder="$"
									type="currency"
									value={otherIncome?.toString() || ""}
									onInputChange={handleOtherIncome}
									disabled={disabled}
								/>
							</Stack>

							{/* total income */}
							<Stack
								direction="row"
								className={
									styles.inputContainer + " " + styles.incomeInputContainer
								}
							>
								<InputLabel
									htmlFor={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}total-income`}
									className={styles.centeredLabel}
								>
									<Heading6
										text="Total"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>

								<MaskedInput
									id={`${
										coBorrower ? "co-borrower-" : "borrower-"
									}total-income`}
									placeholder="$"
									type="currency"
									value={totalIncome?.toString() || ""}
									onInputChange={handleTotalIncome}
									disabled={true}
								/>
							</Stack>
						</Stack>
					))}
			</Stack>
		</Box>
	);
};

export default EmploymentInfo;
