import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import MaskedInput from "../../custom-input/masked-input";

// helper utils
import { ownerOptions, assetTypeOptions } from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const AssetInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<IconButton
					id={`${index}-asset-delete-btn`}
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.vodIndex)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>

			{/* account owner */}
			<TableCell>
				<SecondarySelect
					id={`${index}-asset-owner`}
					value={row?.owner || ""}
					handleChange={(event) =>
						handleUpdate(index, "owner", event.target.value)
					}
					options={ownerOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* account type */}
			<TableCell>
				<SecondarySelect
					id={`${index}-asset-type`}
					value={row?.assetType || ""}
					handleChange={(event) =>
						handleUpdate(index, "assetType", event.target.value)
					}
					options={assetTypeOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* Financial Institution */}
			<TableCell>
				<OutlinedTextInput
					id={`${index}-asset-holder-name`}
					type="text"
					value={row?.holderName || ""}
					handleChange={(event) =>
						handleUpdate(index, "holderName", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>

			{/* Account Number */}
			<TableCell>
				<OutlinedTextInput
					id={`${index}-asset-account-number`}
					type="text"
					value={row?.accountIdentifier || ""}
					handleChange={(event) =>
						handleUpdate(index, "accountIdentifier", event.target.value)
					}
					disabled={disableRow}
				/>
			</TableCell>

			{/* Cash or Market value */}
			<TableCell>
				<MaskedInput
					id={`${index}-asset-market-value`}
					placeholder="$"
					type="currency"
					value={row?.urla2020CashOrMarketValueAmount || ""}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "urla2020CashOrMarketValueAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
		</TableRow>
	);
};

export default AssetInformationRow;
