export const selectedUserWithLoanChannel = (
	userDetails = {},
	impersonatedUser = {}
) => {
	if (userDetails?.loan_channel) {
		return userDetails;
	} else if (impersonatedUser?.loan_channel) {
		return impersonatedUser;
	} else {
		return {};
	}
};

export const selectedUserWithAccessLevel = (
	userDetails = {},
	impersonatedUser = {}
) => {
	if (userDetails?.access_level) {
		return userDetails;
	} else if (impersonatedUser?.access_level) {
		return impersonatedUser;
	} else {
		return {};
	}
};
