import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { utils, writeFileXLSX } from "xlsx";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

// api slice
import { useGetPipelineMutation } from "../../features/pipeline/pipelineApiSlice";
import { useGetAdminPipelineMutation } from "../../features/admin/pipeline/pipelineApiSlice";
import { useGetAdminUnfilteredPipelineMutation } from "../../features/admin/pipeline/unfilteredLoanApiSlice";
import { useGetAePipelineMutation } from "../../features/admin/pipeline/aePipelineApiSlice";
import { useGetAmPipelineMutation } from "../../features/admin/pipeline/amPipelineApiSlice";

// redux slice
import { selectUserDetails } from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// custom components
import CustomLayout from "../../components/layout";
import CustomTable from "../../components/custom-table";
import Heading3 from "../../components/typography/heading-03";
import SecondaryButton from "../../components/buttons/secondary-button";

// custom modal
import LoaderModal from "../../components/modal/loader-modal";
import ErrorModal from "../../components/modal/error-modal";
import ConfirmationModal from "../../components/modal/confirmation-modal";

// custom styles
import styles from "./index.module.scss";

const Pipeline = () => {
	const navigate = useNavigate();
	const [loans, setLoans] = useState([]);
	const [loadingData, setLoadingData] = useState(false);
	const [loaderModal, setLoaderModal] = useState(loadingData);

	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	const [getPipeline, { isLoading: brokerLoading }] = useGetPipelineMutation();

	const [getAdminPipeline, { isLoading: adminLoading }] =
		useGetAdminPipelineMutation();

	const [getAdminUnfilteredPipeline, { isLoading: adminUnfilteredLoading }] =
		useGetAdminUnfilteredPipelineMutation();

	const [getAmPipeline, { isLoading: amPipelineLoading }] =
		useGetAmPipelineMutation();

	const [getAePipeline, { isLoading: aePipelineLoading }] =
		useGetAePipelineMutation();

	const [errorModalVisible, setErrorModalVisible] = useState(false);
	const [errorText, setErrorText] = useState("");

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	useEffect(() => {
		console.log("50 running pipeline API");

		async function getAllPipelineData() {
			console.log("67 user details:", userDetails);
			console.log("67 user roles:", userDetails?.user_roles);
			console.log(
				"67 user roles includes admin",
				userDetails?.user_roles?.includes("admin")
			);
			console.log("67 impersonated user:", impersonatedUser?.email);

			if (
				userDetails?.user_roles?.includes("admin") === true &&
				impersonatedUser?.email !== undefined &&
				impersonatedUser?.email !== null &&
				impersonatedUser?.email !== ""
			) {
				console.log("28 org name:", impersonatedUser);
				const pipelineAdminData = await getAdminPipeline({
					brokerEmail: impersonatedUser?.email,
					loan_channel: impersonatedUser?.loan_channel,
				}).unwrap();
				console.log("65 impersonated data:", pipelineAdminData?.data);
				setLoans(pipelineAdminData?.data);
				return;
			} else if (
				(impersonatedUser?.email === undefined ||
					impersonatedUser?.email === null) &&
				userDetails?.user_roles?.includes("admin") === true
			) {
				const pipelineAdminLoanData =
					await getAdminUnfilteredPipeline().unwrap();
				console.log("65 admin data:", pipelineAdminLoanData?.data);
				setLoans(pipelineAdminLoanData?.data);
				return;
			} else if (
				userDetails?.user_roles?.includes("manager") ||
				userDetails?.user_roles?.includes("AM")
			) {
				// account manager APIs
				console.log("150 pipeline manager");
				try {
					const pipelineData = await getAmPipeline().unwrap();
					console.log("65 account manager pipeline data:", pipelineData?.data);
					setLoans(pipelineData?.data);
				} catch (error) {
					handleOpenErrorModal();
					setErrorText("Error loading data.");
				}
			} else if (
				userDetails?.user_roles?.includes("executive") ||
				userDetails?.user_roles?.includes("AE")
			) {
				// account executive APIs
				console.log("150 pipeline executive");
				try {
					const pipelineData = await getAePipeline().unwrap();
					console.log(
						"65 account executive pipeline data:",
						pipelineData?.data
					);
					setLoans(pipelineData?.data);
				} catch (error) {
					handleOpenErrorModal();
					setErrorText("Error loading data.");
				}
			} else if (
				userDetails?.user_roles === undefined ||
				userDetails?.user_roles === "" ||
				userDetails?.user_roles?.includes("admin") === false
			) {
				const brokerPipelineData = await getPipeline().unwrap();
				console.log("65 broker data:", brokerPipelineData?.data);
				setLoans(brokerPipelineData?.data);
				return;
			}
		}

		getAllPipelineData();
	}, [userDetails, impersonatedUser]);

	useEffect(() => {
		if (!userDetails?.user_roles?.includes("admin")) {
			setLoadingData(brokerLoading);
			setLoaderModal(brokerLoading);
		}
	}, [brokerLoading]);

	useEffect(() => {
		if (userDetails?.user_roles?.includes("admin")) {
			setLoadingData(adminLoading);
			setLoaderModal(adminLoading);
		}
	}, [adminLoading]);

	useEffect(() => {
		if (userDetails?.user_roles?.includes("admin")) {
			setLoadingData(adminUnfilteredLoading);
			setLoaderModal(adminUnfilteredLoading);
		}
	}, [adminUnfilteredLoading]);

	useEffect(() => {
		if (userDetails?.user_roles?.includes("admin")) {
			setLoadingData(amPipelineLoading);
			setLoaderModal(amPipelineLoading);
		}
	}, [amPipelineLoading]);

	useEffect(() => {
		if (userDetails?.user_roles?.includes("admin")) {
			setLoadingData(aePipelineLoading);
			setLoaderModal(aePipelineLoading);
		}
	}, [aePipelineLoading]);

	const pipelineColumns = [
		{ id: "number", label: "Loan Number", minWidth: 200 },
		{ id: "status", label: "Loan Status", minWidth: 275 },
		{ id: "start-date", label: "Start Date", minWidth: 175 },
		{ id: "name", label: "Borrower Name", minWidth: 350 },
		{ id: "address", label: "Property Address", minWidth: 450 },
		{ id: "program", label: "Loan Program", minWidth: 675 },
		{ id: "expiration-date", label: "Lock Expiration Date", minWidth: 250 },
		{ id: "folder", label: "Loan Folder", minWidth: 225 },
	];

	const adminPipelineColumns = [
		{ id: "number", label: "Loan Number", minWidth: 200 },
		{ id: "status", label: "Loan Status", minWidth: 275 },
		{ id: "start-date", label: "Start Date", minWidth: 175 },
		{ id: "name", label: "Borrower Name", minWidth: 225 },
		{ id: "address", label: "Property Address", minWidth: 415 },
		{ id: "program", label: "Loan Program", minWidth: 675 },
		{ id: "expiration-date", label: "Lock Expiration Date", minWidth: 250 },
		{ id: "folder", label: "Loan Folder", minWidth: 225 },
		{ id: "tpo", label: "TPO", minWidth: 225 },
	];

	// TODO remove the following functions
	const handleExportExcelClick = () => {
		const loanData = loans.map(({ loanGuid, fields }) => ({
			loanNumber: fields["Loan.LoanNumber"],
			startDate: fields["Fields.2025"]?.split(" ")[0],
			borrowerName: fields["Loan.BorrowerName"],
			propertyAddress:
				fields["Fields.URLA.X73"] +
				"," +
				fields["Fields.URLA.X75"] +
				"," +
				fields["Fields.12"] +
				"," +
				fields["Fields.14"],
			loanProgram: fields["Fields.1401"],
			lockExpirationDate: fields["Fields.762"]?.split(" ")[0],
			loanStatus: fields["Fields.Log.MS.CurrentMilestone"],
		}));

		const ws = utils.json_to_sheet(loanData);
		const wb = utils.book_new();
		utils.book_append_sheet(wb, ws, "Data");
		writeFileXLSX(wb, "Exported Excel.xlsx");
	};

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loans",
			path: "/pipeline",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack
					direction="row"
					className={styles.stackContainer}
					sx={{
						overflow: "hidden",
					}}
				>
					{/* pipeline table */}
					<Stack direction="column" className={styles.tableContainer}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							className={styles.tableTitleContainer}
						>
							<Heading3 text="Pipeline" fontType="semibold" />

							<SecondaryButton
								text="Export to Excel"
								startIcon={
									<DownloadOutlinedIcon className={styles.downloadIcon} />
								}
								onClick={handleExportExcelClick}
							/>
						</Stack>

						<CustomTable
							columnData={
								((impersonatedUser?.email === undefined ||
									impersonatedUser?.email === null) &&
									userDetails?.user_roles?.includes("admin")) ||
								userDetails?.user_roles?.includes("executive") ||
								userDetails?.user_roles?.includes("manager") ||
								userDetails?.user_roles?.includes("AE") ||
								userDetails?.user_roles?.includes("AM")
									? adminPipelineColumns
									: pipelineColumns
							}
							rowData={loans?.filter(
								(loan) =>
									loan?.fields["Loan.LoanFolder"] === "Pipeline" ||
									loan?.fields["Loan.LoanFolder"] ===
										"Portal - Not Submitted" ||
									loan?.fields["Loan.LoanFolder"] === "Funded" ||
									loan?.fields["Loan.LoanFolder"] === "Adverse Loans"
							)}
							tableType={
								((impersonatedUser?.email === undefined ||
									impersonatedUser?.email === null) &&
									userDetails?.user_roles?.includes("admin")) ||
								userDetails?.user_roles?.includes("executive") ||
								userDetails?.user_roles?.includes("manager") ||
								userDetails?.user_roles?.includes("AE") ||
								userDetails?.user_roles?.includes("AM")
									? "admin-pipeline"
									: "pipeline"
							}
							isLoading={
								brokerLoading ||
								adminLoading ||
								adminUnfilteredLoading ||
								amPipelineLoading ||
								aePipelineLoading
							}
							setSortedData={setLoans}
							handleOpenConfirmModal={handleOpenConfirmModal}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			<LoaderModal open={loaderModal} />
			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
			<ConfirmationModal
				open={confirmModalVisible}
				text="Sign in as a broker to gain access."
				confirmText="Sign In"
				handleConfirm={handleSubmitConfirmModal}
				closeText="Close"
				handleClose={handleCloseConfirmModal}
			/>
		</Box>
	);
};

export default Pipeline;
