import React from "react";
import { Typography } from "@mui/material";

import styles from "./index.module.scss";

const Body1 = ({ text, extraClass, error, fontType, isLink }) => {
	const customClass = `${styles.body1} ${extraClass} ${
		error ? styles.error : ""
	} ${fontType === "semibold" && styles.semibold} ${isLink && styles.link} `;

	return <Typography className={customClass}>{text}</Typography>;
};

export default Body1;
