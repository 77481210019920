import { isEmptyOrUndefined } from "../filter-value";

export const validateDeclarationInformation = (
	borrowerPairData,
	borrowerDeclaration
) => {
	let error = false;
	const mandatoryQuestions = [
		"intentToOccupyIndicator", // A
		"specialBorrowerSellerRelationshipIndicator", // B
		"undisclosedBorrowedFundsIndicator", // C
		"undisclosedMortgageApplicationIndicator", // D.1
		"undisclosedCreditApplicationIndicator", // D.2
		"propertyProposedCleanEnergyLienIndicator", // E
		"undisclosedComakerOfNoteIndicator", // F
		"outstandingJudgementsIndicator", // G
		"presentlyDelinquentIndicatorURLA", // H
		"partyToLawsuitIndicatorURLA", // I
		"priorPropertyDeedInLieuConveyedIndicator", // J
		"priorPropertyShortSaleCompletedIndicator", // K
		"priorPropertyForeclosureCompletedIndicator", // L
		"bankruptcyIndicator", // M
	];

	const currentDeclatationNeedValidation = (index) => {
		return (
			index % 2 === 0 || !isEmptyOrUndefined(borrowerPairData[index].firstName)
		);
	};

	for (let i = 0; i < borrowerDeclaration.length; i++) {
		const currentDeclaration = borrowerDeclaration[i];
		if (currentDeclatationNeedValidation(i)) {
			for (let mandatoryQuestion of mandatoryQuestions) {
				if (currentDeclaration[mandatoryQuestion] === undefined) {
					console.log(
						"Declaration validation Error found: ",
						mandatoryQuestion,
						i
					);
					return true;
				}
			}

			if (currentDeclaration["intentToOccupyIndicator"] === true) {
				if (
					isEmptyOrUndefined(
						currentDeclaration["homeownerPastThreeYearsIndicator"]
					)
				) {
					console.log(
						"55 Declaration validation Error found: homeownerPastThreeYearsIndicator true ",
						i
					);
					return true;
				}

				if (currentDeclaration["homeownerPastThreeYearsIndicator"] === true) {
					if (
						isEmptyOrUndefined(currentDeclaration["priorPropertyUsageType"]) ||
						isEmptyOrUndefined(currentDeclaration["priorPropertyTitleType"])
					) {
						console.log(
							"75 Declaration validation Error found: priorProperty true ",
							i
						);
						return true;
					}
				}
			}

			if (currentDeclaration["undisclosedBorrowedFundsIndicator"] === true) {
				if (
					isEmptyOrUndefined(
						currentDeclaration["undisclosedBorrowedFundsAmount"]
					)
				) {
					console.log(
						"Declaration validation Error found: undisclosedBorrowedFundsIndicator amount ",
						i
					);
					return true;
				}
			}

			if (currentDeclaration["bankruptcyIndicator"] === true) {
				if (
					(isEmptyOrUndefined(
						currentDeclaration["bankruptcyIndicatorChapterSeven"]
					) ||
						currentDeclaration["bankruptcyIndicatorChapterSeven"] === false) &&
					(isEmptyOrUndefined(
						currentDeclaration["bankruptcyIndicatorChapterEleven"]
					) ||
						currentDeclaration["bankruptcyIndicatorChapterEleven"] === false) &&
					(isEmptyOrUndefined(
						currentDeclaration["bankruptcyIndicatorChapterTwelve"]
					) ||
						currentDeclaration["bankruptcyIndicatorChapterTwelve"] === false) &&
					(isEmptyOrUndefined(
						currentDeclaration["bankruptcyIndicatorChapterThirteen"]
					) ||
						currentDeclaration["bankruptcyIndicatorChapterThirteen"] === false)
				) {
					console.log(
						"Declaration validation Error found: bankruptcyIndicator 4 options none selected ",
						i
					);
					return true;
				}
			}
		}
	}

	return error;
};
