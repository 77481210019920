import { apiSlice } from "../../../app/api/apiSlice";

export const aeMilestoneCountSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAeMilestoneCount: builder.mutation({
			query: () => ({
				url: "/api/v1/admin/aeam_filtered_milestone/AE/",
				method: "GET",
			}),
		}),
	}),
});

export const { useGetAeMilestoneCountMutation } = aeMilestoneCountSlice;
