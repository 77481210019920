import React from "react";

// mui components
import { TableCell, TableRow } from "@mui/material";

// custom components
import Body3 from "../../typography/body-03";

// custom styles
import styles from "../index.module.scss";

const EligibleProductAdjustmentsRow = ({ row, extraData }) => {
	return (
		<TableRow hover tabIndex={-1}>
			<TableCell>
				<Body3 text={row?.reason} extraClass={styles.greenText} />
			</TableCell>
			<TableCell align="right">
				<Body3
					text={`${Number(extraData?.rowData?.rate)} %`}
					extraClass={styles.greenText}
				/>
			</TableCell>
			<TableCell align="right">
				<Body3
					text={`${Number(row?.adjustor)} %`}
					extraClass={styles.greenText}
				/>
			</TableCell>
		</TableRow>
	);
};

export default EligibleProductAdjustmentsRow;
