import React from "react";
import { Button } from "@mui/material";

import Heading5 from "../../typography/heading-05";

import styles from "./index.module.scss";

const PrimaryButton = ({
	text,
	fontType,
	startIcon,
	endIcon,
	onClick,
	fullWidth,
	disabled,
	extraClass,
	extraTextClass,
}) => {
	const customClass = `${styles.primaryBtn} ${extraClass}`;
	const customTextClass = `${styles.primaryBtnText} ${extraTextClass}`;

	return (
		<Button
			variant="contained"
			color="inherit"
			startIcon={startIcon}
			endIcon={endIcon}
			onClick={onClick}
			className={customClass}
			fullWidth={fullWidth}
			disabled={disabled}
		>
			<Heading5
				text={text}
				fontType={fontType || "semibold"}
				extraClass={customTextClass}
			/>
		</Button>
	);
};

export default PrimaryButton;
