import React from "react";

// mui components
import { Checkbox, Stack, TableCell, TableRow } from "@mui/material";

// custom components
import Body3 from "../../typography/body-03";

// mui icons
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const SatisfyingConditionsRow = ({
	row,
	handleRowClick,
	selectedRow,
	minimizedRow,
	handleAddMinimizeRow,
	handleDeleteMinimizeRow,
}) => {
	const isSelected = (name) => selectedRow.indexOf(name) !== -1;

	const isItemSelected = isSelected(row.id);
	const labelId = `enhanced-table-checkbox-${row.id}`;

	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.id}
			onClick={(event) => handleRowClick(event, row.id)}
			role="checkbox"
			aria-checked={isItemSelected}
			selected={isItemSelected}
			className={styles.conditionRow}
		>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={isItemSelected}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>
			<TableCell>
				<Body3 text={row.status} extraClass={styles.greenText} />
			</TableCell>
			<TableCell>
				<Body3 text={row.sub_status} extraClass={styles.greenText} />
			</TableCell>
			<TableCell>
				<Body3 text={row.category} extraClass={styles.greenText} />
			</TableCell>
			<TableCell>
				<Stack direction="row" className={styles.overflowTextContainer}>
					<Body3
						text={row.description}
						extraClass={
							minimizedRow?.includes(row?.id)
								? styles.truncatedText
								: styles.truncatedText + " " + styles.expandedText
						}
					/>
					{row.description !== "" &&
						(minimizedRow?.includes(row?.id) ? (
							<ExpandMoreOutlined
								onClick={() => handleDeleteMinimizeRow(row?.id)}
								className={styles.truncatedIcon}
							/>
						) : (
							<ExpandLessOutlined
								onClick={() => handleAddMinimizeRow(row?.id)}
								className={styles.truncatedIcon}
							/>
						))}
				</Stack>
			</TableCell>
		</TableRow>
	);
};

export default SatisfyingConditionsRow;
