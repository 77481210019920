import { apiSlice } from "../../app/api/apiSlice";

export const createBorrowerPairSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createBorrowerPair: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/loan/borrower_pair/${credentials.loanGuid}/`,
				method: "POST",
				body: credentials.data,
			}),
		}),
	}),
});

export const { useCreateBorrowerPairMutation } = createBorrowerPairSlice;
