import React, { useState } from "react";
import { useSelector } from "react-redux";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import SupportingDocs from "../../../supporting-docs";
import PrimaryButton from "../../../buttons/primary-button";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Body2 from "../../../typography/body-02";
import Body3 from "../../../typography/body-03";

// api slice
import { useLockRequestMutation } from "../../../../features/lock-request/lockRequestApiSlice";
import { useLockRequestLogMutation } from "../../../../features/lock-request-log/lockRequestLogApiSlice";

// reducer slice
import { setLockRequestLog } from "../../../../features/lock-request-log/lockRequestLogSlice";
import { selectUserDetails } from "../../../../features/auth/authSlice";
import { getLockRequestLog } from "../../../../app/services/lock-request-log";

// helper utils
import { filterValue } from "../../../../utils/filter-value";
import { formatBytes } from "../../../../utils/format-bytes";

// custom styles
import styles from "./index.module.scss";

const RequestLockDrawer = ({
	loanData,
	fieldData,
	handleCloseDrawer,
	// processing
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	setProcessingText,
	// success
	handleOpenSuccessModal,
	setSuccessText,
	// error
	handleOpenErrorModal,
	setErrorText,
}) => {
	const [lockRequest] = useLockRequestMutation();
	const [lockRequestLog] = useLockRequestLogMutation();
	const userDetails = useSelector(selectUserDetails);

	const fileTypes = ["PDF", "PNG", "JPEG", "JPG", "HTML", "TXT"];
	const [lockRequestFile, setLockRequestFile] = useState(null);

	const handleFileAttach = async (file) => {
		setLockRequestFile(file);
	};

	const deleteFile = () => {
		setLockRequestFile(null);
	};

	const handleCancel = () => {
		console.log("40 cancel clicked.");
		setLockRequestFile(null);
	};

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseProcessingModal();
		handleOpenErrorModal();
		setErrorText("There was an error getting lock info");
	};

	const handleSubmitLockRequest = async () => {
		if (lockRequestFile?.length === 0) return;

		console.log("55 lockRequestFile:", lockRequestFile);

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Your Request is being processed");

		let address = filterValue(fieldData, "URLA.X73");

		var formData = new FormData();
		formData.append("loan_number", loanData?.loanNumber);
		formData.append(
			"borrower_name",
			loanData?.applications[0]?.borrower?.fullName
		);
		formData.append("property_address", address);
		formData.append("attachment", lockRequestFile);

		// dispatch API and open modal if successful
		try {
			const lockRequestData = await lockRequest({ formData }).unwrap();
			console.log("310 file upload res:", lockRequestData);
			console.log("75 use effect:", userDetails?.email, loanData);

			if (lockRequestData?.message === "Email sent successfully.") {
				setLockRequestLog(lockRequestData);

				getLockRequestLog({
					email: userDetails?.email,
					loanNumber: loanData?.loanNumber,
					apiRequest: lockRequestLog,
					handleErrorModal: handleErrorModal,
				});

				handleCloseProcessingModal();
				handleOpenSuccessModal();
				setSuccessText("Your Lock Request has been sent");
			}
		} catch (err) {
			console.log("95 err:", err);
			handleCloseProcessingModal();
			// TODO aqib open error modal
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Request Lock"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.drawerBodyContainer}>
				<Heading3
					text="Upload Supporting Documentation:"
					extraClass={styles.labelText}
					fontType="semibold"
				/>

				<SupportingDocs
					fileCount={
						lockRequestFile !== null && lockRequestFile !== undefined ? 1 : 0
					}
					fileTypes={fileTypes}
					text="or drag to upload supporting files"
					handleFileAttach={handleFileAttach}
					multiple={false}
				/>
			</Stack>

			{lockRequestFile !== undefined && lockRequestFile !== null && (
				<Stack direction="column" className={styles.uploadedFileContainer}>
					<Stack direction="row" className={styles.fileListContainer}>
						<Box className={styles.fileIconContainer}>
							<UploadFileIcon className={styles.fileIcon} />
						</Box>
						<Stack direction="column" className={styles.fileNameContainer}>
							<Body2 text={lockRequestFile?.name} />
							<Body3 text={formatBytes(lockRequestFile?.size)} />
						</Stack>

						<Box
							sx={{ cursor: "pointer" }}
							onClick={(event) => deleteFile(event)}
							className={styles.deleteIconContainer}
						>
							<CloseIcon className={styles.deleteIcon} />
						</Box>
					</Stack>
				</Stack>
			)}

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				className={styles.drawerFooterContainer}
			>
				<SecondaryButton
					text="Reset"
					extraClass={styles.cancelBtn}
					extraTextClass={styles.cancelText}
					onClick={handleCancel}
					disabled={lockRequestFile === null}
				/>
				<PrimaryButton
					text="Request Lock"
					onClick={handleSubmitLockRequest}
					disabled={lockRequestFile === null}
				/>
			</Stack>
		</Stack>
	);
};

export default RequestLockDrawer;
