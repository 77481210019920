import React from "react";
import { Divider } from "@mui/material";

import styles from "./index.module.scss";

const CustomDivider = ({ extraClass }) => {
	const customClass = `${styles.customDivider} ${extraClass}`;

	return <Divider className={customClass} />;
};

export default CustomDivider;
