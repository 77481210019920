import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// mui components
import { Box, Typography } from "@mui/material";

// custom components
import CustomLoader from "../../components/loader";
import LoginSkeleton from "../../assets/icons/login-skeleton";

// custom icons
import Logo from "../../assets/icons/logo";
import DotsBg from "../../assets/icons/dots-bg";

// api slice
import { useSetPasswordActionMutation } from "../../features/set-password/setPasswordApiSlice";

// custom styles
import styles from "./index.module.scss";

export default function SetPasswordPage() {
	const navigate = useNavigate();
	const [setPasswordAction, { isLoading }] = useSetPasswordActionMutation();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");

	let { id } = useParams();
	console.log("22 id:", id);

	const handleSetPasswordClick = async (e) => {
		e.preventDefault();

		if (password !== confirmPassword) {
			setError("Passwords do not match.");
		} else {
			setError("");

			// dispatch api
			try {
				const userData = await setPasswordAction({
					uuid: id,
					password,
				}).unwrap();
				setError(userData.message || "Password reset successfully.");
				setSuccess(true);
			} catch (err) {
				setError(err?.data?.message || "An error occurred. Please try again.");
				setSuccess(false);
			}
		}
	};

	return (
		<Box className={styles.pageContainer}>
			<Box className={styles.bodyContainer}>
				<Box
					className={styles.bodyLeftContainer}
					sx={{
						display: { xs: "none", md: "flex" },
					}}
				>
					<Box className={styles.imageContainer}>
						<Logo extraStyles={{ width: "100%", height: "100%" }} />
					</Box>

					<Box className={styles.skeletonContainer}>
						<LoginSkeleton
							extraStyles={{
								position: "relative",
								transform: "translate(31px, 0px)",
								width: "300px",
								height: "250px",
							}}
						/>
					</Box>
				</Box>

				<Box className={styles.bodyRightContainer}>
					<Box
						className={styles.rightTopContainer}
						sx={{
							display: { xs: "none", md: "flex" },
						}}
					>
						<DotsBg extraStyles={{ width: "85px", height: "80px" }} />
					</Box>
					<form
						onSubmit={handleSetPasswordClick}
						className={styles.formContainer}
					>
						<Box className={styles.formBodyContainer}>
							<Typography
								component="p"
								sx={{
									fontSize: "1.75rem",
									lineHeight: "2.25rem",
									fontWeight: 600,
									color: "#000",
								}}
								className={styles.resetText}
							>
								Create New Password
							</Typography>

							<Box className={styles.formFieldsContainer}>
								<Box className={styles.inputFieldContainer}>
									<label htmlFor="password" className={styles.inputLabel}>
										Password
									</label>
									<input
										type="password"
										className={styles.inputField}
										id="password"
										aria-describedby="password"
										placeholder="Enter your password"
										value={password}
										onChange={(event) => {
											setPassword(event.target.value);
											setError("");
										}}
									/>
								</Box>

								<Box className={styles.inputFieldContainer}>
									<label
										htmlFor="confirm-password"
										className={styles.inputLabel}
									>
										Confirm Password
									</label>
									<input
										type="password"
										className={styles.inputField}
										id="confirm-password"
										aria-describedby="password"
										placeholder="Confirm new password"
										value={confirmPassword}
										onChange={(event) => {
											setConfirmPassword(event.target.value);
											setError("");
										}}
									/>
								</Box>
							</Box>

							{error !== "" && <p style={{ color: "#f00" }}>{error}</p>}
							{!success && (
								<button
									type="submit"
									onClick={handleSetPasswordClick}
									className={styles.submitBtn}
								>
									{isLoading ? (
										<CustomLoader sx={{ color: "#fff" }} size={20} />
									) : (
										"Set Password"
									)}
								</button>
							)}
						</Box>

						{success && (
							<Box className={styles.formBodyContainer}>
								<Typography
									component="p"
									sx={{
										fontSize: "1rem",
										lineHeight: "1.25rem",
										fontWeight: 400,
										color: "#000",
										textAlign: "center",
										marginBottom: "4px",
									}}
									className={styles.loginText}
								>
									Back to Login
								</Typography>

								<Box
									onClick={() => navigate("/login")}
									className={styles.footerBtn}
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginBottom: "24px",
									}}
								>
									<Typography
										component="p"
										sx={{
											fontFamily: "Sofia Sans",
											fontWeight: 700,
											fontSize: "1rem",
											lineHeight: "1.25rem",
										}}
									>
										Login
									</Typography>
								</Box>
							</Box>
						)}
					</form>
				</Box>
			</Box>
		</Box>
	);
}
