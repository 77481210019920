export const validateAssetsInformation = (
	borrowerResponseData,
	borrowerLiability,
	borrowerRealEstate
) => {
	for (let index = 0; index < borrowerResponseData.length; index++) {
		let borrowerPairLiability = borrowerLiability[index];
		let borrowerPairRealEstate = borrowerRealEstate[index];

		if (borrowerPairLiability?.length > 0) {
			for (let i = 0; i < borrowerPairLiability.length; i++) {
				const liabilityElement = borrowerPairLiability[i];

				if (
					(liabilityElement?.subjectLoanResubordinationIndicator ||
						liabilityElement?.bankLiabilityConsideredIndicator) &&
					(!liabilityElement?.currentLienPosition ||
						!liabilityElement?.proposedLienPosition)
				) {
					console.log("275 liability error:", liabilityElement);
					return true;
				}
			}
		}

		if (borrowerPairRealEstate?.length > 0) {
			for (let i = 0; i < borrowerPairRealEstate.length; i++) {
				const realEstateElement = borrowerPairRealEstate[i];

				if (!realEstateElement?.futurePropertyUsageType) {
					console.log("275 real estate error:", realEstateElement);
					return true;
				}
			}
		}
	}
};
