import React from "react";

// mui components
import { Box, IconButton, InputLabel, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import MaskedInput from "../../custom-input/masked-input";
import SecondarySelect from "../../custom-select/secondary-select";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body3 from "../../typography/body-03";

// mui icons
import { ErrorOutlined, HelpOutlineOutlined } from "@mui/icons-material";

// helper utils
import { stateOptions } from "../../../utils/select-options";
import { isNullOrUndefinedOrEmpty } from "../../../utils/filter-value";

// custom styles
import styles from "./index.module.scss";

const PropertyDetails = ({
	data,
	setData = () => null,
	loanData,
	setLoanData,
	grossRent,
	setGrossRent,
	homeownersInsurance,
	setHomeownersInsurance,
	floodInsurance,
	setFloodInsurance,
	propertyTaxes,
	setPropertyTaxes,
	realEstateTaxes,
	setRealEstateTaxes,
	hoaDues,
	setHoaDues,
	supplementalInsurance,
	setSupplementalInsurance,
	disabled,
	viewLoan,
	error,
	setHelpText,
	handleOpenHelpText,
}) => {
	const handleChangeUnitCount = (event) => {
		const min = 0;
		const max = 999;

		const value = Math.max(min, Math.min(max, Number(event.target.value)));
		console.log("830 value:", value);

		setData({
			...data,
			financedNumberOfUnits: parseInt(value).toFixed(0),
		});
	};

	const handleGrossRent = (value) => {
		setGrossRent(value);
	};

	const handleHomeownersInsurance = (value) => {
		setHomeownersInsurance(value);
	};

	const handleFloodInsurance = (value) => {
		setFloodInsurance(value);
	};

	const handlePropertyTaxes = (value) => {
		setPropertyTaxes(value);
	};

	const handleHoaDues = (value) => {
		setHoaDues(value);
	};

	const handleSupplementalInsurance = (value) => {
		setSupplementalInsurance(value);
	};

	const handleRealEstateTaxes = (value) => {
		setRealEstateTaxes(value);
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Subject Property Details" fontType="semibold" />

				<Body3 text="Provide details about the property" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				{/* basic information */}
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* Address Line 1 */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-address-line">
							<Heading6
								text="Address Line 1"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-address-line"
							type="text"
							placeholder="Street Line 1"
							fullWidth={true}
							value={data?.addressLineText}
							handleChange={(event) =>
								setData({
									...data,
									addressLineText: event.target.value,
								})
							}
							disabled={disabled}
							error={
								error &&
								(data?.addressLineText === null ||
									data?.addressLineText === undefined ||
									data?.addressLineText === "")
							}
							showEndIcon={
								error &&
								(data?.addressLineText === null ||
									data?.addressLineText === undefined ||
									data?.addressLineText === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>

					{/* unit number */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-unit-number">
							<Heading6
								text="Address Line 2 / Unit Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-unit-number"
							type="text"
							placeholder="Unit Number"
							fullWidth={true}
							value={data?.addressUnitIdentifier}
							handleChange={(event) =>
								setData({
									...data,
									addressUnitIdentifier: event.target.value,
								})
							}
							disabled={disabled}
						/>
					</Stack>

					{/* city */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-city">
							<Heading6
								text="City"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-city"
							type="text"
							placeholder="City"
							value={data?.city}
							handleChange={(event) =>
								setData({ ...data, city: event.target.value })
							}
							disabled={disabled}
							error={
								error &&
								(data?.city === null ||
									data?.city === undefined ||
									data?.city === "")
							}
							showEndIcon={
								error &&
								(data?.city === null ||
									data?.city === undefined ||
									data?.city === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>

					{/* state */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-state">
							<Heading6
								text="State"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<SecondarySelect
							id="select-state"
							displayEmpty={true}
							value={data?.state || ""}
							handleChange={(event) =>
								setData({ ...data, state: event.target.value })
							}
							options={stateOptions}
							disabled={disabled}
							error={
								error &&
								(data?.state === null ||
									data?.state === undefined ||
									data?.state === "")
							}
						/>
					</Stack>

					{/* postal code */}
					<Stack direction="column" className={styles.inputContainer}>
						<InputLabel htmlFor="outlined-input-postal-code">
							<Heading6
								text="Postal Code"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-postal-code"
							type="text"
							placeholder="Postal Code"
							value={data?.postalCode}
							handleChange={(event) =>
								setData({ ...data, postalCode: event.target.value })
							}
							disabled={disabled}
							error={
								error &&
								(data?.postalCode === null ||
									data?.postalCode === undefined ||
									data?.postalCode === "")
							}
							showEndIcon={
								error &&
								(data?.postalCode === null ||
									data?.postalCode === undefined ||
									data?.postalCode === "")
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* units owned */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-units-owned">
							<Heading6
								text="Number of units owned"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-units-owned"
							type="number"
							placeholder="Unit count"
							min={0}
							max={999}
							maxLength={3}
							fullWidth={true}
							value={data?.financedNumberOfUnits}
							handleChange={handleChangeUnitCount}
							disabled={disabled}
							error={
								error &&
								(data?.financedNumberOfUnits === null ||
									data?.financedNumberOfUnits === undefined ||
									data?.financedNumberOfUnits === "" ||
									data?.financedNumberOfUnits === "0" ||
									data?.financedNumberOfUnits === 0)
							}
							showEndIcon={
								error &&
								(data?.financedNumberOfUnits === null ||
									data?.financedNumberOfUnits === undefined ||
									data?.financedNumberOfUnits === "" ||
									data?.financedNumberOfUnits === "0" ||
									data?.financedNumberOfUnits === 0)
							}
							endIcon={<ErrorOutlined className={styles.errorIcon} />}
						/>
					</Stack>

					{/* rental income */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-rental-income">
							<Heading6
								text="Expected Gross Rental Income"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="outlined-input-rental-income"
							type="currency"
							placeholder="Rent ($ / mo.)"
							fullWidth={true}
							value={grossRent?.toString() || ""}
							onInputChange={handleGrossRent}
							disabled={disabled}
							error={error && isNullOrUndefinedOrEmpty(grossRent?.toString())}
						/>
					</Stack>
				</Stack>

				<Heading5
					text="Proposed Housing Expense (Subject Property)"
					fontType="semibold"
				/>

				<Stack direction="column" className={styles.inputSectionContainer}>
					{/* First mortgage (P&L) */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<Stack direction="row">
							<InputLabel htmlFor="outlined-input-first-mortgage">
								<Heading6
									text="First Mortgage (P&L)"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<IconButton
								className={styles.helperIconBtn}
								onClick={() => {
									console.log("385 clicked.");
									setHelpText(
										"The “First Mortgage (P&L)” will be calculated based on the Loan Product and Terms Selected and displayed on the “Underwriting Summary” Screen."
									);
									handleOpenHelpText();
								}}
							>
								<HelpOutlineOutlined className={styles.helperIcon} />
							</IconButton>
						</Stack>

						<MaskedInput
							id="outlined-input-first-mortgage"
							type="currency"
							placeholder="$"
							fullWidth={true}
							value={loanData?.proposedFirstMortgageAmount?.toString() || "0"}
							onInputChange={() => console.log("first mortgage - field id:228")}
							disabled={true}
						/>
					</Stack>

					{/* Subordinate Liens (P&L) */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<Stack direction="row">
							<InputLabel htmlFor="outlined-input-subordinate-liens">
								<Heading6
									text="Subordinate Liens (P&L)"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<IconButton
								className={styles.helperIconBtn}
								onClick={() => {
									console.log("385 clicked.");
									setHelpText(
										"“Subordinate Liens” will be calculated based on the liabilities entered on the “Asset and Liability” screen."
									);
									handleOpenHelpText();
								}}
							>
								<HelpOutlineOutlined className={styles.helperIcon} />
							</IconButton>
						</Stack>

						<MaskedInput
							id="outlined-input-subordinate-liens"
							type="currency"
							placeholder="$"
							fullWidth={true}
							value={loanData?.proposedOtherMortgagesAmount?.toString() || "0"}
							onInputChange={() =>
								console.log("subordinate liens - field id:229")
							}
							disabled={true}
						/>
					</Stack>

					{/* Homeowner's insurance */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<Stack direction="row">
							<InputLabel htmlFor="outlined-input-homeowner-insurance">
								<Heading6
									text="Homeowner's Insurance"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<IconButton
								className={styles.helperIconBtn}
								onClick={() => {
									console.log("385 clicked.");
									setHelpText(
										"Please include in this field Homeowner's Insurance and Supplement Insurance in this field."
									);
									handleOpenHelpText();
								}}
							>
								<HelpOutlineOutlined className={styles.helperIcon} />
							</IconButton>
						</Stack>

						<MaskedInput
							id="outlined-input-homeowner-insurance"
							type="currency"
							placeholder="$ / mo."
							fullWidth={true}
							value={homeownersInsurance?.toString()}
							onInputChange={handleHomeownersInsurance}
							disabled={disabled}
							error={
								error &&
								(isNullOrUndefinedOrEmpty(homeownersInsurance?.toString()) ||
									homeownersInsurance?.toString() === "0")
							}
						/>
					</Stack>

					{/* flood insurance */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-flood-insurance">
							<Heading6
								text="Flood Insurance"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="outlined-input-flood-insurance"
							type="currency"
							placeholder="$ / mo."
							fullWidth={true}
							value={floodInsurance?.toString()}
							onInputChange={handleFloodInsurance}
							disabled={disabled}
						/>
					</Stack>

					{/* supplemental property insurance */}
					{/* <Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-supplemental-insurance">
							<Heading6
								text="Supplemental Property Insurance"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="outlined-input-supplemental-insurance"
							type="currency"
							placeholder="$ / mo."
							fullWidth={true}
							value={supplementalInsurance?.toString()}
							onInputChange={handleSupplementalInsurance}
							disabled={true}
						/>
					</Stack> */}

					{viewLoan ? (
						//  Real Estate taxes
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-real-estate-taxes">
								<Heading6
									text="Real Estate Taxes"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id="outlined-input-real-estate-taxes"
								type="currency"
								placeholder="$ / mo."
								fullWidth={true}
								value={realEstateTaxes?.toString()}
								onInputChange={handleRealEstateTaxes}
								disabled={true}
							/>
						</Stack>
					) : (
						// Property taxes
						<Stack
							direction="column"
							className={styles.inputContainer + " " + styles.inlineContainer}
						>
							<InputLabel htmlFor="outlined-input-property-taxes">
								<Heading6
									text="Property Taxes"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id="outlined-input-property-taxes"
								type="currency"
								placeholder="$ / mo."
								fullWidth={true}
								value={propertyTaxes?.toString()}
								onInputChange={handlePropertyTaxes}
								disabled={disabled}
								error={
									error &&
									(isNullOrUndefinedOrEmpty(propertyTaxes?.toString()) ||
										propertyTaxes?.toString() === "0")
								}
							/>
						</Stack>
					)}

					{/* hoa project dues */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.inlineContainer}
					>
						<InputLabel htmlFor="outlined-input-hoa-dues">
							<Heading6
								text="HOA/Project Dues"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<MaskedInput
							id="outlined-input-hoa-dues"
							type="currency"
							placeholder="$ / mo."
							fullWidth={true}
							value={hoaDues?.toString()}
							onInputChange={handleHoaDues}
							disabled={disabled}
						/>
					</Stack>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default PropertyDetails;
