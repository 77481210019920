import { apiSlice } from "../../app/api/apiSlice";

export const getEligibleProductDetailsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getEligibleProductDetails: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/loan/get_ob_product_details/?ob_business_channel_id=${credentials?.ob_business_channel_id}&ob_originator_id=${credentials?.ob_originator_id}&ob_loan_id=${credentials?.ob_loan_id}&search_id=${credentials?.search_id}&product_id=${credentials?.product_id}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetEligibleProductDetailsMutation } =
	getEligibleProductDetailsSlice;
