import { apiSlice } from "../../app/api/apiSlice";

export const creditVendorsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		creditVendors: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/credit_partners/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useCreditVendorsMutation } = creditVendorsApiSlice;
