import React from "react";
import { useSelector } from "react-redux";

// mui components
import { Stack, Grid } from "@mui/material";

// custom components
import Heading6 from "../../typography/heading-06";
import Body2 from "../../typography/body-02";

// reducer slice
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// helper utils
import { filterValue } from "../../../utils/filter-value";
import { formatCurrency } from "../../../utils/format-currency";
import { selectedUserWithLoanChannel } from "../../../utils/common-helpers";
import { LOAN_CHANNEL_CONSTANTS } from "../../../utils/common-constants";

// custom styles
import styles from "./index.module.scss";

const LoanParametersSummaryCard = ({ loanData, fieldData }) => {
	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	return (
		<Stack
			direction="column"
			alignItems="flex-start"
			className={styles.cardContainer}
		>
			<Grid
				container
				className={styles.bottomContainer}
				columns={15}
				columnSpacing={4}
				rowSpacing={3}
			>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Loan Product" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "1401") || "NA"}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Purpose" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "19") || "NA"}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="FICO" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "VASUMM.X23") || "NA"}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Occupancy" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "1811") || "NA"}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Property Type" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "1041") || "NA"}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Appraised Value" fontType="semibold" />
						<Body2
							text={formatCurrency.format(
								Number(filterValue(fieldData, "356") || 0).toFixed(2)
							)}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Purchase Price" fontType="semibold" />
						<Body2
							text={formatCurrency.format(
								Number(filterValue(fieldData, "136") || 0).toFixed(2)
							)}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Loan Amount" fontType="semibold" />
						<Body2
							text={formatCurrency.format(
								Number(filterValue(fieldData, "1109") || 0).toFixed(2)
							)}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="LTV" fontType="semibold" />
						<Body2
							text={`${Number(filterValue(fieldData, "353")).toFixed(3)}%`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="CLTV" fontType="semibold" />
						<Body2
							text={`${Number(filterValue(fieldData, "976")).toFixed(3)}%`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="HCLTV" fontType="semibold" />
						<Body2
							text={`${Number(filterValue(fieldData, "1540")).toFixed(3)}%`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="DTI" fontType="semibold" />
						<Body2
							text={`${Number(filterValue(fieldData, "742")).toFixed(3)}%`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="DSCR" fontType="semibold" />
						<Body2
							text={`${Number(
								filterValue(fieldData, "CX.DEBTSERVICECOVERAGERATIO")
							).toFixed(3)}%`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Auto Debit" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "CX.AutoDebit") || "NA"}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>
				<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Impounds" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "1550") || "NA"}
							extraClass={styles.detailsValue}
						/>
					</Stack>
				</Grid>

				{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CB ||
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS) && (
					<Grid item className={styles.detailContainer} xs={6} sm={5} md={3}>
						<Stack direction="column" className={styles.detailDiv}>
							<Heading6 text="Comp Plan" fontType="semibold" />
							<Body2
								text={filterValue(fieldData, "LCP.X1") || "NA"}
								extraClass={styles.detailsValue}
							/>
						</Stack>
					</Grid>
				)}
			</Grid>
		</Stack>
	);
};

export default LoanParametersSummaryCard;
