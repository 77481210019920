import { createSlice } from "@reduxjs/toolkit";

const lockRequestLog = createSlice({
	name: "lockRequestLog",
	initialState: {
		data: [],
	},
	reducers: {
		setLockRequestLog: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setLockRequestLog } = lockRequestLog.actions;
export default lockRequestLog.reducer;

export const selectLockRequestLog = (state) => state.lockRequestLog.data;
