export const appraisal1Fields = {
	typeOfAppraisal: "CX.APPR1.TYPE",
	orderStatus: "CX.APPR1.STATUS",
	statusDate: "CX.APPR1.STATUSDT",
	followUpDate: "CX.APPR1.FOLLOWUPDT",
	orderDate: "CX.APPR1.ORDERDATE",
	inspectionDate: "CX.APPR1.INSPECTDATE",
	dueDate: "CX.APPR1.DUEDATE",
	receivedDate: "CX.APPR1.RECDATE",
	cost: "CX.APPR1.PRICE",
	amountPaid: "CX.APPR1.PDAMT",
};

export const appraisal2Fields = {
	typeOfAppraisal: "CX.APPR2.TYPE",
	orderStatus: "CX.APPR2.STATUS",
	statusDate: "CX.APPR2.STATUSDT",
	followUpDate: "CX.APPR2.FOLLOWUPDT",
	orderDate: "CX.APPR2.ORDERDATE",
	inspectionDate: "CX.APPR2.INSPECTDATE",
	dueDate: "CX.APPR2.DUEDATE",
	receivedDate: "CX.APPR2.RECDATE",
	cost: "CX.APPR2.PRICE",
	amountPaid: "CX.APPR2.PDAMT",
};

export const appraisal3Fields = {
	typeOfAppraisal: "CX.APPR3.TYPE",
	orderStatus: "CX.APPR3.STATUS",
	statusDate: "CX.APPR3.STATUS.DT",
	followUpDate: "CX.APPR3.FOLLOWDT",
	orderDate: "CX.APPR3.ORDERDATE",
	inspectionDate: "CX.APPR3.INSPECTDATE",
	dueDate: "CX.APPR3.DUEDATE",
	receivedDate: "CX.APPR3.RECDATE",
	cost: "CX.APPR3.PRICE",
	amountPaid: "CX.APPR3.PDAMT",
};

export const appraisalFieldTitle = {
	typeOfAppraisal: "Type of Appraisal",
	orderStatus: "Order Status",
	statusDate: "Status Date",
	followUpDate: "Follow-Up Date",
	orderDate: "Order Date",
	inspectionDate: "Inspection Date",
	dueDate: "Due Date",
	receivedDate: "Received Date",
	cost: "Cost",
	amountPaid: "Amount Paid",
};
