import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMilestoneCount: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/pipeline/milestone_count/",
				method: "GET",
			}),
		}),
	}),
});

export const { useGetMilestoneCountMutation } = usersApiSlice;
