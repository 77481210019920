import { apiSlice } from "../../app/api/apiSlice";

export const convertMismoApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		convertMismo: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/convert_mismo_xml/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useConvertMismoMutation } = convertMismoApiSlice;
