import { apiSlice } from "../../../app/api/apiSlice";

export const searchUserApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		searchUser: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/admin/aeam_users/${credentials?.email}/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useSearchUserMutation } = searchUserApiSlice;
