import { apiSlice } from "../../app/api/apiSlice";

export const getIneligibleProductsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getIneligibleProducts: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/loan/get_ob_ineligible_products/?ob_business_channel_id=${credentials?.ob_business_channel_id}&ob_originator_id=${credentials?.ob_originator_id}&ob_loan_id=${credentials?.ob_loan_id}&search_id=${credentials?.searchId}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetIneligibleProductsMutation } = getIneligibleProductsSlice;
