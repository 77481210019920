import React from "react";
import { Typography } from "@mui/material";

import styles from "./index.module.scss";

const Body2 = ({ text, extraClass, error, fontType, isLink, handleClick }) => {
	const customClass = `${styles.body2} ${extraClass} ${
		error ? styles.error : ""
	} ${fontType === "semibold" ? styles.semibold : ""}  ${
		isLink ? styles.link : ""
	} `;

	return (
		<Typography className={customClass} onClick={handleClick}>
			{text}
		</Typography>
	);
};

export default Body2;
