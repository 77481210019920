import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import DatePicker from "react-date-picker";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomCheckbox from "../../custom-checkbox";
import CustomRadio from "../../custom-radio";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// helper utils
import { militaryServingOptions } from "../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";
import { isEmptyOrUndefined } from "../../../utils/filter-value";

const MilitaryInfo = ({
	militaryIndex,
	coBorrower,
	militaryData,
	data,
	setData,
	disabled,
	coBorrowerName,
	error,
}) => {
	const [serviceCompletionDate, setServiceCompletionDate] = useState("");

	useEffect(() => {
		console.log(
			"27 date:",
			militaryData?.militaryServiceExpectedCompletionDate
		);

		if (
			militaryData?.militaryServiceExpectedCompletionDate !== null &&
			militaryData?.militaryServiceExpectedCompletionDate !== undefined
		) {
			let year = militaryData?.militaryServiceExpectedCompletionDate?.slice(-4);
			let month = militaryData?.militaryServiceExpectedCompletionDate?.slice(
				0,
				2
			);
			let date = militaryData?.militaryServiceExpectedCompletionDate?.slice(
				3,
				5
			);

			let formattedJSDate = new Date(year, month - 1, date);
			console.log("27 formatted JS date:", formattedJSDate);
			const isValidDate = (dateObject) =>
				new Date(dateObject).toString() !== "Invalid Date";

			if (isValidDate(formattedJSDate)) {
				setServiceCompletionDate(formattedJSDate);
			} else {
				let newYear =
					militaryData?.militaryServiceExpectedCompletionDate?.slice(0, 4);
				let newMonth =
					militaryData?.militaryServiceExpectedCompletionDate?.slice(5, 7);
				let newDate =
					militaryData?.militaryServiceExpectedCompletionDate?.slice(8, 10);

				console.log("44 newYear:", newYear);
				console.log("44 newMonth:", newMonth);
				console.log("44 newDate:", newDate);

				let formattedDate = new Date(newYear, newMonth - 1, newDate);
				console.log("27 view loan formatted JS date:", formattedDate);
				const isValidNewDate = (dateObject) =>
					new Date(dateObject).toString() !== "Invalid Date";

				if (isValidNewDate(formattedDate)) {
					setServiceCompletionDate(formattedDate);
				} else {
					setServiceCompletionDate("");
				}
			}
		} else {
			setServiceCompletionDate("");
		}
	}, [militaryData]);

	const handleDateChange = (dateValue) => {
		console.log("32 date:", dateValue);

		let formattedDate = DateTime.fromJSDate(dateValue).toFormat("MM-dd-yyyy");
		console.log("35 formatted date:", formattedDate);

		setData(
			data.map((data, index) => {
				if (militaryIndex === index) {
					return {
						...data,
						militaryServiceExpectedCompletionDate: formattedDate,
					};
				} else {
					return data;
				}
			})
		);
	};

	let showOnSubmitError =
		(!coBorrower || (coBorrower && !isEmptyOrUndefined(coBorrowerName))) &&
		error;

	const militaryIndicatorIsTrueAndNoneSelected = () => {
		if (
			militaryData["selfDeclaredMilitaryServiceIndicator"]?.toString() ==
			true.toString()
		) {
			if (
				(isEmptyOrUndefined(militaryData["activeDuty"]) ||
					militaryData["activeDuty"].toString() == false.toString()) &&
				(isEmptyOrUndefined(
					militaryData["spousalVABenefitsEligibilityIndicator"]
				) ||
					militaryData["spousalVABenefitsEligibilityIndicator"].toString() ==
						false.toString()) &&
				(isEmptyOrUndefined(militaryData["veteran"]) ||
					militaryData["veteran"].toString() == false.toString()) &&
				(isEmptyOrUndefined(
					militaryData["reserveNationalGuardReserveActivated"]
				) ||
					militaryData["reserveNationalGuardReserveActivated"].toString() ==
						false.toString())
			) {
				return true;
			}
		}
		return false;
	};

	const militaryIndicatorIsTrueAndNoneSelectedBoolean =
		militaryIndicatorIsTrueAndNoneSelected();

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text={`Military Information - ${
						coBorrower ? "Co Borrower " : "Borrower "
					}`}
					fontType="semibold"
				/>
				<Body3 text="Provide details about the borrower" />
			</Stack>

			<Stack direction="column" className={styles.formSectionContainer}>
				<Stack direction="column" className={styles.inputContainer}>
					<CustomRadio
						id={`radio-military-service-${
							coBorrower ? "borrower" : "co-borrower"
						}`}
						value={militaryData?.selfDeclaredMilitaryServiceIndicator}
						handleChange={(event) =>
							setData(
								data.map((data, index) => {
									if (militaryIndex === index) {
										return {
											...data,
											selfDeclaredMilitaryServiceIndicator: event.target.value,
										};
									} else {
										return data;
									}
								})
							)
						}
						label="Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?"
						extraClass={styles.radioContainer}
						extraLabelClass={styles.radioLabelText}
						disabled={disabled}
						data={militaryServingOptions}
						error={
							showOnSubmitError &&
							isEmptyOrUndefined(
								data[militaryIndex]?.selfDeclaredMilitaryServiceIndicator
							)
						}
					/>
				</Stack>

				<Stack direction="row" className={styles.inputContainer}>
					<Stack direction="column" className={styles.checkboxContainer}>
						<CustomCheckbox
							checked={militaryData?.activeDuty}
							handleChange={() =>
								setData(
									data.map((data, index) => {
										if (militaryIndex === index) {
											return {
												...data,
												activeDuty: !militaryData?.activeDuty,
											};
										} else {
											return data;
										}
									})
								)
							}
							label="Currently serving on active duty with projected expiration date of service/tour"
							disabled={
								disabled || !militaryData?.selfDeclaredMilitaryServiceIndicator
							}
							error={
								showOnSubmitError &&
								militaryIndicatorIsTrueAndNoneSelectedBoolean
							}
						/>
					</Stack>

					{/* service completion date */}
					<Stack direction="column" className={styles.inputContainer}>
						<DatePicker
							className={
								disabled
									? styles.datepicker + " " + styles.disabledDatepicker
									: styles.datepicker
							}
							id="inputGroup-sizing-default6"
							format="MM-dd-yyyy"
							value={serviceCompletionDate || ""}
							onChange={handleDateChange}
							disabled={
								disabled || !militaryData?.selfDeclaredMilitaryServiceIndicator
							}
						/>
					</Stack>
				</Stack>

				<Stack direction="row" className={styles.inputContainer}>
					<CustomCheckbox
						checked={militaryData?.veteran}
						handleChange={() =>
							setData(
								data.map((data, index) => {
									if (militaryIndex === index) {
										return {
											...data,
											veteran: !militaryData?.veteran,
										};
									} else {
										return data;
									}
								})
							)
						}
						label="Currently retired, discharged, or separated from service"
						disabled={
							disabled || !militaryData?.selfDeclaredMilitaryServiceIndicator
						}
						error={
							showOnSubmitError && militaryIndicatorIsTrueAndNoneSelectedBoolean
						}
					/>
				</Stack>

				<Stack direction="row" className={styles.inputContainer}>
					<CustomCheckbox
						checked={militaryData?.reserveNationalGuardReserveActivated}
						handleChange={() =>
							setData(
								data.map((data, index) => {
									if (militaryIndex === index) {
										return {
											...data,
											reserveNationalGuardReserveActivated:
												!militaryData?.reserveNationalGuardReserveActivated,
										};
									} else {
										return data;
									}
								})
							)
						}
						label="Only period of service was as a non-activated member of the Reserve or National Guard"
						disabled={
							disabled || !militaryData?.selfDeclaredMilitaryServiceIndicator
						}
						error={
							showOnSubmitError && militaryIndicatorIsTrueAndNoneSelectedBoolean
						}
					/>
				</Stack>

				<Stack direction="row" className={styles.inputContainer}>
					<CustomCheckbox
						checked={militaryData?.spousalVABenefitsEligibilityIndicator}
						handleChange={() =>
							setData(
								data.map((data, index) => {
									if (militaryIndex === index) {
										return {
											...data,
											spousalVABenefitsEligibilityIndicator:
												!militaryData?.spousalVABenefitsEligibilityIndicator,
										};
									} else {
										return data;
									}
								})
							)
						}
						label="Surviving Spouse"
						disabled={
							disabled || !militaryData?.selfDeclaredMilitaryServiceIndicator
						}
						error={
							showOnSubmitError && militaryIndicatorIsTrueAndNoneSelectedBoolean
						}
					/>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default MilitaryInfo;
