import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setAccessToken } from "../../features/auth/authSlice";
import {
	storageDelete,
	storageLoad,
	storageSave,
} from "../../utils/localStorage";

console.log("10 env:", process.env);

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_ENDPOINT,
	prepareHeaders: (headers, { getState }) => {
		const token = getState().auth.token;

		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
			headers.set("Accept", "application/json");
		}

		return headers;
	},
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	const refreshToken = storageLoad("refresh-token");
	console.log("38 refresh token:", refreshToken);

	var formData = new FormData();
	formData.append("refresh", refreshToken);

	console.log("35 result:", result);

	if (result?.error?.status === 401) {
		console.log("sending refresh token", result?.error);
		// send refresh token to get a new access token
		const refreshResult = await baseQuery(
			{
				url: "/api/token/refresh/",
				method: "POST",
				body: formData,
			},
			api,
			extraOptions
		);
		console.log("38 refresh:", refreshResult);
		console.log("38 refresh data:", refreshResult?.data);

		if (
			refreshToken !== null &&
			refreshResult?.data === undefined &&
			refreshResult?.error?.data?.detail?.includes("Token is invalid")
		) {
			console.log("45 log out");
			storageDelete("access-token");
			storageDelete("refresh-token");
			api.dispatch(logOut());
			window.location.reload();
		}

		console.log("75 refreshed access token", refreshResult?.data?.access);

		if (refreshResult?.data) {
			// store the new token
			api.dispatch(setAccessToken(refreshResult?.data?.access));
			storageSave("access-token", refreshResult?.data?.access);

			// retry the original query with the new access token
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(logOut());
		}
	}

	return result;
};

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({}),
});
