import { apiSlice } from "../../app/api/apiSlice";

export const deleteBorrowerPairSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		deleteBorrowerPair: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/loan/update_borrower_pair/${credentials.loanGuid}/application/${credentials.borrowerPairId}/`,
				method: "DELETE",
				body: credentials.loanData,
			}),
		}),
	}),
});

export const { useDeleteBorrowerPairMutation } = deleteBorrowerPairSlice;
