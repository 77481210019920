import React from "react";

// mui components
import { Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";

// custom styles
import styles from "./index.module.scss";

const IneligibleFilter = ({ ineligibleProducts }) => {
	const ineligibleProductColumn = [
		{ id: "program", label: "Loan Program", minWidth: 175 },
		{ id: "explanation", label: "Explanation", minWidth: 175 },
	];

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack direction="column" className={styles.productAccordionContainer}>
				<Stack direction="column" className={styles.productAccordion}>
					<CustomTable
						overflow
						squaredBorder={true}
						tableType="ineligible-product"
						columnData={ineligibleProductColumn}
						rowData={ineligibleProducts}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default IneligibleFilter;
