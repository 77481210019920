import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import CustomLayout from "../../components/layout";
import LoanProgress from "../../components/loan-progress";
import CreateLoanApplication from "../../components/loan-form/create-loan-application";
import LoanAddressApplication from "../../components/loan-form/loan-address-application";
import CreditReissueApplication from "../../components/loan-form/credit-reissue-application";
import LoanEmploymentApplication from "../../components/loan-form/loan-employment-application";
import LoanAssetsApplication from "../../components/loan-form/loan-assets-application";
import LoanDemographicApplication from "../../components/loan-form/loan-demographic-application";
import EditLoanApplication from "../../components/loan-form/edit-loan-application";
import LoanTermsApplication from "../../components/loan-form/create-loan-terms";
import UnderwritingSummary from "../../components/loan-form/underwriting-summary";
import LoanPricingApplication from "../../components/loan-form/loan-pricing-application";
import LoanFeeApplication from "../../components/loan-form/create-loan-fee";
import LoanDocuments from "../../components/loan-form/create-loan-documents";
import Heading5 from "../../components/typography/heading-05";

// mui icons
import { CloseOutlined } from "@mui/icons-material";

// reducer slice
import {
	selectOrganizationName,
	selectUserDetails,
} from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// custom modal
import ConfirmationModal from "../../components/modal/confirmation-modal";

//helper utils
import { LOAN_CHANNEL_CONSTANTS } from "../../utils/common-constants";
import { selectedUserWithLoanChannel } from "../../utils/common-helpers";

// custom styles
import styles from "./index.module.scss";

export const CreateLoan = () => {
	const navigate = useNavigate();

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);
	const organizationName = useSelector(selectOrganizationName);

	const loanSteps = [
		"Borrower Information",
		"Address",
		"Credit Reissue",
		"Employment and Income",
		"Assets and Liabilities",
		"Declarations and Others",
		"Loan Product and Terms",
		"Underwriting Summary",
		"Loan Pricing",
		`${
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR ||
			selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS
				? "Closing Information"
				: "Fees"
		}`,
		"Supporting Documents",
	];

	const [loanApplicationStage, setLoanApplicationStage] = useState(0);
	const [applicationData, setApplicationData] = useState({});
	const [loanGuid, setLoanGuid] = useState("");

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);

	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};

	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleConfirmExit = () => {
		navigate(`/home`);
	};

	const handleCloseAdminModal = () => {
		navigate(-1);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	useEffect(() => {
		// check if admin signed in as another user has an organization
		console.log("150 user roles:", userDetails?.user_roles);
		console.log("150 organization name:", organizationName);

		if (
			userDetails?.user_roles?.includes("admin") &&
			(organizationName === undefined ||
				organizationName === null ||
				organizationName === "")
		) {
			console.log("140 No organization");
			setConfirmModalText("Sign in as a broker to gain access.");
			handleOpenConfirmModal();
		}
	}, [organizationName, userDetails]);

	const breadcrumbData = [
		{
			label: "Home",
			path: "/home",
		},
		{
			label: "Loans",
			path: "/pipeline",
		},
		{
			label: loanGuid,
			path: "",
		},
	];

	return (
		<Box>
			<CustomLayout breadcrumb={breadcrumbData} disabledOverflow={true}>
				<Stack direction="row" className={styles.stackContainer}>
					<Stack direction="row" className={styles.bodyContainer}>
						<Stack direction="column" className={styles.loanContainer}>
							{/* page title and close btn container */}
							<Box direction="row" className={styles.headerContainer}>
								<Stack className={styles.headerTextContainer}>
									<Heading5
										text={`Step ${
											loanApplicationStage === 0
												? loanApplicationStage + 1
												: loanApplicationStage
										}: ${
											loanSteps[
												loanApplicationStage === 0
													? loanApplicationStage
													: loanApplicationStage - 1
											]
										}`}
										fontType="semibold"
										className={styles.headerText}
									/>
								</Stack>

								<Stack
									className={styles.closeIconContainer}
									onClick={() => {
										setConfirmModalText("Are you sure you want to exit?");
										handleOpenConfirmModal();
									}}
								>
									<CloseOutlined className={styles.closeIcon} />
								</Stack>
							</Box>

							{loanApplicationStage === 0 ? (
								<CreateLoanApplication
									setLoanApplicationStage={setLoanApplicationStage}
									applicationData={applicationData}
									setApplicationData={setApplicationData}
									setLoanGuid={setLoanGuid}
									organizationName={organizationName}
									// need to pass 1 instead of 0 for the component to skip next component
									currentStage={1}
								/>
							) : loanApplicationStage === 1 ? (
								<EditLoanApplication
									setLoanApplicationStage={setLoanApplicationStage}
									applicationData={applicationData}
									setApplicationData={setApplicationData}
									loanGuid={loanGuid}
									organizationName={organizationName}
									currentStage={1}
								/>
							) : loanApplicationStage === 2 ? (
								<LoanAddressApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={2}
								/>
							) : loanApplicationStage === 3 ? (
								<CreditReissueApplication
									setLoanApplicationStage={setLoanApplicationStage}
									setApplicationData={setApplicationData}
									loanGuid={loanGuid}
									currentStage={3}
								/>
							) : loanApplicationStage === 4 ? (
								<LoanEmploymentApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={4}
								/>
							) : loanApplicationStage === 5 ? (
								<LoanAssetsApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={5}
								/>
							) : loanApplicationStage === 6 ? (
								<LoanDemographicApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={6}
								/>
							) : loanApplicationStage === 7 ? (
								<LoanTermsApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={7}
								/>
							) : loanApplicationStage === 8 ? (
								<UnderwritingSummary
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={8}
								/>
							) : loanApplicationStage === 9 ? (
								<LoanPricingApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={9}
								/>
							) : loanApplicationStage === 10 ? (
								<LoanFeeApplication
									setLoanApplicationStage={setLoanApplicationStage}
									loanGuid={loanGuid}
									currentStage={10}
								/>
							) : (
								loanApplicationStage === 11 && (
									<LoanDocuments
										setLoanApplicationStage={setLoanApplicationStage}
										loanGuid={loanGuid}
										currentStage={11}
									/>
								)
							)}
						</Stack>

						{/* application progress */}
						<LoanProgress
							loanSteps={loanSteps}
							currentStep={
								loanApplicationStage === 0
									? loanApplicationStage
									: loanApplicationStage - 1
							}
						/>
					</Stack>
				</Stack>
			</CustomLayout>

			{/* modals */}
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText={
					confirmModalText?.includes("exit") ? "Exit Application" : "Sign In"
				}
				handleConfirm={
					confirmModalText?.includes("exit")
						? handleConfirmExit
						: handleSubmitConfirmModal
				}
				closeText={
					confirmModalText?.includes("exit") ? "Return to Application" : "Close"
				}
				handleClose={
					confirmModalText?.includes("exit")
						? handleCloseConfirmModal
						: handleCloseAdminModal
				}
			/>
		</Box>
	);
};

export default CreateLoan;
