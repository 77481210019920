import { apiSlice } from "../../app/api/apiSlice";

export const loanSummaryApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getLoanSummary: builder.query({
			query: (loanId) => `/api/v1/pipeline/${loanId}`,
		}),
	}),
});

export const { useGetLoanSummaryQuery } = loanSummaryApiSlice;
