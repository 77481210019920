import { apiSlice } from "../../app/api/apiSlice";

export const createOBLockRequestSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createOBLockRequest: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/create_ob_lock_request/`,
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useCreateOBLockRequestMutation } = createOBLockRequestSlice;
