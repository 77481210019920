import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// mui components
import { Box, Typography } from "@mui/material";

// custom components
import CustomLoader from "../../components/loader";
import LoginSkeleton from "../../assets/icons/login-skeleton";

// custom icons
import Logo from "../../assets/icons/logo";
import DotsBg from "../../assets/icons/dots-bg";

// api slice
import { useResetMutation } from "../../features/reset-password/resetApiSlice";

// custom styles
import styles from "./index.module.scss";

const ResetPassword = () => {
	const navigate = useNavigate();
	const [reset, { isLoading }] = useResetMutation();
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");

	const handleResetClick = async (e) => {
		e.preventDefault();

		setError("");
		try {
			const forgetPassowrd = await reset({
				email,
			}).unwrap();
			setError(forgetPassowrd?.message || "Password reset successfully.");
		} catch (err) {
			setError(err?.data?.message || "An error occurred. Please try again.");
		}
	};

	return (
		<Box className={styles.pageContainer}>
			<Box className={styles.bodyContainer}>
				<Box
					className={styles.bodyLeftContainer}
					sx={{
						display: { xs: "none", md: "flex" },
					}}
				>
					<Box className={styles.imageContainer}>
						<Logo extraStyles={{ width: "100%", height: "100%" }} />
					</Box>

					<Box className={styles.skeletonContainer}>
						<LoginSkeleton
							extraStyles={{
								position: "relative",
								transform: "translate(31px, 0px)",
								width: "300px",
								height: "250px",
							}}
						/>
					</Box>
				</Box>

				<Box className={styles.bodyRightContainer}>
					<Box
						className={styles.rightTopContainer}
						sx={{
							display: { xs: "none", md: "flex" },
						}}
					>
						<DotsBg extraStyles={{ width: "85px", height: "80px" }} />
					</Box>

					<form onSubmit={handleResetClick} className={styles.formContainer}>
						<Box className={styles.formBodyContainer}>
							<Typography
								component="p"
								sx={{
									fontSize: "1.75rem",
									lineHeight: "2.25rem",
									fontWeight: 600,
									color: "#000",
								}}
								className={styles.resetText}
							>
								Reset Password
							</Typography>

							<Box className={styles.formFieldsContainer}>
								<Box className={styles.inputFieldContainer}>
									<label htmlFor="email" className={styles.inputLabel}>
										Email
									</label>
									<input
										type="email"
										className={styles.inputField}
										id="email"
										aria-describedby="emailHelp"
										placeholder="Enter your email"
										value={email}
										onChange={(event) => {
											setEmail(event.target.value);
											setError("");
										}}
									/>
								</Box>
							</Box>

							{error !== "" && <p style={{ color: "#f00" }}>{error}</p>}
							<button
								type="submit"
								onClick={handleResetClick}
								className={styles.submitBtn}
							>
								{isLoading ? (
									<CustomLoader sx={{ color: "#fff" }} size={20} />
								) : (
									"Reset Password"
								)}
							</button>
						</Box>

						<Box className={styles.formBodyContainer}>
							<Typography
								component="p"
								sx={{
									fontSize: "1rem",
									lineHeight: "1.25rem",
									fontWeight: 400,
									color: "#000",
									textAlign: "center",
									marginBottom: "4px",
								}}
								className={styles.loginText}
							>
								Remember your password?
							</Typography>

							<Box
								onClick={() => navigate("/login")}
								className={styles.footerBtn}
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginBottom: "24px",
								}}
							>
								<Typography
									component="p"
									sx={{
										fontFamily: "Sofia Sans",
										fontWeight: 700,
										fontSize: "1rem",
										lineHeight: "1.25rem",
									}}
								>
									Login
								</Typography>
							</Box>
						</Box>
					</form>
				</Box>
			</Box>
		</Box>
	);
};

export default ResetPassword;
