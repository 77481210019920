import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// mui components
import { Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import Heading3 from "../../typography/heading-03";
import Heading5 from "../../typography/heading-05";
import PrimaryButton from "../../buttons/primary-button";
import TertiaryButton from "../../buttons/tertiary-button";
import LoanParametersSummaryCard from "../loan-parameters-summary";
import LoanPricingCard from "../loan-pricing";

// api services
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// redux slices
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// custom styles
import styles from "./index.module.scss";

const RequestLockCard = ({
	loanData,
	handleTabChange,
	getFieldValuesOnLoanSummary,
}) => {
	const loanPricingCardRef = useRef(null);
	const [field] = useFieldMutation();

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const [fieldValues, setFieldValues] = useState([]);
	const [isPriceLoanDisabled, setIsPriceLoanDisabled] = useState(true);

	const handlePriceLoan = async () => {
		if (loanPricingCardRef?.current) {
			loanPricingCardRef.current.handleSubmitApplication();
		}
	};

	const getFieldValues = async () => {
		console.log(
			"RequestLockCard:getFieldValues:encompassId, loanNumber",
			loanData?.encompassId,
			loanData?.loanNumber
		);

		try {
			const getValues = await field({
				loan_guid: loanData?.encompassId,
				fields:
					"1401,19,VASUMM.X23,1811,1041,356,136,1109,353,976,1540,742,CX.DEBTSERVICECOVERAGERATIO,CX.AUTODEBIT,1550,LCP.X1",
			}).unwrap();

			console.log(
				"RequestLockCard:getFieldValues:all fields",
				getValues?.response
			);

			if (getValues?.response?.length > 0) {
				setFieldValues(getValues.response);
			}
		} catch (err) {
			console.log("RequestLockCard:getFieldValues:error", err);
		}
	};

	useEffect(() => {
		getFieldValues();
	}, []);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Check Pricing & Lock"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, 0)}
					/>
					<PrimaryButton
						text="Request Lock"
						onClick={handlePriceLoan}
						disabled={
							isPriceLoanDisabled ||
							userDetails?.user_roles?.includes("manager") ||
							userDetails?.user_roles?.includes("AM") ||
							userDetails?.user_roles?.includes("executive") ||
							userDetails?.user_roles?.includes("AE") ||
							(userDetails?.user_roles?.includes("admin") === true &&
								impersonatedUser?.email !== null &&
								impersonatedUser?.email !== undefined &&
								impersonatedUser?.email !== "" &&
								(impersonatedUser?.user_roles?.includes("manager") ||
									impersonatedUser?.user_roles?.includes("AM") ||
									impersonatedUser?.user_roles?.includes("executive") ||
									impersonatedUser?.user_roles?.includes("AE")))
						}
					/>
				</Stack>
			</Stack>

			<LoanParametersSummaryCard loanData={loanData} fieldData={fieldValues} />

			<Heading5
				text="Note: If there is a change to the loan terms above, please submit a loan change request prior to locking your loan."
				fontType="semibold"
			/>

			{/* OB pricing section container */}
			<LoanPricingCard
				loanGuid={loanData?.encompassId}
				setIsPriceLoanDisabled={setIsPriceLoanDisabled}
				handleTabChange={handleTabChange}
				getFieldValuesOnLoanSummary={getFieldValuesOnLoanSummary}
				ref={loanPricingCardRef}
			/>
		</Stack>
	);
};

export default RequestLockCard;
