import React from "react";

// mui components
import { Box, IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import CustomCheckbox from "../../custom-checkbox";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// mui icons
import { EditOutlined, DeleteOutlined } from "@mui/icons-material";

// helper utils
import { formatCurrency } from "../../../utils/format-currency";

// custom styles
import styles from "../index.module.scss";

const RealEstateInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
	liabilityCount,
	handleReOwned,
	handleViewLinked,
	isSubmitted,
}) => {
	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.label}
			className={
				!row?.futurePropertyUsageType && isSubmitted ? styles.errorRow : ""
			}
		>
			{/* edit icon */}
			<TableCell>
				<IconButton
					id={`${index}-estate-edit-btn`}
					className={styles.editIconBtn}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
					disabled={disableRow}
				>
					<EditOutlined className={styles.editIcon} />
				</IconButton>
			</TableCell>

			{/* delete icon */}
			<TableCell>
				<IconButton
					id={`${index}-estate-delete-btn`}
					className={styles.deleteIconBtn}
					onClick={() => !disableRow && handleRemoveRow(row?.reoId)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>

			{/* owner */}
			<TableCell>
				<Box
					id={`${index}-estate-owner`}
					className={
						styles.fieldContainer +
						" " +
						(disableRow ? styles.disabledContainer : "")
					}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
				>
					<Heading5
						text={
							row?.owner === "CoBorrower" ? "Co Borrower" : row?.owner || ""
						}
						fontType="semibold"
					/>
				</Box>
			</TableCell>

			{/* status */}
			<TableCell>
				<Box
					id={`${index}-estate-status`}
					className={
						styles.fieldContainer +
						" " +
						(disableRow ? styles.disabledContainer : "")
					}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
				>
					<Heading5
						text={
							row?.dispositionStatusType === "RetainForRental"
								? "Retain"
								: row?.dispositionStatusType === "PendingSale"
								? "Pending Sale"
								: row?.dispositionStatusType === "Sold"
								? "Sold"
								: ""
						}
						fontType="semibold"
					/>
				</Box>
			</TableCell>

			{/* property address */}
			<TableCell>
				<Box
					id={`${index}-estate-address`}
					className={
						styles.fieldContainer +
						" " +
						(disableRow ? styles.disabledContainer : "")
					}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
				>
					<Heading5
						text={`${
							row?.uRLA2020StreetAddress !== undefined
								? row?.uRLA2020StreetAddress
								: ""
						}${row?.unitNumber !== undefined ? ", " + row?.unitNumber : ""}
						${row?.city !== undefined ? ", " + row?.city : ""}${
							row?.state !== undefined ? ", " + row?.state : ""
						}${row?.postalCode !== undefined ? ", " + row?.postalCode : ""}`}
						fontType="semibold"
					/>
				</Box>
			</TableCell>

			{/* property value */}
			<TableCell>
				<Box
					id={`${index}-estate-value`}
					className={
						styles.fieldContainer +
						" " +
						(disableRow ? styles.disabledContainer : "")
					}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
				>
					<Heading5
						text={formatCurrency.format(Number(row?.marketValueAmount || 0))}
						fontType="semibold"
					/>
				</Box>
			</TableCell>

			{/* lien amount */}
			{/* <TableCell>
				<MaskedInput
					placeholder="$"
					type="currency"
					value={row?.marketValueAmount?.toString()}
					onInputChange={(value) =>
						disableRow !== true &&
						handleUpdate(index, "marketValueAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell> */}

			{/* lien holder */}
			{/* <TableCell>
				<SecondarySelect
					displayEmpty={true}
					value={""}
					handleChange={(event) =>
						handleUpdate(index, "owner", event.target.value)
					}
					options={ownerOptions}
					disabled={disableRow}
				/>
			</TableCell> */}

			{/* Monthly Insurance, Taxes, HOA */}
			<TableCell>
				<Box
					id={`${index}-estate-taxes`}
					className={
						styles.fieldContainer +
						" " +
						(disableRow ? styles.disabledContainer : "")
					}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
				>
					<Heading5
						text={formatCurrency.format(row?.maintenanceExpenseAmount || 0)}
						fontType="semibold"
					/>
				</Box>
			</TableCell>

			{/* rental Income Gross Amount */}
			<TableCell>
				<Box
					id={`${index}-estate-rental-income`}
					className={
						styles.fieldContainer +
						" " +
						(disableRow ? styles.disabledContainer : "")
					}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
				>
					<Heading5
						text={formatCurrency.format(row?.rentalIncomeGrossAmount || 0)}
						fontType="semibold"
					/>
				</Box>
			</TableCell>

			{/* subject property indicator */}
			<TableCell
				id={`${index}-estate-subject-indicator`}
				align="center"
				onClick={(event) => !disableRow && handleUpdate(event, row?.reoId, row)}
			>
				<CustomCheckbox
					checked={row?.subjectIndicator || false}
					handleChange={() => console.log("subject property")}
					disabled={true}
					extraClass={styles.checkboxInput}
				/>
			</TableCell>

			{/* property use */}
			<TableCell>
				<Box
					id={`${index}-estate-use`}
					className={
						styles.fieldContainer +
						" " +
						(disableRow ? styles.disabledContainer : "") +
						(isSubmitted &&
							!row?.futurePropertyUsageType &&
							styles.errorContainer)
					}
					onClick={(event) =>
						!disableRow && handleUpdate(event, row?.reoId, row)
					}
				>
					<Heading5
						text={
							row?.futurePropertyUsageType === "PrimaryResidence"
								? "Primary"
								: row?.futurePropertyUsageType === "SecondHome"
								? "Second Home"
								: row?.futurePropertyUsageType === "Investment"
								? "Investment Property"
								: row?.futurePropertyUsageType || ""
						}
						fontType="semibold"
					/>
				</Box>
			</TableCell>

			{/* link liabilities */}
			<TableCell>
				<Body3
					id={`${index}-estate-link-liabilities`}
					text="Click to link liabilities"
					isLink={liabilityCount > 0}
					extraClass={
						disableRow || liabilityCount <= 0 ? styles.disabledText : ""
					}
					handleClick={() =>
						liabilityCount > 0 &&
						disableRow !== true &&
						handleReOwned(row?.reoId)
					}
				/>
			</TableCell>

			{/* linked liabilities */}
			<TableCell>
				<Body3
					id={`${index}-estate-linked-liabilities`}
					text="Click to view linked liabilities"
					isLink={liabilityCount > 0}
					extraClass={liabilityCount <= 0 ? styles.disabledText : ""}
					handleClick={(event) =>
						liabilityCount > 0 && handleViewLinked(event, row?.reoId, row)
					}
				/>
			</TableCell>
		</TableRow>
	);
};

export default RealEstateInformationRow;
