import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import SecondarySelect from "../../custom-select/secondary-select";
import MaskedInput from "../../custom-input/masked-input";

// helper utils
import {
	incomeOwnerOptions,
	additionalIncomeSourceOptions,
} from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const AdditionalIncomeRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			{/* <TableCell>
				<IconButton
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.id)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell> */}
			<TableCell>
				<SecondarySelect
					id={`${index}-income-source-owner`}
					value={row?.owner || ""}
					handleChange={(event) =>
						handleUpdate(index, "owner", event.target.value)
					}
					options={incomeOwnerOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<SecondarySelect
					id={`${index}-income-source-description`}
					value={row?.description || ""}
					handleChange={(event) =>
						handleUpdate(index, "description", event.target.value)
					}
					options={additionalIncomeSourceOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>
			<TableCell>
				<MaskedInput
					id={`${index}-income-source-amount`}
					placeholder="$"
					type="currency"
					value={row?.monthlyAmount || ""}
					onInputChange={(value) =>
						disableRow !== true && handleUpdate(index, "monthlyAmount", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
		</TableRow>
	);
};

export default AdditionalIncomeRow;
