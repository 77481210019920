import { store } from "../store";
import { setPostClosingConditions } from "../../features/post-closing-conditions/postClosingSlice";

export const getPostClosingConditions = async ({
	loanGuid,
	apiRequest,
	handleErrorModal,
}) => {
	try {
		const postClosingValue = await apiRequest({ loanGuid }).unwrap();

		// save response in redux store
		store.dispatch(setPostClosingConditions(postClosingValue?.data));
	} catch (err) {
		console.log("260 error:", err);
		handleErrorModal(err);
	}
};
