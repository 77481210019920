import React, { useEffect, useState } from "react";

// mui components
import { Box, IconButton, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// mui icons
import { AddOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const AdditionalIncome = ({
	additionalIncomeIndex,
	additionalIncomeData,
	data,
	setData,
	disabled,
}) => {
	const [additionalIncomeRows, setAdditionalIncomeRows] = useState([]);

	const additionalIncomeColumns = [
		// { id: "index", label: "", minWidth: 48 },
		{ id: "owner", label: "Borrower/Co-Borrower", minWidth: 165 },
		{ id: "income-source", label: "Income Source", minWidth: 165 },
		{
			id: "monthly-income",
			label: "Monthly Income",
			minWidth: 310,
		},
	];

	useEffect(() => {
		if (additionalIncomeData !== undefined) {
			setAdditionalIncomeRows(additionalIncomeData);
		}
	}, [additionalIncomeData]);

	const handleAddRow = () => {
		setData(
			data.map((data, index) => {
				if (additionalIncomeIndex === index) {
					return [
						...data,
						{
							id: `OtherIncomeSource/${data?.length}`,
							description: "",
							monthlyAmount: 0,
							owner: "",
						},
					];
				} else {
					return data;
				}
			})
		);
	};

	const handleDeleteRow = (rowIndex, vodIndex) => {
		console.log("70 row index:", rowIndex);
		console.log("70 vod index:", vodIndex);

		setData(
			data.map((data, index) => {
				if (additionalIncomeIndex === index) {
					return additionalIncomeData
						?.filter((row, i) => row?.id !== vodIndex)
						.map((row, index) =>
							index >= rowIndex
								? {
										...row,
										id: `OtherIncomeSource/${index}`,
								  }
								: row
						);
				} else {
					return data;
				}
			})
		);
	};

	const handleChangeRow = (rowIndex, field, value) => {
		console.log("63 row:", rowIndex, field, value);

		setAdditionalIncomeRows(() =>
			additionalIncomeRows?.map((obj, i) => {
				if (i === rowIndex) {
					return { ...obj, [field]: value };
				}

				return obj;
			})
		);

		setData(
			data.map((data, index) => {
				if (additionalIncomeIndex === index) {
					let updatedData = additionalIncomeData?.map((obj, i) => {
						if (i === rowIndex) {
							return { ...obj, [field]: value };
						}
						return obj;
					});
					return updatedData;
				} else {
					return data;
				}
			})
		);
	};

	console.log("140 additional income:", data);

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5
					text="Income from Other Sources - Borrower and Co-Borrower"
					fontType="semibold"
				/>

				<Body3 text="Provide details about income from other sources" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* additional income table  */}
					<CustomTable
						tableType="additional-income"
						columnData={additionalIncomeColumns}
						rowData={additionalIncomeRows}
						handleRemoveRow={handleDeleteRow}
						handleUpdate={handleChangeRow}
						disableRow={disabled}
					/>

					{additionalIncomeData?.length < 10 && (
						<Box className={styles.addIconContainer}>
							<IconButton
								id="add-additional-income-btn"
								className={styles.addIconBtn}
								onClick={() => handleAddRow()}
								disabled={disabled}
							>
								<AddOutlined className={styles.addIcon} />
							</IconButton>
						</Box>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};

export default AdditionalIncome;
