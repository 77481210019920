import {
	Box,
	Checkbox,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { sortAmount, sortDate } from "../../../utils/sort-methods";

import Heading4 from "../../typography/heading-04";

import styles from "./index.module.scss";

import "./mui-styles.scss";

const TableHeadCheckboxSort = (props) => {
	const {
		data,
		// sort
		order,
		orderBy,
		onRequestSort,
		rowData,
		setSortedData,
		// select
		selectedCount,
		rowCount,
		onSelectAllClick,
	} = props;

	const createSortHandler = (property) => (event) => {
		let fieldId;

		if (property === "label") {
			fieldId = "title";
		} else if (property === "upload-date") {
			fieldId = "createdDate";
		}

		let sortedData;

		if (property.includes("date")) {
			sortedData = sortDate(rowData, order, fieldId);
		} else {
			sortedData = sortAmount(rowData, order, fieldId);
		}

		setSortedData(sortedData);
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						className={styles.checkboxContainer}
						indeterminate={selectedCount > 0 && selectedCount < rowCount}
						checked={rowCount > 0 && selectedCount === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{data.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ minWidth: headCell.minWidth }}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							<Heading4
								text={headCell.label}
								extraClass={styles.thText}
								fontType="semibold"
							/>
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeadCheckboxSort;
