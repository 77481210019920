import React from "react";

// mui components
import { TableCell, TableRow } from "@mui/material";

// custom components
import Body3 from "../../typography/body-03";

// custom styles
import styles from "../index.module.scss";

const IneligibleProductRow = ({ row, handleRowClick }) => {
	return (
		<TableRow
			hover
			tabIndex={-1}
			key={row.productId}
			onClick={(event) => handleRowClick(event, row?.productId)}
			role="checkbox"
		>
			<TableCell>
				<Body3 text={row?.productName} extraClass={styles.greenText} />
			</TableCell>
			<TableCell>
				{row?.ineligibleReason?.length > 0 &&
					row?.ineligibleReason?.map((reason, index) => (
						<Body3 text={reason} extraClass={styles.greenText} key={index} />
					))}
			</TableCell>
		</TableRow>
	);
};

export default IneligibleProductRow;
