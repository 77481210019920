import React, { Fragment } from "react";

// mui components
import { Box, IconButton } from "@mui/material";

// mui icons
import { AddOutlined } from "@mui/icons-material";

// custom components
import EmploymentInfo from "../employment-information";
import CustomDivider from "../../custom-divider";

// custom styles
import styles from "./index.module.scss";

const BorrowerEmploymentInfo = ({
	data,
	setData,
	borrowerPairIndex,
	disabled,
}) => {
	console.log("25 employment data:", borrowerPairIndex, data, disabled);

	const handleAddSecondaryEmployment = (owner) => {
		let ownerDataLength = data?.filter((item) => item?.owner === owner)?.length;

		let newObject = {
			id: `Employment/${ownerDataLength === 0 ? "1" : data?.length}`,
			owner: owner,
			currentEmploymentIndicator: true,
			noLinkToDocTrackIndicator: true,
			militaryEmployer: false,
		};

		let newArray = [...data];

		newArray.splice(ownerDataLength === 0 ? "1" : data?.length, 0, newObject);

		console.log("80 updated arr:", newArray);

		setData((prevData) => {
			// Create a new state array using map to modify the first object in the first child array
			const newState = prevData.map((parentArray, parentIndex) => {
				// borrower pair index
				if (parentIndex === borrowerPairIndex) {
					// Modify the first object in the first child array
					return newArray;
				}
				return parentArray;
			});

			return newState;
		});
	};

	const handleAddPreviousEmployment = (owner) => {
		let ownerDataLength = data?.filter((item) => item?.owner === owner)?.length;

		let newObject = {
			id: `Employment/${ownerDataLength === 0 ? "1" : data?.length}`,
			owner: owner,
			currentEmploymentIndicator: false,
			noLinkToDocTrackIndicator: true,
			militaryEmployer: false,
		};

		let newArray = [...data];

		newArray.splice(ownerDataLength === 0 ? "1" : data?.length, 0, newObject);

		console.log("80 updated arr:", newArray);

		setData((prevData) => {
			// Create a new state array using map to modify the first object in the first child array
			const newState = prevData.map((parentArray, parentIndex) => {
				// borrower pair index
				if (parentIndex === borrowerPairIndex) {
					// Modify the first object in the first child array
					return newArray;
				}
				return parentArray;
			});

			return newState;
		});
	};

	return (
		<Box className={styles.cardContainer}>
			{data
				?.filter((item) => item?.owner === "Borrower")
				?.map((finance, index) => (
					<Fragment key={index}>
						<EmploymentInfo
							key={index}
							coBorrower={finance?.owner === "CoBorrower"}
							borrowerPairIndex={borrowerPairIndex}
							financeIndex={index}
							financeData={finance}
							primary={
								finance?.currentEmploymentIndicator === true && index === 0
							}
							additional={
								finance?.currentEmploymentIndicator === true && index === 1
							}
							previous={
								finance?.currentEmploymentIndicator === false ||
								finance?.currentEmploymentIndicator === undefined
							}
							data={data}
							setData={setData}
							disabled={disabled}
						/>

						{data?.filter((item) => item?.owner === "Borrower")?.length < 3 &&
							data?.filter((item) => item?.owner === "Borrower")?.length - 1 ===
								index && (
								<Box className={styles.addIconContainer}>
									<IconButton
										id={`add-${
											finance?.owner === "Borrower"
												? "borrower-"
												: "coborrower-"
										}employment-btn`}
										className={styles.addIconBtn}
										onClick={() =>
											finance?.currentEmploymentIndicator === true &&
											index === 0
												? handleAddSecondaryEmployment("Borrower")
												: handleAddPreviousEmployment("Borrower")
										}
										disabled={disabled}
									>
										<AddOutlined className={styles.addIcon} />
									</IconButton>
								</Box>
							)}

						<CustomDivider />
					</Fragment>
				))}

			{data
				?.filter((item) => item?.owner === "CoBorrower")
				?.map((finance, index) => (
					<Fragment key={index}>
						<EmploymentInfo
							key={index}
							coBorrower={finance?.owner === "CoBorrower"}
							borrowerPairIndex={borrowerPairIndex}
							financeIndex={index}
							financeData={finance}
							primary={
								finance?.currentEmploymentIndicator === true && index === 0
							}
							additional={
								finance?.currentEmploymentIndicator === true && index === 1
							}
							previous={
								finance?.currentEmploymentIndicator === false ||
								finance?.currentEmploymentIndicator === undefined
							}
							data={data}
							setData={setData}
							disabled={disabled}
						/>

						{data?.filter((item) => item?.owner === "CoBorrower")?.length < 3 &&
							data?.filter((item) => item?.owner === "CoBorrower")?.length -
								1 ===
								index && (
								<Box className={styles.addIconContainer}>
									<IconButton
										className={styles.addIconBtn}
										onClick={() =>
											finance?.currentEmploymentIndicator === true &&
											index === 0
												? handleAddSecondaryEmployment("CoBorrower")
												: handleAddPreviousEmployment("CoBorrower")
										}
										disabled={disabled}
									>
										<AddOutlined className={styles.addIcon} />
									</IconButton>
								</Box>
							)}

						<CustomDivider />
					</Fragment>
				))}
		</Box>
	);
};

export default BorrowerEmploymentInfo;
