import { apiSlice } from "../../app/api/apiSlice";

export const updateFieldsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateFields: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/upload_field_values/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useUpdateFieldsMutation } = updateFieldsApiSlice;
