import React, { useState } from "react";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import { ErrorOutlined, SearchOutlined } from "@mui/icons-material";

// mui components
import { InputLabel, Stack } from "@mui/material";

// api slice
import { useSearchUserMutation } from "../../../../features/admin/search-user/searchUserApiSlice";
import { useRemoveUserMutation } from "../../../../features/admin/remove-user/removeUserApiSlice";

// custom components
import OutlinedTextInput from "../../../custom-input/outlined-text-input";
import SecondarySelect from "../../../custom-select/secondary-select";
import PrimaryButton from "../../../buttons/primary-button";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Heading5 from "../../../typography/heading-05";
import Heading6 from "../../../typography/heading-06";
import Body3 from "../../../typography/body-03";

// custom styles
import styles from "./index.module.scss";

const RemoveUserDrawer = ({
	handleCloseDrawer,
	handleOpenProcessingModal,
	handleCloseProcessingModal,
	setProcessingText,
	handleOpenSuccessModal,
	setSuccessText,
	handleOpenErrorModal,
	setErrorText,
	// custom prop
	setRedirect,
}) => {
	const [email, setEmail] = useState("");
	const [searchedUser, setSearchedUser] = useState({});
	const [error, setError] = useState("");

	const userRoleList = [
		{ label: "Account Executive", value: "AE" },
		{ label: "Account Manager", value: "AM" },
	];

	const [searchUser] = useSearchUserMutation();
	const [removeUser] = useRemoveUserMutation();

	const handleCancel = () => {
		console.log("50 cancel clicked.");
		setEmail("");
		setSearchedUser({});
		setError("");
	};

	const handleSearchUser = async () => {
		handleOpenProcessingModal();
		setError("");

		try {
			const searchedUser = await searchUser({
				email,
			}).unwrap();

			setSearchedUser(searchedUser);
			handleCloseProcessingModal();
		} catch (err) {
			console.log("95 err:", err);

			setSearchedUser({});
			handleCloseProcessingModal();
			setError("Email ID not found");
		}
	};

	const handleRemoveUser = async () => {
		console.log("315 running ");

		handleCloseDrawer();
		handleOpenProcessingModal();
		setProcessingText("Removing user");

		// dispatch API and open modal if successful
		try {
			const removedUser = await removeUser({
				email: searchedUser?.email,
			}).unwrap();

			console.log("95 removed user:", removedUser);

			handleCloseProcessingModal();
			setSuccessText(removedUser?.data || "User has been removed.");

			setRedirect("admin");
			handleOpenSuccessModal();
		} catch (err) {
			console.log("95 err:", err);
			handleCloseProcessingModal();
			setErrorText(err?.data?.message);
			handleOpenErrorModal();
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text="Remove new AE/AM"
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>
			<Stack direction="column" className={styles.searchContainer}>
				<Stack direction="column" className={styles.searchTopContainer}>
					<Heading5 text="Search AE/AM" fontType="semibold" />

					<Stack
						direction="row"
						alignItems="flex-end"
						justifyContent="flex-start"
						gap={3}
						className={styles.drawerBodyContainer}
					>
						<Stack direction="column" className={styles.inputContainer}>
							<Stack direction="row" className={styles.folderContainer}>
								<InputLabel htmlFor="input-email">
									<Heading6
										text="Email ID of AE/AM"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>
							</Stack>

							<Stack direction="row" className={styles.searchBtnContainer}>
								<OutlinedTextInput
									id="input-email"
									type="text"
									placeholder="Email"
									value={email}
									handleChange={(event) => {
										setError("");
										setEmail(event.target.value);
									}}
									error={error !== ""}
									showEndIcon={error !== ""}
									endIcon={<ErrorOutlined className={styles.errorIcon} />}
								/>

								<PrimaryButton
									text="Search"
									onClick={handleSearchUser}
									extraClass={styles.searchBtn}
									startIcon={<SearchOutlined className={styles.searchIcon} />}
									disabled={email === ""}
								/>
							</Stack>

							{error !== "" && (
								<Body3 text={error} extraClass={styles.errorText} />
							)}
						</Stack>
					</Stack>
				</Stack>

				<Stack direction="column" className={styles.drawerBodyContainer}>
					{/* first name */}
					<Stack direction="row" className={styles.nameInputContainer}>
						<Stack direction="column" className={styles.inputContainer}>
							<Stack direction="row" className={styles.folderContainer}>
								<InputLabel htmlFor="input-first-name">
									<Heading6
										text="First Name"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>
							</Stack>

							<OutlinedTextInput
								id="input-first-name"
								type="text"
								placeholder="First Name"
								value={searchedUser?.first_name || ""}
								handleChange={(event) =>
									console.log("195 first name:", event.target.value)
								}
								disabled={true}
							/>
						</Stack>
						{/* last name */}
						<Stack direction="column" className={styles.inputContainer}>
							<Stack direction="row" className={styles.folderContainer}>
								<InputLabel htmlFor="input-last-name">
									<Heading6
										text="Last Name"
										fontType="semibold"
										extraClass={styles.inputHeader}
									/>
								</InputLabel>
							</Stack>

							<OutlinedTextInput
								id="input-last-name"
								type="text"
								placeholder="Last Name"
								value={searchedUser?.last_name || ""}
								handleChange={(event) =>
									console.log("195 last name:", event.target.value)
								}
								disabled={true}
							/>
						</Stack>
					</Stack>

					{/* user role */}
					<Stack direction="column" className={styles.inputContainer}>
						<Stack direction="row" className={styles.folderContainer}>
							<InputLabel htmlFor="input-user-type">
								<Heading6
									text="User Type"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>
						</Stack>

						<SecondarySelect
							displayEmpty={true}
							value={searchedUser?.role || ""}
							handleChange={(event) =>
								console.log("235 role:", event.target.value)
							}
							options={userRoleList}
							disabled={true}
						/>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						className={styles.drawerFooterContainer}
					>
						<SecondaryButton
							text="Reset"
							extraClass={styles.cancelBtn}
							extraTextClass={styles.cancelText}
							onClick={handleCancel}
							disabled={
								(searchedUser?.first_name === undefined ||
									searchedUser?.first_name === "") &&
								(searchedUser?.last_name === undefined ||
									searchedUser?.last_name === "") &&
								(searchedUser?.role === undefined ||
									searchedUser?.role === "") &&
								email === ""
							}
						/>
						<PrimaryButton
							text="Remove AE/AM"
							onClick={handleRemoveUser}
							extraClass={styles.removeUserBtn}
							disabled={
								searchedUser?.email === undefined || searchedUser?.email === ""
							}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default RemoveUserDrawer;
