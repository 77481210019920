import React, { useEffect, useState } from "react";

// mui components
import { InputLabel, Stack } from "@mui/material";

// mui icons
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

// custom components
import SingleSelect from "../../custom-select/single-select";
import CustomTable from "../../custom-table";
import Heading6 from "../../typography/heading-06";
import Heading5 from "../../typography/heading-05";

// custom styles
import styles from "./index.module.scss";

const EligibleFilter = ({
	eligibleProducts,
	expandedProduct,
	toggleExpand,
	eligibleProductDetails,
	selectedRow,
	setSelectedRow,
	setSelectedProduct,
	toggleDrawer,
	hidePriceColumn = false,
	hidePointsPercentageColumn = false,
	hidePointsValueColumn = false,
	hideEligibleProductFilters = false,
}) => {
	console.log("30 eligibleProducts:", eligibleProducts);

	const [filteredEligibleProducts, setFilteredEligibleProducts] = useState(
		eligibleProducts || []
	);

	const [lockPeriod, setLockPeriod] = useState("30");
	const [amortizationType, setAmortizationType] = useState("Fixed");
	const [loanTerm, setLoanTerm] = useState("ThirtyYear");

	const [eligibleSubProducts, setEligibleSubProducts] = useState([]);

	let eligibleProductColumn = [
		{ id: "id", label: "", minWidth: 75 },
		{ id: "rate", label: "Rate", minWidth: 175 },
		{ id: "price", label: "Price", minWidth: 175 },
		{ id: "points-percentage", label: "Points (%)", minWidth: 175 },
		{ id: "points-value", label: "Points ($)", minWidth: 175 },
		{ id: "payment", label: "Payment", minWidth: 175 },
		{ id: "lock-period", label: "Lock Period", minWidth: 175 },
		{ id: "name", label: "Name", minWidth: 310 },
		{ id: "more", label: "", minWidth: 175 },
	];

	if (hidePriceColumn) {
		eligibleProductColumn = eligibleProductColumn.filter(
			(product) => product.id !== "price"
		);
	}

	if (hidePointsPercentageColumn) {
		eligibleProductColumn = eligibleProductColumn.filter(
			(product) => product.id !== "points-percentage"
		);
	}

	if (hidePointsValueColumn) {
		eligibleProductColumn = eligibleProductColumn.filter(
			(product) => product.id !== "points-value"
		);
	}

	useEffect(() => {
		console.log("45 lock period:", lockPeriod);
		console.log("45 eligible product details:", eligibleProductDetails);

		if (
			eligibleProductDetails !== undefined &&
			eligibleProductDetails?.quotes?.length > 0
		) {
			console.log("40 eligible sub products:", eligibleProductDetails?.quotes);
			const filteredEligibleSubProducts =
				eligibleProductDetails?.quotes?.filter(
					(item) => Number(item?.lockPeriod) === Number(lockPeriod)
				);

			console.log("40 filtered sub products:", filteredEligibleSubProducts);
			setEligibleSubProducts(filteredEligibleSubProducts);
		}
	}, [eligibleProductDetails, lockPeriod]);

	useEffect(() => {
		console.log("60 eligible product:", eligibleProducts);
		console.log("60 filtered eligible product:", filteredEligibleProducts);
		console.log("60 amortization type:", amortizationType);
		console.log("60 loan term:", loanTerm);

		if (
			!hideEligibleProductFilters &&
			eligibleProducts !== undefined &&
			eligibleProducts?.length > 0
		) {
			const filteredEligibleProductsArr = eligibleProducts?.filter(
				(item) =>
					item?.amortizationType === amortizationType &&
					item?.loanTerm === loanTerm
			);

			console.log("40 filtered sub products:", filteredEligibleProductsArr);
			setFilteredEligibleProducts(filteredEligibleProductsArr);
		}
	}, [eligibleProducts, amortizationType, loanTerm]);

	const handleLockPeriod = (event) => {
		setLockPeriod(event.target.value);
	};

	const handleAmortizationType = (event) => {
		setAmortizationType(event.target.value);
	};

	const handleLoanTerm = (event) => {
		setLoanTerm(event.target.value);
	};

	console.log("30 eligible products:", filteredEligibleProducts);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			{!hideEligibleProductFilters ? (
				<Stack direction="column" className={styles.filterContainer}>
					<Heading6
						text="Filter and select your loan product :"
						fontType="semibold"
						extraClass={styles.filterHeader}
					/>

					<Stack direction="row" className={styles.filterInputContainer}>
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="input-max-fees">
								<Heading6
									text="Lock Period"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SingleSelect
								id="lock-duration"
								displayEmpty={true}
								value={lockPeriod}
								handleChange={handleLockPeriod}
								options={[
									{ value: "15", label: "15" },
									{ value: "30", label: "30" },
									{ value: "45", label: "45" },
									{ value: "60", label: "60" },
								]}
								extraClass={styles.inputFieldContainer}
							/>
						</Stack>

						<Stack direction="column" className={styles.inputContainer}>
							<Heading6
								text="Amortization Type"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>

							<SingleSelect
								id="amortization-type"
								displayEmpty={true}
								value={amortizationType}
								handleChange={handleAmortizationType}
								options={[
									{ value: "ARM", label: "ARM" },
									{ value: "Fixed", label: "Fixed" },
								]}
								extraClass={styles.inputFieldContainer}
							/>
						</Stack>

						<Stack direction="column" className={styles.inputContainer}>
							<Heading6
								text="Loan Term"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>

							<SingleSelect
								id="loan-term"
								displayEmpty={true}
								value={loanTerm}
								handleChange={handleLoanTerm}
								options={[
									{ value: "FortyYear", label: "40 Year" },
									{ value: "ThirtyYear", label: "30 Year" },
									{ value: "TwentyFiveYear", label: "25 Year" },
									{ value: "TwentyYear", label: "20 Year" },
									{ value: "FifteenYear", label: "15 Year" },
									{ value: "TwelveYear", label: "12 Year" },
									{ value: "TenYear", label: "10 Year" },
								]}
								extraClass={styles.inputFieldContainer}
							/>
						</Stack>
					</Stack>
				</Stack>
			) : null}

			<Stack direction="column" className={styles.productAccordionContainer}>
				{filteredEligibleProducts?.map((item) => (
					<Stack
						direction="column"
						className={styles.productAccordion}
						key={item?.productId}
					>
						<Stack
							direction="row"
							className={
								expandedProduct === item?.productId
									? styles.productAccordionHeader + " " + styles.borderExpanded
									: styles.productAccordionHeader + " " + styles.borderCollapsed
							}
							onClick={() => toggleExpand(item?.productId)}
						>
							<Stack direction="column" gap={1}>
								<Heading5 text={item?.productName} fontType="semibold" />
							</Stack>
							<Stack direction="row" p={2} gap={1} alignItems="center">
								<Heading6
									text={
										expandedProduct === item?.productId
											? "Show less"
											: "Show all options"
									}
									fontType="semibold"
								/>
								{expandedProduct === item?.productId ? (
									<ExpandLessOutlined className={styles.accordionIcon} />
								) : (
									<ExpandMoreOutlined className={styles.accordionIcon} />
								)}
							</Stack>
						</Stack>

						{expandedProduct === item?.productId && (
							<Stack direction="row" className={styles.productAccordionBody}>
								<CustomTable
									squaredBorder={true}
									maxHeight={true}
									enableSelect={true}
									tableType="eligible-product"
									columnData={eligibleProductColumn}
									rowData={eligibleSubProducts}
									setSortedData={setEligibleSubProducts}
									selectedRow={selectedRow}
									setSelectedRow={setSelectedRow}
									currentProductName={
										expandedProduct === item?.productId && item?.productName
									}
									setSelectedProduct={setSelectedProduct}
									toggleDrawer={toggleDrawer}
									selectedProduct={item}
									hidePriceColumn={hidePriceColumn}
									hidePointsPercentageColumn={hidePointsPercentageColumn}
									hidePointsValueColumn={hidePointsValueColumn}
								/>
							</Stack>
						)}
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default EligibleFilter;
