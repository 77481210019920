import React from "react";

// mui components
import { Button } from "@mui/material";

// custom components
import Heading5 from "../../typography/heading-05";

// custom styles
import styles from "./index.module.scss";

const SecondaryButton = ({
	text,
	extraClass,
	extraTextClass,
	startIcon,
	endIcon,
	onClick,
	fullWidth,
	disabled,
	fontType,
}) => {
	const customClass = `${styles.secondaryBtn} ${extraClass}`;
	const customTextClass = `${styles.secondaryBtnText} ${extraTextClass}`;

	return (
		<Button
			variant="outlined"
			startIcon={startIcon}
			endIcon={endIcon}
			onClick={onClick}
			className={customClass}
			fullWidth={fullWidth}
			disabled={disabled}
		>
			<Heading5
				text={text}
				fontType={fontType || "semibold"}
				extraClass={customTextClass}
			/>
		</Button>
	);
};

export default SecondaryButton;
