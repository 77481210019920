import React from "react";

// mui components
import { Modal, Stack } from "@mui/material";

// custom components
import PrimaryButton from "../../buttons/primary-button";
import Body2 from "../../typography/body-02";

// mui icons
import { WarningAmberOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

export default function NavigationModal({
	open,
	text,
	confirmText,
	handleConfirm,
}) {
	return (
		<Modal
			open={open}
			aria-labelledby="navigation modal"
			aria-describedby="navigation modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<WarningAmberOutlined className={styles.iconContainer} />

				<Stack
					direction="column"
					alignItems="center"
					className={styles.textContainer}
				>
					<Body2 text={text} extraClass={styles.navigationText} />
				</Stack>

				<PrimaryButton
					text={confirmText}
					onClick={handleConfirm}
					fullWidth={true}
				/>
			</Stack>
		</Modal>
	);
}
