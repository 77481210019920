import { isEmptyOrUndefined } from "../filter-value";

export const validateDemographicProvided = (
	borrowerPairData,
	borrowerDemographicProvided
) => {
	let error = false;
	const demographicProvidedNeedValidation = (index) => {
		return (
			index % 2 === 0 || !isEmptyOrUndefined(borrowerPairData[index]?.firstName)
		);
	};
	for (let i = 0; i < borrowerDemographicProvided.length; i++) {
		const currentInfo = borrowerDemographicProvided[i];
		if (demographicProvidedNeedValidation(i)) {
			if (isEmptyOrUndefined(currentInfo["applicationTakenMethodType"])) {
				console.log(
					"Demographic provided validation Error found: applicationTakenMethodType undefined ",
					i
				);
				return true;
			}
			if (
				isEmptyOrUndefined(currentInfo["isEthnicityBasedOnVisual"]) ||
				isEmptyOrUndefined(currentInfo["isRaceBasedOnVisual"]) ||
				isEmptyOrUndefined(currentInfo["isSexBasedOnVisual"])
			) {
				console.log(
					"Demographic provided validation Error: answer F2F all 3 questions ",
					i
				);
				return true;
			}
		}
	}
	return error;
};
