import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";

import Heading5 from "../../typography/heading-05";

import styles from "./index.module.scss";

const CustomTableHead = ({ data }) => {
	return (
		<TableHead>
			<TableRow>
				{data.map((column) => (
					<TableCell
						key={column.id}
						align={column.align}
						style={{ minWidth: column.minWidth }}
						className={styles.headerStack}
					>
						<Heading5
							text={column.label}
							extraClass={styles.thText}
							fontType="semibold"
						/>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default CustomTableHead;
