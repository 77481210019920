import React from "react";

// mui components
import { Modal, Stack, List, ListItem } from "@mui/material";
import { HelpOutlineOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";
import Heading4 from "../../typography/heading-04";
import PrimaryButton from "../../buttons/primary-button";
import Body3 from "../../typography/body-03";

// constants
import { SUPPORT_EMAIL } from "../../../utils/email-constants";

export default function HelpCenterModal({ open, showHelpCentre }) {
	const handleMailClick = () => {
		const mailtoLink = `mailto:${SUPPORT_EMAIL}`;
		window.open(mailtoLink, "_blank");
	};

	return (
		<Modal
			open={open}
			aria-labelledby="loading modal"
			aria-describedby="loading modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Stack
					direction="row"
					justifyContent="center"
					alignItems="center"
					className={styles.modalHeadingContainer}
				>
					<HelpOutlineOutlined className={styles.modalHeadingIcon} />
					<Heading4
						text="Help Center"
						fontType="semibold"
						extraClass={styles.modalHeadingText}
					/>
				</Stack>
				<Stack
					direction="column"
					alignItems="center"
					className={styles.modalText}
				>
					<List sx={{ listStyle: "decimal", pl: 4 }}>
						<ListItem
							className={styles.lisItemText}
							sx={{ display: "list-item" }}
						>
							<Body3
								extraClass={styles.helpCentreText}
								text="If you required assistance with the functionality of the system, please contact ‘TPO Portal Support’ at "
							/>
							<Body3
								isLink={true}
								extraClass={styles.lineHeight18}
								handleClick={handleMailClick}
								text={SUPPORT_EMAIL}
							/>
						</ListItem>
						<ListItem
							className={styles.lisItemText}
							sx={{ display: "list-item" }}
						>
							<Body3
								extraClass={styles.helpCentreText}
								text="If you need assistance regarding status of a loan, a remedy provided, or a general question on your loan, please contact"
							/>
							<Body3
								extraClass={styles.helpCentreText}
								text="your Account Manager."
							/>
						</ListItem>
					</List>
				</Stack>
				<PrimaryButton
					text="Return to portal"
					extraClass={styles.backButton}
					onClick={() => showHelpCentre(false)}
				/>
			</Stack>
		</Modal>
	);
}
