import React from "react";
import { useNavigate, useParams } from "react-router-dom";

// mui components
import { Box, Divider, Stack } from "@mui/material";

// mui icons
import LaunchIcon from "@mui/icons-material/Launch";

// custom components
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";
import Body2 from "../../typography/body-02";
import Body3 from "../../typography/body-03";

// helper utils
import { filterValue } from "../../../utils/filter-value";
import { formatCurrency } from "../../../utils/format-currency";

// custom styles
import styles from "./index.module.scss";

const LoanSummaryCard = ({ loanData, fieldData }) => {
	const navigate = useNavigate();
	let { id: loanGuid } = useParams();

	const milestoneStatus = (status) => {
		console.log("25 value:", status);

		if (status === "Started") return "Loan Submitted";
		if (status === "Recd Cred Pkg") return "Rec'd Credit Pkg";
		if (status === "Review Cred Pkg") return "Rec'd Credit Pkg";
		if (status === "Submit to UW") return "Submitted to UW";
		if (status === "Suspended") return "In UWing Review";
		if (status === "Recd Conditions") return "Condition Review";
		if (status === "UW Cond Review") return "Condition Review";
		if (status === "QC Requested") return "Condition Review";
		if (status === "QC Complete") return "Condition Review";
		if (status === "CTC") return "Clear to Close";
		if (status === "Closing Prep") return "Closing Docs";
		if (status === "Funding Prep") return "Wire Sent";
		if (status === "Funding") return "Funded";
		if (status === "Recd Closing Pkg") return "Rec'd Closing Pkg";
		if (status === "Recd Purch Conditions") return "Rec'd Purch Conditions";

		return status;
	};

	return (
		<Stack
			direction="column"
			alignItems="flex-start"
			className={styles.cardContainer}
		>
			<Stack direction="column" className={styles.topContainer}>
				<Heading5
					text={`${filterValue(fieldData, "URLA.X73")} ${filterValue(
						fieldData,
						"URLA.X75"
					)} ${filterValue(fieldData, "12")} ${filterValue(fieldData, "14")}`}
					extraClass={styles.addressText}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					className={styles.completeLoanContainer}
					onClick={() =>
						loanData?.loanNumber !== undefined &&
						navigate(`/view-loan/${loanGuid}/${loanData?.loanNumber}`)
					}
				>
					<Body3
						text="Go to Complete Application"
						extraClass={styles.completeLoanLink}
					/>
					<LaunchIcon className={styles.completeLoanIcon} />
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Loan Amount" fontType="semibold" />
						<Body2
							text={formatCurrency.format(
								loanData?.borrowerRequestedLoanAmount || 0
							)}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>

				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Borrower Name" fontType="semibold" />
						<Body2
							text={`${filterValue(fieldData, "4000")} ${filterValue(
								fieldData,
								"4001"
							)} ${filterValue(fieldData, "4002")}`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Co Borrower Name" fontType="semibold" />
						<Body2
							text={`${filterValue(fieldData, "4004")} ${filterValue(
								fieldData,
								"4005"
							)} ${filterValue(fieldData, "4006")}`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Loan Number" fontType="semibold" />
						<Body2
							text={loanData?.loanNumber}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="FICO" fontType="semibold" />
						<Body2
							text={filterValue(fieldData, "VASUMM.X23")}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Milestone Status" fontType="semibold" />
						<Body2
							text={milestoneStatus(
								filterValue(fieldData, "Log.MS.CurrentMilestone")
							)}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Rate" fontType="semibold" />
						<Body2
							text={`${Number(filterValue(fieldData, "2160")).toFixed(3)}%`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Lock" fontType="semibold" />
						<Body2
							text={`${filterValue(fieldData, "2400")}`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
				<Stack direction="row" className={styles.detailContainer}>
					<Stack direction="column" className={styles.detailDiv}>
						<Heading6 text="Requested Lock Rate" fontType="semibold" />
						<Body2
							text={`${Number(filterValue(fieldData, "3")).toFixed(3)}%`}
							extraClass={styles.detailsValue}
						/>
					</Stack>
					<Box className={styles.dividerContainer}>
						<Divider className={styles.dividerLine} />
					</Box>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default LoanSummaryCard;
