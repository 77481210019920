import { apiSlice } from "../../app/api/apiSlice";

export const loanDocsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		loanDocs: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/upload_attachment/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useLoanDocsMutation } = loanDocsApiSlice;
