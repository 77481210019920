import { createSlice } from "@reduxjs/toolkit";

const underwritingConditions = createSlice({
	name: "underwritingConditions",
	initialState: {
		data: [],
	},
	reducers: {
		setUnderwritingConditions: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setUnderwritingConditions } = underwritingConditions.actions;
export default underwritingConditions.reducer;

export const selectUnderwritingConditions = (state) =>
	state.underwritingConditions?.data;
