import React from "react";
import { useSelector } from "react-redux";

// mui components
import {
	Modal,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";

// custom components
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading6 from "../../typography/heading-06";
import Body2 from "../../typography/body-02";
import Body3 from "../../typography/body-03";

// reducer selector
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// helper utils
import { formatCurrency } from "../../../utils/format-currency";
import {
	LOAN_CHANNEL_CONSTANTS,
	TPO_SECONDARY,
} from "../../../utils/common-constants";
import {
	selectedUserWithAccessLevel,
	selectedUserWithLoanChannel,
} from "../../../utils/common-helpers";

// mui icons
import { InfoOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

export default function PricingModal({
	open,
	data,
	productName,
	confirmText,
	handleConfirm,
	closeText,
	handleClose,
}) {
	const pointsPercentage =
		data?.price > 100 ? data?.rebatePercent : data?.discountPercent;
	const pointsAmount =
		data?.price > 100 ? data?.rebateDollar : data?.discountDollar;

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	return (
		<Modal
			open={open}
			aria-labelledby="confirmation modal"
			aria-describedby="confirmation modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<InfoOutlined className={styles.iconContainer} />

				<Stack
					direction="column"
					alignItems="center"
					className={styles.textContainer}
				>
					<Body2
						text="Review details before you continue"
						extraClass={styles.confirmationText}
					/>
				</Stack>

				<Stack className={styles.infoContainer}>
					<Stack className={styles.loanOptionContainer}>
						<Heading6
							text="Selected loan option"
							fontType="semibold"
							extraClass={styles.loanOptionText}
						/>

						<Body3 text={productName} extraClass={styles.loanOptionValue} />
					</Stack>

					<TableContainer className={styles.tableContainer}>
						<Table className={styles.table}>
							<TableBody>
								<TableRow hover tabIndex={-1}>
									<TableCell>
										<Body3 text="Rate" extraClass={styles.tableHeader} />
									</TableCell>
									<TableCell align="right">
										<Body3
											text={`${data?.rate?.toFixed(3)}%`}
											extraClass={styles.tableValue}
										/>
									</TableCell>
								</TableRow>
								{(!(
									selectedUserWithLoanChannel(userDetails, impersonatedUser)
										?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									selectedUserWithLoanChannel(userDetails, impersonatedUser)
										?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
								) ||
									selectedUserWithAccessLevel(userDetails, impersonatedUser)
										?.access_level === TPO_SECONDARY) && (
									<TableRow hover tabIndex={-1}>
										<TableCell>
											<Body3
												text="Points/Credits %"
												extraClass={styles.tableHeader}
											/>
										</TableCell>
										<TableCell align="right">
											<Body3
												text={`${pointsPercentage?.toFixed(3)}%`}
												extraClass={styles.tableValue}
											/>
										</TableCell>
									</TableRow>
								)}
								{(!(
									selectedUserWithLoanChannel(userDetails, impersonatedUser)
										?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									selectedUserWithLoanChannel(userDetails, impersonatedUser)
										?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
								) ||
									selectedUserWithAccessLevel(userDetails, impersonatedUser)
										?.access_level === TPO_SECONDARY) && (
									<TableRow hover tabIndex={-1}>
										<TableCell>
											<Body3
												text="Points/Credits $"
												extraClass={styles.tableHeader}
											/>
										</TableCell>
										<TableCell align="right">
											<Body3
												text={formatCurrency.format(Number(pointsAmount) || 0)}
												extraClass={styles.tableValue}
											/>
										</TableCell>
									</TableRow>
								)}
								<TableRow hover tabIndex={-1}>
									<TableCell>
										<Body3 text="Payment" extraClass={styles.tableHeader} />
									</TableCell>
									<TableCell align="right">
										<Body3
											text={formatCurrency.format(
												Number(data?.totalPayment) || 0
											)}
											extraClass={styles.tableValue}
										/>
									</TableCell>
								</TableRow>
								<TableRow hover tabIndex={-1}>
									<TableCell>
										<Body3 text="Lock Period" extraClass={styles.tableHeader} />
									</TableCell>
									<TableCell align="right">
										<Body3
											text={`${data?.lockPeriod || 0} Days`}
											extraClass={styles.tableValue}
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>

				<PrimaryButton
					text={confirmText}
					onClick={handleConfirm}
					fullWidth={true}
				/>

				<SecondaryButton
					text={closeText}
					onClick={handleClose}
					fullWidth={true}
					extraClass={styles.closeBtn}
					extraTextClass={styles.closeBtnText}
				/>
			</Stack>
		</Modal>
	);
}
