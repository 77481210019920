import React from "react";

const DotsBg = ({ extraStyles }) => {
	return (
		<svg
			width="92"
			height="91"
			viewBox="0 0 92 91"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={extraStyles}
		>
			<g opacity="0.58">
				<circle cx="4.28715" cy="-0.000934601" r="3.61625" fill="#C1D0BB" />
				<circle cx="18.752" cy="-0.000934601" r="3.61625" fill="#C1D0BB" />
				<circle cx="33.2168" cy="-0.000934601" r="3.61625" fill="#C1D0BB" />
				<circle cx="47.6827" cy="-0.000934601" r="3.61625" fill="#C1D0BB" />
				<circle cx="62.1475" cy="-0.000934601" r="3.61625" fill="#C1D0BB" />
				<circle cx="76.6124" cy="-0.000934601" r="3.61625" fill="#C1D0BB" />
				<circle cx="91.0772" cy="-0.000934601" r="3.61625" fill="#C1D0BB" />
				<circle cx="4.28715" cy="14.4639" r="3.61625" fill="#C1D0BB" />
				<circle cx="18.752" cy="14.4639" r="3.61625" fill="#C1D0BB" />
				<circle cx="33.2168" cy="14.4639" r="3.61625" fill="#C1D0BB" />
				<circle cx="47.6827" cy="14.4639" r="3.61625" fill="#C1D0BB" />
				<circle cx="62.1475" cy="14.4639" r="3.61625" fill="#C1D0BB" />
				<circle cx="76.6124" cy="14.4639" r="3.61625" fill="#C1D0BB" />
				<circle cx="91.0772" cy="14.4639" r="3.61625" fill="#C1D0BB" />
				<circle cx="4.28715" cy="28.9288" r="3.61625" fill="#C1D0BB" />
				<circle cx="18.752" cy="28.9288" r="3.61625" fill="#C1D0BB" />
				<circle cx="33.2168" cy="28.9288" r="3.61625" fill="#C1D0BB" />
				<circle cx="47.6827" cy="28.9288" r="3.61625" fill="#C1D0BB" />
				<circle cx="62.1475" cy="28.9288" r="3.61625" fill="#C1D0BB" />
				<circle cx="76.6124" cy="28.9288" r="3.61625" fill="#C1D0BB" />
				<circle cx="91.0772" cy="28.9288" r="3.61625" fill="#C1D0BB" />
				<circle cx="4.28715" cy="43.3936" r="3.61625" fill="#C1D0BB" />
				<circle cx="18.752" cy="43.3936" r="3.61625" fill="#C1D0BB" />
				<circle cx="33.2168" cy="43.3936" r="3.61625" fill="#C1D0BB" />
				<circle cx="47.6827" cy="43.3936" r="3.61625" fill="#C1D0BB" />
				<circle cx="62.1475" cy="43.3936" r="3.61625" fill="#C1D0BB" />
				<circle cx="76.6124" cy="43.3936" r="3.61625" fill="#C1D0BB" />
				<circle cx="91.0772" cy="43.3936" r="3.61625" fill="#C1D0BB" />
				<circle cx="4.28715" cy="57.8584" r="3.61625" fill="#C1D0BB" />
				<circle cx="18.752" cy="57.8584" r="3.61625" fill="#C1D0BB" />
				<circle cx="33.2168" cy="57.8584" r="3.61625" fill="#C1D0BB" />
				<circle cx="47.6827" cy="57.8584" r="3.61625" fill="#C1D0BB" />
				<circle cx="62.1475" cy="57.8584" r="3.61625" fill="#C1D0BB" />
				<circle cx="76.6124" cy="57.8584" r="3.61625" fill="#C1D0BB" />
				<circle cx="91.0772" cy="57.8584" r="3.61625" fill="#C1D0BB" />
				<circle cx="4.28715" cy="72.3233" r="3.61625" fill="#C1D0BB" />
				<circle cx="18.752" cy="72.3233" r="3.61625" fill="#C1D0BB" />
				<circle cx="33.2168" cy="72.3233" r="3.61625" fill="#C1D0BB" />
				<circle cx="47.6827" cy="72.3233" r="3.61625" fill="#C1D0BB" />
				<circle cx="62.1475" cy="72.3233" r="3.61625" fill="#C1D0BB" />
				<circle cx="76.6124" cy="72.3233" r="3.61625" fill="#C1D0BB" />
				<circle cx="91.0772" cy="72.3233" r="3.61625" fill="#C1D0BB" />
				<circle cx="4.28715" cy="86.7901" r="3.61625" fill="#C1D0BB" />
				<circle cx="18.752" cy="86.7901" r="3.61625" fill="#C1D0BB" />
				<circle cx="33.2168" cy="86.7901" r="3.61625" fill="#C1D0BB" />
				<circle cx="47.6827" cy="86.7901" r="3.61625" fill="#C1D0BB" />
				<circle cx="62.1475" cy="86.7901" r="3.61625" fill="#C1D0BB" />
				<circle cx="76.6124" cy="86.7901" r="3.61625" fill="#C1D0BB" />
				<circle cx="91.0772" cy="86.7901" r="3.61625" fill="#C1D0BB" />
			</g>
		</svg>
	);
};

export default DotsBg;
