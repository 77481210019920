const { DateTime } = require("luxon");

export const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

export const descendingComparator = (a, b, orderBy) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

export const getComparator = (order, orderBy) => {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

const getFieldValue = (obj, property) => {
	if (property in obj) {
		return obj[property];
	} else {
		const propertyParts = property
			.split(/[\[\]]+/)
			.filter((part) => part.length > 0);
		let value = obj;
		for (let part of propertyParts) {
			if (value && typeof value === "object") {
				const nestedProperty = part.replace(/['"]/g, ""); // Remove quotes if present
				if (nestedProperty in value) {
					value = value[nestedProperty];
				} else {
					return undefined;
				}
			} else {
				return undefined;
			}
		}
		return value;
	}
};

export const sortAmount = (array, sortOrder, fieldId) => {
	var sortedArr = [...array];

	return sortOrder === "desc"
		? sortedArr.sort(
				(a, b) =>
					getFieldValue(b, fieldId).localeCompare(getFieldValue(a, fieldId)) ||
					!getFieldValue(b, fieldId) - !getFieldValue(a, fieldId)
		  )
		: sortedArr.sort(
				(a, b) =>
					!getFieldValue(a, fieldId) - !getFieldValue(b, fieldId) ||
					getFieldValue(a, fieldId).localeCompare(getFieldValue(b, fieldId))
		  );
};

export const sortNumber = (array, sortOrder, fieldId) => {
	return array.sort((a, b) => {
		const numA = parseFloat(getFieldValue(a, fieldId));
		const numB = parseFloat(getFieldValue(b, fieldId));

		if (sortOrder === "asc") {
			return numA - numB; // Ascending order
		} else if (sortOrder === "desc") {
			return numB - numA; // Descending order
		}

		return 0; // No change in order if no valid order is provided
	});
};

const recognizeLuxonFormat = (dateString) => {
	const potentialFormats = [
		"M/d/yyyy hh:mm:ss a",
		"MM/dd/yyyy h:mm a",
		"MMMM dd, yyyy HH:mm:ss",
	];

	for (const format of potentialFormats) {
		const parsedDate = DateTime.fromFormat(dateString, format);
		if (parsedDate.isValid) {
			return format;
		}
	}

	return false; // Format not recognized
};

const convertTimezone = (dateString) => {
	const createdOnLuxon = DateTime.fromISO(dateString, { zone: "utc" });

	return createdOnLuxon;
};

export const sortDate = (array, sortOrder, fieldId) => {
	var sortedArr = [...array];

	sortedArr.sort((a, b) => {
		const dateA =
			getFieldValue(a, fieldId)?.includes("T") ||
			getFieldValue(a, fieldId)?.includes("Z")
				? convertTimezone(a[fieldId])
				: getFieldValue(a, fieldId)
				? DateTime.fromFormat(
						getFieldValue(a, fieldId),
						recognizeLuxonFormat(getFieldValue(a, fieldId))
				  )
				: null;
		const dateB =
			getFieldValue(b, fieldId)?.includes("T") ||
			getFieldValue(b, fieldId)?.includes("Z")
				? convertTimezone(b[fieldId])
				: getFieldValue(b, fieldId)
				? DateTime.fromFormat(
						getFieldValue(b, fieldId),
						recognizeLuxonFormat(getFieldValue(b, fieldId))
				  )
				: null;

		if (dateA && dateB) {
			if (sortOrder === "asc") {
				return dateA - dateB;
			} else {
				return dateB - dateA;
			}
		} else if (dateA) {
			return -1;
		} else if (dateB) {
			return 1;
		} else {
			return 0;
		}
	});

	return sortedArr;
};

export const sortLoanStatus = (array, sortOrder, fieldId) => {
	const getLoanStatus = (obj) => {
		const loanStatus = getFieldValue(obj, fieldId);

		switch (loanStatus) {
			case "Started":
				return "Loan Submitted";
			case "Loan Submitted":
				return "Loan Submitted";
			case "Review Submission":
				return "Review Submission";
			case "Incomplete Credit Pkg":
				return "Incomplete Credit Pkg";
			case "Recd Cred Pkg":
				return "Rec'd Credit Pkg";
			case "Review Cred Pkg":
				return "Rec'd Credit Pkg";
			case "File Setup":
				return "File Setup";
			case "Submit to UW":
				return "Submitted to UW";
			case "In UWing Review":
				return "In UWing Review";
			case "Suspended":
				return "In UWing Review";
			case "Cond'l Approval":
				return "Cond'l Approval";
			case "Recd Conditions":
				return "Condition Review";
			case "UW Cond Review":
				return "Condition Review";
			case "QC Requested":
				return "Condition Review";
			case "QC Complete":
				return "Condition Review";
			case "CTC":
				return "Clear to Close";
			case "Closing Prep":
				return "Closing Docs";
			case "Closing":
				return "Closing";
			case "Funding Prep":
				return "Wire Sent";
			case "Funding":
				return "Funded";
			case "Recd Closing Pkg":
				return "Rec'd Closing Pkg";
			case "Review Closing Pkg":
				return "Review Closing Pkg";
			case "Purch Suspended":
				return "Purch Suspended";
			case "Recd Purch Conditions":
				return "Rec'd Purch Conditions";
			case "Review Purch Conditions":
				return "Review Purch Conditions";
			case "Cleared 4 Purch":
				return "Cleared 4 Purch";
			case "Create PA":
				return "Create PA";
			case "Request Wire":
				return "Request Wire";
			case "Purchase":
				return "Purchase";
			default:
				return "";
		}
	};

	const sortedArr = [...array];

	return sortedArr?.sort((a, b) => {
		const statusA = getLoanStatus(a);
		const statusB = getLoanStatus(b);

		const milestoneA =
			a.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
				? " incomplete application"
				: statusA;

		const milestoneB =
			b.fields["Loan.LoanFolder"] === "Portal - Not Submitted"
				? " incomplete application"
				: statusB;

		if (sortOrder === "asc") {
			return milestoneA.localeCompare(milestoneB);
		} else if (sortOrder === "desc") {
			return milestoneB.localeCompare(milestoneA);
		} else {
			console.error("Invalid sort order. Please use 'asc' or 'desc'.");
		}
	});
};
