import { apiSlice } from "../../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		importTpoUser: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/org_tpo/import/`,
				method: "POST",
			}),
		}),
	}),
});

export const { useImportTpoUserMutation } = applicationApiSlice;
