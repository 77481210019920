import { DateTime } from "luxon";

export const timeZoneOffset = (dateValue) => {
	console.log("5 date value:", dateValue);

	const dateTimezoneOffset = new Date().getTimezoneOffset();
	console.log("5 timezone offset:", dateTimezoneOffset);

	let formattedDate;

	if (dateTimezoneOffset > 0) {
		formattedDate = DateTime.fromISO(dateValue, { zone: "utc" })
			.plus({
				hour: dateTimezoneOffset / 60,
			})
			.toFormat("MM-dd-yyyy");
	} else {
		formattedDate = DateTime.fromISO(dateValue, { zone: "utc" })
			.minus({
				hour: dateTimezoneOffset / 60,
			})
			.toFormat("MM-dd-yyyy");
	}

	console.log("25 invalid date:", formattedDate === "Invalid DateTime");
	console.log("25 formatted date:", formattedDate);
	return formattedDate;
};
