import { useSelector } from "react-redux";

import { Routes, Route, Navigate } from "react-router-dom";

import HomePage from "./pages/home";
import Pipeline from "./pages/pipeline";
import NewLoan from "./pages/new-loan";

import LoanSummary from "./pages/loan-summary";
import CreateLoan from "./pages/create-loan";
import AdminScreen from "./pages/admin-screen";
import ViewLoan from "./pages/view-loan";
import EditLoan from "./pages/edit-loan";
import ResourceLibrary from "./pages/resource-library";

import Login from "./pages/login";
import ResetPassword from "./pages/reset-password";
import SetPassword from "./pages/set-password";

import RequireAuth from "./features/auth/RequireAuth";

import {
	selectCurrentToken,
	selectRefreshToken,
} from "./features/auth/authSlice";

import { storageLoad } from "./utils/localStorage";

import { AwsRum } from "aws-rum-web";

function App() {
	const token = useSelector(selectCurrentToken);
	const refreshToken = useSelector(selectRefreshToken);
	const localAccessToken = storageLoad("access-token");
	const localRefreshToken = storageLoad("refresh-token");

	console.log("44 process:", process.env);

	try {
		const config = {
			sessionSampleRate: 1,
			guestRoleArn: process.env.REACT_APP_API_ENDPOINT,
			identityPoolId: process.env.identityPoolId,
			endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
			telemetries: ["performance", "errors", "http"],
			allowCookies: true,
			enableXRay: false,
		};

		const APPLICATION_ID = process.env.APPLICATION_ID;
		const APPLICATION_VERSION = "1.0.0";
		const APPLICATION_REGION = "us-east-1";

		const awsRum = new AwsRum(
			APPLICATION_ID,
			APPLICATION_VERSION,
			APPLICATION_REGION,
			config
		);
	} catch (error) {
		// Ignore errors thrown during CloudWatch RUM web client initialization
	}

	return (
		<Routes>
			{(token !== null && token !== undefined) ||
			(refreshToken !== null && refreshToken !== undefined) ||
			(localAccessToken !== null && localAccessToken !== undefined) ||
			(localRefreshToken !== null && localRefreshToken !== undefined) ? (
				<Route>
					{/* protected routes */}
					<Route element={<RequireAuth />}>
						<Route path="/home" element={<HomePage />} />
						<Route path="/pipeline" element={<Pipeline />} />
						<Route path="/resource-library" element={<ResourceLibrary />} />
						<Route path="/admin" element={<AdminScreen />} />

						{/* loan submission flows */}
						<Route path="/new-loan" element={<NewLoan />} />
						<Route path="/create-loan" element={<CreateLoan />} />
						<Route path="/edit-loan/:id" element={<EditLoan />} />
						<Route path="/view-loan/:id/:loanId" element={<ViewLoan />} />

						{/* completed loan details */}
						<Route path="/loan-summary/:id" element={<LoanSummary />} />

						<Route path="*" element={<Navigate to="/home" replace />} />
					</Route>
				</Route>
			) : (
				<Route>
					{/* public routes */}
					<Route path="/login" element={<Login />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/set-password/:id" element={<SetPassword />} />
					<Route path="*" element={<Navigate to="/login" replace />} />
				</Route>
			)}
		</Routes>
	);
}

export default App;
