import React, { useState } from "react";
import uuid from "react-uuid";

// mui components
import { InputLabel, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import RequiredError from "../../../required-error";
import OutlinedTextInput from "../../../custom-input/outlined-text-input";
import MaskedInput from "../../../custom-input/masked-input";
import SecondarySelect from "../../../custom-select/secondary-select";
import CustomCheckbox from "../../../custom-checkbox";
import PrimaryButton from "../../../buttons/primary-button";
import SecondaryButton from "../../../buttons/secondary-button";
import Heading3 from "../../../typography/heading-03";
import Heading6 from "../../../typography/heading-06";

// helper utils
import {
	ownerOptions,
	realEstateStatusOptions,
	realEstateUseOptions,
	stateOptions,
} from "../../../../utils/select-options";

// custom styles
import styles from "./index.module.scss";

const AddRealEstate = ({
	handleCloseDrawer,
	// custom prop
	selectedTab,
	selectedRealEstateData,
	realEstateData,
	setRealEstateData,
}) => {
	const [estateData, setEstateData] = useState(selectedRealEstateData || {});
	const [error, setError] = useState("");

	const handleAddRealEstate = () => {
		console.log(
			"40 real estate data:",
			realEstateData,
			selectedTab,
			estateData
		);
		if (!estateData?.futurePropertyUsageType) {
			setError("provide a value");
		} else {
			setError("");
			setRealEstateData((prevState) => {
				return prevState.map((row, i) => {
					// check the correct borrower pair
					if (i === selectedTab) {
						// Check if the updated object has a reo id
						if (estateData?.reoId) {
							// If the object has an ID, find and update the existing object in the child array
							return row.map((obj) =>
								obj.reoId === estateData.reoId ? estateData : obj
							);
						} else {
							// If the object does not have an ID, push it to the end of the child array
							return [...row, { reoId: uuid(), ...estateData }];
						}
					}
					return row;
				});
			});
			handleCloseDrawer();
		}
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.drawerHeaderContainer}
			>
				<Heading3
					text={`${estateData?.reoId ? "Update" : "Add"} Real Estate`}
					extraClass={styles.headerText}
					fontType="semibold"
				/>
				<SecondaryButton
					startIcon={<CloseIcon />}
					text="Close"
					onClick={handleCloseDrawer}
				/>
			</Stack>

			<Stack direction="column" className={styles.drawerBodyContainer}>
				<Stack direction="column" className={styles.inputSectionContainer}>
					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* owner */}
						<Stack
							direction="column"
							flexGrow={1}
							className={styles.inputContainer}
						>
							<InputLabel htmlFor="select-owner">
								<Heading6
									text="Owner"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-owner"
								extraClass={styles.fullWidthContainer}
								value={estateData?.owner || ""}
								handleChange={(event) =>
									setEstateData((prevState) => ({
										...prevState,
										owner: event.target.value,
									}))
								}
								options={ownerOptions}
								displayEmpty={true}
							/>
						</Stack>

						{/* status */}
						<Stack
							direction="column"
							flexGrow={1}
							className={styles.inputContainer}
						>
							<InputLabel htmlFor="select-status">
								<Heading6
									text="Status"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-status"
								extraClass={styles.fullWidthContainer}
								value={
									estateData?.dispositionStatusType === "Retain"
										? "RetainForRental" || ""
										: estateData?.dispositionStatusType || ""
								}
								handleChange={(event) =>
									setEstateData((prevState) => ({
										...prevState,
										dispositionStatusType: event.target.value,
									}))
								}
								options={realEstateStatusOptions}
								displayEmpty={true}
							/>
						</Stack>
					</Stack>

					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* Property Value */}
						<Stack
							direction="column"
							flexGrow={1}
							className={styles.inputContainer}
						>
							<InputLabel htmlFor="outlined-property-value">
								<Heading6
									text="Property Value"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id="outlined-property-value"
								extraClass={styles.fullWidthContainer}
								type="currency"
								placeholder="$"
								value={estateData?.marketValueAmount?.toString() || ""}
								onInputChange={(value) =>
									setEstateData((prevState) => ({
										...prevState,
										marketValueAmount: value,
									}))
								}
							/>
						</Stack>

						{/* Monthly Insurance, Taxes, HOA */}
						<Stack
							direction="column"
							flexGrow={1}
							className={styles.inputContainer}
						>
							<InputLabel htmlFor="outlined-monthly-taxes">
								<Heading6
									text="Monthly Insurance, Taxes, HOA"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id="outlined-monthly-taxes"
								extraClass={styles.fullWidthContainer}
								type="currency"
								placeholder="$"
								value={estateData?.maintenanceExpenseAmount?.toString() || ""}
								onInputChange={(value) =>
									setEstateData((prevState) => ({
										...prevState,
										maintenanceExpenseAmount: value,
									}))
								}
							/>
						</Stack>

						{/* Current or Estimated Monthly Rent */}
						<Stack
							direction="column"
							flexGrow={1}
							className={styles.inputContainer}
						>
							<InputLabel htmlFor="outlined-monthly-rent">
								<Heading6
									text="Current or Estimated Monthly Rent"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<MaskedInput
								id="outlined-monthly-rent"
								extraClass={styles.fullWidthContainer}
								type="currency"
								placeholder="$"
								value={estateData?.rentalIncomeGrossAmount?.toString() || ""}
								onInputChange={(value) =>
									setEstateData((prevState) => ({
										...prevState,
										rentalIncomeGrossAmount: value,
									}))
								}
							/>
						</Stack>
					</Stack>

					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* property use */}
						<Stack
							direction="column"
							flexGrow={1}
							className={styles.inputContainer}
						>
							<InputLabel htmlFor="select-property-use">
								<Heading6
									text="Property Use"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-property-use"
								extraClass={styles.fullWidthContainer}
								displayEmpty={true}
								value={estateData?.futurePropertyUsageType || ""}
								handleChange={(event) =>
									setEstateData((prevState) => ({
										...prevState,
										futurePropertyUsageType: event.target.value,
									}))
								}
								options={realEstateUseOptions}
								error={error && !estateData?.futurePropertyUsageType}
							/>
						</Stack>

						{/* subject property indicator */}
						<Stack
							direction="column"
							flexGrow={1}
							justifyContent="flex-end"
							className={styles.inputContainer}
						>
							<Stack
								className={
									styles.fullWidthContainer + " " + styles.checkboxContainer
								}
							>
								<CustomCheckbox
									extraClass={styles.checkboxInput}
									checked={estateData?.subjectIndicator || false}
									handleChange={(event) =>
										setEstateData((prevState) => ({
											...prevState,
											subjectIndicator: !estateData?.subjectIndicator,
										}))
									}
									label={
										<Heading6
											text="Subject Property"
											fontType="semibold"
											extraClass={styles.inputHeader}
										/>
									}
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>

				<Stack direction="column" className={styles.inputSectionContainer}>
					{/* Address Line 1 */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-address-line-1">
							<Heading6
								text="Address Line 1"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-address-line-1"
							type="text"
							placeholder="Street Line 1"
							fullWidth={true}
							value={estateData?.uRLA2020StreetAddress || ""}
							handleChange={(event) =>
								setEstateData((prevState) => ({
									...prevState,
									uRLA2020StreetAddress: event.target.value,
								}))
							}
						/>
					</Stack>

					{/* Address Line 2 */}
					<Stack
						direction="column"
						className={styles.inputContainer + " " + styles.fullWidthContainer}
					>
						<InputLabel htmlFor="outlined-input-address-line-2">
							<Heading6
								text="Address Line 2 / Unit Number"
								fontType="semibold"
								extraClass={styles.inputHeader}
							/>
						</InputLabel>

						<OutlinedTextInput
							id="outlined-input-address-line-2"
							type="text"
							placeholder="Unit Number"
							fullWidth={true}
							value={estateData?.unitNumber || ""}
							handleChange={(event) =>
								setEstateData((prevState) => ({
									...prevState,
									unitNumber: event.target.value,
								}))
							}
						/>
					</Stack>

					<Stack direction="row" className={styles.inputSectionContainer}>
						{/* city */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-city">
								<Heading6
									text="City"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-city"
								type="text"
								placeholder="City"
								value={estateData?.city || ""}
								handleChange={(event) =>
									setEstateData((prevState) => ({
										...prevState,
										city: event.target.value,
									}))
								}
							/>
						</Stack>

						{/* state */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="select-state">
								<Heading6
									text="State"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<SecondarySelect
								id="select-state"
								displayEmpty={true}
								value={estateData?.state || ""}
								handleChange={(event) =>
									setEstateData((prevState) => ({
										...prevState,
										state: event.target.value,
									}))
								}
								options={stateOptions}
							/>
						</Stack>

						{/* postal code */}
						<Stack direction="column" className={styles.inputContainer}>
							<InputLabel htmlFor="outlined-input-postal-code">
								<Heading6
									text="Postal Code"
									fontType="semibold"
									extraClass={styles.inputHeader}
								/>
							</InputLabel>

							<OutlinedTextInput
								id="outlined-input-postal-code"
								type="text"
								placeholder="Postal Code"
								value={estateData?.postalCode || ""}
								handleChange={(event) =>
									setEstateData((prevState) => ({
										...prevState,
										postalCode: event.target.value,
									}))
								}
							/>
						</Stack>
					</Stack>
				</Stack>

				{error !== "" && (
					<RequiredError
						headerText="Missing fields"
						text={
							error.includes("provide a value") &&
							"Please provide a value for all fields highlighted below before you continue."
						}
						extraClass={styles.errorContainer}
					/>
				)}

				{/* footer container */}
				<Stack direction="row" className={styles.footerContainer}>
					<SecondaryButton
						extraClass={styles.cancelBtn}
						onClick={() => {
							setEstateData(selectedRealEstateData || {});
							setError("");
						}}
						text="Cancel"
					/>

					<PrimaryButton
						extraClass={styles.addBtn}
						onClick={handleAddRealEstate}
						text={`${estateData?.reoId ? "Update" : "Add"} Real Estate`}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default AddRealEstate;
