import { apiSlice } from "../../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAdminPipeline: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/admin/loan/pipeline_modified/?email=${credentials?.brokerEmail}&loan_channel=${credentials?.loan_channel}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetAdminPipelineMutation } = usersApiSlice;
