import { store } from "../store";
import { setBrokerUploadedDocs } from "../../features/broker-uploads/brokerUploadSlice";

export const getBrokerUploads = async ({ loanGuid, brokerUploads }) => {
	var formData = new FormData();
	formData.append("loan_guid", loanGuid);

	try {
		const brokerUploadValue = await brokerUploads({ formData }).unwrap();
		// save response in redux store
		store.dispatch(setBrokerUploadedDocs(brokerUploadValue?.response));
	} catch (err) {
		console.log("260 error:", err);
	}
};
