import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";

// mui components
import { Box, Stack } from "@mui/material";

// custom components
import RequiredError from "../../required-error";
import SecondaryButton from "../../buttons/secondary-button";
import PrimaryButton from "../../buttons/primary-button";
import SupportingDocs from "../../supporting-docs";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";
import Body2 from "../../typography/body-02";

// helper utils
import { formatBytes } from "../../../utils/format-bytes";
import { selectedUserWithLoanChannel } from "../../../utils/common-helpers";

// custom modal
import LoadingDataModal from "../../modal/loading-data-modal";
import SuccessModal from "../../modal/success-modal";
import ErrorModal from "../../modal/error-modal";

// mui icons
import { ArrowBackOutlined, DoneOutlined } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useLoanDocsMutation } from "../../../features/loan-docs/loanDocsApiSlice";
import { useUpdateFieldValuesTpoMutation } from "../../../features/update-field-value-tpo/updateFieldsApiSlice";
import { useMoveLoanMutation } from "../../../features/move-loan/moveLoanApiSlice";
import { usePopulateFieldsMutation } from "../../../features/populate-fields/populateFieldsApiSlice";

// reducer slices
import { setLoanDocsReducer } from "../../../features/loan-docs/loanDocsSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// reducer slices
import {
	selectLoEmail,
	selectUserDetails,
} from "../../../features/auth/authSlice";

// custom styles
import styles from "./index.module.scss";

const fileTypes = ["PDF", "PNG", "JPEG", "JPG", "HTML", "TXT"];

export const LoanDocuments = ({
	setLoanApplicationStage,
	loanGuid,
	currentStage,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let { id } = useParams();

	const loEmail = useSelector(selectLoEmail);
	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const [loanEmail, setLoanEmail] = useState("");
	const [loanOrganization, setLoanOrganization] = useState("");

	console.log("60 user details:", userDetails);
	console.log("60 lo email:", loEmail);
	console.log("60 impersonated user:", impersonatedUser?.email);

	console.log("23 id:", id);

	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);
		navigate("/pipeline");
	};

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
		navigate("/pipeline");
	};

	const [field] = useFieldMutation();
	const [loanDocs, { isLoading }] = useLoanDocsMutation();

	const [updateFieldValuesTpo, { isLoading: isUpdatingFields }] =
		useUpdateFieldValuesTpoMutation();
	const [moveLoan, { isLoading: isMovingLoan }] = useMoveLoanMutation();
	const [populateFields, { isLoading: isPopulatingFields }] =
		usePopulateFieldsMutation();

	const [file, setFile] = useState("");
	const [error, setError] = useState("");
	const [uploadedFiles, setUploadedFiles] = useState([]);

	const handleFileUpload = async (file) => {
		setFile(file);
		console.log("34 file:", file);
		console.log("34 file length:", file?.length);
		let newArr = [];
		Array.from(file).forEach((file) => newArr.push(file));
		setUploadedFiles([...uploadedFiles, ...newArr]);
	};

	const deleteFile = (event, index) => {
		console.log("43 files:", uploadedFiles, index);

		let newArr = uploadedFiles;
		newArr.splice(index, 1);

		setUploadedFiles([...newArr]);
	};

	const handleNavigateAfterDelay = () => {
		setTimeout(() => {
			navigate("/pipeline");
		}, 3000); // 3 seconds
	};

	const handlePrevClick = () => {
		console.log("67 prev click");
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const isExecutiveOrManager =
		userDetails?.user_roles?.includes("executive") ||
		userDetails?.user_roles?.includes("manager") ||
		userDetails?.user_roles?.includes("AE") ||
		userDetails?.user_roles?.includes("AM");

	const handleSubmitFiles = async () => {
		console.log("50 submit click!!!", file, uploadedFiles);

		for (let index = 0; index < uploadedFiles.length; index++) {
			const element = uploadedFiles[index];

			var formData = new FormData();
			if (id !== undefined && id !== "") {
				formData.append("loan_guid", id);
			} else if (loanGuid !== undefined && loanGuid !== "") {
				formData.append("loan_guid", loanGuid);
			}
			formData.append("file", element);

			// dispatch API and open modal if successful
			try {
				const fileUpload = await loanDocs({ formData }).unwrap();
				console.log("99 file data:", fileUpload);
				dispatch(setLoanDocsReducer(fileUpload));

				if (index === uploadedFiles.length - 1) {
					// run at the end of the loop
					let valuesArr = [
						{ id: "TPO.X4", value: DateTime.now().toFormat("MM-dd-yyyy") },
						{ id: "TPO.X90", value: DateTime.now().toFormat("MM-dd-yyyy") },
					];

					console.log("191 loan guid:", loanGuid);

					const updateFieldValues = await updateFieldValuesTpo({
						loan_guid: loanGuid,
						field_values: valuesArr,
					}).unwrap();

					console.log("90 updated values:", updateFieldValues);

					const moveLoanValue = await moveLoan({
						loan_guid: loanGuid,
					}).unwrap();

					console.log("90 updated values:", moveLoanValue);

					let userEmail;

					console.log("200 isExecutiveOrManager:", isExecutiveOrManager);

					if (isExecutiveOrManager) {
						userEmail = loanEmail;
					} else if (
						loEmail !== null &&
						loEmail !== undefined &&
						loEmail !== ""
					) {
						userEmail = loEmail;
					} else if (
						impersonatedUser?.email !== null &&
						impersonatedUser?.email !== undefined &&
						impersonatedUser?.email !== ""
					) {
						userEmail = impersonatedUser?.email;
					} else {
						userEmail = userDetails?.email;
					}

					console.log("210 user email:", userEmail);

					let regex = /\(([^)]+)\)/;

					console.log("215 org loan channel:", loanOrganization);
					console.log("215 loan channel:", loanOrganization?.match(regex)?.[1]);

					const loanChannel = isExecutiveOrManager
						? loanOrganization?.match(regex)?.[1]?.toUpperCase()
						: selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel;

					const populateCustomFields = await populateFields({
						loan_guid: loanGuid,
						email: userEmail,
						loan_channel: loanChannel,
					}).unwrap();

					console.log("150 populateCustomFields:", populateCustomFields);

					handleOpenSuccessModal();
					handleNavigateAfterDelay();
				}
			} catch (err) {
				console.log("95 err:", err);

				if (err?.data?.message?.details !== undefined) {
					console.log("180 updateFieldValues details:", err);
					setError(err?.data?.message?.details);
				} else {
					console.log("180 updateFieldValues message:", err);
					setError(err?.data?.message);
				}
			}
		}
	};

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("250 field values:", fieldValues);

		setLoanEmail(findFieldValue(fieldValues, "3968"));
		setLoanOrganization(findFieldValue(fieldValues, "TPO.X14"));
	};

	const getFieldValues = async (loanId) => {
		console.log("135 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "3968,TPO.X14",
			}).unwrap();
			console.log("265 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("270 error:", err);
		}
	};

	useEffect(() => {
		console.log("275 loan Guid:", loanGuid);

		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Stack direction="row" className={styles.headerLinkContainer}>
						<Heading5 text="Upload Supporting Documents" fontType="semibold" />
					</Stack>

					<Body3 text="Please refer to the Resources Tab for our minimum required submission docs per loan product/program when uploading" />
				</Stack>

				{/* loan flow error */}
				{error !== undefined && error !== "" && (
					<RequiredError
						headerText="Missing fields"
						text={
							error.includes("already moved")
								? "Already moved to 'Pipeline'."
								: error
						}
					/>
				)}

				<SupportingDocs
					fileCount={uploadedFiles?.length}
					fileTypes={fileTypes}
					text="or drag to upload supporting files"
					handleFileAttach={handleFileUpload}
					multiple={true}
				/>

				{uploadedFiles !== undefined && uploadedFiles?.length > 0 && (
					<Stack direction="column" className={styles.uploadedFileContainer}>
						{uploadedFiles?.map((file, index) => (
							<Stack
								direction="row"
								key={index}
								className={styles.fileListContainer}
							>
								<Box className={styles.fileIconContainer}>
									<UploadFileIcon className={styles.fileIcon} />
								</Box>
								<Stack direction="column" className={styles.fileNameContainer}>
									<Body2 text={file?.name} />
									<Body3 text={formatBytes(file?.size)} />
								</Stack>

								<Box
									sx={{ cursor: "pointer" }}
									onClick={(event) => deleteFile(event, index)}
									className={styles.deleteIconContainer}
								>
									<CloseIcon className={styles.deleteIcon} />
								</Box>
							</Stack>
						))}
					</Stack>
				)}
			</Stack>

			{/* footer buttons */}
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrevClick}
				/>
				<PrimaryButton
					// TODO loader isUpdatingFields
					text="Submit Application"
					endIcon={<DoneOutlined className={styles.nextIcon} />}
					onClick={handleSubmitFiles}
					disabled={uploadedFiles?.length === 0}
				/>
			</Stack>

			{/* modals */}
			<LoadingDataModal
				open={
					isLoading || isUpdatingFields || isMovingLoan || isPopulatingFields
				}
				text="Please wait while loan is being created"
			/>
			<SuccessModal
				open={successModalVisible}
				text="Loan Created Successfully"
				handleClose={handleCloseSuccessModal}
			/>
			<ErrorModal
				open={errorModalVisible}
				text="This loan has been successfully submitted to the Lender already"
				handleClose={handleCloseErrorModal}
			/>
		</Stack>
	);
};

export default LoanDocuments;
