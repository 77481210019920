import { createSlice } from "@reduxjs/toolkit";

const brokerUploadedDocs = createSlice({
	name: "brokerUploadedDocs",
	initialState: {
		data: [],
	},
	reducers: {
		setBrokerUploadedDocs: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setBrokerUploadedDocs } = brokerUploadedDocs.actions;
export default brokerUploadedDocs.reducer;

export const selectBrokerUploadedDocs = (state) =>
	state.brokerUploadedDocs.data;
