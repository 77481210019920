import React from "react";

// mui components
import { Modal, Stack, Box } from "@mui/material";

// custom components
import Heading2 from "../../typography/heading-02";

// custom animations
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading.json";

// custom styles
import styles from "./index.module.scss";

export default function LoaderModal({ open }) {
	return (
		<Modal
			open={open}
			aria-labelledby="loading modal"
			aria-describedby="loading modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Box className={styles.animationContainer}>
					<Lottie animationData={loadingAnimation} loop={true} />
				</Box>

				<Heading2
					text="Loading..."
					extraClass={styles.successText}
					fontType="semibold"
				/>
			</Stack>
		</Modal>
	);
}
