import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

export const theme = createTheme({
	palette: {
		primary: {
			mainHoverBg: "#A7008B",
			mainBg: "#131313",
			main: "#131313",
			light: "#FFF",
			dark: "#C424A8",
			black: "#000",
			lightBlackBg: "#E4F2FF",
			lightBlack: "#334259",
			grey: "#6F85A7",
			white: "#fff",
			blue: "#0588FF",
			errorBg: "#FFF0F3",
			errorText: "#FF3D00",
			successBg: "#EEFFFB",
			successText: "#04AB84",
			disabledBg: "#EDEDED",
			disabledText: "#909090",
			fontSize: "2rem",
			borderRadius: "8px",
		},
		secondary: { main: "#fff" },
	},
	typography: {
		fontSize: 16,
		h1: {
			fontSize: "2.5rem",
			fontWeight: 600,
			lineHeight: 1.3,
			letterSpacing: "0em",
		},
		h2: {
			fontSize: "2.5rem",
			fontWeight: 600,
			lineHeight: 1.25,
			letterSpacing: "0em",
		},
		allVariants: {
			fontFamily: "Inter, Roboto, sans-serif",
			lineHeight: "120%",
		},
		regular: {
			lineHeight: "120%",
			fontWeight: 400,
		},

		bold: {
			lineHeight: "120%",
			fontWeight: 600,
		},

		p: {
			fontSize: "1rem",
			color: "red",
			[breakpoints.down("xl")]: {
				htmlFontSize: 14,
				fontSize: "1rem",
				color: "green",
			},
		},
	},
});
