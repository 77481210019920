import { apiSlice } from "../../../app/api/apiSlice";

export const checkUserApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		checkUser: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/tpo/check_user/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { useCheckUserMutation } = checkUserApiSlice;
