import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";

const maskSSN = (ssn) => {
	console.log("5 ssn:", ssn);

	const ssnRegex = /^(\d{3})-(\d{2})-(\d{4})$/;
	const match = ssn?.match(ssnRegex);
	console.log("5 match:", match);

	if (!match) return ssn;

	const [, , , lastPart] = match;
	const maskedFirstPart = "XXX";
	const maskedSecondPart = "XX";

	return `${maskedFirstPart}-${maskedSecondPart}-${lastPart}`;
};

const formatSSN = (ssn) => {
	const ssnRegex = /^(\d{3})(\d{2})(\d{4})$/;
	const match = ssn?.match(ssnRegex);

	if (!match) return ssn;

	const [, firstPart, secondPart, lastPart] = match;

	return `${firstPart}-${secondPart}-${lastPart}`;
};

const SSNInput = ({ unmaskedSSN, onUnmaskedSSNChange, ...props }) => {
	const [ssn, setSSN] = useState(""); // Set your default value here
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		console.log("35 unmaskedSSN:", unmaskedSSN);

		const maskedSSN = maskSSN(unmaskedSSN);
		console.log("35 maskedSSN:", maskedSSN);

		setSSN(maskedSSN);
	}, [unmaskedSSN]);

	const handleChange = (event) => {
		const inputValue = event.target.value.slice(0, 11);
		onUnmaskedSSNChange(formatSSN(inputValue));

		const maskedSSN = maskSSN(inputValue);
		console.log("45 masked:", maskedSSN);

		setSSN(maskedSSN !== null ? maskedSSN : inputValue);
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	console.log("50 is focused:", isFocused);
	console.log("50 unmasked SSN:", unmaskedSSN);
	console.log("50 ssn:", ssn);

	const customClass = `${
		props.disabled
			? styles.ssnInput + " " + styles.disabledSsnInput
			: styles.ssnInput
	}`;

	return (
		<input
			className={customClass}
			type="text"
			value={isFocused ? unmaskedSSN : ssn}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur}
			maxLength={11}
			{...props}
		/>
	);
};

export default SSNInput;
