import { apiSlice } from "../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		pushDataToEncompass: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/push_ob_data/",
				method: "POST",
				body: credentials?.formData,
			}),
		}),
	}),
});

export const { usePushDataToEncompassMutation } = applicationApiSlice;
