import { store } from "../store";
import { setLockRequestLog } from "../../features/lock-request-log/lockRequestLogSlice";

export const getLockRequestLog = async ({
	email,
	loanNumber,
	apiRequest,
	handleErrorModal,
}) => {
	try {
		const lockRequestLogValue = await apiRequest({
			email,
			loan_number: loanNumber,
		}).unwrap();

		// save response in redux store
		store.dispatch(setLockRequestLog(lockRequestLogValue));
	} catch (err) {
		console.log("260 error:", err);
		handleErrorModal(err);
	}
};
