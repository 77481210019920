import { store } from "../store";
import { setPreliminaryConditions } from "../../features/preliminary-conditions/preliminarySlice";

export const getPreliminaryConditions = async ({
	loanGuid,
	apiRequest,
	handleErrorModal,
}) => {
	try {
		const preliminaryValue = await apiRequest({ loanGuid }).unwrap();

		// save response in redux store
		store.dispatch(setPreliminaryConditions(preliminaryValue?.data));
	} catch (err) {
		console.log("260 error:", err);
		handleErrorModal(err);
	}
};
