import { TableCell, TableRow } from "@mui/material";
import React from "react";
import Body1 from "../../typography/body-01";
import { DateTime } from "luxon";

const LockHistoryRow = ({ row }) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<Body1 text={row.broker} />
			</TableCell>
			<TableCell>
				<Body1
					text={DateTime.fromISO(row?.date_of_request, {
						zone: "America/New_York",
					}).toFormat("MM/dd/yyyy HH:mm a")}
				/>
			</TableCell>
		</TableRow>
	);
};

export default LockHistoryRow;
