import IMask from "imask";

export class XMask extends IMask.MaskedRegExp {
	constructor(_) {
		super(_);
		this.re = /\d/;
		this.x = "X";
	}

	/**
	 * Returns X
	 */
	get value() {
		return this._value === "" ? "" : this.x;
	}

	/**
	 * Return actual value
	 */
	get unmaskedValue() {
		return this._value;
	}

	/**
	 * @internal
	 */
	_update(_opts) {}

	/**
	 * Validates internal value
	 * @internal
	 */
	validate(_) {
		return this._value.search(this.re) >= 0;
	}

	/**
	 * Return actual value
	 * @internal
	 */
	extractInput() {
		return this._value;
	}
}
