import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	selectCurrentToken,
	selectRefreshToken,
	logOut,
	setUserDetails,
	setOrganizationName,
} from "./authSlice";
import { storageDelete, storageLoad } from "../../utils/localStorage";
import { useUserDetailsMutation } from "../user-details/userDetailsApiSlice";

const RequireAuth = () => {
	const [userDetails] = useUserDetailsMutation();
	const token = useSelector(selectCurrentToken);
	const refreshToken = useSelector(selectRefreshToken);
	const localAccessToken = storageLoad("access-token");
	const localRefreshToken = storageLoad("refresh-token");

	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		let time;
		const resetTimer = () => {
			clearTimeout(time);
			time = setTimeout(handleLogout, 35 * 60 * 1000);
		};
		const handleLogout = () => {
			dispatch(logOut());
			storageDelete("access-token");
			storageDelete("refresh-token");
		};
		// DOM Events
		window.addEventListener("mousemove", resetTimer);
		window.addEventListener("keydown", resetTimer);
		resetTimer();
		return () => {
			window.removeEventListener("mousemove", resetTimer);
			window.removeEventListener("keydown", resetTimer);
			clearTimeout(time);
		};
	});

	const getUserDetails = async () => {
		try {
			const userDetailData = await userDetails().unwrap();
			console.log("50 user details:", userDetailData?.data);

			dispatch(setUserDetails({ ...userDetailData?.data }));
			dispatch(setOrganizationName(userDetailData?.data?.organization_name));
		} catch (err) {
			console.log("54 error:", err);

			dispatch(logOut());
			storageDelete("access-token");
			storageDelete("refresh-token");
		}
	};

	useEffect(() => {
		if (token || refreshToken || localAccessToken || localRefreshToken) {
			console.log("60 getting user details");
			getUserDetails();
		}
	}, []);

	return token || refreshToken || localAccessToken || localRefreshToken ? (
		<Outlet />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};
export default RequireAuth;
