import { apiSlice } from "../../app/api/apiSlice";

export const LockRequestLogApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		lockRequestLog: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/loan/lock_request_log/",
				method: "POST",
				body: credentials,
			}),
		}),
	}),
});

export const { useLockRequestLogMutation } = LockRequestLogApiSlice;
