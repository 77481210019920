import { apiSlice } from "../../../app/api/apiSlice";

export const aePipelineSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAePipeline: builder.mutation({
			query: () => ({
				url: `/api/v1/admin/aeam_pipeline/AE/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetAePipelineMutation } = aePipelineSlice;
