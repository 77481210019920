import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import DatePicker from "react-date-picker";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import PrimaryButton from "../../buttons/primary-button";
import TertiaryButton from "../../buttons/tertiary-button";
import CustomDivider from "../../custom-divider";
import Heading3 from "../../typography/heading-03";
import Body1 from "../../typography/body-01";

// helper functions
import { filterValue } from "../../../utils/filter-value";

// reducer slice
import { selectFieldValues } from "../../../features/field-values/fieldValueSlice";

// api slices
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// custom styles
import styles from "./index.module.scss";

const ClosingInformationCard = ({
	handleTabChange,
	toggleDrawer,
	handleOpenLoadingDataModal,
	handleCloseLoadingDataModal,
	setLoadingDataText,
	handleOpenErrorModal,
	setErrorModalText,
}) => {
	const params = useParams();

	const [field] = useFieldMutation();
	const fieldValues = useSelector(selectFieldValues);

	console.log("50 field values:", fieldValues);

	const [fieldData, setFieldData] = useState([]);

	const [closingDateEnabled, setClosingDateEnabled] = useState(false);

	const handleErrorModal = (data) => {
		console.log("60 data:", data);

		handleCloseLoadingDataModal();
		handleOpenErrorModal();
		setErrorModalText("There was an error getting important dates");
	};

	const getFieldValues = async () => {
		console.log("75 loan id:", params?.id);
		handleOpenLoadingDataModal();

		try {
			const getValues = await field({
				loan_guid: params?.id,
				fields:
					"763,CX.REQUESTED.CLOSING.DATE,CX.SCHEDULED.CLOSING.DATE,CX.RESCHEDULED.CLOSING.DATE,CX.Closing.Docs.Sent.Date,CX.WIRE.REQUEST.DATE,748,411,416,88,417,412,413,1174,414,Log.MS.Stage",
			}).unwrap();

			console.log("75 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setFieldData(getValues?.response);
				handleCloseLoadingDataModal();
				setLoadingDataText("Setting up the lock info");
			}
		} catch (err) {
			console.log("270 error:", err);
			handleErrorModal(err);
		}
	};

	useEffect(() => {
		getFieldValues();
	}, []);

	useEffect(() => {
		if (fieldValues !== undefined && fieldValues?.length > 0) {
			console.log("90 field values:", fieldValues);
			setFieldData(fieldValues);

			handleCloseLoadingDataModal();
		}
	}, [fieldValues]);

	useEffect(() => {
		let enabledMilestones = [
			"Closing Prep",
			"Closing",
			"Funding Prep",
			"Funding",
			"Shipping",
			"Servicer Tape",
			"Servicer Doc",
			"Investor Sale",
			"Accounting",
			"PC Due Dilligence",
			"Completion",
		];

		const loanMilestone = filterValue(fieldData, "Log.MS.Stage");
		const enabledMilestoneMatch = enabledMilestones?.includes(loanMilestone);

		const closingDate = filterValue(fieldData, "CX.REQUESTED.CLOSING.DATE");

		console.log(
			"115 loan milestone:",
			loanMilestone,
			enabledMilestoneMatch,
			closingDate,
			closingDate !== ""
		);

		setClosingDateEnabled(
			enabledMilestoneMatch ? closingDate !== "" : !enabledMilestoneMatch
		);
	}, [fieldData]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Closing Dates Information"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
				</Stack>
			</Stack>

			<Stack direction="column" className={styles.bottomContainer}>
				{/* Estimated Closing Date */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Estimated Closing Date:"
							extraClass={styles.labelText}
						/>
					</Box>
					<Box className={styles.infoDetailValue}>
						<DatePicker
							className={styles.datepicker + " " + styles.disabledDatepicker}
							format="MM-dd-yyyy"
							value={filterValue(fieldData, "763") || ""}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Requested Closing Date */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Requested Closing Date:"
							extraClass={styles.labelText}
						/>
					</Box>

					<Stack direction="row" className={styles.infoDetailValue}>
						<DatePicker
							className={styles.datepicker + " " + styles.disabledDatepicker}
							format="MM-dd-yyyy"
							value={filterValue(fieldData, "CX.REQUESTED.CLOSING.DATE") || ""}
							disabled={true}
						/>

						<PrimaryButton
							text="Request a Closing Date"
							onClick={(e) => toggleDrawer(e, "request-closing-date")}
							disabled={closingDateEnabled}
						/>

						<Body1
							text="Closing Date may be requested once loan has reached Clear-to-Close status"
							extraClass={styles.valueText}
						/>
					</Stack>
				</Stack>

				{/* Scheduled Closing Date */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Scheduled Closing Date:"
							extraClass={styles.labelText}
						/>
					</Box>
					<Box className={styles.infoDetailValue}>
						<DatePicker
							className={styles.datepicker + " " + styles.disabledDatepicker}
							format="MM-dd-yyyy"
							value={filterValue(fieldData, "CX.SCHEDULED.CLOSING.DATE") || ""}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Rescheduled Closing Date */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Rescheduled Closing Date:"
							extraClass={styles.labelText}
						/>
					</Box>
					<Box className={styles.infoDetailValue}>
						<DatePicker
							className={styles.datepicker + " " + styles.disabledDatepicker}
							format="MM-dd-yyyy"
							value={
								filterValue(fieldData, "CX.RESCHEDULED.CLOSING.DATE") || ""
							}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Document Sent Date */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1 text="Document Sent Date:" extraClass={styles.labelText} />
					</Box>
					<Box className={styles.infoDetailValue}>
						<DatePicker
							className={styles.datepicker + " " + styles.disabledDatepicker}
							format="MM-dd-yyyy"
							value={
								filterValue(fieldData, "CX.CLOSING.DOCS.SENT.DATE")
									? DateTime?.fromFormat(
											filterValue(fieldData, "CX.CLOSING.DOCS.SENT.DATE"),
											"yyyy/MM/dd HH:mm:ss"
									  )?.toFormat("L/d/yyyy")
									: ""
							}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Wire Requested Date */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1 text="Wire Requested Date:" extraClass={styles.labelText} />
					</Box>
					<Box className={styles.infoDetailValue}>
						<DatePicker
							className={styles.datepicker + " " + styles.disabledDatepicker}
							format="MM-dd-yyyy"
							value={filterValue(fieldData, "CX.WIRE.REQUEST.DATE") || ""}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Closing Date */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1 text="Closing Date:" extraClass={styles.labelText} />
					</Box>
					<Box className={styles.infoDetailValue}>
						<DatePicker
							className={styles.datepicker + " " + styles.disabledDatepicker}
							format="MM-dd-yyyy"
							value={filterValue(fieldData, "748") || ""}
							disabled={true}
						/>
					</Box>
				</Stack>
			</Stack>

			<CustomDivider />

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Title Company Information"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>
			</Stack>

			<Stack direction="column" className={styles.bottomContainer}>
				{/* Title Company Name */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer + " " + styles.titleContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1 text="Title Company Name:" extraClass={styles.labelText} />
					</Box>

					<Box
						className={styles.infoDetailValue + " " + styles.fullWidthContainer}
					>
						<OutlinedTextInput
							fullWidth={true}
							value={filterValue(fieldData, "411")}
							handleChange={(event) => console.log(event.target.value)}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Title Company Officer Name */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer + " " + styles.titleContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Title Company Officer Name:"
							extraClass={styles.labelText}
						/>
					</Box>

					<Box
						className={styles.infoDetailValue + " " + styles.fullWidthContainer}
					>
						<OutlinedTextInput
							fullWidth={true}
							value={filterValue(fieldData, "416")}
							handleChange={(event) => console.log(event.target.value)}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Title Company Officer Email */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer + " " + styles.titleContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Title Company Officer Email:"
							extraClass={styles.labelText}
						/>
					</Box>

					<Box
						className={styles.infoDetailValue + " " + styles.fullWidthContainer}
					>
						<OutlinedTextInput
							fullWidth={true}
							value={filterValue(fieldData, "88")}
							handleChange={(event) => console.log(event.target.value)}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Title Company Officer Phone */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer + " " + styles.titleContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Title Company Officer Phone:"
							extraClass={styles.labelText}
						/>
					</Box>

					<Box
						className={styles.infoDetailValue + " " + styles.fullWidthContainer}
					>
						<OutlinedTextInput
							fullWidth={true}
							value={filterValue(fieldData, "417")}
							handleChange={(event) => console.log(event.target.value)}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Title Company Address */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer + " " + styles.titleContainer}
				>
					<Box className={styles.infoDetailTitle}>
						<Body1
							text="Title Company Address:"
							extraClass={styles.labelText}
						/>
					</Box>

					<Box
						className={styles.infoDetailValue + " " + styles.fullWidthContainer}
					>
						<OutlinedTextInput
							fullWidth={true}
							value={filterValue(fieldData, "412")}
							handleChange={(event) => console.log(event.target.value)}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Title Company City */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer + " " + styles.titleContainer}
				>
					<Box className={styles.infoDetailTitle}></Box>

					<Box
						className={styles.infoDetailValue + " " + styles.fullWidthContainer}
					>
						<OutlinedTextInput
							fullWidth={true}
							value={filterValue(fieldData, "413")}
							handleChange={(event) => console.log(event.target.value)}
							disabled={true}
						/>
					</Box>
				</Stack>

				{/* Title Company State */}
				<Stack
					direction="row"
					alignItems="center"
					className={styles.infoDetailContainer + " " + styles.titleContainer}
				>
					<Box className={styles.infoDetailTitle}></Box>

					<Stack
						direction="row"
						gap={3}
						flexWrap="wrap"
						className={styles.fullWidthContainer}
					>
						<Box
							className={styles.infoDetailValue + " " + styles.inlineContainer}
						>
							<OutlinedTextInput
								fullWidth={true}
								value={filterValue(fieldData, "1174")}
								handleChange={(event) => console.log(event.target.value)}
								disabled={true}
							/>
						</Box>

						<Box
							className={styles.infoDetailValue + " " + styles.inlineContainer}
						>
							<OutlinedTextInput
								fullWidth={true}
								value={filterValue(fieldData, "414")}
								handleChange={(event) => console.log(event.target.value)}
								disabled={true}
							/>
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ClosingInformationCard;
