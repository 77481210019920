import React from "react";

// mui components
import { TableCell, TableRow } from "@mui/material";

// custom components
import Body3 from "../../typography/body-03";

// helper utils
import { formatCurrency } from "../../../utils/format-currency";

// custom styles
import styles from "../index.module.scss";

const EligibleProductPaymentsRow = ({ row }) => {
	return (
		<TableRow hover tabIndex={-1}>
			<TableCell>
				<Body3 text={row?.label} extraClass={styles.greenText} />
			</TableCell>
			<TableCell align="right">
				<Body3
					text={formatCurrency.format(Number(row?.value) || 0)}
					extraClass={styles.greenText}
				/>
			</TableCell>
		</TableRow>
	);
};

export default EligibleProductPaymentsRow;
