import React, { useEffect, useState } from "react";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// custom components
import TabPanel from "../../tab-panel";
import RequiredError from "../../required-error";
import DeclarationInfo from "../../loan-form-card/declaration-information";
import MilitaryInfo from "../../loan-form-card/military-information";
import DemographicInfo from "../../loan-form-card/demographic-information";
import DemographicProvidedInfo from "../../loan-form-card/demographic-provided-information";
import ViewLoanInfoCard from "../../../pages/view-loan/components/info-card";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading6 from "../../typography/heading-06";

// api slice
import { useGetBorrowerPairMutation } from "../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useUpdateBorrowerPairMutation } from "../../../features/update-borrower-pair/updateBorrowerPairApiSlice";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// custom modal
import LoaderModal from "../../modal/loader-modal";

// helper utils
import { validateDeclarationInformation } from "../../../utils/required-field-helper/declaration-section-required-helper";
import { validateMilitaryInformation } from "../../../utils/required-field-helper/military-info-required-helper";
import { validateDemographicProvided } from "../../../utils/required-field-helper/demographic-provided-info-required-helper";
import { validateDemographicInformation } from "../../../utils/required-field-helper/demographic-info-required-helper";

// custom styles
import styles from "./index.module.scss";

export const LoanDemographicApplication = ({
	setLoanApplicationStage,
	loanGuid,
	currentStage,
	viewLoan,
}) => {
	console.log("45 loan id:", loanGuid);

	const [getBorrowerPair, { isLoading: loadingBorrowerPairData }] =
		useGetBorrowerPairMutation();

	const [updateBorrowerPair, { isLoading: updatingBorrowerPair }] =
		useUpdateBorrowerPairMutation();

	const [borrowerResponseData, setBorrowerResponseData] = useState([]);
	const [borrowerPairData, setBorrowerPairData] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const [borrowerDeclaration, setBorrowerDeclaration] = useState([]);
	const [borrowerMilitary, setBorrowerMilitary] = useState([]);
	const [borrowerDemographicProvided, setBorrowerDemographicProvided] =
		useState([]);
	const [borrowerDemographicInfo, setBorrowerDemographicInfo] = useState([]);

	const getAllBorrowerPair = async () => {
		const borrowerPairData = await getBorrowerPair({
			loanGuid,
		}).unwrap();

		console.log("90 borrower data:", borrowerPairData?.response);

		let declarationArr = [];
		let militaryArr = [];
		let demographicProvidedArr = [];
		let demographicInfoArr = [];

		for (let index = 0; index < borrowerPairData?.response?.length; index++) {
			console.log("100 res data:", borrowerPairData?.response[index]);

			if (
				Object.keys(borrowerPairData?.response[index]?.borrower).length > 0 &&
				Object.keys(borrowerPairData?.response[index]?.coborrower).length > 0
			) {
				// both borrower and co-borrower
				militaryArr.push(
					{
						militaryServiceExpectedCompletionDate:
							borrowerPairData?.response[index]?.borrower
								?.militaryServiceExpectedCompletionDate,
						SelfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.borrower
								?.SelfDeclaredMilitaryServiceIndicator,
						selfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.borrower
								?.selfDeclaredMilitaryServiceIndicator,
						activeDuty: borrowerPairData?.response[index]?.borrower?.activeDuty,
						veteran: borrowerPairData?.response[index]?.borrower?.veteran,
						reserveNationalGuardReserveActivated:
							borrowerPairData?.response[index]?.borrower
								?.reserveNationalGuardReserveActivated,
						spousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.borrower
								?.spousalVABenefitsEligibilityIndicator,
						SpousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.borrower
								?.SpousalVABenefitsEligibilityIndicator,
					},
					{
						militaryServiceExpectedCompletionDate:
							borrowerPairData?.response[index]?.coborrower
								?.militaryServiceExpectedCompletionDate,
						SelfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.SelfDeclaredMilitaryServiceIndicator,
						selfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.selfDeclaredMilitaryServiceIndicator,
						activeDuty:
							borrowerPairData?.response[index]?.coborrower?.activeDuty,
						veteran: borrowerPairData?.response[index]?.coborrower?.veteran,
						reserveNationalGuardReserveActivated:
							borrowerPairData?.response[index]?.coborrower
								?.reserveNationalGuardReserveActivated,
						spousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.spousalVABenefitsEligibilityIndicator,
						SpousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.SpousalVABenefitsEligibilityIndicator,
					}
				);
				demographicProvidedArr.push(
					// borrower
					{
						applicationTakenMethodType:
							borrowerPairData?.response[index]?.borrower
								?.applicationTakenMethodType,
						isEthnicityBasedOnVisual:
							borrowerPairData?.response[index]?.borrower
								?.isEthnicityBasedOnVisual,
						isRaceBasedOnVisual:
							borrowerPairData?.response[index]?.borrower?.isRaceBasedOnVisual,
						isSexBasedOnVisual:
							borrowerPairData?.response[index]?.borrower?.isSexBasedOnVisual,
					},
					// coborrower
					{
						applicationTakenMethodType:
							borrowerPairData?.response[index]?.coborrower
								?.applicationTakenMethodType,
						isEthnicityBasedOnVisual:
							borrowerPairData?.response[index]?.coborrower
								?.isEthnicityBasedOnVisual,
						isRaceBasedOnVisual:
							borrowerPairData?.response[index]?.coborrower
								?.isRaceBasedOnVisual,
						isSexBasedOnVisual:
							borrowerPairData?.response[index]?.coborrower?.isSexBasedOnVisual,
					}
				);
				demographicInfoArr.push(
					// borrower
					{
						hmdaEthnicityHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaEthnicityHispanicLatinoIndicator,
						hmdaEthnicityNotHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaEthnicityNotHispanicLatinoIndicator,
						hmdaMexicanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaMexicanIndicator,
						hmdaPuertoRicanIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaPuertoRicanIndicator,
						hmdaCubanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaCubanIndicator,
						hmdaHispanicLatinoOtherOriginIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaHispanicLatinoOtherOriginIndicator,
						hmdaOtherHispanicLatinoOrigin:
							borrowerPairData?.response[index]?.borrower
								?.hmdaOtherHispanicLatinoOrigin,
						hmdaEthnicityDoNotWishIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaEthnicityDoNotWishIndicator,
						hmdaAmericanIndianIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAmericanIndianIndicator,
						hmdaRaceDoNotWishProvideIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaRaceDoNotWishProvideIndicator,
						hmdaAmericanIndianTribe:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAmericanIndianTribe,
						americanIndianOtherValue:
							borrowerPairData?.response[index]?.borrower
								?.americanIndianOtherValue,
						hmdaAsianIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaAsianIndicator,
						hmdaAsianIndianIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAsianIndianIndicator,
						hmdaChineseIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaChineseIndicator,
						hmdaFilipinoIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaFilipinoIndicator,
						hmdaJapaneseIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaJapaneseIndicator,
						hmdaKoreanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaKoreanIndicator,
						hmdaVietnameseIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaVietnameseIndicator,
						hmdaAsianOtherRaceIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAsianOtherRaceIndicator,
						hmdaOtherAsianRace:
							borrowerPairData?.response[index]?.borrower?.hmdaOtherAsianRace,
						hmdaAfricanAmericanIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAfricanAmericanIndicator,
						hmdaPacificIslanderIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaPacificIslanderIndicator,
						hmdaNativeHawaiianIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaNativeHawaiianIndicator,
						hmdaGuamanianOrChamorroIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGuamanianOrChamorroIndicator,
						hmdaSamoanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaSamoanIndicator,
						hmdaPacificIslanderOtherIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaPacificIslanderOtherIndicator,
						hmdaOtherPacificIslanderRace:
							borrowerPairData?.response[index]?.borrower
								?.hmdaOtherPacificIslanderRace,
						hmdaWhiteIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaWhiteIndicator,
						hmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGendertypeFemaleIndicator,
						HmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.HmdaGendertypeFemaleIndicator,
						hmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGendertypeMaleIndicator,
						HmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.HmdaGendertypeMaleIndicator,
						hmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGendertypeDoNotWishIndicator,
						HmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.borrower
								?.HmdaGendertypeDoNotWishIndicator,
					},
					// coborrower
					{
						hmdaEthnicityHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaEthnicityHispanicLatinoIndicator,
						hmdaEthnicityNotHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaEthnicityNotHispanicLatinoIndicator,
						hmdaMexicanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaMexicanIndicator,
						hmdaPuertoRicanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaPuertoRicanIndicator,
						hmdaCubanIndicator:
							borrowerPairData?.response[index]?.coborrower?.hmdaCubanIndicator,
						hmdaHispanicLatinoOtherOriginIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaHispanicLatinoOtherOriginIndicator,
						hmdaOtherHispanicLatinoOrigin:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaOtherHispanicLatinoOrigin,
						hmdaEthnicityDoNotWishIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaEthnicityDoNotWishIndicator,
						hmdaAmericanIndianIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAmericanIndianIndicator,
						hmdaRaceDoNotWishProvideIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaRaceDoNotWishProvideIndicator,
						hmdaAmericanIndianTribe:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAmericanIndianTribe,
						americanIndianOtherValue:
							borrowerPairData?.response[index]?.coborrower
								?.americanIndianOtherValue,
						hmdaAsianIndicator:
							borrowerPairData?.response[index]?.coborrower?.hmdaAsianIndicator,
						hmdaAsianIndianIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAsianIndianIndicator,
						hmdaChineseIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaChineseIndicator,
						hmdaFilipinoIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaFilipinoIndicator,
						hmdaJapaneseIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaJapaneseIndicator,
						hmdaKoreanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaKoreanIndicator,
						hmdaVietnameseIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaVietnameseIndicator,
						hmdaAsianOtherRaceIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAsianOtherRaceIndicator,
						hmdaOtherAsianRace:
							borrowerPairData?.response[index]?.coborrower?.hmdaOtherAsianRace,
						hmdaAfricanAmericanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAfricanAmericanIndicator,
						hmdaPacificIslanderIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaPacificIslanderIndicator,
						hmdaNativeHawaiianIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaNativeHawaiianIndicator,
						hmdaGuamanianOrChamorroIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGuamanianOrChamorroIndicator,
						hmdaSamoanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaSamoanIndicator,
						hmdaPacificIslanderOtherIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaPacificIslanderOtherIndicator,
						hmdaOtherPacificIslanderRace:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaOtherPacificIslanderRace,
						hmdaWhiteIndicator:
							borrowerPairData?.response[index]?.coborrower?.hmdaWhiteIndicator,
						hmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGendertypeFemaleIndicator,
						HmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.HmdaGendertypeFemaleIndicator,
						hmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGendertypeMaleIndicator,
						HmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.HmdaGendertypeMaleIndicator,
						hmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGendertypeDoNotWishIndicator,
						HmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.HmdaGendertypeDoNotWishIndicator,
					}
				);
				declarationArr.push(
					{
						undisclosedBorrowedFundsAmount:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedBorrowedFundsAmount,
						intentToOccupyIndicator:
							borrowerPairData?.response[index]?.borrower
								?.intentToOccupyIndicator,
						homeownerPastThreeYearsIndicator:
							borrowerPairData?.response[index]?.borrower
								?.homeownerPastThreeYearsIndicator,
						priorPropertyUsageType:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyUsageType,
						priorPropertyTitleType:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyTitleType,
						specialBorrowerSellerRelationshipIndicator:
							borrowerPairData?.response[index]?.borrower
								?.specialBorrowerSellerRelationshipIndicator,
						undisclosedBorrowedFundsIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedBorrowedFundsIndicator,
						undisclosedMortgageApplicationIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedMortgageApplicationIndicator,
						undisclosedCreditApplicationIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedCreditApplicationIndicator,
						propertyProposedCleanEnergyLienIndicator:
							borrowerPairData?.response[index]?.borrower
								?.propertyProposedCleanEnergyLienIndicator,
						undisclosedComakerOfNoteIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedComakerOfNoteIndicator,
						outstandingJudgementsIndicator:
							borrowerPairData?.response[index]?.borrower
								?.outstandingJudgementsIndicator,
						presentlyDelinquentIndicatorURLA:
							borrowerPairData?.response[index]?.borrower
								?.presentlyDelinquentIndicatorURLA,
						partyToLawsuitIndicatorURLA:
							borrowerPairData?.response[index]?.borrower
								?.partyToLawsuitIndicatorURLA,
						priorPropertyDeedInLieuConveyedIndicator:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyDeedInLieuConveyedIndicator,
						priorPropertyShortSaleCompletedIndicator:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyShortSaleCompletedIndicator,
						priorPropertyForeclosureCompletedIndicator:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyForeclosureCompletedIndicator,
						bankruptcyIndicator:
							borrowerPairData?.response[index]?.borrower?.bankruptcyIndicator,
						bankruptcyIndicatorChapterSeven:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterSeven,
						bankruptcyIndicatorChapterEleven:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterEleven,
						bankruptcyIndicatorChapterTwelve:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterTwelve,
						bankruptcyIndicatorChapterThirteen:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterThirteen,
					},
					// coborrower
					{
						undisclosedBorrowedFundsAmount:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedBorrowedFundsAmount,
						intentToOccupyIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.intentToOccupyIndicator,
						homeownerPastThreeYearsIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.homeownerPastThreeYearsIndicator,
						priorPropertyUsageType:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyUsageType,
						priorPropertyTitleType:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyTitleType,
						specialBorrowerSellerRelationshipIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.specialBorrowerSellerRelationshipIndicator,
						undisclosedBorrowedFundsIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedBorrowedFundsIndicator,
						undisclosedMortgageApplicationIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedMortgageApplicationIndicator,
						undisclosedCreditApplicationIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedCreditApplicationIndicator,
						propertyProposedCleanEnergyLienIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.propertyProposedCleanEnergyLienIndicator,
						undisclosedComakerOfNoteIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedComakerOfNoteIndicator,
						outstandingJudgementsIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.outstandingJudgementsIndicator,
						presentlyDelinquentIndicatorURLA:
							borrowerPairData?.response[index]?.coborrower
								?.presentlyDelinquentIndicatorURLA,
						partyToLawsuitIndicatorURLA:
							borrowerPairData?.response[index]?.coborrower
								?.partyToLawsuitIndicatorURLA,
						priorPropertyDeedInLieuConveyedIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyDeedInLieuConveyedIndicator,
						priorPropertyShortSaleCompletedIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyShortSaleCompletedIndicator,
						priorPropertyForeclosureCompletedIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyForeclosureCompletedIndicator,
						bankruptcyIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicator,
						bankruptcyIndicatorChapterSeven:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterSeven,
						bankruptcyIndicatorChapterEleven:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterEleven,
						bankruptcyIndicatorChapterTwelve:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterTwelve,
						bankruptcyIndicatorChapterThirteen:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterThirteen,
					}
				);
			} else if (
				Object.keys(borrowerPairData?.response[index]?.coborrower).length <= 0
			) {
				// only borrower, no co-borrower
				militaryArr.push(
					{
						militaryServiceExpectedCompletionDate:
							borrowerPairData?.response[index]?.borrower
								?.militaryServiceExpectedCompletionDate,
						SelfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.borrower
								?.SelfDeclaredMilitaryServiceIndicator,
						selfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.borrower
								?.selfDeclaredMilitaryServiceIndicator,
						activeDuty: borrowerPairData?.response[index]?.borrower?.activeDuty,
						veteran: borrowerPairData?.response[index]?.borrower?.veteran,
						reserveNationalGuardReserveActivated:
							borrowerPairData?.response[index]?.borrower
								?.reserveNationalGuardReserveActivated,
						spousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.borrower
								?.spousalVABenefitsEligibilityIndicator,
						SpousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.borrower
								?.SpousalVABenefitsEligibilityIndicator,
					},
					{}
				);
				demographicProvidedArr.push(
					{
						applicationTakenMethodType:
							borrowerPairData?.response[index]?.borrower
								?.applicationTakenMethodType,
						isEthnicityBasedOnVisual:
							borrowerPairData?.response[index]?.borrower
								?.isEthnicityBasedOnVisual,
						isRaceBasedOnVisual:
							borrowerPairData?.response[index]?.borrower?.isRaceBasedOnVisual,
						isSexBasedOnVisual:
							borrowerPairData?.response[index]?.borrower?.isSexBasedOnVisual,
					},
					{}
				);
				demographicInfoArr.push(
					{
						hmdaEthnicityHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaEthnicityHispanicLatinoIndicator,
						hmdaEthnicityNotHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaEthnicityNotHispanicLatinoIndicator,
						hmdaMexicanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaMexicanIndicator,
						hmdaPuertoRicanIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaPuertoRicanIndicator,
						hmdaCubanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaCubanIndicator,
						hmdaHispanicLatinoOtherOriginIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaHispanicLatinoOtherOriginIndicator,
						hmdaOtherHispanicLatinoOrigin:
							borrowerPairData?.response[index]?.borrower
								?.hmdaOtherHispanicLatinoOrigin,
						hmdaEthnicityDoNotWishIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaEthnicityDoNotWishIndicator,
						hmdaAmericanIndianIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAmericanIndianIndicator,
						hmdaRaceDoNotWishProvideIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaRaceDoNotWishProvideIndicator,
						hmdaAmericanIndianTribe:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAmericanIndianTribe,
						americanIndianOtherValue:
							borrowerPairData?.response[index]?.borrower
								?.americanIndianOtherValue,
						hmdaAsianIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaAsianIndicator,
						hmdaAsianIndianIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAsianIndianIndicator,
						hmdaChineseIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaChineseIndicator,
						hmdaFilipinoIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaFilipinoIndicator,
						hmdaJapaneseIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaJapaneseIndicator,
						hmdaKoreanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaKoreanIndicator,
						hmdaVietnameseIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaVietnameseIndicator,
						hmdaAsianOtherRaceIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAsianOtherRaceIndicator,
						hmdaOtherAsianRace:
							borrowerPairData?.response[index]?.borrower?.hmdaOtherAsianRace,
						hmdaAfricanAmericanIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaAfricanAmericanIndicator,
						hmdaPacificIslanderIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaPacificIslanderIndicator,
						hmdaNativeHawaiianIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaNativeHawaiianIndicator,
						hmdaGuamanianOrChamorroIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGuamanianOrChamorroIndicator,
						hmdaSamoanIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaSamoanIndicator,
						hmdaPacificIslanderOtherIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaPacificIslanderOtherIndicator,
						hmdaOtherPacificIslanderRace:
							borrowerPairData?.response[index]?.borrower
								?.hmdaOtherPacificIslanderRace,
						hmdaWhiteIndicator:
							borrowerPairData?.response[index]?.borrower?.hmdaWhiteIndicator,
						hmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGendertypeFemaleIndicator,
						HmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.HmdaGendertypeFemaleIndicator,
						hmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGendertypeMaleIndicator,
						HmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.borrower
								?.HmdaGendertypeMaleIndicator,
						hmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.borrower
								?.hmdaGendertypeDoNotWishIndicator,
						HmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.borrower
								?.HmdaGendertypeDoNotWishIndicator,
					},
					{}
				);
				declarationArr.push(
					{
						undisclosedBorrowedFundsAmount:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedBorrowedFundsAmount,
						intentToOccupyIndicator:
							borrowerPairData?.response[index]?.borrower
								?.intentToOccupyIndicator,
						homeownerPastThreeYearsIndicator:
							borrowerPairData?.response[index]?.borrower
								?.homeownerPastThreeYearsIndicator,
						priorPropertyUsageType:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyUsageType,
						priorPropertyTitleType:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyTitleType,
						specialBorrowerSellerRelationshipIndicator:
							borrowerPairData?.response[index]?.borrower
								?.specialBorrowerSellerRelationshipIndicator,
						undisclosedBorrowedFundsIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedBorrowedFundsIndicator,
						undisclosedMortgageApplicationIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedMortgageApplicationIndicator,
						undisclosedCreditApplicationIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedCreditApplicationIndicator,
						propertyProposedCleanEnergyLienIndicator:
							borrowerPairData?.response[index]?.borrower
								?.propertyProposedCleanEnergyLienIndicator,
						undisclosedComakerOfNoteIndicator:
							borrowerPairData?.response[index]?.borrower
								?.undisclosedComakerOfNoteIndicator,
						outstandingJudgementsIndicator:
							borrowerPairData?.response[index]?.borrower
								?.outstandingJudgementsIndicator,
						presentlyDelinquentIndicatorURLA:
							borrowerPairData?.response[index]?.borrower
								?.presentlyDelinquentIndicatorURLA,
						partyToLawsuitIndicatorURLA:
							borrowerPairData?.response[index]?.borrower
								?.partyToLawsuitIndicatorURLA,
						priorPropertyDeedInLieuConveyedIndicator:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyDeedInLieuConveyedIndicator,
						priorPropertyShortSaleCompletedIndicator:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyShortSaleCompletedIndicator,
						priorPropertyForeclosureCompletedIndicator:
							borrowerPairData?.response[index]?.borrower
								?.priorPropertyForeclosureCompletedIndicator,
						bankruptcyIndicator:
							borrowerPairData?.response[index]?.borrower?.bankruptcyIndicator,
						bankruptcyIndicatorChapterSeven:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterSeven,
						bankruptcyIndicatorChapterEleven:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterEleven,
						bankruptcyIndicatorChapterTwelve:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterTwelve,
						bankruptcyIndicatorChapterThirteen:
							borrowerPairData?.response[index]?.borrower
								?.bankruptcyIndicatorChapterThirteen,
					},
					{}
				);
			} else if (
				Object.keys(borrowerPairData?.response[index]?.borrower).length <= 0
			) {
				// no borrower, only co-borrower
				militaryArr.push(
					{},
					{
						militaryServiceExpectedCompletionDate:
							borrowerPairData?.response[index]?.coborrower
								?.militaryServiceExpectedCompletionDate,
						SelfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.SelfDeclaredMilitaryServiceIndicator,
						selfDeclaredMilitaryServiceIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.selfDeclaredMilitaryServiceIndicator,
						activeDuty:
							borrowerPairData?.response[index]?.coborrower?.activeDuty,
						veteran: borrowerPairData?.response[index]?.coborrower?.veteran,
						reserveNationalGuardReserveActivated:
							borrowerPairData?.response[index]?.coborrower
								?.reserveNationalGuardReserveActivated,
						spousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.spousalVABenefitsEligibilityIndicator,
						SpousalVABenefitsEligibilityIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.SpousalVABenefitsEligibilityIndicator,
					}
				);
				demographicProvidedArr.push(
					{},
					{
						applicationTakenMethodType:
							borrowerPairData?.response[index]?.coborrower
								?.applicationTakenMethodType,
						isEthnicityBasedOnVisual:
							borrowerPairData?.response[index]?.coborrower
								?.isEthnicityBasedOnVisual,
						isRaceBasedOnVisual:
							borrowerPairData?.response[index]?.coborrower
								?.isRaceBasedOnVisual,
						isSexBasedOnVisual:
							borrowerPairData?.response[index]?.coborrower?.isSexBasedOnVisual,
					}
				);
				demographicInfoArr.push(
					{},
					{
						hmdaEthnicityHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaEthnicityHispanicLatinoIndicator,
						hmdaEthnicityNotHispanicLatinoIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaEthnicityNotHispanicLatinoIndicator,
						hmdaMexicanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaMexicanIndicator,
						hmdaPuertoRicanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaPuertoRicanIndicator,
						hmdaCubanIndicator:
							borrowerPairData?.response[index]?.coborrower?.hmdaCubanIndicator,
						hmdaHispanicLatinoOtherOriginIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaHispanicLatinoOtherOriginIndicator,
						hmdaOtherHispanicLatinoOrigin:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaOtherHispanicLatinoOrigin,
						hmdaEthnicityDoNotWishIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaEthnicityDoNotWishIndicator,
						hmdaAmericanIndianIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAmericanIndianIndicator,
						hmdaRaceDoNotWishProvideIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaRaceDoNotWishProvideIndicator,
						hmdaAmericanIndianTribe:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAmericanIndianTribe,
						americanIndianOtherValue:
							borrowerPairData?.response[index]?.coborrower
								?.americanIndianOtherValue,
						hmdaAsianIndicator:
							borrowerPairData?.response[index]?.coborrower?.hmdaAsianIndicator,
						hmdaAsianIndianIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAsianIndianIndicator,
						hmdaChineseIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaChineseIndicator,
						hmdaFilipinoIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaFilipinoIndicator,
						hmdaJapaneseIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaJapaneseIndicator,
						hmdaKoreanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaKoreanIndicator,
						hmdaVietnameseIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaVietnameseIndicator,
						hmdaAsianOtherRaceIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAsianOtherRaceIndicator,
						hmdaOtherAsianRace:
							borrowerPairData?.response[index]?.coborrower?.hmdaOtherAsianRace,
						hmdaAfricanAmericanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaAfricanAmericanIndicator,
						hmdaPacificIslanderIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaPacificIslanderIndicator,
						hmdaNativeHawaiianIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaNativeHawaiianIndicator,
						hmdaGuamanianOrChamorroIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGuamanianOrChamorroIndicator,
						hmdaSamoanIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaSamoanIndicator,
						hmdaPacificIslanderOtherIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaPacificIslanderOtherIndicator,
						hmdaOtherPacificIslanderRace:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaOtherPacificIslanderRace,
						hmdaWhiteIndicator:
							borrowerPairData?.response[index]?.coborrower?.hmdaWhiteIndicator,
						hmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGendertypeFemaleIndicator,
						HmdaGendertypeFemaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.HmdaGendertypeFemaleIndicator,
						hmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGendertypeMaleIndicator,
						HmdaGendertypeMaleIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.HmdaGendertypeMaleIndicator,
						hmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.hmdaGendertypeDoNotWishIndicator,
						HmdaGendertypeDoNotWishIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.HmdaGendertypeDoNotWishIndicator,
					}
				);
				declarationArr.push(
					{},
					{
						undisclosedBorrowedFundsAmount:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedBorrowedFundsAmount,
						intentToOccupyIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.intentToOccupyIndicator,
						homeownerPastThreeYearsIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.homeownerPastThreeYearsIndicator,
						priorPropertyUsageType:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyUsageType,
						priorPropertyTitleType:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyTitleType,
						specialBorrowerSellerRelationshipIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.specialBorrowerSellerRelationshipIndicator,
						undisclosedBorrowedFundsIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedBorrowedFundsIndicator,
						undisclosedMortgageApplicationIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedMortgageApplicationIndicator,
						undisclosedCreditApplicationIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedCreditApplicationIndicator,
						propertyProposedCleanEnergyLienIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.propertyProposedCleanEnergyLienIndicator,
						undisclosedComakerOfNoteIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.undisclosedComakerOfNoteIndicator,
						outstandingJudgementsIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.outstandingJudgementsIndicator,
						presentlyDelinquentIndicatorURLA:
							borrowerPairData?.response[index]?.coborrower
								?.presentlyDelinquentIndicatorURLA,
						partyToLawsuitIndicatorURLA:
							borrowerPairData?.response[index]?.coborrower
								?.partyToLawsuitIndicatorURLA,
						priorPropertyDeedInLieuConveyedIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyDeedInLieuConveyedIndicator,
						priorPropertyShortSaleCompletedIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyShortSaleCompletedIndicator,
						priorPropertyForeclosureCompletedIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.priorPropertyForeclosureCompletedIndicator,
						bankruptcyIndicator:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicator,
						bankruptcyIndicatorChapterSeven:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterSeven,
						bankruptcyIndicatorChapterEleven:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterEleven,
						bankruptcyIndicatorChapterTwelve:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterTwelve,
						bankruptcyIndicatorChapterThirteen:
							borrowerPairData?.response[index]?.coborrower
								?.bankruptcyIndicatorChapterThirteen,
					}
				);
			} else {
				// no borrower, no co-borrower
				militaryArr.push({}, {});
				demographicProvidedArr.push({}, {});
				demographicInfoArr.push({}, {});
				declarationArr.push({}, {});
			}
		}

		setBorrowerDeclaration(declarationArr);
		setBorrowerMilitary(militaryArr);
		setBorrowerDemographicProvided(demographicProvidedArr);
		setBorrowerDemographicInfo(demographicInfoArr);

		setBorrowerResponseData(borrowerPairData?.response);

		let pairArr = [];

		borrowerPairData?.response?.map((item) =>
			pairArr.push(item.borrower, item.coborrower)
		);

		console.log("135 pair arr:", pairArr);

		setBorrowerPairData(pairArr);
	};

	useEffect(() => {
		console.log("160 loan Guid:", loanGuid);
		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	console.log("200 declaration arr:", borrowerDeclaration);
	console.log("200 military arr:", borrowerMilitary);
	console.log("200 demographicProvided arr:", borrowerDemographicProvided);
	console.log("200 demographicInfo arr:", borrowerDemographicInfo);

	const [error, setError] = useState("");

	const updateBorrowerPairFunc = async (index) => {
		console.log("345 index:", index);
		console.log("345 res data:", borrowerResponseData[index]);

		console.log("695 borrowerDeclaration:", borrowerDeclaration);

		let borrowerDataIndex =
			index === 0 ? 0 : index === 1 ? 2 : index === 2 && 4;

		let coBorrowerDataIndex =
			index === 0 ? 1 : index === 1 ? 3 : index === 2 && 5;

		console.log("700 borrower data:", borrowerPairData[coBorrowerDataIndex]);

		console.log(
			"700 borrowerDeclaration at index:",
			borrowerDeclaration[coBorrowerDataIndex]
		);

		console.log(
			"700 borrower Military:",
			borrowerMilitary[coBorrowerDataIndex]
		);

		console.log(
			"700 borrower DemographicProvided:",
			borrowerDemographicProvided[coBorrowerDataIndex]
		);

		console.log(
			"700 borrower DemographicInfo:",
			borrowerDemographicInfo[coBorrowerDataIndex]
		);

		let borrowerData = {
			...borrowerPairData[borrowerDataIndex],
			...borrowerDeclaration[borrowerDataIndex],
			...borrowerMilitary[borrowerDataIndex],
			...borrowerDemographicProvided[borrowerDataIndex],
			...borrowerDemographicInfo[borrowerDataIndex],
		};

		console.log("205 borrower data:", borrowerData);

		let coBorrowerData = {
			...borrowerPairData[coBorrowerDataIndex],
			...borrowerDeclaration[coBorrowerDataIndex],
			...borrowerMilitary[coBorrowerDataIndex],
			...borrowerDemographicProvided[coBorrowerDataIndex],
			...borrowerDemographicInfo[coBorrowerDataIndex],
		};

		console.log("215 coBorrower data:", coBorrowerData);

		console.log("1700 index:", index);

		let applications = [
			{
				borrower: borrowerData,
				coborrower: coBorrowerData,
			},
		];

		const updateLoanBroker = await updateBorrowerPair({
			loanGuid,
			loanData: {
				id: borrowerResponseData[index].borrowerPairId,
				applicationId: borrowerResponseData[index].borrowerPairId,
				applicationIndex: index,
				...applications[0],
			},
			borrowerPairId: borrowerResponseData[index].borrowerPairId,
		}).unwrap();

		console.log("90 update broker loan:", updateLoanBroker);

		return updateLoanBroker;
	};

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
		setLoanApplicationStage(currentStage - 1);
	};

	const handleLoanUpdateAPI = async () => {
		try {
			console.log("700 loan id:", loanGuid);
			console.log("700 borrowerResponseData:", borrowerResponseData);
			console.log("700 borrowerPairData:", borrowerPairData);

			for (let index = 0; index < borrowerResponseData.length; index++) {
				const element = borrowerResponseData[index];
				console.log("390 el:", element);

				let updateResponse = await updateBorrowerPairFunc(index);

				console.log("393 updateResponse:", updateResponse);

				if (
					index === borrowerResponseData.length - 1 &&
					updateResponse.message.includes("success")
				) {
					console.log("395 update borrower pair");
					window.scrollTo({
						top: 0,
						left: 0,
						behavior: "smooth",
					});
					setLoanApplicationStage(currentStage + 1);
				}
			}
		} catch (err) {
			console.log("95 err:", err);
			setError(err?.data?.details);
		}
	};

	const handleNext = async () => {
		if (
			validateDeclarationInformation(borrowerPairData, borrowerDeclaration) ||
			validateMilitaryInformation(borrowerPairData, borrowerMilitary) ||
			validateDemographicProvided(
				borrowerPairData,
				borrowerDemographicProvided
			) ||
			validateDemographicInformation(borrowerPairData, borrowerDemographicInfo)
		) {
			setError("Please provide a value for all fields highlighted above.");
			return;
		}

		console.log("450 pairData:", borrowerPairData);

		console.log("450 declarationData:", borrowerDeclaration);
		console.log("450 militaryData:", borrowerMilitary);
		console.log("450 demographicProvidedData:", borrowerDemographicProvided);
		console.log("450 demographicInfoData:", borrowerDemographicInfo);

		handleLoanUpdateAPI();
	};

	const handleNextView = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	console.log("525 borrower pair:", borrowerPairData);

	const renderTabs = () => {
		let arr = [];
		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			arr.push(
				<Tab
					className={styles.tabBtnContainer}
					label={
						<Stack direction="row" className={styles.tabLabelContainer}>
							<Heading6
								text={`Borrower Pair ${i}`}
								fontType="semibold"
								extraClass={
									tabValue === i - 1 ? styles.activeTabText : styles.tabText
								}
							/>
						</Stack>
					}
					{...a11yProps(i - 1)}
				/>
			);
		}
		return arr;
	};

	const informationViewElements = (othersStartIndex, i) => {
		return (
			<Stack>
				{viewLoan && (
					<ViewLoanInfoCard
						borrower={borrowerPairData[0]}
						coBorrower={borrowerPairData[1]}
					/>
				)}

				{/* declaration info */}
				{borrowerDeclaration
					?.slice(othersStartIndex, othersStartIndex + 2)
					?.map(
						(declaration, index) =>
							index ===
								borrowerDeclaration?.slice(
									othersStartIndex,
									othersStartIndex + 2
								)?.length -
									1 && (
								<DeclarationInfo
									key={index}
									declarationIndex={index + othersStartIndex}
									coBorrower={index % 2 !== 0}
									coBorrowerName={borrowerPairData[2 * (i - 1) + 1]?.firstName}
									declarationData={declaration}
									data={borrowerDeclaration}
									setData={setBorrowerDeclaration}
									error={error.includes("provide a value")}
									disabled={viewLoan}
								/>
							)
					)}

				{/* military */}
				{borrowerMilitary
					?.slice(othersStartIndex, othersStartIndex + 2)
					?.map((military, index) => (
						<MilitaryInfo
							key={index}
							militaryIndex={index + othersStartIndex}
							coBorrower={index % 2 !== 0}
							coBorrowerName={borrowerPairData[2 * (i - 1) + 1]?.firstName}
							militaryData={military}
							data={borrowerMilitary}
							setData={setBorrowerMilitary}
							error={error.includes("provide a value")}
							disabled={viewLoan}
						/>
					))}

				{/* demographic provided info */}
				{borrowerDemographicProvided
					?.slice(othersStartIndex, othersStartIndex + 2)
					?.map((demographic, index) => (
						<DemographicProvidedInfo
							key={index}
							demographicIndex={index + othersStartIndex}
							coBorrower={index % 2 !== 0}
							coBorrowerName={borrowerPairData[2 * (i - 1) + 1]?.firstName}
							demographicData={demographic}
							data={borrowerDemographicProvided}
							setData={setBorrowerDemographicProvided}
							error={error.includes("provide a value")}
							disabled={viewLoan}
						/>
					))}

				{/* demographic info */}
				{borrowerDemographicInfo
					?.slice(othersStartIndex, othersStartIndex + 2)
					?.map((demographic, index) => (
						<DemographicInfo
							key={index}
							demographicIndex={index + othersStartIndex}
							coBorrower={index % 2 !== 0}
							coBorrowerName={borrowerPairData[2 * (i - 1) + 1]?.firstName}
							demographicData={demographic}
							data={borrowerDemographicInfo}
							setData={setBorrowerDemographicInfo}
							error={error.includes("provide a value")}
							disabled={viewLoan}
						/>
					))}
			</Stack>
		);
	};

	const renderInformation = () => {
		let arr = [];
		for (let i = 1; i <= borrowerPairData.length / 2; i++) {
			// i is initialized to 1 instead of 0 so that we can it helps in thinking about borrower pair number.
			let othersStartIndex = 2 * (i - 1); // where data for a pair in in factor of 2

			if (borrowerPairData.length <= 2) {
				arr.push(
					<Box className={styles.tabPanelContainer}>
						{informationViewElements(othersStartIndex, i)}
					</Box>
				);
			} else {
				arr.push(
					<TabPanel
						value={tabValue}
						index={i - 1}
						className={tabValue === i - 1 ? styles.tabPanelContainer : ""}
					>
						{informationViewElements(othersStartIndex, i)}
					</TabPanel>
				);
			}
		}
		return arr;
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				{borrowerPairData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="basic tabs example"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						{renderTabs()}
					</Tabs>
				)}

				{renderInformation()}
			</Stack>

			{error !== "" && (
				<RequiredError
					headerText={error.includes("Loan is locked") ? "" : "Missing fields"}
					text={
						error.includes("Loan is locked")
							? "This loan is currently being edited by Logan Finance. Once Logan Finance exits the loan, you will be able to navigate the loan in the system and the data displayed will be updated as needed."
							: error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value") &&
							  "Please provide a value for all fields highlighted above."
					}
				/>
			)}

			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text={viewLoan ? "Continue" : "Save and Continue"}
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={() => (viewLoan ? handleNextView() : handleNext())}
				/>
			</Stack>

			{/* modals */}
			<LoaderModal open={loadingBorrowerPairData || updatingBorrowerPair} />
		</Stack>
	);
};

export default LoanDemographicApplication;
