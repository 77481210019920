import { apiSlice } from "../../app/api/apiSlice";

export const preliminaryConditionApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		preliminaryCondition: builder.mutation({
			query: (credentials) => ({
				url: `/api/v1/loan/get_conditions/${credentials?.loanGuid}/preliminary`,
				method: "GET",
			}),
		}),
	}),
});

export const { usePreliminaryConditionMutation } = preliminaryConditionApiSlice;
