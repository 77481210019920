import React from "react";

// mui icons
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";

// mui components
import { Stack, Divider, Box } from "@mui/material";

// custom components
import Heading6 from "../typography/heading-06";
import Body2 from "../typography/body-02";

// custom styles
import styles from "./index.module.scss";

const LoanProgress = ({
	loanSteps,
	currentStep,
	clickNavigation,
	setLoanApplicationStage,
}) => {
	return (
		<Stack direction="column" className={styles.loanProgressContainer}>
			<Heading6 text="Application Progress" fontType="semibold" />

			<Stack direction="column" className={styles.loanStepContainer}>
				{loanSteps?.map((item, index) => (
					<Box key={index}>
						<Stack direction="row" className={styles.loanStepDiv}>
							{currentStep > index ? (
								<CircleIcon className={styles.enabledLoanStepDot} />
							) : (
								<CircleOutlinedIcon
									className={
										currentStep >= index
											? styles.enabledLoanStepDot
											: styles.loanStepDot
									}
								/>
							)}

							<Body2
								text={item}
								extraClass={
									currentStep >= index
										? styles.enabledLoanStepText
										: styles.loanStepText
								}
								isLink={clickNavigation && currentStep !== index}
								handleClick={() =>
									clickNavigation && setLoanApplicationStage(index)
								}
							/>
						</Stack>
						<Box className={styles.dividerContainer}>
							{index + 1 !== loanSteps?.length && (
								<Divider
									orientation="vertical"
									className={
										currentStep > index
											? styles.enabledLoanStepDivider
											: styles.loanStepDivider
									}
								/>
							)}
						</Box>
					</Box>
				))}
			</Stack>
		</Stack>
	);
};

export default LoanProgress;
