import { apiSlice } from "../../app/api/apiSlice";

export const resourceLibraryFolderApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		resourceLibraryFolder: builder.mutation({
			query: (credentials) => ({
				url: "/api/v1/resource_document_folders/",
				method: "GET",
			}),
		}),
	}),
});

export const { useResourceLibraryFolderMutation } =
	resourceLibraryFolderApiSlice;
