import { createSlice } from "@reduxjs/toolkit";

const impersonatedUserSlice = createSlice({
	name: "impersonatedUser",
	initialState: { user: null },
	reducers: {
		setImpersonatedUserReducer: (state, action) => {
			const data = action.payload;
			state.user = data;
		},
	},
});

export const { setImpersonatedUserReducer } = impersonatedUserSlice.actions;

export default impersonatedUserSlice.reducer;

export const selectImpersonatedUser = (state) => state.impersonatedUser.user;
