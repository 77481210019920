import React from "react";

// mui components
import { IconButton, TableCell, TableRow } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import SecondarySelect from "../../custom-select/secondary-select";
import MaskedInput from "../../custom-input/masked-input";

// helper utils
import {
	ownerOptions,
	otherLiabilitiesTypeOptions,
} from "../../../utils/select-options";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";

// custom styles
import styles from "../index.module.scss";

const OtherLiabilitiesInformationRow = ({
	row,
	index,
	handleRemoveRow,
	handleUpdate,
	disableRow,
}) => {
	return (
		<TableRow hover tabIndex={-1} key={row.label}>
			<TableCell>
				<IconButton
					id={`${index}-other-liability-delete-btn`}
					className={styles.deleteIconBtn}
					onClick={() => handleRemoveRow(index, row?.vodIndex)}
					disabled={disableRow}
				>
					<DeleteOutlined className={styles.deleteIcon} />
				</IconButton>
			</TableCell>

			{/* asset owner */}
			<TableCell>
				<SecondarySelect
					id={`${index}-other-liability-owner`}
					value={row?.borrowerType || ""}
					handleChange={(event) =>
						handleUpdate(index, "borrowerType", event.target.value)
					}
					options={ownerOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* type */}
			<TableCell>
				<SecondarySelect
					id={`${index}-other-liability-type`}
					value={row?.liabilityOrExpenseType || ""}
					handleChange={(event) =>
						handleUpdate(index, "liabilityOrExpenseType", event.target.value)
					}
					options={otherLiabilitiesTypeOptions}
					displayEmpty={true}
					disabled={disableRow}
				/>
			</TableCell>

			{/* description */}
			<TableCell>
				<OutlinedTextInput
					id={`${index}-other-liability-description`}
					type="text"
					value={row?.otherDescription || ""}
					handleChange={(event) =>
						handleUpdate(index, "otherDescription", event.target.value)
					}
					disabled={disableRow || row?.liabilityOrExpenseType !== "Other"}
				/>
			</TableCell>

			{/* Cash or Market value */}
			<TableCell>
				<MaskedInput
					id={`${index}-other-liability-market-value`}
					placeholder="$"
					type="currency"
					value={row?.monthlyPayment || ""}
					onInputChange={(value) =>
						disableRow !== true && handleUpdate(index, "monthlyPayment", value)
					}
					disabled={disableRow}
				/>
			</TableCell>
		</TableRow>
	);
};

export default OtherLiabilitiesInformationRow;
