import React from "react";

// mui components
import { Box, InputLabel, Modal, Stack } from "@mui/material";

// custom components
import OutlinedTextInput from "../../custom-input/outlined-text-input";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading5 from "../../typography/heading-05";
import Heading6 from "../../typography/heading-06";

// custom styles
import styles from "./index.module.scss";

export default function UpdatePasswordModal({
	open,
	password,
	setPassword,
	confirmPassword,
	setConfirmPassword,
	modalError,
	handleClose,
	handleSubmit,
}) {
	return (
		<Modal
			open={open}
			aria-labelledby="update password modal"
			aria-describedby="update password modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Stack direction="column" className={styles.inputContainer}>
					<InputLabel htmlFor="input-new-password">
						<Heading6
							text="Enter New Password"
							fontType="semibold"
							extraClass={styles.inputHeader}
						/>
					</InputLabel>

					<OutlinedTextInput
						id="input-new-password"
						placeholder="Enter New Password"
						type="text"
						fullWidth={true}
						value={password || ""}
						handleChange={(e) => setPassword(e.target.value)}
					/>
				</Stack>
				<Stack direction="column" className={styles.inputContainer}>
					<InputLabel htmlFor="input-confirm-password">
						<Heading6
							text="Confirm New Password"
							fontType="semibold"
							extraClass={styles.inputHeader}
						/>
					</InputLabel>

					<OutlinedTextInput
						id="input-confirm-password"
						placeholder="Confirm New Password"
						type="text"
						fullWidth={true}
						value={confirmPassword || ""}
						handleChange={(e) => setConfirmPassword(e.target.value)}
					/>
				</Stack>

				{/* error text */}
				{modalError !== "" && (
					<Box className={styles.inputContainer}>
						<Heading5 text={modalError} fontType="semibold" error={true} />
					</Box>
				)}

				<PrimaryButton text="Submit" onClick={handleSubmit} fullWidth={true} />

				<SecondaryButton
					text="Cancel"
					onClick={handleClose}
					fullWidth={true}
					extraClass={styles.closeBtn}
					extraTextClass={styles.closeBtnText}
				/>
			</Stack>
		</Modal>
	);
}
