import React from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

// mui components
import { IconButton, Stack, TableCell, TableRow } from "@mui/material";

// custom components
import Body2 from "../../typography/body-02";

// reducer slice
import { selectUserDetails } from "../../../features/auth/authSlice";

// mui icons
import { DeleteOutlined } from "@mui/icons-material";
import FolderIcon from "@mui/icons-material/Folder";

// custom styles
import styles from "../index.module.scss";

const ResourceFolderRow = ({
	row,
	index,
	handleFolderClick,
	handleFolderDelete,
}) => {
	const userDetails = useSelector(selectUserDetails);

	return (
		<TableRow hover tabIndex={-1} key={row.id}>
			{/* folder name */}
			<TableCell>
				<Stack direction="row" className={styles.folderNameContainer}>
					<FolderIcon className={styles.folderIcon} />
					<Body2
						text={row?.folder_name}
						isLink={true}
						handleClick={() => handleFolderClick(row?.folder_name)}
					/>
				</Stack>
			</TableCell>

			{/* folder type */}
			<TableCell>
				<Body2 text="Folder" />
			</TableCell>

			{/* folder created date */}
			<TableCell>
				<Body2
					text={DateTime.fromISO(row?.created_date, {
						zone: "America/New_York",
					}).toFormat("MM/dd/yyyy")}
				/>
			</TableCell>

			{/* folder actions */}
			{userDetails?.user_roles?.includes("admin") && (
				<TableCell>
					<Stack direction="row" className={styles.actionsContainer}>
						<IconButton
							className={styles.iconBtn}
							onClick={() => handleFolderDelete(row?.folder_name, index)}
						>
							<DeleteOutlined className={styles.deleteIcon} />
						</IconButton>
					</Stack>
				</TableCell>
			)}
		</TableRow>
	);
};

export default ResourceFolderRow;
