import React from "react";

// mui components
import { Modal, Stack, Box } from "@mui/material";

// custom components
import Heading2 from "../../typography/heading-02";

// custom animations
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/animations/loading-files.json";

// custom styles
import styles from "./index.module.scss";
import Body1 from "../../typography/body-01";

export default function LoadingDataModal({ open, text }) {
	return (
		<Modal
			open={open}
			aria-labelledby="loading modal"
			aria-describedby="loading modal"
			disableAutoFocus={true}
			disableEscapeKeyDown={true}
		>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				className={styles.modalBodyContainer}
			>
				<Box className={styles.animationContainer}>
					<Lottie animationData={loadingAnimation} loop={true} />
				</Box>

				<Stack
					direction="column"
					alignItems="center"
					className={styles.textContainer}
				>
					<Heading2
						text="Almost there..."
						extraClass={styles.processingText}
						fontType="semibold"
					/>

					<Body1 text={text} extraClass={styles.processingSubText} />
				</Stack>
			</Stack>
		</Modal>
	);
}
