import React, { useEffect, useState } from "react";

// mui components
import { Box, IconButton, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import CustomDivider from "../../custom-divider";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// mui icons
import { AddOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";
import MaskedInput from "../../custom-input/masked-input";

const OtherAssetsInformation = ({
	assetIndex,
	assetData,
	data,
	setData,
	disabled,
}) => {
	const [assetRows, setAssetRows] = useState([]);
	const [total, setTotal] = useState("0");

	const assetInfoColumns = [
		{ id: "index", label: "", minWidth: 48 },
		{ id: "owner", label: "Owner", minWidth: 165 },
		{ id: "asset-type", label: "Type", minWidth: 165 },
		{ id: "asset-description", label: "Description", minWidth: 165 },
		{ id: "cash-market-value", label: "Cash or Market value", minWidth: 200 },
	];

	const handleTotal = (totalData) => {
		const marketValueTotal = totalData
			.map((item) =>
				parseInt(
					item.cashOrMarketValue !== undefined && item.cashOrMarketValue !== ""
						? item.cashOrMarketValue
						: 0
				)
			)
			.reduce((prev, next) => prev + next);

		setTotal(marketValueTotal.toString());
	};

	useEffect(() => {
		if (assetData !== undefined) {
			setAssetRows(assetData);

			if (assetData?.length > 0) {
				handleTotal(assetData);
			}
		}
	}, [assetData]);

	const handleAddRow = () => {
		setData(
			data.map((data, index) => {
				if (assetIndex === index) {
					return [
						...data,
						{
							isVod: "true",
							vodIndex:
								assetData === undefined || assetData?.length === 0
									? 1
									: data?.length + 1,
						},
					];
				} else {
					return data;
				}
			})
		);
	};

	const handleDeleteRow = (rowIndex, vodIndex) => {
		setData(
			data.map((data, index) => {
				if (assetIndex === index) {
					return assetData
						?.filter((row, i) => rowIndex !== i)
						.map((row) =>
							row.vodIndex > vodIndex
								? { ...row, vodIndex: row.vodIndex - 1 }
								: row
						);
				} else {
					return data;
				}
			})
		);
	};

	const handleChangeRow = (rowIndex, field, value) => {
		console.log("63 row:", rowIndex, field, value);

		setAssetRows(() =>
			assetRows?.map((obj, i) => {
				if (i === rowIndex) {
					return { ...obj, [field]: value };
				}

				return obj;
			})
		);

		setData(
			data.map((data, index) => {
				if (assetIndex === index) {
					let updatedData = assetData?.map((obj, i) => {
						if (i === rowIndex) {
							return { ...obj, [field]: value };
						}
						return obj;
					});
					return updatedData;
				} else {
					return data;
				}
			})
		);
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Other Assets" fontType="semibold" />

				<Body3 text="Provide details about the other assets" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* other assets information table  */}
					<CustomTable
						tableType="other-assets-information"
						columnData={assetInfoColumns}
						rowData={assetRows}
						handleRemoveRow={handleDeleteRow}
						handleUpdate={handleChangeRow}
						disableRow={disabled}
					/>

					<Box className={styles.addIconContainer}>
						<IconButton
							id="add-other-asset-btn"
							className={styles.addIconBtn}
							onClick={() => handleAddRow()}
							disabled={disabled || assetRows?.length >= 25}
						>
							<AddOutlined className={styles.addIcon} />
						</IconButton>
					</Box>

					<Stack className={styles.totalContainer}>
						<Body3 text="Other assets total" extraClass={styles.totalText} />
						<MaskedInput
							placeholder="$"
							type="currency"
							value={total}
							onInputChange={(value) => console.log("315 value:", value)}
							disabled={true}
						/>
					</Stack>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default OtherAssetsInformation;
