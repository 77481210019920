import React, { useEffect, useState } from "react";

// mui components
import { Box, IconButton, Stack } from "@mui/material";

// custom components
import CustomTable from "../../custom-table";
import CustomDivider from "../../custom-divider";
import MaskedInput from "../../custom-input/masked-input";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";

// mui icons
import { AddOutlined } from "@mui/icons-material";

// custom styles
import styles from "./index.module.scss";

const LiabilitesInformation = ({
	liabilityIndex,
	liabilityData,
	data,
	setData,
	disabled,
	isSubmitted,
}) => {
	const [total, setTotal] = useState("0");

	const liabilityInfoColumns = [
		{ id: "index", label: "", minWidth: 48 },
		{ id: "account-owner", label: "Account Owner", minWidth: 165 },
		{ id: "account-type", label: "Account Type", minWidth: 165 },
		{
			id: "company-name",
			label: "Company Name",
			minWidth: 310,
		},
		{ id: "account-number", label: "Account Number", minWidth: 310 },
		{ id: "unpaid-balance", label: "Unpaid Balance", minWidth: 120 },
		{ id: "paid-off", label: "Paid Off", minWidth: 120, align: "center" },
		{ id: "monthly-amount", label: "Monthly Amount", minWidth: 120 },
		{
			id: "re-owned",
			label: "Included in RE owned",
			minWidth: 210,
			align: "center",
		},
		{ id: "exclude-dti", label: "Exclude DTI", minWidth: 120 },
		{ id: "resubordination", label: "Resubordinate Indicator", minWidth: 210 },
		{
			id: "bank-liability-considered",
			label: "Bank Liabilities Considered in Exposure",
			minWidth: 310,
		},
		{
			id: "current-lien-position",
			label: "Current Lien Position",
			minWidth: 210,
		},
		{
			id: "proposed-lien-position",
			label: "Proposed Lien Position",
			minWidth: 210,
		},
	];

	const handleTotal = (totalData) => {
		const marketValueTotal = totalData
			.map((item) =>
				parseInt(
					item.unpaidBalanceAmount !== undefined &&
						item.unpaidBalanceAmount !== ""
						? item.unpaidBalanceAmount
						: 0
				)
			)
			.reduce((prev, next) => prev + next);

		setTotal(marketValueTotal.toString());
	};

	useEffect(() => {
		if (
			data[liabilityIndex] !== undefined &&
			data[liabilityIndex]?.length > 0
		) {
			console.log("83 data:", data);

			handleTotal(data[liabilityIndex]);
		}
	}, [data]);

	const handleAddRow = () => {
		setData(
			data.map((data, index) => {
				if (liabilityIndex === index) {
					return [
						...data,
						{
							volIndex:
								data === undefined || data?.length === 0 ? 1 : data?.length + 1,
						},
					];
				} else {
					return data;
				}
			})
		);
	};

	const handleDeleteRow = (rowIndex, volIndex) => {
		setData(
			data.map((data, index) => {
				if (liabilityIndex === index) {
					return liabilityData
						?.filter((row, i) => rowIndex !== i)
						.map((row) =>
							row.volIndex > volIndex
								? { ...row, volIndex: row.volIndex - 1 }
								: row
						);
				} else {
					return data;
				}
			})
		);
	};

	const handleChangeRow = (rowIndex, field, value) => {
		setData(
			data.map((data, index) => {
				if (liabilityIndex === index) {
					let updatedData = liabilityData?.map((obj, i) => {
						if (i === rowIndex) {
							return { ...obj, [field]: value };
						}
						return obj;
					});
					return updatedData;
				} else {
					return data;
				}
			})
		);
	};

	return (
		<Box className={styles.cardContainer}>
			<Stack direction="column" className={styles.cardHeaderContainer}>
				<Heading5 text="Liabilities" fontType="semibold" />

				<Body3 text="Provide details about the liabilities" />
			</Stack>
			<Stack direction="column" className={styles.formSectionContainer}>
				<Stack direction="row" className={styles.inputSectionContainer}>
					{/* liabilities information table  */}
					<CustomTable
						tableType="liabilities-information"
						columnData={liabilityInfoColumns}
						rowData={liabilityData}
						handleRemoveRow={handleDeleteRow}
						handleUpdate={handleChangeRow}
						disableRow={disabled}
						isSubmitted={isSubmitted}
					/>

					<Box className={styles.addIconContainer}>
						<IconButton
							id="add-liabilities-btn"
							className={styles.addIconBtn}
							onClick={() => handleAddRow()}
							disabled={disabled || liabilityData?.length >= 25}
						>
							<AddOutlined className={styles.addIcon} />
						</IconButton>
					</Box>

					<Stack className={styles.totalContainer}>
						<Body3 text="Liabilities total" extraClass={styles.totalText} />
						<MaskedInput
							placeholder="$"
							type="currency"
							value={total}
							onInputChange={(value) => console.log("315 value:", value)}
							disabled={true}
						/>
					</Stack>
				</Stack>
			</Stack>

			<CustomDivider />
		</Box>
	);
};

export default LiabilitesInformation;
